import { CLabel } from "@coreui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Cloud } from "../../assets/icons/upload_cloud_icon.svg";

type Props = {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  path:string;
};
export const BulkPatientForm = ({ setOpen, path }: Props) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
      }}
    >
      <div className="bulk-patient-form">
        <div className="d-flex justify-content-center pt-[30px]">
          <Cloud stroke="#4D5761" />
        </div>

        <div className="d-flex justify-content-center mt-3">
          <button title="upload-csv-patient-button" type="button">
            <div>
              <span className="cursor-pointer bulk-click-title-first-1" onClick={() => {
                setOpen && setOpen(true);
              }}>
                Click to upload
              </span>
              <span className="ml-1 bulk-click-title-second-1" > or drag and drop </span>
            </div>
          </button>
        </div>
        <div className="d-flex justify-content-center pt-[4px] bulk-click-title-second-1">
            XLX or CSV 
        </div>

        <button
          type="button"
          onClick={() => {
            navigate(path)
          }}
          className="h-[35px] w-[131px] mt-[12px] ml-[16%] rounded-[6px] flex items-center place-content-center save_btn"
        >
          Add new patient
        </button>
      </div>
    </div>
  );
};
