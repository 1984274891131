import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as dateFns from "date-fns";
import moment from "moment";
import React, { useEffect } from "react";
import "tippy.js/dist/tippy.css";
import { InitialProjectedState } from "../../../Controls/Calendar";
import { getProjectedCertifications } from "../../../helpers/fetchPatientData";
import { TProjected } from "../../../Services/Interfaces/Interfaces";
import "./styles/Calendar.css";



var _ = require("lodash");

interface ICalProps {
  id: number | string;
  selectedMonth: Date;
  changeFlag: boolean;
  hiddenArrow?:boolean;
  clinicianId:number;
}



const FrequencyCalendarAID = (props: ICalProps) => {
  const [state, setMyState] = React.useState<{ id: number | string, selectedMonth: Date }>({ id: props.id, selectedMonth: props.selectedMonth });
  const [projectedData, setProjectedData] = React.useState<TProjected>(InitialProjectedState);

  useEffect(() => {
    const iniDay = moment(state.selectedMonth).startOf("month").toISOString();
    const endDay = moment(state.selectedMonth).endOf("month").toISOString();

    const myId = props.id ? (props.id as number) : 0;
    const myClinicitanId = props.clinicianId ? (props.clinicianId as number) : 0;

    if (myId !== 0 && myClinicitanId !== 0) {

      getProjectedCertifications(iniDay, endDay, myId.toString(),myClinicitanId.toString()).then((res: any) => {
        setProjectedData(res.data);
      });
    }

  }, [state.selectedMonth, props.changeFlag]);

  const renderHeader = () => {
    const label = dateFns.format(state.selectedMonth, "MMM yyyy");

    return props.hiddenArrow ? (
      <div className="grow flex flex-row p-[2px] max-h-[40px] min-h-[40px] content-center border-b">
        <div className="grow month-label noselect">{label}</div>
      </div>
    ) : (
      <div className="grow flex flex-row p-[2px] max-h-[40px] min-h-[40px] content-center border-b">
        <FontAwesomeIcon
          icon={faAngleLeft}
          size="1x"
          color="#1F2A37"
          className="w-[16px] h-[16px] cursor-pointer"
          onClick={prevMonth}
        />
        <div className="grow month-label noselect">{label}</div>
        <FontAwesomeIcon
          icon={faAngleRight}
          size="1x"
          color="#1F2A37"
          className="w-[16px] h-[16px] cursor-pointer"
          onClick={nextMonth}
        />
      </div>
    );
  };

  const renderDays = () => {
    const startDate = dateFns.startOfWeek(state.selectedMonth);
    const days = [];

    for (let i = 0; i < 7; i++) {
      const label = dateFns.format(dateFns.addDays(startDate, i), "E")[0];
      days.push(
        <div key={i} className="content-center">
          {label}
        </div>
      );
    }

    return (
      <div className="grow grid grid-cols-7 max-h-[39px] min-h-[39px] py-[1px] days noselect">
        {days}
      </div>
    );
  };

  function renderCells() {
    const { selectedMonth } = state;

    const monthStart = dateFns.startOfMonth(selectedMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);
    const rows = [];
    let days = [];
    let day = startDate;

    const eoc = moment(projectedData.eoc).toDate();
    const recertRangeIni = moment(eoc).add(-4, "days").add(200, "ms").toDate();
    const recertRangeIniStrict = moment(eoc)
      .add(-5, "days")
      .add(200, "ms")
      .toDate();

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const formattedDate = dateFns.format(day, "d");
        const cloneDay = day;
        let outerClass = "content-center calendar-td";
        let innerClass = "";

        if (cloneDay < monthStart || cloneDay > monthEnd)
          outerClass += " outside-month";
        else {
          const interval = projectedData.frequenciesRange.find((x) => {
            const ini = new Date(x.iniDay);
            const end = new Date(x.endDay);
            return ini <= cloneDay && end >= cloneDay;
          });

          let adminIs = false;
          let evaluationIs = false;


          if(projectedData?.admission !== undefined){
            const admin = new Date(projectedData?.admission);
            if(admin){
               adminIs = dateFns.isSameDay(cloneDay, admin);
              if (adminIs){
                innerClass += " frecs-addmission";
              }
            }
            
          }
          if(projectedData?.evaluation !== undefined){
            const evaluation = new Date(projectedData?.evaluation);
            if(evaluation){

               evaluationIs = dateFns.isSameDay(cloneDay, evaluation);
              if (evaluationIs){
                innerClass += " frecs-evaluation";
              }
            }
          }

          if (interval) {
            outerClass += " frecs-range";

            if (cloneDay >= recertRangeIni && cloneDay <= eoc)
              outerClass += " recert";

            // const admin = new Date(projectedData.admission);
            // const adminIs = dateFns.isSameDay(cloneDay, admin);
            // if (adminIs) innerClass += " frecs-addmission";
            // TODO Why backgroundColor is not used

            let adminIs = false;
            let evaluationIs = false;

            if(projectedData?.admission !== undefined){
              const admin = new Date(projectedData?.admission);
              if(admin){
                 adminIs = dateFns.isSameDay(cloneDay, admin);
                if (adminIs){
                  innerClass += " frecs-addmission";
                  // backgroundColor =bgAdmission  
                }
              }
            }



            if(projectedData?.evaluation !== undefined){
              const evaluation = new Date(projectedData?.evaluation);
              if(evaluation){
                 evaluationIs = dateFns.isSameDay(cloneDay, evaluation);
                if (evaluationIs){
                  innerClass += " frecs-evaluation";
                  // backgroundColor =bgEvaluation  
                }
              }
            }

            const dre30Id = projectedData.visit30dre.find((x) => {
              const d30 = new Date(x);
              return dateFns.isSameDay(cloneDay, d30);
            });
            if (dre30Id) innerClass += " frecs-30";

            if (cloneDay >= recertRangeIniStrict && cloneDay < recertRangeIni)
              innerClass +=
                " ini-recert" + (i === 6 ? " end-recert-range" : "");

            const ini = new Date(interval.iniDay);
            const end = new Date(interval.endDay);
            if (i === 0 || dateFns.isSameDay(cloneDay, ini))
              outerClass += " initial";
            if (i === 6 || dateFns.isSameDay(cloneDay, end)) {
              outerClass += " final";
              innerClass += " force-end";
            }
          }
        }

        days.push(
          <div key={day.toString()} className={outerClass}>
            <div className={innerClass}>{formattedDate}</div>
          </div>
        );

        day = dateFns.addDays(day, 1);
      }

      rows.push(
        <div
          key={day.toString()}
          className="grow grid grid-cols-7 py-[1px] max-h-[39px] min-h-[39px]"
        >
          {days}
        </div>
      );

      days = [];
    }
    return (
      <div className="grow shrink flex flex-col justify-items-center noselect">
        {rows}
      </div>
    );
  }

  function nextMonth() {
    state.selectedMonth = dateFns.addMonths(state.selectedMonth, 1);
    setMyState({ ...state });
  }

  function prevMonth() {
    state.selectedMonth = dateFns.subMonths(state.selectedMonth, 1);
    setMyState({ ...state });
  }

  return (
    <div className="flex flex-col place-content-stretch gap-[5px] calendar-frecs">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};

export default FrequencyCalendarAID;
