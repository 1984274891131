import { createAsyncThunk } from "@reduxjs/toolkit";
import { userLogin, userLoginSocial, userRecover, userSignUp, userSignUpSocial, userLinkSocial } from "../../../api/authApi";
import { IAuth, IAuthSocial, IPwdRecovery } from "../../../interfaces/IUser";

export const authLogin = createAsyncThunk(
  "auth/login",
  async (user: IAuth, thunkAPI) => {
    const { data } = await userLogin(user);
    if (data.status !== 200 || !data.data) {
      return thunkAPI.rejectWithValue(data.message);
    }
    return data.data;
  }
);

export const authSignup = createAsyncThunk(
  "auth/signup",
  async (user: IAuth, thunkAPI) => {
    const { data } = await userSignUp(user);
    if (data.status !== 200) {
      return thunkAPI.rejectWithValue(data.message);
    }
    return data.data;
  }
);
export const authLoginSocial = createAsyncThunk(
  "auth/login-social",
  async (user: IAuthSocial, thunkAPI) => {
    const { data } = await userLoginSocial(user);
    if (data.status !== 200 || !data.data) {
      return thunkAPI.rejectWithValue(data.message);
    }
    return data.data;
  }
);

export const authSignupSocial = createAsyncThunk(
  "auth/signup-social",
  async (user: IAuthSocial, thunkAPI) => {
    const { data }:any = await userSignUpSocial(user);
    if (data.status !== 200) {
      const errorMessage:string= data.message?.response?.data?.message ?? data.message


      return thunkAPI.rejectWithValue(errorMessage ?? "Network Error");
    }
    return data.data;
  }
);
export const linkSocialAccount = createAsyncThunk(
  "auth/link-social",
  async (user: IAuthSocial, thunkAPI) => {
    const { data } = await userLinkSocial(user);
    if (data.status !== 200) {
      return thunkAPI.rejectWithValue(data.message);
    }
    return data.data;
  }
);