import React, { useState } from "react";
import { ErrorMessage, useField } from "formik";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface Props {
  label: string | JSX.Element;
  name: string;
  type?: "text" | "email" | "password";
  placeholder?: string;
  icon?: string;
  plusClass?: string;
  [x: string]: any;
}

export const MyTextInput = ({ label, ...props }: Props) => {
  const [field, meta] = useField(props);
  const [showPass, changeShowPass] = useState(false);

  const handleShow = () => {
    changeShowPass(!showPass);
  };

  return (
    <div className={`text__input`}>
      <label htmlFor={props.id || props.name}>
        {label}
        {props.span ? <span className="span-required">{props.span}</span> : ""}
      </label>
      {props.icon && (
        <i className={`${props.icon} ${meta.error && "icon__error"}`}></i>
      )}
      <input
        style={{ paddingLeft: props.icon ? "44px" : "16px" }}
        className={`text-input ${meta.error && "input__error"} ${
          !props.icon ? "pl-8" : "pl-[54px]"
        } ${props.inEdition && "input_edit"} ${
          props.plusClass ? props.plusClass : ""
        }`}
        {...field}
        {...props}
        type={
          props.type == "password"
            ? showPass
              ? "text"
              : "password"
            : props.type
        }
      />
      {props.type == "password" ? (
        <button className=" img__input_show" type="button" onClick={handleShow}>
          <FontAwesomeIcon
            className="user-eye-icon"
            icon={showPass ? faEye : faEyeSlash}
          />
        </button>
      ) : (
        ""
      )}
      {/* <ErrorMessage name={props.name} component="span" /> */}
    </div>
  );
};
