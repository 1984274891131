import React from "react";
import { faArrowRight, faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  selectIsUserError,
  setIsUserError,
} from "../../store/slices/notification/notificationSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const ErrorSubmitUser = () => {
  const isUserError = useAppSelector(selectIsUserError);
  const dispatch = useAppDispatch();
  const onClick = () => dispatch(setIsUserError(false));
  return (
    <div
      className={
        isUserError ? "modal-user-error d-block" : "modal-user-error d-none"
      }
    >
      <section className="modal-main-user-error">
        <div className="mx-3 my-4">
          <div className="d-flex justify-content-between mx-3 mb-5 mt-4">
            <div className="modal-patient-title modal-patient-title-error">
              Uh-oh!
            </div>
            <button
              title="close-patient-button"
              type="button"
              onClick={onClick}
            >
              <FontAwesomeIcon
                icon={faClose}
                className="modal-patient-button-close-success-error"
                aria-hidden="true"
              />
            </button>
          </div>
          <div className="m-3 modal-patient-body-title modal-patient-body-title-error">
            Something went wrong!
          </div>
          <div className="m-3 modal-patient-bodyDetail-title modal-patient-bodyDetail-title-error">
            Profile information could not be saved. Please try again
          </div>
          <div className="mx-3 mt-[40px] d-flex justify-content-end">
            <button
              type="button"
              onClick={onClick}
              className="modal-patient-footer-title modal-patient-footer-title-error"
            >
              Try again&nbsp;&nbsp;
            </button>
            <FontAwesomeIcon
              icon={faArrowRight}
              className="modal-patient-arrow modal-patient-arrow-error"
              aria-hidden="true"
            />
          </div>
        </div>
      </section>
    </div>
  );
};
