import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { IPatient } from "../../../Services/Interfaces";

import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getCareTeam, getPatient } from "../../../store/slices/patient/thunks";

import { LoaderComponent } from "../../../Controls/Loader";

import {
  certificationPeriod,
  DetailsCard,
  VisitHistory,
} from "../../../components/patients";

import "tippy.js/dist/tippy.css";
import CertPopup from "../../../components/patients/CertPopup";

const PatientDetail = () => {
  const { patient, loading } = useAppSelector((state) => state.patient);
  const { careTeam, ctLoading } = useAppSelector((state) => state.careTeam);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IPatient>({
    // defaultValues: initialValues,
    mode: "onBlur", // when the you blur... check for errors
  });

  //Assign the patient data to the context
  useEffect(() => {
    dispatch(getPatient(id || 0));
    dispatch(getCareTeam(id || 0));
  }, []);

  const onSubmit = () => {};
  // TODO on Submit

  if (loading === ("pending" || "idle")) {
    return <LoaderComponent />;
  }

  const handleEdit = () => {
    navigate("/patient/add/" + id);
  };

  return (
    <div className="py-6" style={{ margin: "0 40px", paddingTop: "24px" }}>
      <header style={{ display: "flex", placeContent: "space-between" }}>
        <div className="grid grid-col-1 patient_record">
          <div className="title">
            {patient.firstName} {patient.lastName}
          </div>
          <div className="subtitle-gray pt-7">
            {" "}
            {certificationPeriod(patient.currCert)}
          </div>
        </div>
        <div className="place-content-between">
          <CertPopup haveFrequencies={patient?.frequency?.length > 0}/>
          <button
            onClick={handleEdit}
            className="btn__primary-outline custom-edit-btn"
            style={{
              width: "96px",
              height: "35px",
            }}
          >
            Edit
          </button>
        </div>
      </header>
      <main className="flex">
        <div className="grow">
          <section style={{ paddingBottom: "28px" }}>
            <section
              className="grid grid-cols-3"
              style={{ paddingTop: "32px" }}
            >
              <DetailsCard
                key={"iad_0"}
                title={"Initial Admission"}
                info={{
                  data: [
                    {
                      clinitian_type: "",
                      data: patient.admission,
                      data_type: "Date",
                    },
                  ],
                }}
              />
              <DetailsCard
                key={"iad_1"}
                title={"Evaluation"}
                info={{ data: patient.evaluation }}
              />
              <DetailsCard
                key={"iad_2"}
                title={"Discharge"}
                info={{ data: patient.discharge }}
              />
            </section>
            <section
              className="grid grid-cols-3"
              style={{ paddingTop: "32px" }}
            >
              <DetailsCard
                key={"iad_4"}
                title={"Frequencies"}
                truncate={true}
                info={{ data: patient.frequency }}
              />
              <DetailsCard
                key={"iad_3"}
                title={"Most Recent 30DRE"}
                info={{ data: patient.most30DRE }}
              />
              <DetailsCard
                key={"iad_5"}
                title={"30 Day Re-Evaluation"}
                info={{ data: patient.comming30DRE }}
              />
            </section>
            <section
              className="grid grid-cols-3"
              style={{ paddingTop: "32px" }}
            >
              <DetailsCard
                key={"iad_6"}
                title={"Recert Window"}
                info={{ data: patient.recert }}
              />
              <DetailsCard
                key={"iad_7"}
                title={"End of Care"}
                info={{
                  data: [
                    {
                      clinitian_type: "",
                      data: patient.eoc,
                      data_type: "Date",
                    },
                  ],
                }}
              />
            </section>
          </section>
          <section
            className="border-t border-b mr-[5px]"
          >
            <div className="patient_record_subtitle">
              Address
              <h6> {patient.address}</h6>
            </div>
          </section>
          <section className="col-span-5 mt-8">
          <div className="patient_record_subtitle">
              Care Team
            </div>
            <section style={{paddingTop:'24px'}}>
            <div className="grid grid-cols-3 gap-y-10">
              <DetailsCard
                key="0clinitian"
                title="Team Leader"
                info={{
                  data: [
                    {
                      clinitian_type: "TL",
                      data: careTeam.teamLeaderName,
                      data_type: "Name",
                    },
                  ],
                }}
              />
              <DetailsCard
                key="1clinitian"
                title="Physical Therapy"
                info={{
                  data: [
                    {
                      clinitian_type: "PT",
                      data: careTeam.ptName,
                      data_type: "Name",
                    },
                    {
                      clinitian_type: "PTA",
                      data: careTeam.ptaName,
                      data_type: "Name",
                    },
                  ],
                }}
              />
              <DetailsCard
                key="2clinitian"
                title="Occupational Therapy"
                info={{
                  data: [
                    {
                      clinitian_type: "OT",
                      data: careTeam.otName,
                      data_type: "Name",
                    },
                    {
                      clinitian_type: "OTA",
                      data: careTeam.otaName,
                      data_type: "Name",
                    },
                  ],
                }}
              />
              <DetailsCard
                key="3clinitian"
                title="Skilled Nursing"
                info={{
                  data: [
                    {
                      clinitian_type: "SN",
                      data: careTeam.snName,
                      data_type: "Name",
                    },
                  ],
                }}
              />
              <DetailsCard
                key="4clinitian"
                title="Nursing Assistant"
                info={{
                  data: [
                    {
                      clinitian_type: "AID",
                      data: careTeam.aidName,
                      data_type: "Name",
                    },
                  ],
                }}
              />
              <DetailsCard
                key="6clinitian"
                title="Speech Therapy"
                info={{
                  data: [
                    {
                      clinitian_type: "SLP",
                      data: careTeam.slpName,
                      data_type: "Name",
                    },
                  ],
                }}
              />
              <DetailsCard
                key="7clinitian"
                title="Social Worker"
                info={{
                  data: [
                    {
                      clinitian_type: "MSW",
                      data: careTeam.mswName,
                      data_type: "Name",
                    },
                  ],
                }}
              />
            </div>
            </section>
          </section>
        </div>
        <VisitHistory />
      </main>
    </div>
  );
};

export default PatientDetail;
