import { CForm, CFormGroup, CLabel } from "@coreui/react";
import { faLightbulb } from "@fortawesome/pro-regular-svg-icons";
import { faClose, faPlayCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { ReactComponent as Cloud } from "../../assets/icons/upload_cloud_icon.svg";
import * as API from "../../Services/Api";
import * as utility from "../../Services/utility";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectIsLoading,
  setIsLoading,
} from "../../store/slices/notification/notificationSlice";
import { ErrorSuccessBulk } from "./ErrorSuccessBulk";

type Props = {
  close: () => void;
  setBanner: React.Dispatch<
    React.SetStateAction<{
      status: boolean;
      message: string;
    }>
  >;
};

export const PatientBulkModal = ({ setBanner, close }: Props) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState("");

  // const [file, setFile] = useState({ name: "", size: 0 });
  const validate = (newFile: { name: string; size: number }) => {
    if (newFile) {
      if (!/\.csv$/.test(newFile.name)) {
        setError("File type is not allowed");
        return false;
      } else if (newFile.size > 2097152) {
        setError("File size is not allowed");
        return false;
      }
      setError("");
      return true;
    } else {
      setError("This field is required.");
    }
  };
  const ShowError = () => {
    return (
      <u className="bulk-file-error d-flex justify-content-center">{error}</u>
    );
  };

  const cleanup = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
      setState({
        APIStatus: {
          InProgress: false,
          Failed: false,
          FailMessage: "",
          Failed1: false,
          FailMessage1: "",
        },
        CSV: { file: "", name: "" },
        Duplicates: [],
      });
    }
  };
  const loadFile = (newFile: any) => {
    if (newFile) {
      cleanup();
    }
    const file = {
      file: newFile,
      name: newFile.name,
    };
    setState({
      ...state,
      CSV: file,
      APIStatus: {
        ...state.APIStatus,
        FailMessage: "",
        Failed: false,
      },
    });
  };
  const [state, setState] = useState({
    APIStatus: {
      InProgress: false,
      Failed: false,
      FailMessage: "",
      Failed1: false,
      FailMessage1: "",
    },
    CSV: { file: "", name: "" },
    Duplicates: [],
  });
  const isLoading = useAppSelector(selectIsLoading);

  const UploadCSV = async () => {
    if (utility.isNullOrEmpty(state.CSV?.file)) {
      utility.ShowAlert("Please select a CSV", "error");
      return;
    }

    try {
      setState({
        ...state,
        Duplicates: [],
        APIStatus: {
          ...state.APIStatus,
          FailMessage: "",
          Failed: false,
          InProgress: true,
        },
      });
      dispatch(setIsLoading(true));
      setShowUpload(true);
      let minutesOffset: string = new Date().getTimezoneOffset().toString();

      let formdata = new FormData();
      formdata.append("file", state.CSV.file);
      formdata.append("minutesOffset", minutesOffset);

      let result = await API.PostData(
        "/PatientProfile/ImportPatientCsv",
        formdata
      );
      if (result.status === 200) {
        setState({
          ...state,
          APIStatus: {
            ...state.APIStatus,
            Failed: false,
          },
        });

        dispatch(setIsLoading(false));

        utility.ShowAlert("Patients added successfully");
      } else {
        setState({
          ...state,
          APIStatus: {
            ...state.APIStatus,
            Failed: true,
          },
        });
      }
      if (result.status === 401) {
        setBanner({
          status: true,
          message: result.message,
        });

        dispatch(setIsLoading(false));

        return;
      }
      if (result.data !== null) {
        // That is duplicates found
        setState({
          ...state,
          Duplicates: result.data,
        });

        dispatch(setIsLoading(false));

        throw result;
      }
    } catch (err: any) {
      utility.ShowAlert(err.message, "error");
      setState({
        ...state,
        APIStatus: {
          ...state.APIStatus,
          InProgress: false,
        },
      });
      dispatch(setIsLoading(false));
    } finally {
      // close();
    }
  };
  const dispatch = useAppDispatch();

  const HandleCSV = (e: any) => {
    let file = e.target.files[0];
    if (file === undefined) {
      return;
    }
    if (validate(file)) {
      loadFile(file);
    } else {
      loadFile(null);
    }
  };

  const BulkForm = (
    <section className="mt-4 pl-[16px]">
      <div className="flex place-content-start items-start">
        <div className="w-[40%] ">
          <div className="modal-patient-bulk-title-section">Bulk upload</div>
          <div className="modal-patient-bulk-subtitle mr-4">
          <span className="underline bold cursor-pointer hover:text-sky-700"
          onClick={() => API.getCSVSample()}
          >Download this template</span>, fill out, and upload this template to import your caseload into Logicly.
          </div>
        </div>

        <CForm className="w-[60%]">
          <CFormGroup className="c-form-bulk-upload">
            <div className="third-section-bulk-upload">
              <input
                className="file-upload-input"
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                multiple
                onChange={HandleCSV}
                title="upload-csv"
              />

              <div className="bulk-upload-cloud">
                <span>
                  <Cloud stroke="#39BF9E" />
                </span>
              </div>

              {state.CSV.name !== "" ? (
                <div className="d-flex justify-content-center mt-2">
                  <div className="upload-field">{state.CSV.name}</div>
                </div>
              ) : (
                <>
                  {" "}
                  <div className="d-flex justify-content-center mt-[20px]">
                    <button title="upload-csv-patient-button" type="button">
                      <div>
                        <span className="bulk-click-title-first">
                          <CLabel>Click to upload</CLabel>
                        </span>
                        <span className="bulk-click-title-second ml-1">
                          <CLabel>or drag and drop</CLabel>
                        </span>
                      </div>
                    </button>
                  </div>
                  <div className="d-flex justify-content-center pb-4">
                    <span className="bulk-click-title-footer">
                      SVG, PNG, JPG or GIF (max. 800x400px)
                    </span>
                  </div>
                </>
              )}
            </div>
          </CFormGroup>
        </CForm>
      </div>
      <ShowError />
    </section>
  );
  const UploadBulkForm = () => {
    return (
      <section className="modal-patient-bulk-upload">
        <div className="d-flex justify-content-between align-items-center">
          <div className="modal-patient-bulk-title">Bulk upload</div>
          <button title="close-patient-button" type="button" onClick={close}>
            <FontAwesomeIcon
              icon={faClose}
              className="modal-patient-button-close-bulk"
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="modal-patient-bulk-subtitle mb-5">
          Use our excel sheet to mass upload all of your patients into Logicly
        </div>
        <hr />
        {showLearn && (
          <>
            <span className="second-section-bulk-lightbulb">
              <FontAwesomeIcon icon={faLightbulb} aria-hidden="true" />
            </span>

            <section className="mt-4 second-section-bulk-upload">
              <div className="mt-3 d-flex justify-content-between">
                <div
                  className="second-section-bulk-subtitle"
                  onClick={() => API.getCSVSample()}
                >
                  Learn how to use the bulk uploader
                </div>

                <button
                  title="close-second-section-patient-button"
                  style={{ color: " #027A48" }}
                  type="button"
                  onClick={() => setShowLearn(false)}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    className="modal-patient-button-close-bulk"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div className="mt-3 d-flex justify-content-between  ">
                <div className="second-section-bulk-watch">
                  Watch this 30 seconds video to master it.
                </div>
              </div>
              <div className="mt-4 d-flex justify-content-start ml-2">
                <button
                  title="download-csv-example-patient-button"
                  type="button"
                  onClick={() => API.getCSVSample()}
                >
                  <div className="bulk-icon-play">
                    <FontAwesomeIcon icon={faPlayCircle} aria-hidden="true" />
                  </div>
                </button>
                <div className="second-section-bulk-uploader ml-2">
                  How to use bulk uploader
                </div>
              </div>
            </section>
          </>
        )}
        {BulkForm}
        <hr />
        <section>
          <div className="flex gap-4 w-full place-content-end pt-6">
            <button
              type="reset"
              className="h-[35px] px-[42px] rounded-[6px] flex items-center place-content-center cancel_btn"
              onClick={close}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={UploadCSV}
              className="h-[35px] px-[42px] rounded-[6px] flex items-center place-content-center save_btn"
            >
              Next
            </button>
          </div>
        </section>
      </section>
    );
  };
  const ShowSuccessErrrorBulk = () => {
    return state.APIStatus.Failed ? (
      <ErrorSuccessBulk
        type="error"
        title="Uh-oh!"
        bodyTitle="Something went wrong!"
        bodyDetail="We could not import your caseload. Please try again. If this issue persists, please email support@Logicly.ai"
        footerTitle="Try again"
        onClose={close}
      />
    ) : (
      <ErrorSuccessBulk
        type="success"
        title="Awesome!"
        bodyTitle="Successfully imported caseload"
        bodyDetail="Your patients and their data should now be in Logicly!"
        footerTitle="Patient list"
        isLoading={isLoading}
        onClose={close}
      />
    );
  };
  //TODO ShowUpload to true when Reza send the video
  const [showUpload, setShowUpload] = useState(false);
  const [showLearn, setShowLearn] = useState(false);
  return showUpload ? <ShowSuccessErrrorBulk /> : <UploadBulkForm />;
};
