import { useField } from "formik";
import React, { useState, useEffect } from "react";
import { MyTextInput } from "../components";
import { GOOGLE } from "../Services/utility";

interface IProps {
  address: string;
  Data: any;
  id: string;
  value?: string;
  UnsetAddress?: any;
  SetAddress?: any;
  InProgress?: boolean;
  name?: string;
  inEdition?: boolean;
  mode?: string;
}

const AddressAutoComplete = (props: IProps) => {
  let autocomplete: any;
  useEffect(() => {
    initAutocomplete();
    if (props.SetAddress) {
      props.SetAddress((address: string) => {
        let temp: any = document.getElementById(props.id);
        temp.value = address;
      });
    }
  }, []);

  const initAutocomplete = () => {
    let inputField: any = document.getElementById(props.id);
    if (inputField === null) return;
    inputField.value = props.address;

    // Create the autocomplete object, restricting the search predictions to
    // geographical location types.
    autocomplete = new GOOGLE.maps.places.Autocomplete(inputField, {});
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    autocomplete.setFields([
      "geometry",
      "place_id",
      "formatted_address",
      "vicinity",
    ]);
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    autocomplete.addListener("place_changed", fillInAddress);
  };

  const fillInAddress = () => {
    // Get the place details from the autocomplete object.
    const place = autocomplete.getPlace();

    if (props.Data !== undefined && place !== undefined) {
      props.Data({
        address: place.formatted_address,
        vicinity: place.vicinity,
        lat: place.geometry.location.lat(),
        long: place.geometry.location.lng(),
      });
    }
  };

  const geolocate = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const circle = new GOOGLE.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy,
        });
        if (autocomplete !== undefined)
          autocomplete.setBounds(circle.getBounds());
      });
    }
  };

  return (
    // TODO: Add adress format
    <div id="locationField" className="text__input">

      {props.mode && props.mode == "1" ?
        <div>
          <input
            id={props.id}
            type="text"
            placeholder="Ex: 123 Fake St, Faketown USA, 55555"
            onFocus={geolocate}
            className={`text-input pl-8`}
            onChange={props.UnsetAddress}
            autoComplete="off"
            disabled={props.InProgress}
            value={props.value}
            // label={<span>Home Address</span>}
            name={props.id}
            required
          />
        </div>
        :
        <MyTextInput
          id={props.id}
          placeholder="Ex: 123 Fake St, Faketown USA, 55555"
          autoComplete="off"
          disabled={props.InProgress}
          name={props.name ?? props.id}
          span="*"
          label={<span>Home Address</span>}
          onFocus={geolocate}
          type="text"
          value={props.address}
          onChange={(v: any)=>props.UnsetAddress(v)}
          inEdition={props.inEdition}
          plusClass=" truncate"
        // required
        />
      }

    </div>
  );
};
export default AddressAutoComplete;
