import React from 'react'



export interface IAcceptTerms {
    acceptTerms:boolean;
    setAcceptTerms : React.Dispatch<React.SetStateAction<boolean>>;
}

export const TermsConditions = ({setAcceptTerms,acceptTerms}:IAcceptTerms) => {

    const onChange = ()=> {
        setAcceptTerms(!acceptTerms)
    }
    return (
        <div className="flex justify-start align-center">
         <div className="mr-[8px] checkbox__input">
        <input
          type="checkbox"
          onChange={onChange}
        />        

    </div>
            <p className='terms-condition-label mt-[3px]'>I agree with <a href="https://www.logicly.ai/#/terms" className="font-medium text-blue-600 dark:text-blue-500 hover:underline" target="_blank" rel="noreferrer">Terms and Conditions</a> and <a href="https://www.logicly.ai/#/privacy" className="font-medium text-blue-600 dark:text-blue-500 hover:underline"  target="_blank" rel="noreferrer">Privacy Policy</a></p>

        </div>
    )
} 