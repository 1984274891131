import React, { useState, useEffect, FormEvent } from "react";
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from "@stripe/react-stripe-js";

type Props = {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
};

const CardSection = ({ handleSubmit }: Props) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="white-container payment-card-form">
        <h2>
          Payment Method
          {/* {ExistingCard && <span>Active Card: {ExistingCard}</span>} */}
        </h2>
        {/* <CardElement /> */}
        <div className="row">
          <div className="col-md-6">
            <label>
              Card Number
              <div className="form-control payment">
                <CardNumberElement
                  options={{ placeholder: "XXXX-XXXX-XXXX-XXXX" }}
                />
              </div>
            </label>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-3">
            <label>
              Expiry
              <div className="form-control payment">
                <CardExpiryElement />
              </div>
            </label>
          </div>
          <div className="col-md-3">
            <label>
              CVV/CVC
              <div className="form-control payment">
                <CardCvcElement />
              </div>
            </label>
          </div>
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <label>
              Card Owner
              <input
                type="text"
                // pattern="/[\w\d .]/"
                placeholder="Card Owner Name"
                name="CardOwner"
                className="form-control"
                // value={CardDetails.CardOwner}
                // onChange={HandleChange}
                // disabled={APIStatus.InProgress}
              />
              {/* {ShowError(Errors, "CardOwner")} */}
            </label>
          </div>
        </div>
        <div className="row buttons">
          <div className="col-md-6 text-left">
            <button type="submit" className="save" disabled={false}>
              {"SAVE"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CardSection;
