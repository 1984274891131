import React, { useState } from "react";
import Calendar from "../../Controls/Calendar";
import { useAppSelector } from "../../store/hooks";
import { ContactSection } from "./ContactSection";
import { Legend } from "./Legend";

export const VisitHistory = () => {
  const { patient } = useAppSelector((state) => state.patient);

  const [visibletab, setVisibletab] = useState<number>(1);

  return (
    <div className="flex-none w-[410px] h-[716px]">
      <div
        className="visit-history-continer"
        style={{ backgroundColor: "white" }}
      >
        <div className="flex w-full">
          <button onClick={()=>{setVisibletab(1)}} className={"patient-tab-btn first "+ (visibletab===1?"active":"")}>Visit History</button>
          <button onClick={()=>{setVisibletab(2)}} className={"patient-tab-btn second "+(visibletab===2?"active":"")}>Projected Visits</button>
        </div>
        <div className="flex flex-col place-content-between">
          <div className="flex flex-col">
            <Calendar
              minDate={new Date()}
              maxDate={new Date()}
              id={patient.id}
              historyOrProjected={visibletab===1?"history":"projected"}
              eventDates={[
                { class: "RV", date: new Date("2022-06-08T08:00:00Z") },
                { class: "RV", date: new Date("2022-06-10T04:00:00Z") },
                { class: "D", date: new Date("2022-06-11T08:15:00Z") },
                { class: "RV", date: new Date("2022-06-19T04:00:00Z") },
                { class: "RV", date: new Date("2022-06-28T04:00:00Z") },
              ]}
            />
            <div className="flex items-end">
              <Legend />
            </div>
          </div>
          <ContactSection />
        </div>
      </div>
    </div>
  );
};
