import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
interface Props {
  label: string | JSX.Element;
  name: string;
  type?: "text" | "email" | "password";
  placeholder?: string;
  icon?: IconProp;
  [x: string]: any;
  setFieldValue:(field: string, value: any, shouldValidate?: boolean | undefined) => void
}

export const UserPasswordInput = ({
  label,
  isDisabled = false,
  setFieldValue,
  ...props
}: Props) => {
  const [field, meta] = useField(props);
  const [showPass, changeShowPass] = useState(false);
  const [state, setState] = useState("");
  const [passHidden, setPassHidden] = useState("");
  const onPasswordChange = (e: { target: { value: any } }) => {
    const passwordValue = e.target.value;
    const hiddenPassValue = passHidden;
    let showLength = 1;
    let offset = passwordValue.length - hiddenPassValue.length;
    if (offset > 0) {
      setPassHidden(
        hiddenPassValue +
          passwordValue.substring(
            hiddenPassValue.length,
            hiddenPassValue.length + offset
          )
      );
    } else if (offset < 0) {
      setPassHidden(
        hiddenPassValue.substring(0, hiddenPassValue.length + offset)
      );
    }
    setState(
      passwordValue
        .substring(0, passwordValue.length - showLength)
        .replace(/./g, "•") +
        passwordValue.substring(
          passwordValue.length - showLength,
          passwordValue.length
        )
    );
  };

  const handleShow = () => {
    changeShowPass(!showPass);
  };
  useEffect(() => {
    setFieldValue(props.field, passHidden, false);
  }, [passHidden, props.field, setFieldValue]);

  useEffect(() => {
    const clearPassword = setTimeout(() => {
      setState(passHidden.replace(/./g, "•"));
    }, 1000);
    return () => clearTimeout(clearPassword);
  }, [passHidden]);

  const isError = meta.error?.length ?? 0;

  const okField = meta.value && meta.touched && isError === 0;

  const errorField = meta.touched && meta.error;

  return (
    <div className={`text__input user__input`}>
      {isDisabled ? (
        <>
          <label htmlFor={props.id || props.name}>{label}</label>

          <input
            className="input-without-border pl-8"
            {...field}
            {...props}
            disabled
          />
        </>
      ) : (
        <>
          <label htmlFor={props.id || props.name}>
            {label}
            {props.span ? (
              <span className="span-required">{props.span}</span>
            ) : (
              ""
            )}
          </label>

          {props.icon && (
            <FontAwesomeIcon
              className={`user-icon-lock-position ${
                meta.error && "icon__error"
              }`}
              icon={props.icon}
            />
          )}
          <input
            className={`text-input user-input ${errorField && "input__error"} ${
              !props.icon ? "pl-8" : "pl-[38px]"
            }  ${okField && "input-success-border"}`}
            {...field}
            {...props}
            type="text"
            value={showPass ? passHidden : state}
            onChange={onPasswordChange}
          />

          <button
            className="user-icon-position-show"
            type="button"
            onClick={handleShow}
          >
            <FontAwesomeIcon
              className="user-eye-icon"
              icon={showPass ? faEye : faEyeSlash}
            />
          </button>
        </>
      )}
    </div>
  );
};
