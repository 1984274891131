import Axios from "axios";
import {
  IVisitsAutoSchedule,
  IVIsitsResolveConflic,
  IVisitsToCreate,
  IVisitsToUpdate,
} from "../interfaces/IScheduling";

const base_url = process.env.REACT_APP_API_URL;

export const visitsToSchedule = async (
  dayIni: string,
  dayEnd: string,
  clinitianId: number
) => {
  return await Axios.get(
    base_url +
      "/ManualScheduling" +
      `/PatientVisitsToSchedule?dayIni=${dayIni}&dayEnd=${dayEnd}&clinitianId=${clinitianId}`
  );
};
export const changeVisitType = async (
  id: number,
) => {
  return await Axios.put(
    base_url +
      "/ManualScheduling" +
      `/ChangeRC?visitId=${id}`
  );
};

export const visitsScheduled = async (
  dayIni: string,
  dayEnd: string,
  clinitianId: number,
  patientId: number
) => {
  return await Axios.get(
    base_url +
      "/ManualScheduling" +
      `/ScheduledVisits?dayIni=${dayIni}&dayEnd=${dayEnd}&clinitianId=${clinitianId}&patientId=${patientId}`
  );
};
export const getDischargeRecertConflic = async (start: string, end: string) => {
  return await Axios.get(
    base_url +
      `/ManualScheduling/DischargeRecertConflict?start=${start}&end=${end}`
  );
};

export const putDischargeRecertConflic = async (
  body: IVIsitsResolveConflic
) => {
  return await Axios.put(
    base_url + `/ManualScheduling/ResolveDRConflict`,
    body
  );
};

export const visitsScheduledPatients = async (dayIni: string, dayEnd: string, clinitianId: number) => {
    return await Axios.get(
        base_url + "/ManualScheduling" +
        `/ScheduledVisitsPatients?dayIni=${dayIni}&dayEnd=${dayEnd}&clinitianId=${clinitianId}`);
};

export const visitsAdd = async (body: IVisitsToCreate) => {
    return await Axios.post(base_url + "/ManualScheduling/AddVisit", body);
};

export const visitsUpdate = (body: IVisitsToUpdate) => {
    return Axios.put(base_url + "/ManualScheduling/UpdateVisit", body);
};

export const visitsPatientGet = async (id: number) => {
    return await Axios.get(
        base_url + "/PatientProfile" +
        `/GetNotExtended?id=${id}`);
};

export const visitsDelete = async (id: number) => {
    return await Axios.delete(
        base_url + "/ManualScheduling" +
        `/DeleteVisit?id=${id}`);
};

export const visitsAutoSchedule = async (body: IVisitsAutoSchedule) => {
    return await Axios.post(
        base_url + "/AutoSchedule/AutoScheduling", body);
};

export const checkMissed30DRE = async (start: string, end: string) => {
  return await Axios.post(
    base_url +
      `/ManualScheduling/CheckMissed30DRE?dayIni=${start}&dayEnd=${end}`
  );
};

export const defaultVisitData = {
    cityName: "",
    clinitianId: 0,
    clinitianType: "_",
    collitionCount: 0,
    colorType: "E",
    distance: "-mi",
    end: "1900-08-13T05:00:00Z",
    id: 0,
    isCompleted: false,
    isLocked: false,
    patientId: 0,
    recertId: 0,
    start: "1900-08-13T04:00:00Z",
    title: "_",
};
export const defaultVisitPatientData= {
  clinitianId: 0,
  end: "1900-08-13T05:00:00Z",
  id: 0,
  isCompleted: false,
  isLocked: false,
  patientId: 0,
  recertId: 0,
  start: "1900-08-13T04:00:00Z",
  title: "_",
};
export const defaultVisitListData = {
    id: 0,
    patientId: 0,
    recertId: 0,
    clinitianId: 0,
    type: "Evaluation",
    dateType: "day",
    dateValue: "2022-09-15T04:00:00Z",
    clinitianName: "Ugo jhbhbjh",
    patientName: "testDod Dos",
    distance: "-- mi",
    city: "Denver"
};
