import axios from "axios";
import { getAllUsersWithPagination } from "../Services/Api";

export const userApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/User",
});

export const getAllUsers = async ({
  pagenumber = 1,
  pagesize = 20,
  status = "",
  query = "",
  sortField = "",
  sortDir = "u",
}) => {
  return await getAllUsersWithPagination({
    pagenumber,
    pagesize,
    status,
    query,
    sortField,
    sortDir,
  });
};