import {
  Circle,
  GoogleMap,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import map_pin from "../../assets/icons/map-pin.svg";
import { IResponseValue } from "../../interfaces";
import { GetData } from "../../Services/Api";
import { patientServices } from "../../Services/patient.services";

const containerStyle = {
  height: "200px",
  width: "322px",
  borderRadius: "6px",
};

const generateAddress = (position: any) => {
  const _houses = [];
  for (let i = 0; i < 30; i++) {
    const direction = Math.random() < 0.5 ? -2 : 2;
    _houses.push({
      lat: position.lat + Math.random() / direction,
      lng: position.lng + Math.random() / direction,
    });
  }
  return _houses;
};

const GoogleMapPatient = (props: any) => {
  const [patients, setPatients] = useState([]);
  const [agencyLocation, setAgencyLocation] = useState({ lat: 0, lng: 0 });
  const patientLocation = { lat: props.lat, lng: props.lng };
  const isEmpytPatientLocation = props.lat === 0 && props.lng === 0;

  const clusterStyles = [
    {
      height: 50,
      textColor: "#B42318",
      fontSize: 50,
      width: 50,
      url: 'data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" height="50" width="100"%3E%3Ccircle cx="25" cy="25" r="20" stroke="black" stroke-width="3" fill="green" /%3E%3C/svg%3E',
    },
    {
      height: 50,
      textColor: "#B42318",
      fontSize: 50,
      width: 50,
      url: 'data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" height="50" width="100"%3E%3Ccircle cx="25" cy="25" r="20" stroke="black" stroke-width="3" fill="red" /%3E%3C/svg%3E',
    },
    {
      height: 50,
      textColor: "#B42318",
      fontSize: 50,
      width: 50,
      url: 'data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" height="50" width="100"%3E%3Ccircle cx="25" cy="25" r="20" stroke="black" stroke-width="3" fill="blue" /%3E%3C/svg%3E',
    },
  ];
  const getNearPatients = async () => {
    const result =
      (await patientServices().getNearPatientLocations()) as IResponseValue;
    if (result.status === 200) {
      const patients = result.value.map((element: any) => {
        return { lat: element.latitude, lng: element.longitude };
      });

      setPatients(patients);
    }
  };
  useEffect(() => {
    getNearPatients();
  }, []);
  const getAgencyData = () => {
    GetData("/Agency/GetAgencySetting").then((result) => {
      setAgencyLocation({
        lat: result.data.latitude,
        lng: result.data.longitude,
      });
    });
  };

  useEffect(() => {
    getAgencyData();
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={isEmpytPatientLocation ? agencyLocation : patientLocation}
      zoom={10}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
      }}
    >
      <Marker position={agencyLocation} icon="./icons/map-pin.svg" />
      <Marker position={patientLocation} />
      {
        //TODO View in Documentation}
        /* <Circle
        onClick={(event) => {
          console.log("click");
        }}
        center={agencyLocation}
        radius={15000}
      />
      <Circle
        onClick={(event) => {
          console.log("click");
        }}
        center={agencyLocation}
        radius={30000}
      />
      <Circle
        onClick={(event) => {
          console.log("click");
        }}
        center={agencyLocation}
        radius={60000}
      /> */
      }

      <MarkerClusterer
        options={{
          averageCenter: true,
          styles: clusterStyles,
        }}
      >
        {(clusterer) => (
          <>
            {patients.map((patient: any) => (
              <Marker
                key={patient.lat}
                position={patient}
                clusterer={clusterer}
              />
            ))}
          </>
        )}
      </MarkerClusterer>
    </GoogleMap>
  );
};

export default GoogleMapPatient;
