import React from "react";

export const MyCheckboxRef = ({errors, label, name, register, ...props}: any) => {

  return (
    <div className="checkbox__input parking">
      <label>
        <input
          type="checkbox"
          {...register(name)}
          {...props}
        />
        {label}
      </label>
      {errors[name] && errors[name]}
    </div>
  );
};
