import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./slices/auth/authSlice";
import { certificationSlice } from "./slices/certification";
import { certificationAIDSlice } from "./slices/certificationAID";
import { certificationsSlice } from "./slices/certifications";
import notificationSlice from "./slices/notification/notificationSlice";
import { careTeamSlice } from "./slices/patient";
import { patientSlice } from "./slices/patient/patientSlice";
import { patientsSlice } from "./slices/patients";
import { usersSlice } from "./slices/users";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    patient: patientSlice.reducer,
    careTeam: careTeamSlice.reducer,
    patients: patientsSlice.reducer,
    certifications: certificationsSlice.reducer,
    certification: certificationSlice.reducer,
    certificationAID: certificationAIDSlice.reducer,
    users: usersSlice.reducer,
    notification: notificationSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
