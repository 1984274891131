import Axios from "../interceptors/axios-config";
import { IUserResponse } from "../interfaces";
import { IResponse } from "../interfaces/IResponses";
import { IUserInfo } from "../models";
import { setIsLoading } from "../store/slices/notification/notificationSlice";
import { store } from "../store/store";
const base_url = process.env.REACT_APP_API_URL;

export const userServices = () => ({
  addUser: async (user: IUserInfo, avatar: Blob) => {
    try {
      store.dispatch(setIsLoading(true));
      const formData = new FormData();
      formData.append("avatar", avatar);
      formData.append("weekVisitFormat", user.weekVisitFormat);
      user.workingDays.forEach((value) => {
        formData.append("workingDays", value.toString());
      });


      formData.append("admissionSessionColor", user.admissionSessionColor);
      formData.append("evaluationSessionColor", user.evaluationSessionColor);
      formData.append("treatmentSessionColor", user.treatmentSessionColor);
      formData.append("thirtyDayReEvalSessionColor", user.thirtyDayReEvalSessionColor);
      formData.append("resumptionOfCareSessionColor", user.resumptionOfCareSessionColor);
      formData.append("recertSessionColor", user.recertSessionColor);
      formData.append("dischargeSessionColor", user.dischargeSessionColor);
      formData.append("supervisorSessionColor", user.supervisorSessionColor);

      formData.append("firstName", user.firstName);
      formData.append("lastName", user.lastName);
      formData.append("email", user.email);
      formData.append("disciplineId", user.disciplineId.toString());
      formData.append("address", user.address);
      formData.append(
        "phoneNumber",
        user.phoneNumber ? user.phoneNumber.toString() : ""
      );
      formData.append("status", user.status);
      formData.append(
        "admissionSessionLength",
        user.admissionSessionLength.toString()
      );
      formData.append(
        "evaluationSessionLength",
        user.evaluationSessionLength.toString()
      );
      formData.append(
        "dischargeSessionLength",
        user.dischargeSessionLength.toString()
      );
      formData.append(
        "thirtyDayReEvalSessionLength",
        user.thirtyDayReEvalSessionLength.toString()
      );
      formData.append(
        "recertSessionLength",
        user.recertSessionLength.toString()
      );
      formData.append(
        "treatmentSessionLength",
        user.treatmentSessionLength.toString()
      );
      formData.append(
        "resumptionOfCareSessionLength",
        user.resumptionOfCareSessionLength.toString()
      );
      formData.append(
        "supervisorSessionLength",
        user.supervisorSessionLength.toString()
      );
      formData.append("start", user.start);
      formData.append("end", user.end);

      const response = await Axios.post(base_url + "/User/AddUser", formData);
      switch (response.data.status) {
        case 201:
        case 200:
          return { status: 200, value: response.data.data };
        case 401:
          return { status: 401, value: response.data.message };
        case 402:
          return { status: 402, value: response.data.message };
        default:
          return {
            status: response.data.status,
            value: "Resource not found",
          };
      }
    } catch (error) {
      store.dispatch(setIsLoading(false));

      return { status: error, value: error };
    } finally {
      store.dispatch(setIsLoading(false));
    }
  },
  updateUser: async (
    id: number,
    user: IUserInfo,
    newAvatar: boolean,
    avatar: Blob
  ) => {
    try {
      store.dispatch(setIsLoading(true));

      const formData = new FormData();

      const password = user?.password ?? "";

      formData.append("id", id.toString());
      formData.append("weekVisitFormat", user.weekVisitFormat);
      user.workingDays.forEach((value) => {
        formData.append("workingDays", value.toString());
      });


      formData.append("avatar", avatar);

      formData.append("aseptTerms", user.aseptTerms.toString());

      formData.append("admissionSessionColor", user.admissionSessionColor);
      formData.append("evaluationSessionColor", user.evaluationSessionColor);
      formData.append("treatmentSessionColor", user.treatmentSessionColor);
      formData.append(
        "thirtyDayReEvalSessionColor",
        user.thirtyDayReEvalSessionColor
      );
      formData.append(
        "resumptionOfCareSessionColor",
        user.resumptionOfCareSessionColor
      );
      formData.append("recertSessionColor", user.recertSessionColor);
      formData.append("dischargeSessionColor", user.dischargeSessionColor);
      formData.append("supervisorSessionColor", user.supervisorSessionColor);


      formData.append("newAvatar", newAvatar.toString());

      formData.append("firstName", user.firstName);
      formData.append("lastName", user.lastName);
      formData.append("email", user.email);
      formData.append("password", password);

      formData.append("disciplineId", user.disciplineId.toString());

      formData.append("address", user.address);
      formData.append(
        "phoneNumber",
        user.phoneNumber ? user.phoneNumber.toString() : ""
      );

      formData.append("status", user.status);

      formData.append(
        "admissionSessionLength",
        user.admissionSessionLength.toString()
      );
      formData.append(
        "evaluationSessionLength",
        user.evaluationSessionLength.toString()
      );
      formData.append(
        "dischargeSessionLength",
        user.dischargeSessionLength.toString()
      );
      formData.append(
        "thirtyDayReEvalSessionLength",
        user.thirtyDayReEvalSessionLength.toString()
      );
      formData.append(
        "recertSessionLength",
        user.recertSessionLength.toString()
      );
      formData.append(
        "treatmentSessionLength",
        user.treatmentSessionLength.toString()
      );

      formData.append(
        "resumptionOfCareSessionLength",
        user.resumptionOfCareSessionLength.toString()
      );
      formData.append(
        "supervisorSessionLength",
        user.supervisorSessionLength.toString()
      );

      formData.append("start", user.start);
      formData.append("end", user.end);

      const response = await Axios.put(
        base_url + `/User/UpdateUser/`,
        formData
      );

      switch (response.data.status) {
        case 201:
        case 200:
          return { status: 200, value: response.data.data };
        case 401:
          return { status: 401, value: response.data.message };
        case 402:
          return { status: 402, value: response.data.message };

        default:
          return {
            status: response.data.status,
            value: "Resource not found",
          };
      }
    } catch (error) {
      store.dispatch(setIsLoading(false));

      return { status: error, value: error };
    } finally {
      store.dispatch(setIsLoading(false));
    }
  },
  deleteUser: async (id: number) => {
    try {
      store.dispatch(setIsLoading(true));

      const response = await Axios.delete(base_url + "/User/DeleteUser", {
        params: { id },
      });

      switch (response.data.status) {
        case 201:
        case 200:
          return { status: 200, value: response.data.data };
        case 401:
          return { status: 401, value: response.data.message };
        case 402:
          return { status: 402, value: response.data.message };
        default:
          return {
            status: response.data.status,
            value: "Resource not found",
          };
      }
    } catch (error) {
      return { status: error, value: error };
    } finally {
      store.dispatch(setIsLoading(false));
    }
  },
  getUserBydId: async (id: number) => {
    try {
      store.dispatch(setIsLoading(true));

      const response = await Axios.get<IResponse<IUserResponse>>(
        `${base_url}/User/GetUser`,
        {
          params: { id },
        }
      );

      switch (response.data.status) {
        case 201:
        case 200:
          return { status: 200, value: response.data.data };
        case 401:
          return { status: 401, value: response.data.message };
        case 402:
          return { status: 402, value: response.data.message };
        default:
          return {
            status: response.data.status,
            value: "Resource not found",
          };
      }
    } catch (error) {
      return { status: 500, value: "Internal Server Error" };
    } finally {
      store.dispatch(setIsLoading(false));
    }
  },
  logoutUser: async()=> {
    try {
      store.dispatch(setIsLoading(true));

    const response = await Axios.get(`${base_url}/Auth/Logout`);

    
    switch (response.data.status) {

      case 201:
      case 200:
        return { status: 200 };

      default:
        return {
          status: response.data.status
        };
    }
      
    } catch (error) {
      return { status: 500, value: "Internal Server Error" };
      
    } finally{
      store.dispatch(setIsLoading(false));

    }

  },
});
