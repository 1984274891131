import {DropDownListComponent} from "@syncfusion/ej2-react-dropdowns";
import "datatables.net";
import React, {ChangeEvent, useEffect, useState} from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "tippy.js/dist/tippy.css";
import {Pagination} from "../../../components/patients";
import {PatientBulkModal} from "../../../components/patients/PatientBulkModal";
import {Table} from "../../../components/patients";
import {Toolbar} from "../../../components/patients";
import UpgradePlanBanner from "../../../Controls/UpgradePlanBanner";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {patientsLoad} from "../../../store/slices/patients";
import { getUserObj, setLocalStorage } from "../../../Services/utility";
import { patientStatus } from "../../../helpers/globals";

const Patients = () => {
  const dispatch = useAppDispatch();
  // const { patients } = useAppSelector((state) => state.patients);

  const {total} = useAppSelector((state) => state.patients);
  const userLogged = getUserObj();
  const patientStatusFilter = userLogged?.patientStatusFilter || patientStatus[0] ;
  const [selectedStatus, setSelectedStatus] = useState<string>(patientStatusFilter.name);

  // const patientsFiltering:number =
  //   selectedStatus === "All"
  //   ? total
  //   : patients.filter((patient) => patient.status === selectedStatus).length +1;

    const userTableSize:number = userLogged?.userTableSize || 10 ;

  const [pagesize, setPagesize] = useState(userTableSize);
  const [pagenumber, setPagenumber] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDir, setSortDir] = useState("u");
  const [searchText, setSearchText] = useState("");

  const [open, setOpen] = useState(false);
  const [banner, setBanner] = useState({
    status: false,
    message: "",
  });

  const closePopup = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(patientsLoad({pagesize,status:selectedStatus}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStatus]);

  const handleSortChange = (column: string, dir: string) => {
    dispatch(
      patientsLoad({
        pagesize: pagesize,
        sortField: column,
        sortDir: dir,
        query: searchText,
        pagenumber: pagenumber,
        status:selectedStatus
      })
    );
    setSortColumn(column);
    setSortDir(dir);
  };

  const handleSizeChange = ({target}: ChangeEvent<HTMLSelectElement>) => {
    const size = parseInt(target.value);
    if (size === pagesize) return;
    dispatch(
      patientsLoad({
        pagesize: size,
        sortField: sortColumn,
        sortDir: sortDir,
        query: searchText,
        pagenumber: pagenumber,
        status:selectedStatus
      })
    );
    setPagesize(size);

    setLocalStorage("userData_Apex", JSON.stringify({
      ...userLogged,
      userTableSize:size
    }));

  };

  const handleSerach = (text: string) => {
    dispatch(
      patientsLoad({
        pagesize: pagesize,
        sortField: sortColumn,
        sortDir: sortDir,
        query: text,
        pagenumber: pagenumber,
        status:selectedStatus
      })
    );
    setSearchText(text);
  };

  const handleNumberChange = (numb: number) => {
    if (numb === pagenumber) return;
    dispatch(
      patientsLoad({
        pagesize: pagesize,
        sortField: sortColumn,
        sortDir: sortDir,
        query: searchText,
        pagenumber: numb,
        status:selectedStatus
      })
    );
    setPagenumber(numb);
  };

  return (
    <div className="min-h-[87vh]">
      <Toolbar
        target="patient"
        onSearch={handleSerach}
        path="/patient/add"
        setOpen={setOpen}
        setSelectedStatus={setSelectedStatus}
      />

      <div
        className="rounded-[8px] py-10 px-8 flex flex-col place-content-between patients-height"
        style={{
          backgroundColor: "white",
          margin: "0px 40px",
          height: "90%",
        }}
      >
        <UpgradePlanBanner
          ShowBanner={banner.status}
          BannerMessage={banner.message}
          OnDismiss={() => {
            setBanner({
              status: false,
              message: "",
            });
          }}
          IsFixed={false}
        />
        <Popup closeOnDocumentClick open={open} onClose={closePopup}>
          <PatientBulkModal close={closePopup} setBanner={setBanner} />
        </Popup>
        <Table
          sortHandler={handleSortChange}
          setBanner={setBanner}
          setOpen={setOpen}
          selectedStatus={selectedStatus}
        />

        <footer
          className="flex place-content-between pt-10"
          style={{ borderTop: "1px solid #e3e3e3" }}
        >
          <div className="flex flex-row gap-[8px] items-center pagination-dropdown">
            <h6 className="page-list-size">List Size</h6>
            <DropDownListComponent
              dataSource={[10, 15, 20, 25]}
              value={pagesize}
              onChange={handleSizeChange}
              width="69px"
              popupWidth={"80px"}
              popupHeight={"166px"}
            />
          </div>
          <Pagination
            total={total}
            initialNumber={pagenumber}
            action={(pagenum: number) => handleNumberChange(pagenum)}
            pageSize={pagesize}
          />
        </footer>
      </div>
    </div>
  );
};
export default Patients;
