import React from "react";

interface Props {
  label: string;
  name: string;
  [x: string]: any;
}

export const MyRadioButtonRef = ({
  errors,
  name,
  register,
  label,
  ...props
}: Props) => {
  return (
    <div className="radio__input flex">
      <label>
        <input type="radio" {...register(name)} {...props} />
        <span className="pt-1 ml-[5px]">{label}</span>
      </label>
      {errors[name] && errors[name]}
    </div>
  );
};
