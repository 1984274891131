import React, { Fragment, useState } from "react";

import { Listbox, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons";
import { getUserObj, setLocalStorage } from "../../Services/utility";
import { patientStatus } from "../../helpers/globals";

interface IPatientStatus {
  setStatus: React.Dispatch<React.SetStateAction<string>>;
}
export const DropDownPatientFilter = ({ setStatus }: IPatientStatus) => {
  const userLogged = getUserObj();
  const patientStatusFilter = userLogged?.patientStatusFilter || patientStatus[0] ;
  
  const [selectedPerson, setSelectedPerson] = useState(patientStatusFilter);

  return (
    <Listbox
      value={selectedPerson}
      onChange={(status) => {
        setSelectedPerson(status);
        setStatus(status.name);
        setLocalStorage("userData_Apex", JSON.stringify({
          ...userLogged,
          patientStatusFilter:status
        }));
      }}
    >
      <div className="list__box_patient_filter  w-100 min-w-[108px] bg-white">
        <Listbox.Button className={`w-full cursor-default`}>
          <div className={`user-container icon`}>
            {selectedPerson?.name === "" ? (
              <span className="user-placeholder">Status Filter</span>
            ) : (
              <span className="user-value"> {selectedPerson.name}</span>
            )}
          </div>
          <span className="dropdown__pointer pointer-events-none">
            <FontAwesomeIcon
              color="#6C737F"
              icon={faAngleDown}
              className="dropdown-pointer"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in-out duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className={`dropdown-user-options`}>
            {patientStatus.map((person) => (
              <Listbox.Option
                key={person.id}
                className={`dropdown-li-options relative cursor-pointer select-none`}
                value={person}
              >
               <span>{person.name}</span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
