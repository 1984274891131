import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteRecert, saveRecert, updateRecert } from "../../../api/certApi";
import { getRecertifications } from "../../../helpers/fetchPatientData";
import {
  ICreateRecert,
  IUpdateRecert,
} from "../../../interfaces/ICertifications";

export const getCertifications = createAsyncThunk(
  "certifications/getById",
  async (id: number | string, thunkApi) => {
    const data = await getRecertifications(id);

    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return data.data;
  }
);

export const addCertification = createAsyncThunk(
  "certifications/add",
  async (cert: ICreateRecert, thunkApi) => {
    const data = await saveRecert(cert);
    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return data.data;
  }
);

export const updateCertification = createAsyncThunk(
  "certifications/update",
  async (recert: IUpdateRecert, thunkApi) => {
    const data = await updateRecert(recert);
    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return data.data;
  }
);

export const deleteCertification = createAsyncThunk(
  "certifications/delete",
  async (id: number, thunkApi) => {
    const data = await deleteRecert(id);
    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return id;
  }
);
