import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompletePatient } from "../../../interfaces/IPatient";
import { savePatient, getPatient } from "./thunks";

export interface patientState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  patient: ICompletePatient;
  error: {
    errorType?: string;
    errorMessage?: string;
  };
}

const initialState: patientState = {
  loading: "idle",
  patient: {} as ICompletePatient,
  error: { errorType: "", errorMessage: "" },
};

export const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPatient.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(getPatient.fulfilled, (state, action) => {
      state.patient = action.payload;
      state.loading = "succeeded";
    });
    builder.addCase(getPatient.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });

    builder.addCase(savePatient.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(savePatient.fulfilled, (state, action) => {
      state.patient = action.payload;
      state.loading = "succeeded";
    });
    builder.addCase(savePatient.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });
  },
});

export const {} = patientSlice.actions;
