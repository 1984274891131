import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ICreateFrequency,
  IDeleteFrequency,
  IEditFrequency,
} from "../../../interfaces/IFrequency";
import { DeleteData, editSchedule, saveSchedule } from "../../../Services/Api";

export const createFrequency = createAsyncThunk(
  "certifications/addFrequency",
  async (body: ICreateFrequency, thunkApi) => {
    const data = await saveSchedule(body);
    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return {
      frecs: data.data.frecs,
      vCount: data.data.visitCount,
      wCount: data.data.weeksCount,
    };
  }
);

export const editFrequency = createAsyncThunk(
  "certifications/editFrequency",
  async (body: IEditFrequency, thunkApi) => {
    const data = await editSchedule(body);
    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return {
      frecs: data.data.frecs,
      vCount: data.data.visitCount,
      wCount: data.data.weeksCount,
    };
  }
);

export const deleteFrequency = createAsyncThunk(
  "certifications/deleteFrequency",
  async (body: IDeleteFrequency, thunkApi) => {
    const data = await DeleteData("/PatientSchedule/DeleteFrequency", body);
    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return {
      index: body.index,
      vCount: data.data.visitCount,
      frecs: data.data.frecs,
      wCount: data.data.weeksCount,
    };
  }
);
