import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ManagePatientAllowed } from "../../Services/utility";
import { MySelect } from "../MySelect";
import { DropDownPatientFilter } from "./DropdonwPatientFilter";

type ToolbarProps = {
  target?: string;
  onSearch: Function;
  path: string;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  classButton?: string;
  searchPlaceHolder?: string;
  patientFilter?: string;
  setSelectedStatus?: React.Dispatch<React.SetStateAction<string>>;
};

export const Toolbar = (props: ToolbarProps) => {
  const navigate = useNavigate();
  const [inputT, setInputT] = useState<string>("");

  const onKeyHandler = (e: React.KeyboardEvent) => {
    const value: string = (e.target as any).value.toString().toLowerCase();
    if (e.key === "Enter") props.onSearch(value);
  };
  const onChangeHandler = (e: React.ChangeEvent) => {
    const value: string = (e.target as any).value.toString().toLowerCase();
    setInputT(value);
  };

  return (
    <div className="toolbar__container">
      <div className="toolbar"></div>
      <div
        className=""
        style={{
          width: "80%",
          display: "flex",
          // placeItems: "end",
          placeContent: "end",
        }}
      >
        <div className="right__section flex flex-row content-center">
          {props.setSelectedStatus && (
            <DropDownPatientFilter setStatus={props.setSelectedStatus} />
          )}
          <div className="search__container border rounded-8 flex justify-content-between w-[200px] min-w-[182px] bg-white items-center pl-2 pr-2 pr-4 py-3 mb-8 mr-[8px] font-14">
            <input
              type="text"
              onChange={onChangeHandler}
              onKeyPress={onKeyHandler}
              placeholder={
                props.searchPlaceHolder ?? "Search for " + props.target
              }
              className="border-0 relative w-full px-3 transition ease-in-out m-0 height-22"
            />
            <button
              className="button-less"
              onClick={() => {
                props.onSearch(inputT);
              }}
            >
              <FontAwesomeIcon color="#D2D6DB" size="1x" icon={faSearch} />
            </button>
          </div>
          <button
            className={props.classButton || "button-add-new-pu min-w-[125px]"}
            onClick={() => {
              navigate(props.path);
            }}
            disabled={!ManagePatientAllowed()}
          >
            Add new {props.target?.toLowerCase()}
          </button>
        </div>
      </div>
    </div>
  );
};
