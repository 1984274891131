import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "../../../interfaces/IUser";
import { authLogin, authLoginSocial, authSignup, authSignupSocial, linkSocialAccount } from "./thunks";

export interface authState {
  user: IUser;
  loading: "idle" | "pending" | "succeeded" | "failed";
  error: {
    errorType?: string;
    errorMessage?: string;
  };
}

const initialState: authState = {
  loading: "idle",
  user: {} as IUser,
  error: { errorType: "", errorMessage: "" },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.loading = "idle";
      state.user = initialState.user;
    },
    reset: (state) => {
      state.loading = "idle";
      state.error = {};
    },
  },
  extraReducers(builder) {
    builder.addCase(authLogin.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(authLogin.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.user = action.payload;
    });
    builder.addCase(authLogin.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });

    builder.addCase(authLoginSocial.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(authLoginSocial.fulfilled, (state, action) => {
      state.loading = "succeeded";
      state.user = action.payload;
    });
    builder.addCase(authLoginSocial.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });

    builder.addCase(authSignup.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(authSignup.fulfilled, (state, action) => {
      state.loading = "succeeded";
    });
    builder.addCase(authSignup.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });

    builder.addCase(authSignupSocial.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(authSignupSocial.fulfilled, (state, action) => {
      state.loading = "succeeded";
    });
    builder.addCase(authSignupSocial.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });
    builder.addCase(linkSocialAccount.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(linkSocialAccount.fulfilled, (state, action) => {
      state.loading = "succeeded";
    });
    builder.addCase(linkSocialAccount.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });
  },
});

export const { logout, reset } = authSlice.actions;
