import React, { useEffect, useState } from "react";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HexagonalColorPicker } from "react-hexagon-color-picker";

interface IProps {
  onClose: () => void;
  handleChangeVisitColor: (color: string) => void;
  initialColor: string;
  defaultColorAdmission:string
}
export const SelectVisitColor = ({
  onClose,
  handleChangeVisitColor,
  initialColor,
  defaultColorAdmission
}: IProps) => {
  const [currentColor, setCurrentColor] = useState(initialColor);
  useEffect(() => {
    setCurrentColor(initialColor);
  }, [initialColor]);

  const handleCurrentColorChange = (element: any) => {
    setCurrentColor(element);
  };

  const handleOnDone = () => {
    handleChangeVisitColor(currentColor);
  };

  return (
    <section className="visit-color-modal">
      <div className="m-0">
        <div className="d-flex justify-content-end">
          <button title="close-patient-button" type="button" onClick={onClose}>
            <FontAwesomeIcon
              icon={faClose}
              className="modal-patient-button-close-success-error"
              aria-hidden="true"
            />
          </button>
        </div>

        <div className="hexagon-container">
          <HexagonalColorPicker
            hue={0}
            color={currentColor}
            onColorChange={handleCurrentColorChange}
          />
        </div>
        <div className="visit-color-end-container">
          <button
            className="reset-visit-color"
            onClick={() => setCurrentColor(defaultColorAdmission)}
            type="button"
          >
            Reset
          </button>
          <button
            className="done-visit-color"
            onClick={handleOnDone}
            type="button"
          >
            Done
          </button>
        </div>
      </div>
    </section>
  );
};
