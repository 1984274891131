import { createSlice } from "@reduxjs/toolkit";

export const Errors = {
  notFound: {
    title: "Not Found",
    message: "Resource not found",
    color: "bg-danger",
  },
  unauthorized: {
    title: "Unauthorized",
    message: "You dont have the required permission for this action",
    color: "bg-warning",
  },
  internal: {
    title: "Internal Server Error",
    message: "Oops, looks like something went wrong!",
    color: "bg-danger",
  },
  badRequest: {
    title: "Data Error",
    message: "There was a problem with the data of the request",
    color: "bg-danger",
  },
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    isLoading: false,
    isUserError: false,
    isCloseFrecuencyModal:false,
  },
  reducers: {
    setIsLoading: (state, ...rest) => {
      state.isLoading = rest[0].payload;
    },
    setIsUserError: (state, ...rest) => {
      state.isUserError = rest[0].payload;
    },
    setCloseFrecuencyModal:(state, ...rest) => {
      state.isCloseFrecuencyModal = rest[0].payload;
    },

  },
});

export const selectIsLoading = (state: any) => state.notification.isLoading;
export const selectIsUserError = (state: any) => state.notification.isUserError;
export const selectIsCloseFrecuencyModal = (state: any) => state.notification.selectIsCloseFrecuencyModal;

export const { setIsLoading, setIsUserError ,setCloseFrecuencyModal} = notificationSlice.actions;

export default notificationSlice.reducer;
