import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createFrequency, deleteFrequency, editFrequency } from ".";
import { ICertification } from "../../../interfaces/ICertifications";

export interface certificationState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  certification: ICertification;
  initialCertification: ICertification;
  error: {
    errorType?: string;
    errorMessage?: string;
  };
}

const initialState: certificationState = {
  loading: "idle",
  certification: {} as ICertification,
  initialCertification: {} as ICertification,
  error: {},
};

export const certificationSlice = createSlice({
  name: "certification",
  initialState,
  reducers: {
    setCert: (state, action: PayloadAction<ICertification>) => {
      state.certification = initialState.certification;
      state.certification = action.payload;
    },
    setInitialCertification:(state, action: PayloadAction<ICertification>) => {
      state.initialCertification = action.payload;
    },
    updateCert:(state, action) => {
      state.certification.frequency = action.payload.frequency;
      state.certification.vcount = action.payload.vcount;
      state.certification.wcount = action.payload.wcount;
    },
    sumVisits: (state, action: PayloadAction<number>) => {
      state.certification.vcount += action.payload;
    },
    restVisits: (state, action: PayloadAction<number>) => {
      state.certification.vcount -= action.payload;
    },
    sumWeeks: (state, action: PayloadAction<number>) => {
      state.certification.wcount += action.payload;
    },
    restWeeks: (state, action: PayloadAction<number>) => {
      state.certification.wcount -= action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(createFrequency.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(createFrequency.fulfilled, (state, action) => {
      state.certification.frequency = action.payload.frecs;
      state.certification.vcount = action.payload.vCount;
      state.certification.wcount = action.payload.wCount;
      state.loading = "succeeded";
    });
    builder.addCase(createFrequency.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });

    builder.addCase(editFrequency.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(editFrequency.fulfilled, (state, action) => {
      state.certification.frequency = action.payload.frecs;
      state.certification.vcount = action.payload.vCount;
      state.certification.wcount = action.payload.wCount;
      state.loading = "succeeded";
    });
    builder.addCase(editFrequency.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });

    builder.addCase(deleteFrequency.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(deleteFrequency.fulfilled, (state, action) => {
      state.certification.frequency = action.payload.frecs;
      state.certification.vcount = action.payload.vCount;
      state.certification.wcount = action.payload.wCount;
      state.loading = "succeeded";
    });
    builder.addCase(deleteFrequency.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });
  },
});

export const { setCert, setInitialCertification,updateCert,restVisits, restWeeks, sumVisits, sumWeeks } =
  certificationSlice.actions;
