import { faCheck, faAngleDown }        from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon }         from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState }      from 'react'
import { Listbox, Transition }     from '@headlessui/react'
import React                       from 'react'

export type DropdownOptions = {
  name : string,
  holderName : string,
  eventName : string
};

export type DropDownProps = {
  preText:string,
  onSelect: Function,
  options : DropdownOptions[]
};

export default function Dropdown (props: DropDownProps) {
  const [myselected, setMySelected] = useState(props.options[0])

  // useEffect(()=>{
  //   props.onSelect(selected);
  // }, [selected])

  return (
    <div>
      <Listbox value={myselected} onChange={(v) => {props.onSelect(v); setMySelected(v); }}>
        <div className="relative mt-1 border rounded-8 w-100 bg-white font-14">
          <Listbox.Button className="relative border-0 rounded-8 outline-none w-full cursor-default bg-white px-4 py-3 text-left" style={{height: "42px"}}>
            <div className="row px-4">
              <span className="block truncate font-14">{props.preText}</span>
              <span className="block truncate font-14 ml-2">{myselected.holderName}</span>
            </div>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
            <FontAwesomeIcon color="#6C737F" icon={faAngleDown} className="h-7 w-7 text-gray-400" aria-hidden="true"/>
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="border rounded-8 absolute z-10 mt-1 max-h-65 w-full overflow-auto bg-white py-3 font-14 mybs-select">
              {props.options.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none px-4 py-3 ${
                      active || myselected.name === person.name ? 'bg-gray-50' : ''
                    }`
                  }
                  value={person}
                >
                  {() => (
                    <>
                      <span
                        className={`block truncate`}
                      >
                        {person.name}
                      </span>
                      {myselected.name === person.name ? (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                          <FontAwesomeIcon color="#0BB68C" icon={faCheck} className="h-6 w-6" aria-hidden="true"/>
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
