import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SuccessLayout } from "../../../components/auth/containers/SuccessLayout";
import { reset } from "../../../store/slices/auth/authSlice";

export const SignUpSuccessGmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  return (
    <SuccessLayout>
      <div className="auth__logo">
        <img src="/logo.png" alt="" />
      </div>

      <div className="pt-[28px]">
        <img src="../../images/Success-icon-1.png" alt="" />
      </div>
      <p className="success_header_primary">{"Excellent, all set!"}</p>
      <div
       className="flex justify-center">
 
          <button
            onClick={() => {
              dispatch(reset());
              navigate("/login");
            }}
            className="auth__green-btn-signup"
          >
            Sign in
          </button>
        </div>
    </SuccessLayout>
  );
};
