import React, { useState } from "react";
import * as utility from "../../Services/utility";
import "tippy.js/dist/tippy.css";
import { useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router-dom";

import { faArrowUp, faArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoaderSpinner } from "../UI/LoaderSpinner";
import { BulkPatientForm } from "./BulkPatientForm";
import {
  changeFrequencyByUserProfile,
  changeOneFrequencyByUserProfile,
} from "../../helpers/globals";
import { ValueFromUserData } from "../../Services/utility";

const columns = [
  {
    Header: "First name",
    accessor: "firstName",
  },
  {
    Header: "Last name",
    accessor: "lastName",
  },
  {
    Header: "City",
    accessor: "city",
  },
  {
    Header: "Frequency",
    accessor: "frequency",
  },
  {
    Header: "30DRE",
    accessor: "visit30DRE",
  },
  {
    Header: "End of Care",
    accessor: "eoC",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

type tableProps = {
  sortHandler: Function;
  setBanner: React.Dispatch<
    React.SetStateAction<{
      status: boolean;
      message: string;
    }>
  >;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedStatus?: string;
};

export const Table = (props: tableProps) => {
  const [selectedSort, setSelectedSort] = useState<string>("");
  const { patients, loading } = useAppSelector((state) => state.patients);
  //const patientsFiltering =
  //  props.selectedStatus === "All"
  //    ? patients
  //    : patients.filter((patient) => patient.status === props.selectedStatus);
  const haveDataPatient = patients.length > 0 || props.selectedStatus !== "All";
  // const haveDataPatient = true;
  const [arrowDir, setArrowDir] = useState<string>("u");

  const navigate = useNavigate();

  const handleSelected = (touched: string) => {
    // if (patientsFiltering.length <= 0) return;
    if (loading === "pending" || loading === "idle") return;
    if (touched === "frequency") return;

    const arr = arrowDir === "u" ? "d" : "u";

    props.sortHandler(touched, arr);

    setArrowDir(arr);
    setSelectedSort(touched);
  };
  const userFrequency = ValueFromUserData("weekVisitFormat") ?? "w";

  if (loading === "pending" || loading === "idle") {
    return (
      <table className="users__table">
        <thead>
          <tr className="table__head-container">
            {columns.map((col) => (
              <th
                className={
                  "table__head" +
                  (selectedSort === col.accessor ? " sorted " : "") +
                  (col.accessor !== "frequency" ? " hover:bg-[#E5E7EB]" : " ")
                }
                key={col.accessor}
              >
                <div className="row pl-4">
                  <h5>{col.Header}</h5>
                  {selectedSort === col.accessor ? (
                    <FontAwesomeIcon
                      className="ml-[6.5px] w-[9px] h-[12px]"
                      color="#6C737F"
                      stroke="1px"
                      icon={arrowDir === "u" ? faArrowUp : faArrowDown}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table__body">
          <tr className="table__row">
            <td />
            <td />
            <td />
            <td>
              {" "}
              <LoaderSpinner />{" "}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
  return (
    <table className="users__table">
      <thead>
        <tr className="table__head-container">
          {columns.map((col) => (
            <th
              className={
                "table__head" +
                (selectedSort === col.accessor ? " sorted " : "") +
                (col.accessor !== "frequency" ? " hover:bg-[#E5E7EB]" : " ")
              }
              key={col.accessor}
              onClick={() => handleSelected(col.accessor)}
            >
              <div className="row pl-4">
                <h5>{col.Header}</h5>
                {selectedSort === col.accessor ? (
                  <FontAwesomeIcon
                    className="ml-[6.5px] w-[9px] h-[12px]"
                    color="#6C737F"
                    size="1x"
                    strokeWidth={1}
                    icon={arrowDir === "u" ? faArrowUp : faArrowDown}
                  />
                ) : (
                  ""
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>

      <tbody
        className={`table__body ${
          haveDataPatient ? "" : "table__without__patient"
        }`}
      >
        {haveDataPatient ? (
          patients.map((p) => (
            <tr
              onClick={() => navigate(`/patients/${p.id}`)}
              key={p.id}
              className="table__row cursor-pointer"
            >
              <td className="table__data">{p.firstName}</td>
              <td className="table__data">{p.lastName}</td>
              <td className="table__data">{p.city}</td>
              <td className="table__data truncate">
                {changeFrequencyByUserProfile(
                  p.frequency.split(","),
                  userFrequency
                ).toString()}
              </td>
              <td className="table__data">{utility.getDate(p.visit30DRE)}</td>
              <td className="table__data">{utility.getDate(p.eoC)}</td>
              <td
                className={"table__data status " + p.status.toLocaleLowerCase()}
              >
                <p>{p.status}</p>
              </td>
              {/* <td></td> */}
            </tr>
          ))
        ) : (
          <BulkPatientForm setOpen={props.setOpen} path="/patient/add" />
        )}
      </tbody>
    </table>
  );
};
