import React from "react";
import { useState, useEffect } from "react";
// import { IsAdmin, ValueFromUserData, UTCNow } from "../../../Services/utility";
import moment from "moment";
import { eventTemplateHandlerWeek, eventTemplateHandlerMonth, weekTimeScale, patientBoxTemplate, eventTemplateHandlerDay, patientSearchBoxTemplate } from "./ScheduleTemplates";

import { visitsScheduled, visitsScheduledPatients } from "../../../api/schedulingApi";
import { ShowAlert } from "../../../Services/utility";

import { ScheduleComponent, ViewDirective, ViewsDirective, Week, Month, Day, Inject, EventClickArgs } from "@syncfusion/ej2-react-schedule";
import { ActionEventArgs, FieldModel, EventSettingsModel } from "@syncfusion/ej2-react-schedule";
import _ from "lodash";

const ScheduleView = () => {
  const [inprogress, setInprogress] = useState<boolean>(false);
  const [dayIni, setDayIni] = useState<Date>(moment(new Date()).startOf('week').toDate());
  const [dayEnd, setDayEnd] = useState<Date>(moment(new Date()).endOf('week').toDate());
  const [clinitianId, setClinitianId] = useState<number>(0);
  const [patientId, setPaientId] = useState<number>(0);
  const [calendarEventData, setCalendarEventData] = useState<{ [key: string]: Object }[]>([]);
  const [patientsData, setPatientsData] = useState<{ [key: string]: Object }[]>([]);
  const [filterText, setFilterText] = useState<string>("");
  const [groupText, setGroupText] = useState<string>("");

  const [startTimeWork, setStartTimeWork] = useState<string>('00:00');
  const [endTimeWork, setEndTimeWork] = useState<string>('24:00');

  useEffect(() => {
    const userdata: string = sessionStorage.getItem("userData_Apex") || "";
    const formateddata:any = JSON.parse(userdata)
    setStartTimeWork(formateddata.userStartTime.toString().substring(0,5) || "00:00")
    setEndTimeWork(formateddata.userEndTime.toString().substring(0,5) || "24:00")
  }, [])

  useEffect(() => {
    // todo all api calls refactor
    const getCalendarData = async () => {
      try {
        setInprogress(true);
        const { data: result } = await visitsScheduled(dayIni.toISOString(), dayEnd.toISOString(), clinitianId, patientId);

        if (result.status !== 200) {
          ShowAlert(result.message, "error");
        } else {
          setCalendarEventData(result.data);
        }
      } catch (error: any) {
        ShowAlert(error.message, "error");
      } finally {
        setInprogress(false);
      }
    }

    // todo all api calls refactor
    const getPatientListData = async () => {
      try {
        setInprogress(true);
        const { data: result } = await visitsScheduledPatients(dayIni.toISOString(), dayEnd.toISOString(), clinitianId);

        if (result.status !== 200) {
          ShowAlert(result.message, "error");
        } else {
          setPatientsData(result.data);
        }
      } catch (error: any) {
        ShowAlert(error.message, "error");
      } finally {
        setInprogress(false);
      }
    }

    getCalendarData();
    getPatientListData();

  }, [dayEnd, clinitianId, patientId]);


  const callendarFields: FieldModel = { id: 'id', startTime: { name: 'start' }, endTime: { name: 'end' } };
  const localData: EventSettingsModel = { dataSource: calendarEventData, fields: callendarFields };

  let scheduleObj: ScheduleComponent;

  const onCompleteHandler = (args: ActionEventArgs) => {
    if (inprogress)
    {
      args.cancel=true;
      return;
    }
    if (args.requestType === "dateNavigate" || args.requestType === "viewNavigate") {
      if (scheduleObj) {
        const dates = scheduleObj.selectedDate;
        if (scheduleObj.viewIndex === 0) // day
        {
          setDayIni(moment(dates.toISOString()).startOf('day').toDate());
          setDayEnd(moment(dates.toISOString()).endOf('day').toDate());
        } else if (scheduleObj.viewIndex === 1) // week
        {
          setDayIni(moment(dates.toISOString()).startOf('week').toDate());
          setDayEnd(moment(dates.toISOString()).endOf('week').toDate());
        } else if (scheduleObj.viewIndex === 2) // month
        {
          setDayIni(moment(dates.toISOString()).startOf('month').startOf('week').toDate());
          setDayEnd(moment(dates.toISOString()).endOf('month').endOf('week').toDate());
        }
      }
    }
  }

  const onClickPatientHandler = (id: string) => {
    var myId: number = +id;
    setPaientId(myId);
  }

  const onEventClickHandler = (args: EventClickArgs) => {
    args.cancel = true;
  }

  const fiterList = (list:{ [key: string]: Object }[]) =>{
    if (filterText === "")
      return list;
    else{
      const filter = patientsData.filter(patient =>
        patient?.firstName?.toString().toLowerCase().indexOf(filterText) > -1 ||
        patient?.lastName?.toString().toLowerCase().indexOf(filterText) > -1 ||
        patient?.address?.toString().toLowerCase().indexOf(filterText) > -1);
      return filter;
    }
  }

  const groupList = (list:{ [key: string]: Object }[]) =>{
    if (groupText === "")
      return list;
    else{
      let grouped;
      if (groupText === "lastName")
        grouped = _.groupBy(_.sortBy(list, groupText), (v) => { return v.lastName.toString().slice(0,1) } );
      else
        grouped = _.groupBy(_.sortBy(list, groupText), groupText);

        let sol:{[key:string] : Object}[] = [];

      let counter = 0;
      for (let key in grouped)
      {
        sol.push({
          id : "gl_" + key,
          groupT : key
        });
        counter += 1;

        sol = sol.concat(grouped[key]);
      }

      return sol;
    }
  }

  const patientList = groupList(fiterList(patientsData))?.map(patient => patientBoxTemplate({
    FirstName: patient?.firstName?.toString(),
    LastName: patient?.lastName?.toString(),
    PatientId: patient?.patientId?.toString(),
    Address: patient?.address?.toString(),
    groupT: patient?.groupT?.toString(),
    onClick: onClickPatientHandler
  }));


  return (
    <div className="flex h-100">
      <div className="col-md-3">
        {patientSearchBoxTemplate({NameList:"",
                                   onSelect:(field:string) => setGroupText(field),
                                   onSearch:(text:string) =>  setFilterText(text) })}
        <div className="patient-container">
          {patientList}
        </div>
      </div>
      <div className="col-md-9">
      <div
          className="rounded-[20px] py-10 px-8 flex flex-col place-content-between calendar-container schedule-view"
        >
          <ScheduleComponent
            currentView='Week'
            width='100%'
            height='100%'
            actionComplete={onCompleteHandler}
            allowMultiCellSelection={false}
            allowMultiDrag={false}
            allowMultiRowSelection={false} // todo see this with one hour component
            readonly={true}
            startHour={startTimeWork}
            endHour={endTimeWork}
            eventClick={onEventClickHandler}

            ref={schedule => scheduleObj = schedule as ScheduleComponent}
            eventSettings={localData} >

            <ViewsDirective>
              <ViewDirective option='Day' dateFormat='MMMM dd' timeScale={{ enable: true, slotCount: 1 }} eventTemplate={eventTemplateHandlerDay} />
              <ViewDirective option='Week' timeFormat="h:mm a" dateFormat='MMM dd'  timeScale={{ enable: true, slotCount: 4 }} eventTemplate={eventTemplateHandlerWeek}  />
              <ViewDirective option='Month' eventTemplate={eventTemplateHandlerMonth} />
            </ViewsDirective>
            <Inject services={[Day, Week, Month]}/>
          </ScheduleComponent>
        </div>
      </div>
    </div>
  );
}

export default ScheduleView;
