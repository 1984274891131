import * as React from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { PhonList } from "../interfaces";
import { useState } from "react";

export default function MyDropdownListRef({
  register,
  placeholder,
  data,
  value,
  name,
  span,
  inEdition,
  errors,
  index,
  label,
  field,
  array,
  clearErrors,
  setFieldValue,
  isDirty,
  clearHospitalTransfer,
  addDateHospitalTransfer,
}: any) {
  const fields: object = { text: "name", value: "id" };
  const [myfocus, setFocus] = useState(false)
  let okField = false;


  if (field === "phonList") {
    okField =
      isDirty?.[index]?.relationId &&
      errors?.phonList?.[index]?.relationId === undefined;
  } else if (field === "phonMDList") {
    okField =
      isDirty?.[index]?.relationId &&
      errors?.phonMDList?.[index]?.relationId === undefined;
  } else {
    okField = isDirty && errors[name] === undefined;
  }
  const changeValue = (value: any) => {
    clearErrors(name);
    setFieldValue(name, value.value, { shouldDirty: true });
    if(value && value.value !== "Hospital" ){
    clearHospitalTransfer && clearHospitalTransfer()

    }else if(value && value.value === "Hospital" ){
 

      addDateHospitalTransfer && addDateHospitalTransfer()
    }
  };

  return (
    <div
      onFocus={() => {
        setFocus(true);
      }}
      onBlur={() => {
        setFocus(false);
      }}
      className={`${myfocus ? "custom-focus" : ""}
        ${field == "phonList"
          ? `${errors?.phonList?.[index]?.relationId
            ? "dropdown-error-class"
            : okField
              ? "custom-dropdown-edit"
              : "custom-dropdown"
          }`
          : `${errors?.phonMDList?.[index]?.relationId
            ? "dropdown-error-class"
            : okField
              ? "custom-dropdown-edit"
              : "custom-dropdown"
          }`}
      `}
    >
      <label className="font-bold">
        {label}
        {span ? <span className="span-required">{span}</span> : ""}
      </label>
      <DropDownListComponent
        id={name}
        fields={fields}
        value={value || null}
        change={changeValue}
        dataSource={data}
        popupHeight="200px"
        popupWidth="100%"
        placeholder={placeholder}
        {...register}
      />
    </div>
  );
}
