import { useEffect, useRef } from "react";

export const useEscapeAndClickOutSide = (handlerClick:any,handlerEsc:any) => {
    const domClickRef = useRef<HTMLDivElement>(null);

  
    useEffect(() => {
      const handleClickOutside =(event: any) =>{   

             if (domClickRef.current && !domClickRef.current.contains(event.target)) handlerClick()
      }
      const keyDownHandler = (event: any) => {
        if (event.key === "Escape" && 
            (domClickRef.current && !domClickRef.current.contains(event.target)) 
        ) {
  
          event.preventDefault();
  
          // 👇️ your logic here
          handlerEsc();
        }
      };
  
      window.addEventListener("keydown", keyDownHandler);
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        window.removeEventListener("keydown", keyDownHandler);

      };
    });
  
    return domClickRef;
  }