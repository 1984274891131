import React from "react";
import {HashRouter, Route, Routes} from "react-router-dom";
import routes from "../routes";
import { SignUpSuccessGmail } from "../views/PublicPages/Signup/SignUpSuccessGmail";
import {PrivateRoutes} from "./PrivateRoutes";

interface IAcceptAll {
  [x: string]: any;
}
const Login = React.lazy(() => import("../views/PublicPages/login/Login"));
const Signup = React.lazy(() => import("../views/PublicPages/Signup/Signup"));

const VerificationMessage = React.lazy(
  () =>
    import(
      "../views/PublicPages/AgencyEmailVerification/AgencyEmailVerification"
    )
);

const ForgotPassword = React.lazy(
  () => import("../views/PublicPages/login/ForgotPassword")
);
const PasswordTR = React.lazy(
  () =>
    import("../views/PublicPages/PasswordTokenRecovery/PasswordTokenRecovery")
);
const SuccessMessage = React.lazy(
  () => import("../views/PublicPages/login/SuccessMessage")
);
const SuccessMessageRecovery = React.lazy(
  () =>
    import("../views/PublicPages/PasswordTokenRecovery/SuccesMessageRecovery")
);
const SignUpSuccessMessage = React.lazy(
  () => import("../views/PublicPages/Signup/SignUpSuccess")
);
const Layout = React.lazy(() => import("../containers/Layout"));
export const AppRouter = () => {
  return (
    <HashRouter>
      <React.Suspense
        fallback={
          <div className="loader">
            <i className="fa fa-cog fa-spin" />
          </div>
        }
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/recover-password" element={<PasswordTR />} />
          <Route path="/success-message" element={<SuccessMessage />} />
          <Route path="/email-check" element={<VerificationMessage />} />
          <Route path="/smr" element={<SuccessMessageRecovery />} />
          <Route path="/signup-success" element={<SignUpSuccessMessage />} />
          <Route path="/signup-success-gmail" element={<SignUpSuccessGmail />} />

          <Route path="/" element={<Layout />}>
            <Route>
              {routes.map((route: IAcceptAll, idx) => {
                //
                return (
                  <Route
                    key={idx}
                    path={route.path}
                    // name={route.name}
                    element={<PrivateRoutes path={route.path}><route.component /></PrivateRoutes>}
                  />
                );
              })}
            </Route>
          </Route>
        </Routes>
      </React.Suspense>
    </HashRouter>
  );
};
