import React from "react";

import { TimeScaleModel } from "@syncfusion/ej2-react-schedule";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch,  faSpinner } from "@fortawesome/pro-solid-svg-icons";
import Dropdown from "../../../components/UI/Dropdown";
import { DropdownOptions } from "../../../components/UI/Dropdown";
import { colorByType, getNameVisitByType } from "../../../components/patients/Typpy/TyppyVisit";
import { useGlobalState } from "use-global-state-react";
import moment from "moment";
import search_cert_icon from "../../../assets/icons/search_cert.svg";
import { LoaderSpinner } from "../../../components/UI";
import { getVisitTypeToString } from "../../../helpers/globals";

export const weekTimeScale: TimeScaleModel = {
    enable: true,
    slotCount: 3,
    interval: 60
}

type patientInfoTemplate = {
    FirstName: string,
    groupT: string,
    LastName: string,
    PatientId: string,
    Address: string,
    onClick: Function
};

type patientSearchInfoTemplate = {
    NameList: string,
    onSelect: Function,
    onSearch: Function
};
type visitsSearchInfoTemplate = {
    NameList: string,
    onSelect: Function,
    onSearch: Function
};

export type dateRange = {
    startDate: Date,
    endDate: Date,
    inUse: boolean,
}

const firstLeetersName = (name: string): string => {
    const words = name.split(' ');
    return words.map(v => (v.length === 0) ? "" : v[0]).join('')
}

export const eventTemplateHandlerMonth = (props: { [key: string]: Object }): JSX.Element => {
    const name: string = 'template-visit-month visit-';
    const userdata:string=sessionStorage.getItem("userData_Apex") || "";
    const formateddata: any = JSON.parse(userdata)
    let backgoundVisitColor = "#fff";

      Object.keys(formateddata.visitType).map((visit)=> {

          const compareVisitName = colorByType(props.colorType.toString()).toLowerCase()==="routinevisit"?"treatment" : colorByType(props.colorType.toString()).toLowerCase();



          if(formateddata.visitType[visit].name.toString().toLowerCase()=== compareVisitName){
              backgoundVisitColor=formateddata.visitType[visit].color;
              return ;
            }

      })
      const styleBorderColor = `solid 4px ${backgoundVisitColor}`;

    return (<div className={name}
        style={{borderBottom:styleBorderColor}}
    >
        {/* <p className="template-visit-content visit-title">{props.clinitianType === "ADMIN" ? "IT ADMIN": props.clinitianType}</p> */}
        <p className="template-visit-content visit-title uppercase">{firstLeetersName(props.title.toString())}</p>

    </div>);
};

export const eventTemplateHandlerWeek = (props: { [key: string]: Object }): JSX.Element => {
    const duration = moment.duration(moment(props.end).diff(moment(props.start))).asHours();
    const durationFlag = duration < 0.34;
    const userdata:string=sessionStorage.getItem("userData_Apex") || "";
    const formateddata: any = JSON.parse(userdata)
    let backgoundVisitColor = "#fff";

      Object.keys(formateddata.visitType).map((visit)=> {
          const compareVisitName = colorByType(props.colorType.toString()).toLowerCase()==="routinevisit"?"treatment" :colorByType(props.colorType.toString()).toLowerCase()==="roc"?"resumptionofcare" :colorByType(props.colorType.toString()).toLowerCase();

          if(formateddata.visitType[visit].name.toString().toLowerCase()=== compareVisitName){
              backgoundVisitColor=formateddata.visitType[visit].color;
              return ;
            }

      })
      const styleBorderColor = `solid 4px ${backgoundVisitColor}`;


    const name: string = 'visit-compress-strong flex flex-col items-stretch justify-center px-[3px] template-visit-week gap-y-2';
    const count: number = props.collitionCount as number;

    if (count > 0 || durationFlag)
        return (<div className={name} style={{borderBottom:styleBorderColor}} >
            <p className={(durationFlag ? "visit-compress " : "") + "visit-resize-oposite grow content-center template-visit-content visit-title-alone uppercase"}>{firstLeetersName(props.title.toString())}</p>
            <p className="visit-resize shrink grow-0 content-center template-visit-content visit-type uppercase">{getNameVisitByType(props.colorType.toString())}</p>
            <p className="visit-resize shrink grow content-center template-visit-content visit-title">{props.title}</p>
            <div className="visit-resize shrink grow-0 flex max-h-[20px] template-visit-content visit-footer">
                <p className="visit-resize shrink template-visit-content visit-footer-content visit-distance">{props.distance}</p>
                <p className="visit-resize shrink template-visit-content visit-footer-content visit-location">{props.cityName}</p>
            </div>
        </div>)
    else if(duration === 0.5){
      return  (<div className={name} style={{borderBottom:styleBorderColor}}>
        <p className="grow-0 template-visit-content visit-type uppercase">{getNameVisitByType(props.colorType.toString())}</p>
        <p className="grow-0 content-center template-visit-content visit-title-footer">{props.title}</p>
      </div>);
    }else
        return (<div className={name} style={{borderBottom:styleBorderColor}}>
            <p className="grow-0 template-visit-content visit-type uppercase">{getNameVisitByType(props.colorType.toString())}</p>
            <p className="grow-0 content-center template-visit-content visit-title">{props.title}</p>
            <div className="grow-0  max-h-[20px] template-visit-content visit-footer">
                <p className="template-visit-content visit-footer-content visit-distance">{props.distance}</p>
                <p className="template-visit-content visit-footer-content visit-location">{props.cityName}</p>
            </div>
        </div>);
};

export const eventTemplateHandlerDay = (props: { [key: string]: Object }): JSX.Element => {
    const name: string = 'template-visit-day';
    const userdata:string=sessionStorage.getItem("userData_Apex") || "";
    const formateddata: any = JSON.parse(userdata)
    let backgoundVisitColor = "#fff";

      Object.keys(formateddata.visitType).map((visit)=> {

          const compareVisitName = colorByType(props.colorType.toString()).toLowerCase()==="routinevisit"?"treatment" : colorByType(props.colorType.toString()).toLowerCase();



          if(formateddata.visitType[visit].name.toString().toLowerCase()=== compareVisitName){
              backgoundVisitColor=formateddata.visitType[visit].color;
              return ;
            }

      })
      const styleBorderColor = `solid 4px ${backgoundVisitColor}`;


    return (<div className={name} style={{borderBottom:styleBorderColor}}>

        <p className="template-visit-content visit-type uppercase">{getNameVisitByType(props.colorType.toString())}</p>
        <p className="template-visit-content visit-title">{props.title}</p>
        <div className="template-visit-content visit-footer">
            <p className="template-visit-content visit-footer-content visit-distance">{props.distance}</p>
            <p className="template-visit-content visit-footer-content visit-location">{props.cityName}</p>
        </div>
    </div>);
};

export const DayTemplateHandleMonth = (props: { [key: string]: Object }): JSX.Element => {


    const text = moment(props.date.toString()).date();
    //Remove by Reza
   // const [dateRange,] = useGlobalState<dateRange>("DATE_RANGE_VISIT_ADD", { startDate: new Date(), endDate: new Date(), inUse: false });
    // const myDate: Date = moment(props.date.toString()).toDate();
    // if ((dateRange.startDate > myDate || dateRange.endDate < myDate) && dateRange.inUse)
    //     return (<FontAwesomeIcon color="#EC6B66" icon={faClose} />);
    // else
        return (<div>{text}</div>);
}

const dayName: string[] = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

export const DayTemplateHandleWeek = (props: { [key: string]: Object }): JSX.Element => {


    const text = moment(props.date.toString());
    //Removed by Reza
    // const userdata:string=sessionStorage.getItem("userData_Apex") || "";
    // const formateddata: any = JSON.parse(userdata)
    // const [dateRange,] = useGlobalState<dateRange>("DATE_RANGE_VISIT_ADD", { startDate: new Date(), endDate: new Date(), inUse: false });
    // const workingDays:string[] = formateddata.workingDays;
    // const myDate: Date = moment(props.date.toString()).toDate();
    // const isWorkingDay = parseInt(Array.from(workingDays)[myDate.getDay()] )=== 1

    // const isDifferentDate = myDate.getDay() !== dateRange.startDate.getDay()

    // if (((dateRange.startDate > myDate || dateRange.endDate < myDate) && dateRange.inUse  && isDifferentDate) || !isWorkingDay)
    //     return (<div className="e-header-day w-7 items-center"><FontAwesomeIcon color="#EC6B66" size={"3x"} icon={faClose} /> </div>);
    // else
        return (<div>
            <div className="e-header-day">{dayName[text.day()]}</div>
            <div className="e-header-date e-navigate" role="link">{text.date()}</div>
        </div>);
}

// template event list

export const treeTemplateHandler = (props: { [key: string]: Object }): JSX.Element => {
    const propsID = props.id?.toString() ?? ""
    const propsType = props.type?.toString() ?? ""
  const userdata:string=sessionStorage.getItem("userData_Apex") || "";
  const formateddata: any = JSON.parse(userdata)
  let backgoundVisitColor = "#fff";

    Object.keys(formateddata.visitType).map((visit)=> {

        const compareVisitName =  propsType.toLowerCase()==="routinevisit"?"treatment" : propsType.toLowerCase()==="roc"?"resumptionofcare" :propsType.toLowerCase();

        if(formateddata.visitType[visit].name.toString().toLowerCase()=== compareVisitName){
            backgoundVisitColor=formateddata.visitType[visit].color;
            return ;
          }

    })


    if (props.groupT)
        return (<span className='pre-visit-box my-2 content-center' key={propsID}>
            <span className="group-title text-center text-capitalize py-2">{props.groupT === "RoutineVisit" ? "Routine Visit" : props.groupT}</span>
        </span>
        );

    const circle: string = "visit-" + propsType.toLowerCase() + " circle-t";
    return (<span className='pre-visit-box my-2' key={propsID}>
        <span className={circle} style={{background:backgoundVisitColor}} />
        <span className="info-container">
            <span className="title mb-2 text-capitalize"> {props.patientName} </span>
            <span className="description"> {propsType.toUpperCase() === "ROCE" ? "EVALUATION": getVisitTypeToString(propsType).toUpperCase()} {props.city} {props.distance} </span>
        </span>
    </span>);
};

export const patientBoxTemplate = (props: patientInfoTemplate) => {
    if (props.groupT && props.groupT !== "")
        return (
            <span className="patient-box-group content-center" key={props.PatientId} >
                <span className="text-4xl font-semibold text-center text-uppercase py-2">{props.groupT}</span>
            </span>
        );

    return (
        <span className="patient-box" onClick={() => props.onClick(props.PatientId)} id={props.PatientId} key={props.PatientId} >
            <span className="title"> {props.FirstName} {props.LastName} </span>
            <span className="description"> {props.Address} </span>
        </span>
    );
}

export const patientSearchBoxTemplate = (props: patientSearchInfoTemplate) => {
    const optionsD: DropdownOptions[] = [
        { name: 'None', holderName: '', eventName: "" },
        { name: 'Alphabetical', holderName: 'Alphabetical', eventName: "lastName" },
        { name: 'Distance', holderName: 'Distance', eventName: "distance" },
        { name: 'Location', holderName: 'Location', eventName: "city" },
    ]

    const onChangeHandler = (e: React.ChangeEvent) => {
        props.onSearch((e.target as any).value.toString().toLowerCase());
    }

    const onChangeSelectHandler = (e: any) => {
        props.onSelect(e.eventName.toString());
    }

    return (
        <div className="col w-100 ml-12 mr-10 mt-16 pl-0">
            <div className="border rounded-8 flex justify-content-between w-100 bg-white items-center pl-2 pr-4 py-3 mb-8 font-14">
                <input type="text" onChange={onChangeHandler} placeholder="patients, visit types..." className="border-0 relative w-full px-3 transition ease-in-out m-0 height-22" />
                <FontAwesomeIcon color="#D2D6DB" icon={faSearch} />
            </div>
            <Dropdown onSelect={onChangeSelectHandler} options={optionsD} preText="Group by: " />
        </div>
    )
}

export const visitsSearchBoxTemplate = (props: visitsSearchInfoTemplate) => {
    const optionsD: DropdownOptions[] = [
        { name: 'None', holderName: '', eventName: "" },
        { name: 'Visit type', holderName: 'Visit type', eventName: "type" },
        { name: 'Location', holderName: 'Location', eventName: "city" },
        { name: 'Patient', holderName: 'Patient', eventName: "patientName" },
    ]

    const onChangeHandler = (e: React.ChangeEvent) => {
        props.onSearch((e.target as any).value.toString().toLowerCase());
    }

    const onChangeSelectHandler = (e: any) => {
        props.onSelect(e.eventName.toString());
    }

    return (
        <div className="col w-100 ml-12 mr-10 mt-16 pl-0">
            <div className="border rounded-8 flex justify-content-between w-100 bg-white items-center pl-2 pr-4 py-3 mb-8 font-14">
                <input type="text" onChange={onChangeHandler} placeholder="patients, visit types..." className="border-0 relative w-full px-3 transition ease-in-out m-0 height-22" />
                <FontAwesomeIcon color="#D2D6DB" icon={faSearch} />
            </div>
            <Dropdown onSelect={onChangeSelectHandler} options={optionsD} preText="Group by: " />
        </div>
    )
}

export const VisitsSearchWaiting = () => {
    return (
        <div className="flex flex-col justify-center justify-items-center items-center w-full h-full">
            <img className="h-[72px] w-[72px] mb-[32px] animate-pulse" src={search_cert_icon} alt="" />
        </div>
    )
}
