import axios from "axios";
import { IResponse } from "../interfaces/IResponses";
import { IAuth, IUser, IPwdRecovery, IAuthSocial } from "../interfaces/IUser";
import { GetData } from "../Services/Api";
import AuthAxios from '../interceptors/axios-config'

export const authApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/Auth",
});


export const userLogin = async (user: IAuth) => {
  return await authApi.post<IResponse<IUser>>("/Login", user);

};

export const userLoginSocial = async (user: IAuthSocial) => {
  return await authApi.post<IResponse<IUser>>("/login-social", user);
};

export const userSignUpSocial = async (user: IAuthSocial) => {
  try {
  const result = await authApi.post<IResponse<any>>("/signup-social", user);
  return result;

  } catch (error) {
   return {  data: {message:error, status:500,data:""},
   }
  }


};

export const userLinkSocial = async (user: IAuthSocial) => {
  return await AuthAxios.post<IResponse<any>>(process.env.REACT_APP_API_URL + "/Auth/link-social", user);
};

export const userForgot = async (mail: string) => {
  return await authApi.get<IResponse<IUser>>(`/ResetPassword?email=${mail}`);
};


export const userSignUp = async (user: IAuth) => {
  return await authApi.post<IResponse<any>>("/Signup", user);
};

export const userRecover = async (pwd: IPwdRecovery) => {
  return await authApi.post<IResponse<any>>("/ResetPassword", pwd);
};
export const agencyEmailCheck = async (token: string) => {
  return await authApi.get<IResponse<any>>("/cae/" + token);
}
export const csvReportForAdmins = async () => {
  return await GetData("/Utility/GenMyEmail");
};

