import { createContext } from "react";
import { IPatient, IPatientSchedule } from "../../Services/Interfaces";
import { IVisitHistory } from "../../Services/Interfaces/IVisitHistory";
import { IClinician } from "../../Services/Interfaces/IClinician";
import { ICertification } from "../../Services/Interfaces/ICertification";

type PatientContextProps = {
  patientState: IPatient;
  setPatient: (patient: IPatient) => void;
  setEvaluation: (date: string) => void;
  setFrequency: (frequency: string) => void;
  setVisitDates: (visitDates: []) => void;
  setCertifications: (certifications: ICertification[]) => void;
  addCertification: (certification: ICertification) => void;
  deleteCertification: (id: number) => void;
  setVisitHistory: (visitHistory: IVisitHistory[]) => void;
  setPatientSchedule: (patientSchedule: IPatientSchedule[]) => void;
  setAssignedClinicians: (assignedClinicians: IClinician[]) => void;
  setDischarge: (discharge: string, dischargeType: string) => void;
  setRecert: (recert: string, recertType: string) => void;
  setComming30: (comming30: string) => void;
  setEOC: (eoc: string) => void;
};

export const PatientContext = createContext<PatientContextProps>(
  {} as PatientContextProps
);
