import React, { useState, forwardRef } from "react";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PROPERTIES_INPUT: string[] = [
  "accept",
  "alt",
  "autoComplete",
  "autoFocus",
  "capture",
  "checked",
  "crossOrigin",
  "disabled",
  "enterKeyHint",
  "form",
  "formAction",
  "formEncType",
  "formMethod",
  "formNoValidate",
  "formTarget",
  "height",
  "list",
  "max",
  "maxLength",
  "min",
  "minLength",
  "multiple",
  "name",
  "pattern",
  "placeholder",
  "readOnly",
  "required",
  "size",
  "src",
  "step",
  "type",
  "value",
  "width",
  "onChange",
];

export const MyTextInputRef = ({
  register,
  errors,
  label,
  id,
  name,
  span,
  icon,
  inEdition,
  plusClass,
  type,
  fieldName,
  isDirty,
  i,
  ...props
}: any) => {
  const [showPass, changeShowPass] = useState(false);

  let okField = false;

  if (fieldName === "phonList") {
    okField = isDirty?.[i]?.name && errors?.phonList?.[i]?.name === undefined;
  } else if (fieldName === "phonMDList") {
    okField = isDirty?.[i]?.name && errors?.phonMDList?.[i]?.name === undefined;
  } else {
    okField = isDirty && errors[name] === undefined;
  }

  const handleShow = () => {
    changeShowPass(!showPass);
  };

  let tmpProps = {};
  for (let i in props) {
    if (PROPERTIES_INPUT.some((value) => value == i)) {
      // @ts-ignore
      tmpProps[i] = props[i];
    }
  }

  return (
    <div className={`text__input`}>
      <label htmlFor={id || name}>
        {label}
        {span ? <span className="span-required">{span}</span> : ""}
      </label>
      {icon && <i className={`${icon} ${errors[name] && "icon__error"}`} />}
      <input
        {...register(name)}
        {...tmpProps}
        style={{ paddingLeft: icon ? "44px" : "16px" }}
        className={
          fieldName == "phonList"
            ? `text-input ${errors?.phonList?.[i]?.name && "input__error"} ${
                !icon ? "pl-8" : "pl-[54px]"
              } ${okField && "input_edit"} ${plusClass ?? ""}`
            : fieldName == "phonMDList"
            ? `text-input ${errors?.phonMDList?.[i]?.name && "input__error"} ${
                !icon ? "pl-8" : "pl-[54px]"
              } ${okField && "input_edit"} ${plusClass ?? ""}`
            : `text-input ${errors[name] && "input__error"} ${
                !icon ? "pl-8" : "pl-[54px]"
              } ${okField && "input_edit"} ${plusClass ?? ""}`
        }
        type={type == "password" ? (showPass ? "text" : "password") : type}
      />
      {type == "password" ? (
        <button className=" img__input_show" type="button" onClick={handleShow}>
          <FontAwesomeIcon
            className="user-eye-icon"
            icon={showPass ? faEye : faEyeSlash}
          />
        </button>
      ) : (
        ""
      )}
    </div>
  );
};
