import React, { FormEvent } from "react";
import {
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";

import CardSection from "./CardSection";
import { PostData } from "../../../Services/Api";
import { ShowAlert, UpdateUserDataInLocal } from "../../../Services/utility";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const stripePaymentMethodHandler = async (result: any) => {
    if (result.error) {
      // Show error in payment form
      ShowAlert(result.error.message, "error");
    } else {
      // Otherwise send paymentMethod.id to your server (see Step 4)
      try {
        const res = await PostData("/Card/SaveCardSafe", {
          payment_method_id: result.paymentMethod.id,
        });
        // Handle server response (see Step 4)
        if (res.status === 200) {
          ShowAlert(res.message);
          UpdateUserDataInLocal();
        } else {
          throw res;
        }
      } catch (error: any) {
        ShowAlert(error.message, "error");
      }
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    try {
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement)!,
        billing_details: {
          // Include any additional collected billing details.
          // name: "Jenny Rosen",
        },
      });
      stripePaymentMethodHandler(result);
    } catch (error: any) {
      ShowAlert(error.message, "error");
    }
  };

  return (
    // <form onSubmit={handleSubmit}>
    <CardSection handleSubmit={handleSubmit} />

    // </form>
  );
}
