import moment from "moment";
import { GetData, GetPatient } from "../Services/Api";
import { FormatDate } from "../Services/utility";

export const getPatient = async (id: any) => {
  const { data: patient } = await GetPatient(id);
  return patient;
};

export const getVisitHistory = async (id: any) => {
  const { data } = await GetData(
    `/PatientVisitHistory/GetVisitHistory?pagenumber=1&pagesize=1000&patientid=${id}`
  );
  if (!data) return;
  const visitHistory = data.map((value: any) => ({
    date: new Date(moment(value.start).utc().format("MMM DD YYYY")),
    class: value.colorType,
  }));
  return visitHistory;
};

export const getRecertifications = async (id: any) => {
  return await GetData(`/PatientRecertification/GetAll?patientId=${id}`);
};

export const getFrequency = async (id: any) => {
  const { data } = await getRecertifications(id);
  const { frequency } = data;
  if (frequency) return frequency.split(",");
};

export const getPatientSchedule = async (patientId: any, recertId: any) => {
  const { data } = await GetData(
    `/PatientSchedule/Get?patientId=${patientId}&recertId=${recertId}`
  );
  if (data) return data.items;
};

export const getClinician = async (patientId: any) => {
  const { data } = await GetData(
    `/PatientProfile/GetCliniciansAssignedToPatient?patientId=${patientId}`
  );
  if (data) return data;
};

export const getVisitDates = async (patientId: any, date: Date) => {
  const { data } = await GetData(
    `/MyDocuments/MyDocumentsDue?startdate=${FormatDate(
      date
    )}&patientid=${patientId}`
  );
  return data;
};

export const getScheduledVisits = async (
  start: string,
  end: string,
  marked: boolean,
  clinicianId?: any,
  patientId?: any,
) => {
  const result: any = await GetData(
    `/ManualScheduling/ScheduledVisits?dayIni=${start}&dayEnd=${end}${
      clinicianId ? "&clinicianId=" + clinicianId : ""
    }&${patientId ? "&patientId=" + patientId:""}&marked=${marked}`
  );
  return result;
};

export const getProjectedCertifications = async (
  start: string,
  end: string,
  patientId: string,
  clinitianId?:string
) => {
  const result: any = await GetData(
    `/PatientProfile/GetProyectedCertification?ini=${start}&end=${end}&${patientId && "&patientId=" + patientId}&${clinitianId && "&clinitianId=" + clinitianId}`
  );
  return result;
};
