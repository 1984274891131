import {
  faCaretDown,
  faCaretUp, faCheck, faClose, faDirections, faHouseMedical, faHouseMedicalCircleXmark, faLock, faLockKeyhole, faLockKeyholeOpen, faPhone, faSpinner,
  faTrash, faTrashCan, faXmark
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import { id } from "date-fns/locale";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import {
  changeVisitType,
  getDischargeRecertConflic,
  visitsDelete,
  visitsPatientGet,
  visitsUpdate
} from "../../api/schedulingApi";
import { useClickOutSide } from "../../hooks/useClickOutSide";
import { useEscapeAndClickOutSide } from "../../hooks/useEscapeAndClickOutSide";
import { IVisitsToUpdate } from "../../interfaces/IScheduling";
import { UpdatePatientStatus } from "../../Services/Api";
import * as utility from "../../Services/utility";
import { IsAdmin, IsSuperAdmin, ShowAlert } from "../../Services/utility";
import { useAppDispatch } from "../../store/hooks";
import { addCertification } from "../../store/slices/certifications";
import { dateTransformerDetails } from "../patients/DetailsCard";
import { getNameVisitByType } from "../patients/Typpy/TyppyVisit";
import { RecycleSVG } from "../RecycleSVG";
import { DefineVisitModal } from "./DefineVisitModal";

type vdetailprops = {
  onClose: Function;
  onChangeVisit: Function;
  onDelete: Function;
  vistData: { [key: string]: Object };
  visitId: number;
  setRefreshData: Function;
  handleOpenCloseVisitDetail: any;
  start: Date;
  end: Date;
};

export type visitInfoProps = {
  isOpen: boolean;
  vistData: { [key: string]: Object };
  vistDataId: number;
};

const colorByType = (type: string | undefined): string => {
  if (type === undefined) return "";

  switch (type) {
    case "D":
      return "Discharge";
    case "RV":
      return "Routine Visit";
    case "30":
      return "30DRE";
    case "A":
      return "Admission";
    case "E":
      return "Evaluation";
    case "R":
      return "Recert";
    case "ROC":
      return "ROC";
      case "S":
        return "Supervisor";
  
    default:
      return "";
  }
};

const DetailBarr = (props: {
  expanded: boolean;
  pending: boolean;
  onPress: Function;
}) => {
  const hanleExpantion = () => {
    if (props.pending) return;
    props.onPress();
  };

  return (
    <div
      className={
        "flex flex-row " + (props.expanded ? "mt-[20px]" : "mt-[24px]")
      }
    >
      <div className="grow line-expand mr-[12px]" />
      <div className="grow-0">
        <div className="outer-radius  cursor-pointer" onClick={hanleExpantion}>
          <div className="flex items-center place-content-center inter-radius">
            {props.pending ? (
              <FontAwesomeIcon
                size="2x"
                color="#FFFFFF"
                className="animate-spin "
                icon={faSpinner}
              />
            ) : (
              <FontAwesomeIcon
                size="2x"
                color="#FFFFFF"
                icon={props.expanded ? faCaretUp : faCaretDown}
                aria-hidden="true"
              />
            )}
          </div>
        </div>
      </div>
      <div className="grow line-expand ml-[12px]" />
    </div>
  );
};

const DetailsRow = (props: {
  title: string;
  textu: string;
  truncate?: boolean;
}) => {
  return (
    <div className="flex flex-row">
      <span className="visit-detail-row-title">{props.title}: </span>
      <span
        className={
          "visit-detail-row-title soft-text" +
          (props.truncate ? " truncate" : "")
        }
      >
        {props.textu}
      </span>
    </div>
  );
};

const VisitDetailsComponent = (props: {
  show: boolean;
  visitData: { [key: string]: Object };
  clinitianType: string | undefined;
}) => {
  const rtype = props.visitData?.recertType?.toString();

  let rPrint = "Window";
  if (rtype !== undefined && rtype !== "Range") rPrint = "Date";

  const catDisiplines = (text: string | undefined): string => {
    if (text === undefined || text === "") return "";
    return text.slice(1);
  };

  const changeShowFrequency = (frequency:any)=> {
    const haveMoreThat4frequency = frequency?.toString().split(",").length > 4
    const result = haveMoreThat4frequency ? frequency?.toString().split(",").slice(0, 4)+"..." : frequency;
    return result
  }

  if (props.show)
    return (
      <div className="flex flex-col mt-[24px] mr-[10px] ml-[10px]">
        {/* <span className="visit-detail-header">Patient Details</span> */}
        <DetailsRow
          title="Address"
          textu={props.visitData?.address?.toString()}
        />
        <div className="grid grid-cols-2 gap-[16px] mt-[16px]">
          <DetailsRow
            title="Admission"
            textu={utility.getDate(props.visitData?.admission, "L")}
          />
           <DetailsRow
            title={"Recert " + rPrint}
            textu={dateTransformerDetails(
              props.visitData?.recert?.toString(),
              rtype
            )}
          />
          <DetailsRow
            title={props.clinitianType + " Evaluation"}
            textu={utility.getDate(props.visitData?.evaluation, "L")}
          />
          <DetailsRow
            title="End of Cert"
            textu={moment(props.visitData?.currCert)
              .add(59, "days")
              .format("L")}
          />
            <DetailsRow
              title="Next 30DRE"
              textu={utility.getDate(props.visitData?.comming30DRE, "L")}
            />
           <DetailsRow
            title="Frequency"
            textu={changeShowFrequency(props.visitData?.frequency)}
          />
          <DetailsRow
            title="Previous 30DRE"
            textu={utility.getDate(props.visitData?.most30DRE, "L")}
          />

          <DetailsRow
            title="Disciplines"
            truncate={true}
            textu={catDisiplines(props.visitData?.disciplines?.toString())}
          />


        </div>
      </div>
    );
  else return <div></div>;
};

const VisitDetailPopup = (props: vdetailprops) => {
  // const start = (props.vistData.start as Date).get
  const navigate = useNavigate();


  const hoursMinStart = (props.vistData?.start as Date)?.toLocaleTimeString('en-US', {

    hour: '2-digit',
    
    minute: '2-digit',
    
    });
  const hoursMinEnd = (props.vistData?.end as Date)?.toLocaleTimeString('en-US', {

    hour: '2-digit',
    
    minute: '2-digit',
    
    });

    const end :any= props.vistData?.end as Date
   const now:any = new Date()
   const diffInDays = Math.floor((now - end) / (1000 * 60 * 60 * 24));





    
    const rangeDate = hoursMinStart +" - "+ hoursMinEnd
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);


  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState(true);
  const [pending, setPending] = useState(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [completness, setCompletness] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(
    props.vistData.isCompleted as boolean
  );
  const [visitDataConflic, setVisitDataConflic] = useState({
    cityName: "",
    clinitianId: 0,
    desition: 0,
    distance: "",
    name: "",
    patientId: 0,
  });
  
  const getVisitName = colorByType(props.vistData?.colorType?.toString())
  const isRecertOrDischarge = getVisitName === "Recert"
  const getVisitInvertName = getVisitName === "Discharge" ? "Recert" : getVisitName === "Recert" ? "Discharge" : ""

  const [visitData, setVisitData] = useState<{ [key: string]: Object }>({});

  //Add 60 Days to old Recert to obtain the new
  const newRecert = visitData?.currCert && moment(visitData?.currCert ).add(60, "days").toDate();

  const [openDefineVisit, setOpenDefineVisit] = useState<boolean>(false);
  useEffect(() => {
    const getVisitData = async () => {
      try {
        setPending(true);
        const { data: result } = await visitsPatientGet(props.visitId);

        if (result.status !== 200) {
          ShowAlert(result.message, "error");
        } else {
          setVisitData(result.data);
        }
      } catch (error: any) {
        ShowAlert(error.message, "error");
      } finally {
        setPending(false);
      }
    };

    if (props.visitId !== 0) getVisitData();
  }, [props.visitId]);

  const handleDeleteV = () => {
    setDeleting(true);
    const id: number = props.vistData?.id as number;
    if (id !== undefined) {
      visitsDelete(id)
        .then((data: any) => {
          if (data?.data?.status !== 200) {
            ShowAlert(data?.data?.message, "error");
            setDeleting(false);
          } else {
            props.onDelete(id);
          }
        })
        .catch((error: any) => {})
        .finally(() => {
          setDeleting(false);
        });
    }
  };

  const handleSoftUpdateVisit = (
    toUpdt: IVisitsToUpdate,
    callBack: Function | undefined
  ) => {
    visitsUpdate(toUpdt)
      .catch((err: any) => {
        ShowAlert(err, "error");
      })
      .then((data: any) => {
        if (data?.data?.status === 200) {
          // todo: callendar update : lock, miss, complete
          setCompleted(toUpdt.isCompleted === "true" ? true : false);
          //Create other Certification
          if(getVisitName === "Recert" && toUpdt.isCompleted === "true"){
            dispatch(
              addCertification({
                patientId: parseInt(toUpdt.patientId),
                recertificationDate:  newRecert,
              })
            )
              .then(unwrapResult)
              .then((originalPromiseResult) => {
                ShowAlert("Certification created successfully")
              })
              .catch((rejectedValueOrSerializedError) => {
                ShowAlert(rejectedValueOrSerializedError, "error");
              });
          }
          if(getVisitName === "Discharge" && toUpdt.isCompleted === "true"){
             UpdatePatientStatus(parseInt(toUpdt.patientId),"Discharged").then((result) => {
               console.log(result)
             })
          }
        //   if(getVisitName === "Discharge" && toUpdt.isCompleted === "false"){
        //     UpdatePatientStatus(parseInt(toUpdt.patientId),"Active").then((result) => {
        //       console.log(result)
        //     })
        //  }
        } else {
          ShowAlert(data?.data?.message, "error");
        }
      })
      .finally(() => {
        if (callBack !== undefined) callBack(false);
      });
  };

  const handleChangeVisit = () => {
    const id = props.vistData.id as number
    changeVisitType(id).then((result)=> {
      const response = result?.data
      if(response.status !== 200){
        ShowAlert(response.message,"error")
      }else{
        props.onChangeVisit()
      }
    }).catch((error)=> {
      ShowAlert("Ocurred an error please try again","error")
      
    })
  }

  // LEGEND diffInDays=0 TODAY, -1 is Afer Today
  const handleCompleteVisit = () => {
    if(!completed && diffInDays <= 1){

    const toUpdt: IVisitsToUpdate = {
      patientId: props.vistData.patientId.toString(),
      recertId: props.vistData.recertId.toString(),
      clinitianId: props.vistData.clinitianId.toString(),
      start: (props.vistData.start as Date).toISOString(),
      end: (props.vistData.end as Date).toISOString(),
      isLocked: props.vistData.isLocked.toString(),
      isCompleted: (completed ? false : true).toString(),
      id: props.vistData.id.toString(),
    };
    props.setRefreshData(true);
    setCompletness(true);
    handleSoftUpdateVisit(toUpdt, setCompletness);
    const duration = moment.duration({ weeks: 1 });
    const startDate = moment(props.start).add(duration).toDate();
    const endDate = moment(props.end).add(duration).toDate();
    getDischargeRecertConflic(
      startDate.toISOString(),
      endDate.toISOString()
    ).then(({ data }) => {
      const filterVisitConflict = data.data.find(
        (value: any) => value.patientId === props.visitId
      );
      if (data.data.length > 0 && filterVisitConflict?.patientId > 0) {
        setVisitDataConflic(data.data[0]);
        setOpenDefineVisit(true);
      }
    });
  } else if( diffInDays <= 0){

    
    const toUpdt: IVisitsToUpdate = {
      patientId: props.vistData.patientId.toString(),
      recertId: props.vistData.recertId.toString(),
      clinitianId: props.vistData.clinitianId.toString(),
      start: (props.vistData.start as Date).toISOString(),
      end: (props.vistData.end as Date).toISOString(),
      isLocked: props.vistData.isLocked.toString(),
      isCompleted: (completed ? false : true).toString(),
      id: props.vistData.id.toString(),
    };
    props.setRefreshData(true);
    setCompletness(true);
    handleSoftUpdateVisit(toUpdt, setCompletness);
    const duration = moment.duration({ weeks: 1 });
    const startDate = moment(props.start).add(duration).toDate();
    const endDate = moment(props.end).add(duration).toDate();
    getDischargeRecertConflic(
      startDate.toISOString(),
      endDate.toISOString()
    ).then(({ data }) => {
      const filterVisitConflict = data.data.find(
        (value: any) => value.patientId === props.visitId
      );
      if (data.data.length > 0 && filterVisitConflict?.patientId > 0) {
        setVisitDataConflic(data.data[0]);
        setOpenDefineVisit(true);
      }
    });
  }


    //   props.handleOpenCloseVisitDetail(false);
  };
  //   const handleOpenCloseVisitDetail = (value: boolean) => {
  //     setOpenDetails({
  //       ...openDetails,
  //       isOpen: value,
  //     });
  //   };

  const handleCloseModal = () => props.handleOpenCloseVisitDetail(false);
  // const wrapperVisitDetail = useRef<HTMLDivElement>(null);
  // const wrapperDeleteRef = useRef<HTMLDivElement>(null);

  const wrapperVisitDetail = useEscapeAndClickOutSide(() => {
    handleCloseModal();
  },
  () => {
    handleCloseModal();
  }
  );
  const wrapperDeleteRef = useEscapeAndClickOutSide(() => {
    setOpenConfirmDelete(false);
  },
  () => {
    setOpenConfirmDelete(false);
  }
  );



  return openDefineVisit === true && visitDataConflic.name.length > 0 ? (
    <DefineVisitModal
      setOpen={setOpenDefineVisit}
      visitDataConflic={visitDataConflic}
    />
  ) : openConfirmDelete ? (

         <div
      className="flex w-[365px] h-[309px] delete_patient_popup bg-white"
      ref={wrapperDeleteRef}
    >
      <div className="py-[32px] px-[24px] flex flex-col w-[100%] place-content-between">
        <div>
          <div className="flex flex-row">
            <div className="flex justify-center basis-[95%]">
              <h2 className="text-center ">Delete visit</h2>
            </div>
            <div className="flex justify-end basis-[5%">
              <button
                title="close-user-button"
                type="button"
                onClick={() => {
                  setOpenConfirmDelete(false);
                }}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  className="modal-patient-button-close-bulk"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
          <hr />
          <h3 className="text-center mt-[24px]">Are you sure?</h3>
          <p className="text-center mt-[24px]">
            Delete this visit of {props.vistData?.title}
          </p>
        </div>
        <div className="flex place-content-between">
          <button
            className="pop_btn h-[35px] flex items-center place-content-center px-[50px] rounded-[6px]"
            onClick={() => {
              setOpenConfirmDelete(false);
            }}
          >
            Cancel
          </button>
          <button
            className="pop_btn pop_delete_btn h-[35px] flex items-center place-content-center px-[50px] rounded-[6px]"
            onClick={() => handleDeleteV()}
          >
            {deleting ? (
              <FontAwesomeIcon
                icon={faSpinner}
                className="animate-spin"
                size="1x"
              />
            ) : (
              "Delete"
            )}
          </button>
        </div>
      </div>
    </div>
    
  // </Popup>
  ):
    (
    <div
      className={
        "flex visit-modal-size" +
        (expanded ? " expanded " : " ") +
        "visit_detail_popup bg-white"
      }
      ref={wrapperVisitDetail}
    >
      <div className="p-[24px] flex flex-col w-[100%] h-[100%]">
        <div className="flex flex-row justify-between w-[100%]">
          <div className="visit-detail-title no-underline hover:underline cursor-default" 
            onClick={() => navigate(`/patients/${props.vistData?.patientId}`)}
          >
            {props.vistData?.title} (
            {getNameVisitByType(props.vistData?.colorType?.toString())})
          </div>
          <div
            className="flex items-center place-content-center w-[20px] h-[20px]"
            onClick={() => {
              props.onClose();
            }}
          >
            <FontAwesomeIcon
              size="2x"
              color="#384250"
              icon={faXmark}
              className="cursor-pointer"
            />
          </div>
        </div>
        <div
          className={
            "flex flex-row mt-[4px] " +
            (expanded ? "mb-[10px]" : "mb-[24px]") +
            " visit-detail-citystate"
          }
        >
          {visitData?.cityName ? visitData.cityName.toString().trim() + ", ": ""}
          {visitData?.stateName}{" "}
        </div>
        <div className="visit-detail-range-date">
            {rangeDate}

        </div>
        <hr />
        {/* Visit operations */}
        <div className="grid grid-cols-3 gap-[16px] px-[101px] ">
          <div className="flex flex-col">
            <div className="visit-butoon-operation" onClick={() => {}}>
              <FontAwesomeIcon
                className="visit-button-icon"
                // strokeWidth={30}
                icon={faDirections}      
                aria-hidden="true"
              />{" "}
            </div>
            <div className="mt-[8px] content-center w-full visit-butoon-text">
              {" "}
              Navigate{" "}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="visit-butoon-operation" onClick={() => {}}>
            <FontAwesomeIcon
                className="visit-button-icon"
                // strokeWidth={30}
                icon={faLockKeyholeOpen}      
                aria-hidden="true"
              />{" "}
            </div>
            <div className="mt-[8px] content-center w-full visit-butoon-text">
              {" "}
              Lock{" "}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="visit-butoon-operation" onClick={() => {}}>
            <FontAwesomeIcon
                className="visit-button-icon"
                // strokeWidth={30}
                icon={faHouseMedicalCircleXmark}      
                aria-hidden="true"
              />{" "}
            </div>
            <div className="mt-[8px] content-center w-full visit-butoon-text">
              {" "}
              Miss Visit{" "}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="visit-butoon-operation" onClick={() => {}}>
            <FontAwesomeIcon
                className="visit-button-icon"
                // strokeWidth={30}
                icon={faPhone}      
                aria-hidden="true"
              />{" "}
            </div>
            <div className="mt-[8px] content-center w-full visit-butoon-text">
              {" "}
              Call{" "}
            </div>
          </div>
          <div className="flex flex-col">
            <div
              className="visit-butoon-operation cursor-pointer"
              onClick={() => {
                if(diffInDays <= 0){
                  setOpenConfirmDelete(true)

                }
              }}
            >
              <FontAwesomeIcon
                className={
                  "h-8 w-8" + (deleting ? " motion-safe:animate-ping" : "")
                }
                color="#FFFFFF"
                stroke="#0BB68C"
                strokeWidth={60}
                icon={faTrashCan}
                aria-hidden="true"
              />{" "}
            </div>
            <div
              className={
                "mt-[8px] content-center w-full visit-butoon-text " +
                (deleting ? "deleting" : "")
              }
            >
              {" "}
              Delete Visit{" "}
            </div>
          </div>
          <div className="flex flex-col">
            <div
              className="visit-butoon-operation cursor-pointer"
          
              onClick={() => handleCompleteVisit()}
            >
              <FontAwesomeIcon
                color="#0BB68C"
                stroke="#0BB68C"
                strokeWidth={30}
                icon={faCheck}
                className={
                  "h-8 w-8" + (completness ? " motion-safe:animate-ping" : "")
                }
                aria-hidden="true"
              />{" "}
            </div>
            <div
              className={
                "mt-[8px] content-center w-full visit-butoon-text " +
                (completness ? "deleting" : "")
              }
            >
              {" "}
              {completed ? "Undo visit completion" : "Complete Visit"} 
            </div>
          </div>

        </div>
        {isRecertOrDischarge &&  
        <div className="flex flex-col content-center"
        style={{paddingTop:"10px"}}
        >
            <div
              className="visit-butoon-operation cursor-pointer"
              onClick={() => handleChangeVisit()}
            >
              <RecycleSVG />
            </div>
            <div
              className="mt-[8px] content-center w-full visit-butoon-text"             
            
            >
              {`Change visit to ${getVisitInvertName}`}
            </div>
          </div>
          }
        <VisitDetailsComponent
          show={expanded}
          visitData={visitData}
          clinitianType={props.vistData?.clinitianType?.toString()}
        />
        <DetailBarr
          expanded={expanded}
          pending={pending}
          onPress={() => setExpanded(expanded ? false : true)}
        />
      </div>
    </div>)
};

export default VisitDetailPopup;
