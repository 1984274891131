import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Pagination, Toolbar } from "../../../components/patients";
import { TableUsers } from "../../../components/patients/TableUsers";
// import { TableUsers } from "../../../components/patients/TableUsers";
import UpgradePlanBanner from "../../../Controls/UpgradePlanBanner";
import { getUserObj, setLocalStorage } from "../../../Services/utility";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { usersLoad } from "../../../store/slices/users";

const Users = () => {
  const dispatch = useAppDispatch();

  const { total } = useAppSelector((state) => state.users);
  const [state, setState] = useState({ showBanner: false, bannerMessage: "" });
  const userLogged = getUserObj();
  const patientTableSize:number = userLogged?.patientTableSize || 10 ;
  const [pagesize, setPagesize] = useState(patientTableSize);
  const [pagenumber, setPagenumber] = useState(1);
  const [sortColumn, setSortColumn] = useState("");
  const [sortDir, setSortDir] = useState("u");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(usersLoad({ pagesize }));
  }, []);

  const handleSizeChange = ({ target }: ChangeEvent<HTMLSelectElement>) => {
    const size = parseInt(target.value);
    if (size === pagesize) return;
    dispatch(
      usersLoad({
        pagesize: size,
        sortField: sortColumn,
        sortDir: sortDir,
        query: searchText,
        pagenumber: pagenumber,
      })
    );
    setPagesize(size);
    setLocalStorage("userData_Apex", JSON.stringify({
      ...userLogged,
      patientTableSize:size
    }));
  };

  const handleSortChange = (column: string, dir: string) => {
    dispatch(
      usersLoad({
        pagesize: pagesize,
        sortField: column,
        sortDir: dir,
        query: searchText,
        pagenumber: pagenumber,
      })
    );
    setSortColumn(column);
    setSortDir(dir);
  };

  const handleSerach = (text: string) => {
    dispatch(
      usersLoad({
        pagesize: pagesize,
        sortField: sortColumn,
        sortDir: sortDir,
        query: text,
        pagenumber: pagenumber,
      })
    );
    setSearchText(text);
  };

  const handleNumberChange = (numb: number) => {
    if (numb === pagenumber) return;
    dispatch(
      usersLoad({
        pagesize: pagesize,
        sortField: sortColumn,
        sortDir: sortDir,
        query: searchText,
        pagenumber: numb,
      })
    );
    setPagenumber(numb);
  };

  return (
    <div className="min-h-[87vh]">
      <Toolbar
        target="User"
        path="/user/add"
        onSearch={handleSerach}
        classButton="button-add-new-user"
        searchPlaceHolder="Search for clinician"
      />

      <div
        className="rounded-[8px] py-10 px-8 min-h-[84%] flex flex-col place-content-between"
        style={{
          backgroundColor: "white",
          margin: "0px 40px",
        }}
      >
        <UpgradePlanBanner
          ShowBanner={state.showBanner}
          BannerMessage={state.bannerMessage}
          OnDismiss={() => {
            setState({
              showBanner: false,
              bannerMessage: "",
            });
          }}
          IsFixed={false}
        />

        <TableUsers sortHandler={handleSortChange} />
        <footer
          className="flex place-content-between pt-10"
          style={{ borderTop: "1px solid #e3e3e3" }}
        >
          <div className="flex flex-row gap-[8px] items-center pagination-dropdown">
            <h6 className="page-list-size">List Size</h6>
            <DropDownListComponent
              dataSource={[10, 15, 20, 25]}
              value={pagesize}
              onChange={handleSizeChange}
              width="69px"
              popupWidth={"80px"}
              popupHeight={"166px"}
            />
          </div>
          <Pagination
            total={total}
            initialNumber={pagenumber}
            action={(pagenum: number) => handleNumberChange(pagenum)}
            pageSize={pagesize}
          />
        </footer>
      </div>
    </div>
  );
};

export default Users;
