import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LocalizationProvider,
  StaticTimePicker
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { format } from "date-fns";
import React, { useState } from "react";
import { DateFormatter, DayPicker } from "react-day-picker";
import {
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue
} from "react-hook-form";
import { convertDateTime24to12 } from "../../helpers/globals";
import { ICreatePatient } from "../../interfaces";

type Props = {
  name: any;
  register: UseFormRegister<ICreatePatient>;
  label: string | JSX.Element;
  selected: any;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
  setInputValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  inputValue: string | undefined;
  clearErrors: UseFormClearErrors<ICreatePatient>;
  isDirty: any;
  errors: any;
  setFieldValue: UseFormSetValue<ICreatePatient>;
  span?: any;
  startDateSelected: Date | undefined
};

export default function DateTimePickerPatient({
  register,
  name,
  label,
  isDirty,
  selected,
  setSelected,
  setInputValue,
  inputValue,
  clearErrors,
  errors,
  setFieldValue,
  span,
  startDateSelected,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = React.useState<any>(inputValue);


  const closePopper = () => {
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    setIsOpen((open) => !open);
  };

  const handleDaySelect = (date?: Date) => {
    if (date) setSelected(date);
  };
  const handleSaveDaySelected = () => {
    const timeSelected = value 

    const dateTimer = ("0" +  new Date(timeSelected).getHours()).slice(-2) + ":" +
    ("0" +  new Date(timeSelected).getMinutes()).slice(-2)

    if (selected) {
      const newDate =    ("0" + (new Date(selected).getMonth()+1)).slice(-2) + "/" + 
      ("0" + new Date(selected).getDate()).slice(-2) + "/" +
      new Date(selected).getFullYear() + " " + dateTimer
      setInputValue(convertDateTime24to12(newDate));
      clearErrors(name);
      setFieldValue(name, convertDateTime24to12(newDate), { shouldDirty: true });
    } else {
      setInputValue("");
      setFieldValue(name, null, { shouldDirty: true });
    }
    closePopper();
  };
  const footer = (
    <section className="date-picker-footer">
      <div>
        <div className="flex justify-center w-full time-picker-title ">
          Time of admission
        </div>
        <div className="flex flex-col justify-center w-full">
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <StaticTimePicker
            className="static__time_patient"
              displayStaticWrapperAs="mobile"
              orientation="portrait"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <></>}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="flex justify-between pt-3 pr-0 pl-0">
        <button
          type="button"
          className="h-[35px] w-[132px] px-[42px] rounded-[6px] flex items-center place-content-center cancel_btn ml-[5px]"
          onClick={closePopper}
        >
          Cancel
        </button>

        <button
          type="button"
          onClick={handleSaveDaySelected}
          className="h-[35px] w-[132px] px-[42px] rounded-[6px] flex items-center place-content-center save_btn mr-[5px]"
        >
          Save
        </button>
      </div>
    </section>
  );
  const formatWeekdayName: DateFormatter = (date, options) => {
    return <span>{format(date, "eee", { locale: options?.locale })}</span>;
  };

  const okField = isDirty && errors[name] === undefined;
  const handleKeyDown = (event: any) => {
    if (event.key === "Delete") {
      event.preventDefault();

      setInputValue("");
      setFieldValue(name, null, { shouldDirty: true });
    }
    if (event.key === "Backspace") {
      event.preventDefault();
      setInputValue("");
      setFieldValue(name, null, { shouldDirty: true });
    }
  };
  return (
    <div className="text__input patient-datetime-holder">
      <label htmlFor={name}>
        {label}
        {span ? <span className="span-required">{span}</span> : ""}
      </label>
      <input
        {...register(name)}
        className={`pl-[16px] text-input ${errors[name] && "input__error"} ${
          okField && "input_edit"
        }`}
        placeholder="Select date & time of transfer"
        value={inputValue}
        onKeyDown={handleKeyDown}
      />

      <FontAwesomeIcon
        onClick={handleButtonClick}
        icon={faCalendar}
        color="#9AA4B2"
        size="2x"
        className="w-[18px] h-[18px] cursor-pointer"
      />
      {isOpen && (
        <div className="dialog-datetimepicker-patient">
          <DayPicker
            mode="single"
            showOutsideDays
            fromDate={startDateSelected}
            selected={selected}
            onSelect={handleDaySelect}
            formatters={{ formatWeekdayName }}
            footer={footer}
            defaultMonth={selected}
          />
        </div>
      )}
    </div>
  );
}
