import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import AddressAutoCompleteRef from "../../Controls/AddressAutoCompleteRef";
import { convertDateTime24to12, getIsUndefinedDate, getIsUndefinedDateTime } from "../../helpers/globals";
import { ICreatePatient } from "../../interfaces";
import { GetData, UpdatePatient } from "../../Services/Api";
import { FormatDate, FormatToLocalDateTime } from "../../Services/utility";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { savePatient } from "../../store/slices/patient";
import { PatientErrorContainer } from "../../views/PrivatePages/patients/PatientErrorContainer";
import { MyCheckboxRef } from "../MyCheckboxRef";
import MyDropdownListRef from "../MyDropdownListRef";
import MyDropdownMultipleListRef from "../MyDropdownMultipleListRef ";
import { MyInputPhoneRef } from "../MyInputPhoneRef";
import { MyRadioButtonRef } from "../MyRadioButtonRef";
import { MyTextAreaRef } from "../MyTextAreaRef";
import { MyTextInputRef } from "../MyTextInputRef";
import { LoaderSpinner } from "../UI";
import DatePickerPatient from "./DatePickerPatient";
import DateTimePickerPatient from "./DateTimePickerPatient";
import GoogleMapPatient from "./GoogleMapPatient";

interface IParking {
  id: number;
  name: string;
}
const initialValues = {
  firstName: "",
  lastName: "",
  preferredName: "",
  middleName: "",
  address: "",
  longitude: 0,
  latitude: 0,
  covidPositive: false,
  entranceId: 0,
  admittingClinician: 0,
  notes: "",
  status: "Active",
  precautions: [0],
  ppe: [0],
  pets: false,
  smoking: false,
  parking: [0],
  driveway: 0,
  street: 0,
  parkinglot: 0,
  phonList: [{ id: 0, number: "", selected: true, relationId: 0 }],
  phonMDList: [{ id: 0, number: "", selected: true ,relationId: 0 }],
  phonSelected: 0,
  phonMdSelected: 0,
  admission: FormatDate(moment(new Date()).startOf("day").toDate(), "MM/DD/y"),
  //TODO View the variable in API
  covidStart: undefined,
  covidEnd: undefined,
  hospitalEntrance:undefined,
} as ICreatePatient;

const schema = Yup.object({
  firstName: Yup.string().required("First Name"),
  lastName: Yup.string().required("Last Name"),
  admission: Yup.string().required("Admission").typeError("Invalid Admission"),
  address: Yup.string().required("Address"),
  middleName: Yup.string().nullable(),
  preferredName: Yup.string().nullable(),
  status: Yup.string().required("Status"),
  // entranceID: Yup.number().required(),
  phonList: Yup.array()
    .of(
      Yup.object().shape({
        number: Yup.string()
          .min(3, "Must be more then 3 characters")
          .required("Contact Number"),
        name: Yup.string().required("Contact Name"),
        relationId: Yup.number().moreThan(0, "Contact Relation"),
      })
    )
    .required("Contact Information"),
  phonMDList: Yup.array()
    .of(
      Yup.object().shape({
        number: Yup.string()
          .min(3, "Must be more then 3 characters")
          .required("Physician Number"),
        name: Yup.string().required("Physician  Name"),
        relationId: Yup.number().moreThan(0,"Physician Specialization"),
      })
    )
    .required(),
}).required();

const PatientFormHook = (props: any) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.patient);
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(
    new Date(FormatDate(initialValues.admission, "MM/DD/y"))
  );

  const [selectedDayStartCovid, setSelectedDayStartCovid] = useState<
    Date | undefined
  >(new Date(FormatDate(initialValues.covidStart, "MM/DD/y")));

  const [selectedDayEndCovid, setSelectedDayEndCovid] = useState<
    Date | undefined
  >(new Date(FormatDate(initialValues.covidEnd, "MM/DD/y")));


  const [selectedDayTransfer, setSelectedDayTransfer] = useState<
  Date | undefined
>(new Date(FormatToLocalDateTime(initialValues.hospitalEntrance, "MM/DD/y hh:mm A")));

  const [inputValueDay, setInputValueDay] = useState<string | undefined>(() => {
    return FormatDate(initialValues.admission, "MM/DD/y");
  });
  const [inputValueDayTransfer, setInputValueDayTransfer] = useState<string | undefined>(() => {
    return FormatToLocalDateTime(initialValues.hospitalEntrance, "MM/DD/y hh:mm A");
  });
  const [inputValueDayStartCovid, setInputValueDayStartCovid] = useState<
    string | undefined
  >(undefined);

  const [inputValueDayEndCovid, setInputValueDayEndCovid] = useState<
    string | undefined
  >(undefined);

  const [isSaving, setIsSaving] = useState(false);
  const [otherError, setOtherError] = useState<string>("");
  const [relations, setRelations] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [entrances, setEntrances] = useState([]);
  const [precautions, setPrecautions] = useState([]);
  const [ppes, setPPES] = useState<any[]>([]);
  const [ppesAPI, setPPESAPI] = useState<any[]>([]);
  const [parkings, setParkings] = useState([]);
  const history = useNavigate();
  let [otherRelationship, setOtherRelationship] = useState(false);
  const [isMaxPhone, setMaxPhone] = useState(false);
  const [isMaxMDPhone, setMaxMDPhone] = useState(false);
  const staticEntries = [
    { id: 1, name: "Front" },
    { id: 3, name: "Side" },
    { id: 2, name: "Back" },
  ];

  const staticAdmittingClinician = [
    { id: 1, value: 1, name: "Yes" },
    { id: 2, value: 0, name: "No" },
  ];
  const [patientLocation, setPatientLocation] = useState({
    address: "",
    cityName: "",
    lat: 0,
    long: 0,
  });

  const {
    register,
    reset,
    control,
    handleSubmit,
    setValue,
    setError,
    trigger,
    watch,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useForm<ICreatePatient>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
  });
  const precautionsValues = watch("precautions");
  const ppeArrayFilter: any = [];

  useEffect(() => {
    precautionsValues.forEach((precautionId: number) => {
      ppeArrayFilter.push(
        ppesAPI.filter((ppeValue) => {
          const ppeIds = ppeValue.precautionFlag
            .split("-")
            .map((ppeId: any) => parseInt(ppeId));

          return ppeIds.includes(precautionId) && ppeValue;
        })
      );
    });

    const finalPPES: any[] = Array.from(
      new Set(Array.prototype.concat(...ppeArrayFilter))
    );

    setValue("ppe", [0]);

    setPPES(finalPPES);
  }, [precautionsValues]);



  
  const {
    fields: phonList,
    append: appendPhonList,
    remove: removePhonList,
  } = useFieldArray({
    control,
    name: "phonList",
  });
  const {
    fields: phonMDList,
    append: appendPhonMDList,
    remove: removePhonMDList,
  } = useFieldArray({
    control,
    name: "phonMDList",
  });
  const isHospital = watch("status") === "Hospital" && props.patient != -1;
  const clearHospitalTransfer = () => {
      setInputValueDayTransfer(undefined);
      setSelectedDayTransfer(undefined)
    


  }
  const addDateHospitalTransfer = () => {

    if(selectedDayTransfer === undefined){
      setSelectedDayTransfer(new Date(FormatToLocalDateTime(initialValues.hospitalEntrance, "MM/DD/y hh:mm A")))
      setInputValueDayTransfer(FormatToLocalDateTime(initialValues.hospitalEntrance, "MM/DD/y hh:mm A"));
      setValue("hospitalEntrance",FormatToLocalDateTime(initialValues.hospitalEntrance, "MM/DD/y hh:mm A"))
    }


  }
  useEffect(() => {
    Promise.all([
      GetData("/PatientProfile/GetPhonRelations"),
      GetData("/PatientProfile/GetPhonRelations?md=true"),
      // GetData("/PatientProfile/GetEntranceRelations"),
      GetData("/PatientProfile/GetParking"),
      GetData("/PatientProfile/GetPrecautions"),
      GetData("/PatientProfile/GetPPEs"),
    ]).then((res) => {
      const [phoneRelations, specialitiesRelations, parking, precautions, ppe] =
        res;
      const ppeAPI = ppe.data;
      setParkings(parking.data);
      setRelations(phoneRelations.data);
      setSpecialities(specialitiesRelations.data);
      setPrecautions(precautions.data);
      setPPESAPI(ppeAPI);
      //TODO Eliminate until it can be taken in an organized way from the API
      // setEntrances(entries.data);
      setInputValueDay(FormatDate(moment().startOf("day").toDate(), "MM/DD/y"));

      if (props.patient != -1) {
        const getPatientData = async () => {
          const result = await GetData(
            "/PatientProfile/Get?id=" + props.patient
          );
          setInputValueDay(FormatDate(result.data.admission, "MM/DD/y"));
          setSelectedDay(
            new Date(FormatDate(result.data.admission, "MM/DD/y"))
          );

          const covidStart = getIsUndefinedDate(result.data.covidStart);

          const covidEnd = getIsUndefinedDate(result.data.covidEnd);
          const status:string = result.data.status;
          let hospitalEntrance = getIsUndefinedDateTime(result.data.hospitalEntrance);
 

          if(status.toLowerCase() !== "hospital"){
            hospitalEntrance = undefined;
          }


          if (!!covidStart) {
            setInputValueDayStartCovid(covidStart);
            setSelectedDayStartCovid(new Date(covidStart));
          }
          if (!!covidEnd) {
            setInputValueDayEndCovid(covidEnd);
            setSelectedDayEndCovid(new Date(covidEnd));
          }
          let hospitalEntranceTransformer = hospitalEntrance
          if (!!hospitalEntrance) {

             hospitalEntranceTransformer = convertDateTime24to12(hospitalEntrance)
     
            setInputValueDayTransfer(hospitalEntranceTransformer);
            setSelectedDayTransfer(new Date(hospitalEntranceTransformer));
          }

          setPatientLocation({
            address: result.data.address,
            cityName: result.data.cityName,
            lat: result.data.latitude,
            long: result.data.longitude,
          });
          let values = {
            ...result.data,
            hospitalEntrance:hospitalEntranceTransformer,
            covidStart: covidStart,
            covidEnd: covidEnd,
            parking: result.data.parking,
            driveway: result.data.parking.find(
              (element: number) => element === 1
            ),
            street: result.data.parking.find(
              (element: number) => element === 2
            ),
            parkinglot: result.data.parking.find(
              (element: number) => element === 3
            ),
            precautions: result.data.precautions,
            ppe: result.data.ppe,
            admission: FormatDate(result.data.admission, "MM/DD/YYYY"),
            phonMdSelected: result.data.phonMDList.findIndex(
              (i: any) => i.selected
            ),
            phonSelected: result.data.phonList.findIndex(
              (i: any) => i.selected
            ),
          };
          if (result.data.phonMDList.length > 1) {
            setMaxMDPhone(true);
          }
          if (result.data.phonList.length > 1) {
            setMaxPhone(true);
          }
          reset(values);
          setValue("entranceId", result.data.entranceId);
          setValue(
            "admittingClinician",
            result.data?.admittingClinician ? 1 : 0
          );
          setValue("ppe", result.data.ppe);
        };

        getPatientData();
      }
    });
  }, []);

  const handleEdit = async (values: ICreatePatient) => {
    const body: ICreatePatient = {
      ...values,
      id: props.patient,
      address: patientLocation.address,
      middleName: values.middleName ?? "",
      admissionOffset: new Date(values.admission).getTimezoneOffset(),
    };

    setIsSaving(true);
    const res = await UpdatePatient({
      ...body,
      admittingClinician: values.admittingClinician == 1 ? true : false,
    });
    setIsSaving(false);

    if (res.data.status == 200) {
      history("/patients/" + props.patient);
    } else if (
      res.data.message ===
        "Object reference not set to an instance of an object." ||
      res.data.message === "A zip code cannot be extracted from this address."
    ) {
      setError("address", { type: "custom", message: "Invalid address" });
    } else {
      setOtherError(res.data.message);
    }
  };

  const handleSaveNew = async (values: ICreatePatient) => {
    const body: ICreatePatient = {
      ...values,
      address: values.address,
      middleName: values.middleName ?? "",
      admissionOffset: new Date(values.admission).getTimezoneOffset(),
    };
    props.setisLoading(true);
    const res = await dispatch(savePatient(body));
    if (res.meta.requestStatus === "fulfilled") {
      // ShowAlert("Patient Created Successfully");
      const { id } = (res.payload as any) ?? 0;
      props.setisLoading(false);
      props.setIdPatientCareTeam(parseInt(id))
      props.setVisibletab(2);
      goToTop();

      history(`/patient/add/${id}`);
    } else if (
      res.payload === "Object reference not set to an instance of an object." ||
      res.payload === "A zip code cannot be extracted from this address."
    ) {
      setError("address", { type: "custom", message: "Invalid address" });
    } else {
      setOtherError("Network error ocured");
    }
  };
  const getLengthOfErrors = (obj: any) => {
    let lengthOfObject = Object.keys(obj).length;
    return lengthOfObject > 0;
  };
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onRadioChangeEnterThrough = (e: { target: { value: any } }) => {
    let { value } = e.target;
    setValue("entranceId", value);
  };

  const onRadioChangeAdmittingClinician = (e: any) => {
    let { value } = e.target;

    setValue("admittingClinician", value);
  };
  return (
    <form
    className="patient-form"
      onSubmit={handleSubmit((values) => {
        values["phonMDList"] = values.phonMDList.map((value, index) => ({
          ...value,
          id:0,
          selected: index == values.phonMdSelected,
        }));
        values["phonList"] = values.phonList.map((value, index) => ({
          ...value,
          selected: index == values.phonSelected,

          id:0
        }));
        const parkings: number[] = [
          Number(values.driveway),
          Number(values.street),
          Number(values.parkinglot),
        ];
        const filterParking = parkings.filter((element) => element > 0);

        values["parking"] = filterParking;
        values["admission"] = new Date(values.admission).toJSON();
        values["covidStart"] = !values.covidStart
          ? undefined
          : new Date(values.covidStart).toJSON();
        values["covidEnd"] = !values.covidEnd
          ? undefined
          : new Date(values.covidEnd).toJSON();
          if(values["status"] !== "Hospital"){
            values["hospitalEntrance"] = undefined
          }
        values["latitude"] = patientLocation.lat;
        values["longitude"] = patientLocation.long;
        const precautionsValue = values.precautions.find(
          (element) => element === 0
        );
        values["precautions"] =
          precautionsValue === 0 ? [] : values.precautions;

        const ppeValue = values.ppe.find((element) => element === 0);
        values["ppe"] = ppeValue === 0 ? [] : values.ppe;
        props.patient != -1 ? handleEdit(values) : handleSaveNew(values);
      })}
    >
      {(getLengthOfErrors(errors) || otherError.length > 0) && (
        <PatientErrorContainer error={errors} otherError={otherError} />
      )}

      <section>
        <h5 className="mt-[40px] mb-[23px] px-[16px] patient__heading">
          Patient information
        </h5>
        <div className="flex place-content-between items-center py-[20px]">
          <div className="w-[33.33%] px-[16px]">
            <MyTextInputRef
              label={<span className="font-bold">First name</span>}
              name="firstName"
              register={register}
              placeholder="First name"
              span="*"
              isDirty={dirtyFields.firstName}
              errors={errors}
            />
          </div>
          <div className="w-[33.33%] px-[16px]">
            <MyTextInputRef
              label={<span className="font-bold">Middle name</span>}
              name="middleName"
              register={register}
              placeholder="Middle name"
              isDirty={dirtyFields.middleName}
              inEdition={props.patient != -1}
              errors={errors}
            />
          </div>
          <div className="w-[33.33%] px-[16px]">
            <MyTextInputRef
              label={<span className="font-bold">Last name</span>}
              name="lastName"
              register={register}
              placeholder="Last name"
              span="*"
              isDirty={dirtyFields.lastName}
              inEdition={props.patient != -1}
              errors={errors}
            />
          </div>
        </div>
        <div className="flex place-content-between items-center py-[20px]">
          <div className="w-[33.33%] px-[16px]">
            <MyTextInputRef
              label={<span className="font-bold">Preferred first name</span>}
              name="preferredName"
              register={register}
              placeholder="Preferred first name"
              span=""
              isDirty={dirtyFields.preferredName}
              inEdition={props.patient != -1}
              errors={errors}
            />
          </div>
        </div>

        <hr />

        <h5 className="mt-[40px] mb-[23px] px-[16px] patient__heading">
          Admission information
        </h5>
        <div className="flex place-content-start items-start pt-[20px]">
          <div className="w-[33.33%] px-[16px]">
            <div className="w-full relative">
              <DatePickerPatient
                name="admission"
                register={register}
                label={<span className="font-bold">Date of admission</span>}
                selected={selectedDay}
                setSelected={setSelectedDay}
                setInputValue={setInputValueDay}
                inputValue={inputValueDay}
                clearErrors={clearErrors}
                isDirty={dirtyFields.admission}
                errors={errors}
                setFieldValue={setValue}
                span={"*"}
              />
            </div>

            <p className="form-hint-text">
              The day the patient was admitted to home health services by an
              admitting clinician (ie nurse or physical therapy)
            </p>
          </div>
          <div className="w-[33.33%] px-[16px]">
            <MyDropdownListRef
              label="Status"
              name="status"
              register={register}
              errors={errors}
              placeholder="Select patient status"
              span="*"
              isDirty={dirtyFields.status}
              data={["Active", "Pending", "Hospital", /*"OnHold",*/ "Discharged"]}
              value={watch("status") ?? "Active"}
              setFieldValue={setValue}
              field={"status"}
              inEdition={props.patient != -1}
              clearErrors={clearErrors}
              clearHospitalTransfer={clearHospitalTransfer}
              addDateHospitalTransfer={addDateHospitalTransfer}
            />
          </div>
          {isHospital && (
            <div className="w-[33.33%] px-[16px]">
              <DateTimePickerPatient
                name="hospitalEntrance"
                register={register}
                label={<span className="font-bold">Date & time of transfer</span>}
                selected={selectedDayTransfer}
                setSelected={setSelectedDayTransfer}
                setInputValue={setInputValueDayTransfer}
                inputValue={inputValueDayTransfer}
                clearErrors={clearErrors}
                isDirty={dirtyFields.hospitalEntrance}
                errors={errors}
                setFieldValue={setValue}
                startDateSelected={selectedDay}

              />
            </div>
          )}
        </div>
        <div className="flex place-content-start items-start">
          <div className="w-[33.33%] px-[16px]">
            <h4 className="patient-admitting-clinician-title mt-[32px] mb-[10px]">
              Are you the admitting clinician?
            </h4>
            <div className="flex">
              {staticAdmittingClinician.map(({ id, value, name }) => (
                <div className="pr-[20px]" key={id}>
                  {props.patient != -1 ? (
                    <MyRadioButtonRef
                      label={name}
                      name="admittingClinician"
                      register={register}
                      errors={errors}
                      id={id}
                      checked={value == watch("admittingClinician")}
                      value={value}
                      onChange={onRadioChangeAdmittingClinician}
                    />
                  ) : (
                    <MyRadioButtonRef
                      label={name}
                      name="admittingClinician"
                      register={register}
                      errors={errors}
                      id={id}
                      value={value}
                      defaultChecked={id === 2}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <hr />

        <h5 className="mt-[40px] mb-[23px] px-[16px] patient__heading">
          Contact information
        </h5>
        <div className="flex flex-col">
          {phonList.map((p: any, i) => {
            // const relation = relations.find(({ id }) => id == p.relationId);
            // // @ts-ignore
            // otherRelationship = relation?.name === "Other";

            return (
              <div key={p.id}>
                <div className="flex place-content-start items-start py-[20px]">
                  <div className="w-[33.33%] px-[16px]">
                    <MyInputPhoneRef
                      value={p.number}
                      i={i}
                      list={phonList}
                      fieldName="phonList"
                      name={`phonList.${i}.number`}
                      isDirty={dirtyFields.phonList}
                      register={register}
                      setFieldValue={setValue}
                      inEdition={props.patient != -1}
                      clearErrors={clearErrors}
                      errors={errors}
                    />
                    {i == 0 ? (
                      <p className="form-hint-text">
                        The person to contact for scheduling
                      </p>
                    ) : (
                      <p className="form-hint-text">
                        Back up person to contact for scheduling
                      </p>
                    )}
                  </div>
                  <div className="w-[33.33%] px-[16px]">
                    
                    <MyTextInputRef
                      label={
                        <span className="font-bold">
                          {i == 0 ? "Name of contact" : "Name of 2nd contact"}
                        </span>
                      }
                      name={`phonList.${i}.name`}
                      fieldName="phonList"
                      register={register}
                      errors={errors}
                      placeholder={
                        i == 0 ? "Name of contact" : "Name of 2nd contact"
                      }
                      span="*"
                      isDirty={dirtyFields.phonList}
                      i={i}
                      onChange={(e:any) => {
                        clearErrors(`phonList.${i}.name`);
                        setValue(`phonList.${i}.name`, e.target.value, { shouldDirty: true });
                      }}
                    />

                  </div>
                  <div className="w-[33.33%] px-[16px] flex items-center">
                    <MyDropdownListRef
                      label="Relationship to patient"
                      name={`phonList.${i}.relationId`}
                      register={register}
                      errors={errors}
                      placeholder="Ex: wife, husband, spouse, brother.."
                      span="*"
                      isDirty={dirtyFields.phonList}
                      data={relations}
                      value={phonList[i] ? phonList[i].relationId : null}
                      setFieldValue={setValue}
                      field={"phonList"}
                      array={phonList}
                      index={i}
                      inEdition={props.patient != -1}
                      clearErrors={clearErrors}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <hr />
          {!isMaxPhone ? (
            <div className="flex w-full place-content-center p-6">
              <button
                type="button"
                onClick={() => {
                  appendPhonList({
                    id: new Date().getTime(),
                    patientId: 0,
                    number: "",
                    name: "",
                    notes: "",
                    relationId: 0,
                    selected: false,
                  });
                  trigger(["phonList"]);
                  setMaxPhone(true);
                }}
                style={{
                  height: "40px",
                  width: "170px",
                  borderRadius: "8px",
                  padding: "10px 16px 10px 16px",
                  border: "1px solid #D2D6DB",
                  position: "absolute",
                  marginTop: "-45px",
                  background: "#FFF",
                }}
                className="add-another-contact"
              >
                <div className="flex items-center justify-center space-x-2">
                  <span>Add another contact</span>
                </div>
              </button>
            </div>
          ) : (
            <div className="flex w-full place-content-center p-6">
              <button
                type="button"
                onClick={() => {
                  phonList?.length && removePhonList(phonList.length - 1);
                  setMaxPhone(false);
                }}
                style={{
                  height: "40px",
                  width: "215px",
                  borderRadius: "8px",
                  padding: "10px 16px 10px 16px",
                  border: "1px solid #D2D6DB",
                  position: "absolute",
                  marginTop: "-45px",
                  background: "#FEE4E2",
                }}
                className="add-another-contact"
              >
                <div className="flex items-center justify-center space-x-2">
                  <span>Remove secondary contact</span>
                </div>
              </button>
            </div>
          )}
        </div>

        <h5 className="mt-[40px] mb-[23px] px-[16px] patient__heading">
          Physician information
        </h5>

        <div className="flex flex-col">
          {phonMDList.map((p: any, i) => {
            return (
              <div key={p.id}>
                <div className="flex place-content-start items-start py-[20px]">
                  <div className="w-[33.33%] px-[16px]">
                    <MyInputPhoneRef
                      value={p.number}
                      i={i}
                      list={phonMDList}
                      fieldName="phonMDList"
                      name={`phonMDList.${i}.number`}
                      isDirty={dirtyFields.phonMDList}
                      register={register}
                      errors={errors}
                      setFieldValue={setValue}
                      inEdition={props.patient != -1}
                      clearErrors={clearErrors}
                    />
                    <p className="form-hint-text">
                      The person to contact for orders
                    </p>
                  </div>
                  <div className="w-[33.33%] px-[16px]">
                    <MyTextInputRef
                      label={<span className="font-bold">Physician name</span>}
                      name={`phonMDList.${i}.name`}
                      fieldName="phonMDList"
                      register={register}
                      errors={errors}
                      placeholder="Ex: John Smith"
                      span="*"
                      isDirty={dirtyFields.phonMDList}
                      i={i}
                      onChange={(e:any) => {
                        clearErrors(`phonMDList.${i}.name`);
                        setValue(`phonMDList.${i}.name`, e.target.value, { shouldDirty: true });
                      }}
                    />
                  </div>
                  <div className="w-[33.33%] px-[16px] flex items-center">
                    <MyDropdownListRef
                      label="Specialization"
                      name={`phonMDList.${i}.relationId`}
                      register={register}
                      errors={errors}
                      placeholder="Ex: PCP, ortho, GI..."
                      span="*"
                      isDirty={dirtyFields.phonMDList}
                      data={specialities}
                      value={phonMDList[i] ? phonMDList[i].relationId : null}
                      setFieldValue={setValue}
                      field={"phonMDList"}
                      array={phonMDList}
                      index={i}
                      inEdition={props.patient != -1}
                      clearErrors={clearErrors}
                    />

                  </div>
                </div>
                <div className="flex w-full place-content-end">
                  <MyRadioButtonRef
                    label={"Call for Orders"}
                    name="phonMdSelected"
                    register={register}
                    errors={errors}
                    defaultChecked={i == watch("phonMdSelected")}
                    value={i}
                  />
                </div>
              </div>
            );
          })}
          <hr />
          {!isMaxMDPhone ? (
            <div className="flex w-full place-content-center p-6">
              <button
                type="button"
                onClick={() => {
                  appendPhonMDList({
                    id: new Date().getTime(),
                    patientId: 0,
                    number: "",
                    name: "",
                    notes: "",
                    relationId: 0,
                    selected: false,
                  });
                  trigger(["phonMDList"]);

                  setMaxMDPhone(true);
                }}
                style={{
                  height: "40px",
                  width: "170px",
                  borderRadius: "8px",
                  padding: "10px 16px 10px 16px",
                  border: "1px solid #D2D6DB",
                  position: "absolute",
                  marginTop: "-45px",
                  background: "#FFF",
                }}
                className="add-another-contact"
              >
                <div className="flex items-center justify-center space-x-2">
                  <span>Add another contact</span>
                </div>
              </button>
            </div>
          ) : (
            <div className="flex w-full place-content-center p-6">
              <button
                type="button"
                onClick={() => {
                  phonMDList?.length && removePhonMDList(phonMDList.length - 1);
                  setMaxMDPhone(false);
                }}
                style={{
                  height: "40px",
                  width: "215px",
                  borderRadius: "8px",
                  padding: "10px 16px 10px 16px",
                  border: "1px solid #D2D6DB",
                  position: "absolute",
                  marginTop: "-45px",
                  background: "#FEE4E2",
                }}
                className="add-another-contact"
              >
                <div className="flex items-center justify-center space-x-2">
                  <span>Remove secondary contact</span>
                </div>
              </button>
            </div>
          )}
        </div>

        <div className="flex place-content-start items-start py-[20px]">
          <div className="w-[33.33%] px-[16px] flex flex-col  mt-[30px]">
            <h5 className=" px-[16px] patient__heading mb-[30px]">Home address</h5>
            <AddressAutoCompleteRef
              id={"address"}
              name={"address"}
              register={register}
              errors={errors}
              isDirty={dirtyFields.address}
              inEdition={props.patient != -1}
              Data={(data: any) => {
                clearErrors("address");
                setValue("address", data.address, { shouldDirty: true });
                setPatientLocation({
                  address: data.address,
                  cityName: data.vicinity || data.address,
                  lat: data.lat,
                  long: data.long,
                });
              }}
              address={patientLocation.address}
              // {...(patientLocation.address != '' ? { value: patientLocation.address } : {})}
              UnsetAddress={(v: any) => {
                clearErrors("address");
                setValue("address", v.target.value);
                setPatientLocation({
                  address: v.target.value,
                  cityName: "",
                  lat: 0,
                  long: 0,
                });
              }}
            />
          </div>
          <div className="w-[33.33%] px-[16px]">
            <GoogleMapPatient
              lat={patientLocation.lat}
              lng={patientLocation.long}
              isMarkerShown
              // googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCqB8EW5b48lY_IOp5DIM0kRPC_H1igWc8&libraries=places"
              // loadingElement={<div style={{ height: `100%` }} />}
              // containerElement={<div style={{ height: `200px` }} />}
              // mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </div>

        <hr />

        <h5 className="mt-[40px] mb-[23px] px-[16px] patient__heading">
          Precautions
        </h5>
        <div className="flex place-content-start items-start py-[20px]">
          <div className="w-[33.33%] px-[16px]">
            <div className="w-full relative">
              <MyDropdownMultipleListRef
                preText="Select precautions"
                label="Precautions"
                name="precautions"
                register={register}
                errors={errors}
                isDirty={dirtyFields.precautions}
                options={precautions}
                value={watch("precautions")}
                setFieldValue={setValue}
                span={""}
              />
            </div>
          </div>
          <div className="w-[33.33%] px-[16px]">
            <MyDropdownMultipleListRef
              preText="Select ppe"
              label="PPE"
              name="ppe"
              register={register}
              errors={errors}
              isDirty={dirtyFields.ppe}
              options={ppes}
              value={watch("ppe")}
              setFieldValue={setValue}
              span={""}
            />
          </div>
        </div>
        <hr />

        <h5 className="mt-[40px] mb-[23px] px-[16px] patient__heading">
          COVID Status
        </h5>
        <div className="flex place-content-start items-start py-[20px]">
          <div className="w-[33.33%] px-[16px]">
            <div className="w-full relative">
              <DatePickerPatient
                name="covidStart"
                register={register}
                label={<span className="font-bold">Start date</span>}
                selected={selectedDayStartCovid}
                endDateSelected={selectedDayEndCovid}
                setSelected={setSelectedDayStartCovid}
                setInputValue={setInputValueDayStartCovid}
                inputValue={inputValueDayStartCovid}
                clearErrors={clearErrors}
                isDirty={dirtyFields.covidStart}
                errors={errors}
                setFieldValue={setValue}
              />
            </div>
            <p className="form-hint-text">
              The day the patient was tested positive for COVID
            </p>
          </div>
          <div className="w-[33.33%] px-[16px]">
            <div className="w-full relative">
              <DatePickerPatient
                name="covidEnd"
                register={register}
                label={<span className="font-bold">End date</span>}
                selected={selectedDayEndCovid}
                startDateSelected={selectedDayStartCovid}
                setSelected={setSelectedDayEndCovid}
                setInputValue={setInputValueDayEndCovid}
                inputValue={inputValueDayEndCovid}
                clearErrors={clearErrors}
                isDirty={dirtyFields.covidEnd}
                errors={errors}
                setFieldValue={setValue}
              />
            </div>
            <p className="form-hint-text">
              The day the patient tested negative for COVID
            </p>
          </div>
        </div>
        <hr />

        <h5 className="mt-[40px] mb-[23px] px-[16px] patient__heading">
          Additional information
        </h5>
        <div className="flex py-[20px] ">
          <div className="flex flex-col   px-[16px] pb-[12px] w-[150px]">
            <h6 className="label-radio mb-[24px]">Pet in home</h6>

            <h6 className="label-radio mb-[24px]">Smoking</h6>
            <h6 className=" label-radio mb-[24px]">Enter through</h6>
            <h6 className=" label-radio mb-[24px]">Parking</h6>
          </div>
          <div className="flex flex-col   px-[16px] pb-[12px]">
            <div className="mb-[18px]">
              <MyCheckboxRef
                label={""}
                name="pets"
                register={register}
                errors={errors}
              />
            </div>
            <div className="mb-[18px]">
              <MyCheckboxRef
                label={""}
                name="smoking"
                register={register}
                errors={errors}
              />
            </div>

            <div className="flex ml-[8px]">
              {staticEntries.map(({ id, name }, i) => (
                <div className="pr-[20px]" key={i}>
                  {props.patient != -1 ? (
                    <MyRadioButtonRef
                      label={name}
                      name="entranceId"
                      register={register}
                      errors={errors}
                      id={id}
                      checked={id == watch("entranceId")}
                      value={id}
                      onChange={onRadioChangeEnterThrough}
                    />
                  ) : (
                    <MyRadioButtonRef
                      label={name}
                      name="entranceId"
                      register={register}
                      errors={errors}
                      id={id}
                      value={id}
                      defaultChecked={i == 0}
                    />
                  )}
                </div>
              ))}
            </div>
            <div className="flex">
              {parkings.map(({ id, name }: IParking, i) => (
                <div className="pr-[20px]" key={i}>
                  {props.patient != -1 ? (
                    <MyCheckboxRef
                      label={name}
                      name={name.toLowerCase().replace(/ /g, "")}
                      register={register}
                      errors={errors}
                      id={id}
                      // checked={watch(`parking.${i}.id`).includes(id)}
                      value={id}
                      // onChange={onParkingChange}
                    />
                  ) : (
                    <MyCheckboxRef
                      label={name}
                      name={name.toLowerCase().replace(/ /g, "")}
                      register={register}
                      errors={errors}
                      id={id}
                      value={id}
                      defaultChecked={i == 0}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <hr />
        <div className="flex flex-col py-[20px] ">
          <h3 className="mb-[5px] px-[16px] patient__heading_subt">
            Additional information
          </h3>
          <div className="px-[16px]">
            <MyTextAreaRef
              name="notes"
              fieldName="notes"
              register={register}
              errors={errors}
              placeholder="Enter additional information not captured above"
              isDirty={dirtyFields.notes}
              inEdition={props.patient != -1}
            />
          </div>
          {/* <span className="px-[16px] patient-note-head">
            This is a hint text to help user.
          </span> */}
        </div>
        <hr />
        <div className="flex gap-4 w-full place-content-end p-6">
          <button
            type="button"
            onClick={() =>
              props.patient != -1
                ? history("/patients/" + props.patient)
                : history("/patients")
            }
            className="h-[35px] px-[42px] rounded-[6px] flex items-center place-content-center cancel_btn"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="h-[35px] px-[42px] rounded-[6px] flex items-center place-content-center save_btn"
            disabled={loading === "pending"}
            onClick={() => {
              if (getLengthOfErrors(errors) || otherError.length > 0) goToTop();
            }}
          >
            {loading === "pending" || isSaving ? (
              <LoaderSpinner
                action="Saving"
                className="flex align-items-center"
              />
            ) : (props.patient != -1 ?
              "Save":"Continue to Care Team"
            )}
          </button>
        </div>
      </section>
    </form>
  );
};

export default PatientFormHook;


