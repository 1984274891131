import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPatient } from "../../../interfaces/IPatient";
import { patientsLoad } from "./thunks";

export interface patientsState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  patients: IPatient[];
  total: number;
  error: {
    errorType?: string;
    errorMessage?: string;
  };
}

const initialState: patientsState = {
  loading: "idle",
  patients: [],
  total: 0,
  error: { errorType: "", errorMessage: "" },
};

export const patientsSlice = createSlice({ 
  name: "patients",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(patientsLoad.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(patientsLoad.fulfilled, (state, action) => {
      state.patients = action.payload.pstients;
      state.total = action.payload.total;
      state.loading = "succeeded";
    });
    builder.addCase(patientsLoad.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });
  },
});

export const {} = patientsSlice.actions;
