import React, { useState } from "react";
import { useStore } from "react-redux";
import PatientFormHook from "../../../components/patients/PatientFormHook";
import CareTeam from "./CareTeam";
import Popup from "reactjs-popup";
import { useEffect } from "preact/hooks";
import { useParams, useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getPatient } from "../../../store/slices/patient/thunks";
import { DeletePatient } from "../../../Services/Api";
import { ShowAlert } from "../../../Services/utility";
import { LoaderSpinner } from "../../../components/UI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { useClickOutSide } from "../../../hooks/useClickOutSide";
import { setIsLoading } from "../../../store/slices/notification/notificationSlice";

export const AddPatient = () => {
  const [visibletab, setVisibletab] = useState(1);
  const [openDelete, setOpenDelete] = useState(false);
  const { patient_id } = useParams();
  const [idPatientCareTeam,setIdPatientCareTeam] = useState<number>((patient_id && parseInt(patient_id) > 0) ? parseInt(patient_id) : -1)

  const dispatch = useAppDispatch();
  const { patient, loading } = useAppSelector((state) => state.patient);
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);
  const [isLoading, setisLoadingPatientCareTeam] = useState(false)

  const fullName =  (patient?.firstName + " " + patient?.lastName).length >15 ? (patient?.firstName + " " + patient?.lastName).substring(0,15) + "..." : (patient?.firstName + " " + patient?.lastName);
  const modalDelete = useClickOutSide(() => {
    setOpenDelete(false)
  })
  const handleDeletePatient = async () => {
    setDeleting(true);
    DeletePatient(patient_id)
      .then((data) => {
        if (data.data.status != 200) {
          ShowAlert(data.data.message, "error");
        } else {
          ShowAlert("Succesfull deletion");
        }
      })
      .catch((error) => {
        ShowAlert(error.message, "error");
      });

    setDeleting(false);
    setOpenDelete(false);
    navigate("/patients");
  };

  if (loading === "failed") {
    ShowAlert("Internal Server Error", "error");
  }
  return (
    <>
      <div className="rounded-[8px] p-8 bg-white my-8 mx-10 h-[100%]">
        <div className="flex items-center place-content-between patient-tabs-bar">
          <div>
            <button
              onClick={() => {
                setVisibletab(1);
              }}
              className={!isLoading ? ("patient-tab-btn first " + (visibletab == 1 ? "active" : "")): "patient-tab-btn-desactive first" }
            >
              Patient record
            </button>
            <button
              onClick={() => {
                if(patient_id && parseInt(patient_id) > 0){
                  setVisibletab(2);
                }
              }}
              className={ ((idPatientCareTeam && idPatientCareTeam > 0) && !isLoading) ? ("patient-tab-btn second " + (visibletab == 2 ? "active" : "")): "patient-tab-btn-desactive second"}
            >
              Care team
            </button>
          </div>
          <div>
            {patient_id ? (
              <button
                onClick={() => {
                  dispatch(getPatient(patient_id || 0));
                  setOpenDelete(true);
                }}
                className={"patient-tab-btn-action tab-btn-delete"}
              >
                Delete patient 
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="">
          {visibletab == 1 ? (
            <PatientFormHook
              patient={patient_id ? patient_id : -1}
              setVisibletab={setVisibletab}
              setIdPatientCareTeam={setIdPatientCareTeam}
              setisLoading={setisLoadingPatientCareTeam}
            />
          ) : (
            <CareTeam patient={idPatientCareTeam}
            setisLoading={setisLoadingPatientCareTeam}
            
            />
          )}
        </div>
      </div>

      <Popup
        open={openDelete}
        closeOnDocumentClick
        lockScroll
        contentStyle={{
          width: "100%",
          height: "100%",
        }}
        onClose={() => {
          setOpenDelete(false);
        }}
      >
        <div className="flex w-[365px] h-[309px] delete_patient_popup bg-white " ref={modalDelete}>
          <div className="py-[32px] px-[24px] flex flex-col w-[100%] place-content-between">
            <div>
                    <div className="flex flex-row">
                      <div className="flex justify-center basis-[95%]">
                         <h2 className="text-center ">
                            Delete {fullName}
                          </h2>
                      </div>
                    <div className="flex justify-end basis-[5%">
                        <button  title="close-user-button" type="button" onClick={ () =>setOpenDelete(false)}>
                        <FontAwesomeIcon
                          icon={faClose}
                             className="modal-patient-button-close-bulk"
                             aria-hidden="true"
                        />
                       </button>
                    </div>
                  </div>
              <hr />
              <h3 className="text-center mt-[24px]">Are you sure?</h3>
              <p className="text-center mt-[24px]">
                Deleting this patient will remove patient record and all
                associated data. This <span>cannot</span> be undone.
              </p>
            </div>
            <div className="flex place-content-between">
              <button
                className="pop_btn h-[35px] flex items-center place-content-center px-[50px] rounded-[6px]"
                onClick={() => {
                  setOpenDelete(false);
                }}
              >
                Cancel
              </button>
              <button
                className="pop_btn pop_delete_btn h-[35px] flex items-center place-content-center px-[50px] rounded-[6px]"
                onClick={handleDeletePatient}
              >
                {deleting ? <LoaderSpinner action="Deleting" /> : "Delete"}
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};
