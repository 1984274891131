import { faAngleRight, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getVisitsColorsByUser, VISITS } from "../../../helpers/globals";
import { IAIDCertification, ICertification } from "../../../interfaces";
import { cancelFrequencyByDefault } from "../../../Services/Api";
import { patientServices } from "../../../Services/patient.services";
import { ShowAlert, ValueFromUserData } from "../../../Services/utility";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  setCert,
  setInitialCertification,
} from "../../../store/slices/certification";
import {
  setAIDCert,
  setAIDInitialCertification,
} from "../../../store/slices/certificationAID";
import { setCertifications } from "../../../store/slices/certifications/certificationsSlice";
import { setIsLoading } from "../../../store/slices/notification/notificationSlice";
import { CertificationLayout } from "./CertificationLayout";
import { FreqStep2 } from "./FreqStep2";
import { FreqStep3 } from "./FreqStep3";
import { FreqStepAID } from "./FreqStepAID";
import Calendar from "./FrequencyCalendar";
import FrequencyCalendarAID from "./FrequencyCalendarAID";
import { RecertForm } from "./RecertForm";
export interface ILegendFrecuency {
  label: string;
  type:VISITS;
}
const RightArrow = () => {
  return (
    <FontAwesomeIcon
      icon={faAngleRight}
      size="2x"
      color="#D2D6DB"
      className="grow-0 w-[32px] h-[32px]"
    />
  );
};

export const FrequencyForm = (props: {
  close: () => void;
  // getPatientCertifications: () => Promise<any>;
}) => {

  const domRef = useRef<HTMLDivElement | null>(null);


  const { careTeam } = useAppSelector((state) => state.careTeam);
  const { certification } = useAppSelector((state) => state.certification);
  const { certificationAID } = useAppSelector(
    (state) => state.certificationAID
  );
  const [editFrequency, setEditFrequency] = useState(true);


  let nameOfFirstTab = "";

  if (careTeam?.teamLeader !== 0) {
    if (careTeam?.teamLeader === careTeam?.sn) {
      nameOfFirstTab = "Skilled nursing";
    }
    if (careTeam?.teamLeader === careTeam?.pt) {
      nameOfFirstTab = "Physical therapy";
    }
    if (careTeam?.teamLeader === careTeam?.ot) {
      nameOfFirstTab = "Occupational therapy";
    }
    if (careTeam?.teamLeader === careTeam?.slp) {
      nameOfFirstTab = "Speech Therapy";
    }
  }
  // const getPatientCertifications = async ()=> {
  //   const result = await getRecertifications(initialCertifications.patientId)
  //   if(result.status !== 200){
  //       ShowAlert("Error loading Certificacions","error")
  //       return []
  //   }
  //   else{
  //     dispatch(setCertifications(result.data))
  //     return result.data
  //   }

  // }

  const haveAID =
    careTeam?.aid > 0 && ValueFromUserData("id") === careTeam?.teamLeader;
    

  // const haveAID = true
  // const [aidFrequencies, setAidFrequencies] = useState<any>({})

  const [initialCertifications] = useState<ICertification>(certification);

  useEffect(() => {
    dispatch(setInitialCertification(initialCertifications));
  }, []);

  const [initialAIDCertifications] =
    useState<IAIDCertification>(certificationAID);

  useEffect(() => {}, []);

  useEffect(() => {
    const getFrequenciesAID = () => {
      const body = {
        recertId: initialCertifications.id,
        patientId: initialCertifications.patientId,
        clinitianId: careTeam.aid,
      };
      patientServices()
        .getCertificationsAID(body)
        .then((result: any) => {
          if (result.status === 200) {
            const data = {
              frequency: result.value.frecs,
              blocked: result.value.blocked,
              vcount: result.value.visitCount,
              wcount: result.value.weeksCount,
              id: initialCertifications.id,
              patientId: initialCertifications.patientId,
            };
            dispatch(setAIDCert(data));
            dispatch(setAIDInitialCertification(initialAIDCertifications));
          }
        })
        .catch((error) => {
          ShowAlert("Ocurred an error, please try agina", "error");
        });
    };
    if (haveAID) {
      getFrequenciesAID();
    }
  }, []);

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.notification);

  const [changeFlag, setChangeFlag] = useState<boolean>(false);

  const handleCancelFrecuencyForm = async () => {
    if (certification === initialCertifications && !haveAID) {
      setEditFrequency(false)
    } else if (
      haveAID &&
      certificationAID === initialAIDCertifications &&
      certification === initialCertifications
    ) {
      setEditFrequency(false)
      
    } else if (haveAID && certificationAID === initialAIDCertifications) {
      dispatch(setIsLoading(true));

      const body = {
        patientId: initialCertifications.patientId,
        recertId: initialCertifications.id,
        frecs: initialCertifications.frequency,
        blocked: initialCertifications.blocked,
      };
      const result = await cancelFrequencyByDefault(body);
      if (result.status !== 200) {
        ShowAlert(result.value, "error");
      } else {
        dispatch(setCert(initialCertifications));
      setEditFrequency(false)
        
      //  getPatientCertifications();
      }
      dispatch(setIsLoading(false));
    } else if (
      haveAID &&
      certificationAID !== initialAIDCertifications &&
      certification !== initialCertifications
    ) {
      dispatch(setIsLoading(true));

      Promise.all([
        cancelFrequencyByDefault({
          patientId: initialCertifications.patientId,
          recertId: initialCertifications.id,
          frecs: initialCertifications.frequency,
          blocked: initialCertifications.blocked,
        }),
        cancelFrequencyByDefault({
          patientId: initialAIDCertifications.patientId,
          clinitianId: careTeam.aid,
          recertId: initialAIDCertifications.id,
          frecs: initialAIDCertifications.frequency,
          blocked: initialAIDCertifications.blocked,
        }),
      ]).then((res) => {
        const [cancelFrequency, cancelAIDFrequency] = res;
        if (cancelFrequency.status !== 200) {
          ShowAlert(cancelFrequency.value, "error");
        } else {
          dispatch(setCert(initialCertifications));
      setEditFrequency(false)
          
          // getPatientCertifications();
        }
        if (cancelAIDFrequency.status !== 200) {
          ShowAlert(cancelAIDFrequency.value, "error");
        } else {
          dispatch(setAIDCert(initialAIDCertifications));
      setEditFrequency(false)
          
          // getPatientCertifications();
        }
      });

      dispatch(setIsLoading(false));
    } else if (haveAID && certificationAID !== initialAIDCertifications) {
      dispatch(setIsLoading(true));

      const body = {
        patientId: initialAIDCertifications.patientId,
        clinitianId: careTeam.aid,
        recertId: initialAIDCertifications.id,
        frecs: initialAIDCertifications.frequency,
        blocked: initialAIDCertifications.blocked,
      };

      const result = await cancelFrequencyByDefault(body);
      if (result.status !== 200) {
        ShowAlert(result.value, "error");
      } else {
        dispatch(setAIDCert(initialAIDCertifications));
      setEditFrequency(false)
        
        // getPatientCertifications();
      }
      dispatch(setIsLoading(false));
    } else {
      dispatch(setIsLoading(true));

      const body = {
        patientId: initialCertifications.patientId,
        recertId: initialCertifications.id,
        frecs: initialCertifications.frequency,
        blocked: initialCertifications.blocked,
      };

      const result = await cancelFrequencyByDefault(body);
      if (result.status !== 200) {
        ShowAlert(result.value, "error");
      } else {
        dispatch(setCert(initialCertifications));
        setEditFrequency(false)
        // getPatientCertifications();<FrequencyForm
      }
      dispatch(setIsLoading(false));
    }
    props.close()
  };

  const handleEditEnds = () => {
    setChangeFlag(changeFlag ? false : true);
  };

  const handleSaveEvent = () => {
    if (haveAID && !(certificationAID.frequency.length > 0)) {
      setIsAIDError(true);
    } else {
      setTimeout(() => {
        setEditFrequency(false)
        props.close()
      }, 500);
    }
  };

  const legends: ILegendFrecuency[] = [
    {label:"Admission", type: VISITS.ADMISSION },
    {label:"Evaluation", type: VISITS.EVALUATION },
    {label:"30DRE", type: VISITS.THYRTYDRE },
    { label:"Recert window", type: VISITS.RECERT },
    { label:"Discharge",  type: VISITS.DISCHARGE },
    //{ label:"On hold",  type: VISITS.ONHOLD },
  ];
  const LegendSection = () => {

    return (
      <div className="flex">
        {legends.map((element: ILegendFrecuency, index: number) => (
          <div className="flex items-baseline mr-[8px]" key={index}>
            <div className={`freq-legend mr-[3px]`} style={{backgroundColor:getVisitsColorsByUser(element.type)}}></div>
            <p className="freq-legend-label">{element.label}</p>
          </div>
        ))}
      </div>
    );
  };
  const SaveCancelSection = () => {
    return (
      <div className="flex gap-4 w-full place-content-end  w-100 pr-[18px]">
        <button
          type="button"
          className="h-[35px] px-[42px] min-w-[125px] rounded-[6px] flex items-center place-content-center cancel_btn"
          onClick={handleCancelFrecuencyForm}
          disabled={isLoading}
        >
          {isLoading ? (
            <FontAwesomeIcon
              icon={faSpinner}
              color="#0BB68C"
              className="animate-spin"
            />
          ) : (
            "Cancel"
          )}
        </button>
        <button
          type="button"
          onClick={handleSaveEvent}
          className="h-[31px] px-[42px] rounded-[6px] flex items-center place-content-center save_btn"
          disabled={isLoading}
        >
          Save
        </button>
      </div>
    );
  };
  const [visibletab, setVisibletab] = useState(1);
  const [isAIDError, setIsAIDError] = useState(false);
  const AIDError = () => {
    return (
      <div className="aid-error-container">
        <div className="aid-setting-error-bold-text w-[268px]">
          You must enter a home health aid frequency before saving.
        </div>
        <button onClick={() => setIsAIDError(false)}>
          <i className="fas fa-times hand"></i>
        </button>
      </div>
    );
  };

  //TODO If i delete this the DatePicker is corrupted
  // ******************************************************
  const getRecert = false;

    if (getRecert) {
    return (
      <CertificationLayout
        title="Certification Period"
        close={props.close}
        plusclass={" new-cert-form"}
        name={``}
      >
        <RecertForm
          close={props.close}
          id={1}
          index={1}
          reset={()=>{}}
          setEdit={()=>{}}
          setDelete={()=>{}}
          setNew={()=>{}}
        />
      </CertificationLayout>
    );
  }
  // ******************************************************

  


  return editFrequency ? (
    <CertificationLayout
    plusclass={" edit-cert-form"}
    close={handleCancelFrecuencyForm}
    title="Visit Frequency"
    domRef={domRef}
  >

    <div className="flex flex-col items-center gap-[32px] h-[auto]">
      {/* tre stpes */}
      {isAIDError && (
        <div className="relative">
          <AIDError />
        </div>
      )}
      <div className="flex justify-center w-full items-center">
        <div className="flex">
          <div className="rounded-[8px]  bg-white">
            {haveAID && (
              <div className="flex items-center  visit-tabs-bar">
                <button
                  onClick={() => {
                    setVisibletab(1);
                  }}
                  className={
                    "patient-tab-btn first " +
                    (visibletab == 1 ? "active" : "") +
                    (!haveAID ? " all" : "")
                  }
                  style={{ width: `${!haveAID ? "298px" : "147px"}` }}
                >
                  {nameOfFirstTab}
                </button>
                <button
                  onClick={() => {
                    setVisibletab(2);
                  }}
                  className={
                    "patient-tab-btn second " +
                    (visibletab == 2 ? "active" : "")
                  }
                  style={{ width: "147px" }}
                >
                  Home Health Aid
                </button>
              </div>
            )}

            <div className="">
              {visibletab == 1 ? (
                <FreqStep2
                  calendarUpdater={() => setChangeFlag(!changeFlag)}
                  onEditEnd={() => handleEditEnds()}
                  certification={certification}
                  haveAID={haveAID}
                  domRef={domRef}
                />
              ) : (
                <FreqStepAID
                  calendarUpdater={() => setChangeFlag(!changeFlag)}
                  onEditEnd={() => handleEditEnds()}
                  aidID={careTeam?.aid}
                  domRef={domRef}

                />
              )}
            </div>
          </div>
        </div>

        <RightArrow />
        {visibletab == 1 ? (
          <FreqStep3 haveAID={false} />
        ) : (
          <FreqStep3 haveAID={true} />
        )}
      </div>
      <div className="grow-0 frecs-title-calendar ">
        Result of entered frequency
      </div>
      {/* three callendars */}
      {visibletab == 1 ? (
        <div className="grow flex flex-row gap-[40px] h-2/5 w-full place-content-evenly items-center px-[18px]">
          <div className="grow h-full max-h-[410px]  min-w-[280px] max-w-[280px]">
            <Calendar
              id={certification.patientId}
              changeFlag={changeFlag}
              selectedMonth={moment(certification.recertificationDate).toDate()}
              hiddenArrow={true}
            />
          </div>
          <div className="grow h-full max-h-[410px] min-w-[280px]  max-w-[280px]">
            <Calendar
              id={certification.patientId}
              changeFlag={changeFlag}
              selectedMonth={moment(certification.recertificationDate)
                .add(1, "month")
                .toDate()}
              hiddenArrow={true}
            />
          </div>
          <div className="grow h-full max-h-[410px] min-w-[280px] max-w-[280px]">
            <Calendar
              id={certification.patientId}
              changeFlag={changeFlag}
              selectedMonth={moment(certification.recertificationDate)
                .add(2, "month")
                .toDate()}
              hiddenArrow={true}
            />
          </div>
        </div>
      ) : (
        <div className="grow flex flex-row gap-[40px] h-2/5 w-full place-content-evenly items-center px-[18px]">
          <div className="grow h-full max-h-[410px]  min-w-[280px] max-w-[280px]">
            <FrequencyCalendarAID
              id={certification.patientId}
              clinicianId={careTeam.aid}
              changeFlag={changeFlag}
              selectedMonth={moment(certification.recertificationDate).toDate()}
              hiddenArrow={true}
            />
          </div>
          <div className="grow h-full max-h-[410px] min-w-[280px]  max-w-[280px]">
            <FrequencyCalendarAID
              id={certification.patientId}
              clinicianId={careTeam.aid}
              changeFlag={changeFlag}
              selectedMonth={moment(certification.recertificationDate)
                .add(1, "month")
                .toDate()}
              hiddenArrow={true}
            />
          </div>
          <div className="grow h-full max-h-[410px] min-w-[280px] max-w-[280px]">
            <FrequencyCalendarAID
              id={certification.patientId}
              clinicianId={careTeam.aid}
              changeFlag={changeFlag}
              selectedMonth={moment(certification.recertificationDate)
                .add(2, "month")
                .toDate()}
              hiddenArrow={true}
            />
          </div>
        </div>
      )}

      <LegendSection />
      <SaveCancelSection />
    </div>
  </CertificationLayout>

  ) : null;
};
