import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllPatients } from "../../../api/patientsApi";

type Options = {
  pagenumber?: number;
  pagesize?: number;
  status?: string;
  query?: string;
  sortField?:string;
  sortDir?:string;
};

export const patientsLoad = createAsyncThunk(
  "patients/load",
  async (options: Options, thunkApi) => {
    const { data, headers } = await getAllPatients(options);
    const total = parseInt(headers["x-total-count"]);

    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return { pstients: data.data, total };
  }
);
