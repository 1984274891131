import React, { useEffect, useState } from "react";
import * as dateFns from "date-fns";
import moment from "moment";
import Typpy from "@tippyjs/react";

import { IState, TProjected } from "../Services/Interfaces/Interfaces";
import { getProjectedCertifications, getScheduledVisits } from "../helpers/fetchPatientData";
import { IView } from "../Services/Interfaces/IVisit";

import { colorByType, colorByTypeCrude, TyppyVisit } from "../components/patients/Typpy/TyppyVisit";

import "tippy.js/dist/tippy.css";
import "./styles/Calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { getVisitsColorsByUser, VISITS } from "../helpers/globals";

var _ = require("lodash");

export const InitialProjectedState: TProjected = {
  admission: new Date(),
  evaluation: new Date(),
  eoc: new Date(),
  frequenciesRange: [],
  visit30dre: []
}

const InitialState: IState = {
  id: 0,
  selectedMonth: new Date(),
  selectedDate: new Date(),
  eventDates: [
    {
      dateEnd: new Date(),
      date: new Date(),
      distance: "",
      title: "",
      visitType: "",
    },
  ],
};

interface ICalProps {
  id: number | string;
  onChange?: () => {};
  selectedMonth?: Date;
  selectedDate?: Date;
  eventDates: {
    class: string;
    date: Date;
  }[];
  historyOrProjected: "history" | "projected"
  minDate: Date;
  maxDate: Date;
  customStyles?: {
    header: {};
    title: {};
    prev: {};
    next: {};
    days: {};
    daysRow: {};
    body: {};
    numberRow: {};
    cell: {};
    innerCell: {};
    number: {};
    selected: {};
    event: {};
    disabled: {};
    outOfRange: {};
    extra: any;
  };
}

const plusPropMonth = (state: IState, props: ICalProps): IState => {
  let st = state;
  if (props.selectedMonth)
    st.selectedMonth = props.selectedMonth;
  return st;
}

const Calendar = (props: ICalProps) => {
  const [state, setMyState] = useState<IState>(plusPropMonth(InitialState, props));
  const [projectedData, setProjectedData] = useState<TProjected>(InitialProjectedState);
  const [isDisabledSearchMont, setIsDisabledSearchMont] = useState<boolean>(false)


  const handleMouseEnter = (
    e:
      {
        dateEnd: Date | string;
        date: Date;
        title: string;
        distance: string;
        visitType: string;
        rest?: any;
      } | undefined
  ) => {
    setTimeout(() => {
      const color = colorByTypeCrude(e?.visitType);
      let element = document.querySelector<any | undefined>(".tippy-arrow");
      if (element !== undefined && element !== null){
        element.style.color = color;
        element.style.backgroundColor = color;
      }
        
    }, 0);
  };

  const handleChangeMonth = ((selectedMonth:Date)=> {

    
    const iniDay = moment(selectedMonth).startOf("month").toISOString();
    const endDay = moment(selectedMonth).endOf("month").toISOString();

    const myId = props.id ? (props.id as number) : 0;

    if (myId !== 0 ) {

      getScheduledVisits(iniDay, endDay, true, 0, myId).then((res) => {
        const visitHistory = res?.data.map((visit: IView) => ({
          dateEnd: visit.end,
          date: visit.start,
          title: visit.title,
          distance: visit.distance,
          visitType: visit.colorType,
          res: visit,
        }));
        setMyState({
          ...state,
          eventDates: visitHistory,
        });
      });

      getProjectedCertifications(iniDay, endDay, myId.toString()).then(
        (res: any) => {
          setProjectedData(res.data);

        }
      );
    }
    setIsDisabledSearchMont(false)

  })


  useEffect(() => {
    handleChangeMonth(state.selectedMonth)

    // const iniDay = moment(state.selectedMonth).startOf("month").toISOString();
    // const endDay = moment(state.selectedMonth).endOf("month").toISOString();

    // const myId = props.id ? (props.id as number) : 0;

    // if (myId !== 0 ) {

    //   getScheduledVisits(iniDay, endDay, true, 0, myId).then((res) => {
    //     const visitHistory = res?.data.map((visit: IView) => ({
    //       dateEnd: visit.end,
    //       date: visit.start,
    //       title: visit.title,
    //       distance: visit.distance,
    //       visitType: visit.colorType,
    //       res: visit,
    //     }));
    //     setMyState({
    //       ...state,
    //       eventDates: visitHistory,
    //     });
    //   });

    //   getProjectedCertifications(iniDay, endDay, myId.toString()).then(
    //     (res: any) => {
    //       setProjectedData(res.data);
    //     }
    //   );
    // }
    
  }, []);

  const renderHeader = () => {
    const label: string = moment(state.selectedMonth).format("MMM YYYY"); //dateFns.format(state.selectedMonth, "MMMM yyyy");
    const customStyles: any = props.customStyles || {};

    return (
      <div className="header row" style={customStyles.header}>
        <div
          className="col col-start"
          style={customStyles.prev}
          onClick={isDisabledSearchMont ? ()=> {}: prevMonth}

        >
          <FontAwesomeIcon className="w-[16px] h-[16px]" color="#1F2A37" icon={faAngleLeft} />
        </div>
        <div
          className="col col-center text-[#484F5A]"
          style={customStyles.title}
        >
          <div className="text-[#1F2A37] font-semibold text-[20px] uppercase" >{label}</div>
        </div>
        <div
          className="col col-end"
          style={customStyles.next}
          onClick={isDisabledSearchMont ? ()=> {}: nextMonth}
        >
          <FontAwesomeIcon className="w-[16px] h-[16px]" color="#1F2A37" icon={faAngleRight} />
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const customStyles: any = props.customStyles || {};

    const startDate = dateFns.startOfWeek(state.selectedMonth);
    const days = [];

    for (let i = 0; i < 7; i++) {
      const label = dateFns.format(dateFns.addDays(startDate, i), "E")[0];
      days.push(
        <div
          key={i}
          className="w-[40px] h-[40px] col col-center text-[#1F2A37]"
          style={customStyles.days}
        >
          {label}
        </div>
      );
    }

    return (
      <div className="days row" style={customStyles.daysRow}>
        {days}
      </div>
    );
  };

  function getEvent(day: Date) {
    let evt = _.find(state.eventDates, { date: day });
    //
    return evt;
  }

  function renderCells() {
    const { selectedMonth, selectedDate, eventDates } = state;
    const { minDate, maxDate } = props;
    const customStyles: any = props.customStyles || {};

    const monthStart = dateFns.startOfMonth(selectedMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);
    const rows = [];
    let days = [];
    let day = startDate;

    const eoc = moment(projectedData.eoc).toDate();
    const recertRangeIni = moment(eoc).add(-4, "days").add(200, "ms").toDate();
    const recertRangeIniStrict = moment(eoc).add(-5, "days").add(200, "ms").toDate();



    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const formattedDate = dateFns.format(day, "d");
        const cloneDay = day;
        let outerClass = "col cell";
        let outerStyle = customStyles.cell;
        if (!dateFns.isSameMonth(day, monthStart)) {
          outerStyle = { ...outerStyle, ...customStyles.extra };
        }

        if (dateFns.isBefore(day, minDate) || dateFns.isAfter(day, maxDate)) {
          outerStyle = {
            ...outerStyle,
            ...customStyles.extra,
            ...customStyles.outOfRange,
          };
        }

        let innerClass = "innerCell";
        let innerStyle = customStyles.innerCell;
        let evt = getEvent(day);
        if (evt !== undefined) {
          innerClass += ` event ${evt.class}`;
          innerStyle = { ...innerStyle, ...customStyles.event };
        }
        if (dateFns.isSameDay(day, selectedDate)) {
          innerStyle = { ...innerStyle, ...customStyles.selected };
        }
        let color = {};
        let bg_visit_type = "";
        let backgroundColor =""

        let dayEvent = eventDates.find(
          (e) => new Date(e.date).getDate() === cloneDay.getDate()
        );
        if (!dayEvent) {
          outerClass += " disabled outside";
        }

        // only in history
        if (props.historyOrProjected === "history" && (cloneDay >= monthStart && cloneDay <= monthEnd )) {
          eventDates.forEach((value) => {
            if (value?.date) {
    
              let tmp = new Date(value.date);
              if (
                tmp.getFullYear() === cloneDay.getFullYear() &&
                tmp.getMonth() === cloneDay.getMonth() &&
                tmp.getDate() === cloneDay.getDate()
              ) {
                color = {
                  width: "40px",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor:colorByTypeCrude(value.visitType)
                };
                bg_visit_type = colorByType(value.visitType) + " rounded-[9px]";
                  backgroundColor =  colorByTypeCrude(value.visitType);

              }
            }
          });
          // let dischargedV = false;


          // if(projectedData?.discharged !== undefined){
          //   const discharged = new Date(projectedData?.discharged);
          //   if(discharged){
          //     dischargedV = dateFns.isSameDay(cloneDay, discharged);
          //     if (dischargedV){
          //       outerClass = "col cell";
          //       color = {
          //         width: "40px",
          //         height: "40px",
          //         display: "flex",
          //         alignItems: "center",
          //         justifyContent: "center",
          //         backgroundColor:colorByTypeCrude("D")
          //       };
          //       bg_visit_type += " visit-projected-discharged rounded-[9px]";
          //       backgroundColor =  getVisitsColorsByUser(VISITS.DISCHARGE);
          //       dayEvent = {
          //         dateEnd: discharged,
          //         date: discharged,
          //         title: "",
          //         distance: "",
          //         visitType: "D",
          //       }
          //     }
          //   }
            
          // }
        } else if (cloneDay >= monthStart && cloneDay <= monthEnd ) { // projected
          const interval = projectedData.frequenciesRange.find((x) => {
            const ini = new Date(x.iniDay);
            const end = new Date(x.endDay);
            return ini <= cloneDay && end >= cloneDay;
          });


          let adminIs = false;
          let evaluationIs = false;


          if(projectedData?.admission !== undefined){
            const admin = new Date(projectedData?.admission);
            if(admin){
               adminIs = dateFns.isSameDay(cloneDay, admin);
              if (adminIs){
                bg_visit_type += " visit-projected-addmission";
                backgroundColor =  getVisitsColorsByUser(VISITS.ADMISSION);
              }
            }
            
          }
          if(projectedData?.evaluation !== undefined){
            const evaluation = new Date(projectedData?.evaluation);
            if(evaluation){

               evaluationIs = dateFns.isSameDay(cloneDay, evaluation);
              if (evaluationIs){
                bg_visit_type += " visit-projected-evaluation";
                backgroundColor =  getVisitsColorsByUser(VISITS.EVALUATION);
              }
            }
          }
          
          if (interval) {
            innerClass = " visit-projected-range";
 

            if ((cloneDay >= recertRangeIni) && (cloneDay <= eoc)){
              innerClass += " recert-range";
              backgroundColor =  getVisitsColorsByUser(VISITS.RECERT);

              innerStyle ={...innerStyle,background:backgroundColor}
            }
           
            let adminIs = false;
            let evaluationIs = false;


            if(projectedData?.admission !== undefined){
              const admin = new Date(projectedData?.admission);
              if(admin){
                 adminIs = dateFns.isSameDay(cloneDay, admin);
                if (adminIs){
                  bg_visit_type += " visit-projected-addmission";
                  backgroundColor =  getVisitsColorsByUser(VISITS.ADMISSION);
                }
              }
              
            }



            if(projectedData?.evaluation !== undefined){
              const evaluation = new Date(projectedData?.evaluation);
              if(evaluation){

                 evaluationIs = dateFns.isSameDay(cloneDay, evaluation);
                if (evaluationIs){
                  bg_visit_type += " visit-projected-evaluation";
                  backgroundColor =  getVisitsColorsByUser(VISITS.EVALUATION);
                }
              }
            }

       


            const dre30Id = projectedData.visit30dre.find((x) => {
              const d30 = new Date(x);
              return dateFns.isSameDay(cloneDay, d30)
            });
            if (dre30Id){
              bg_visit_type += " visit-projected-30dre";
              backgroundColor =  getVisitsColorsByUser(VISITS.THYRTYDRE);
            }
     


            if (cloneDay >= recertRangeIniStrict && cloneDay < recertRangeIni){
              bg_visit_type += " recert-range-ini" + (i === 6?" end-recert-range":"");
              backgroundColor =  getVisitsColorsByUser(VISITS.RECERT);

            }

            const ini = new Date(interval.iniDay);
            const end = new Date(interval.endDay);
            if (i === 0 || dateFns.isSameDay(cloneDay, ini))
              innerClass += " initial" + ((adminIs || evaluationIs || dre30Id)?" left-restrinct":"");
            if (i === 6 || dateFns.isSameDay(cloneDay, end))
              innerClass += " final" + ((adminIs || evaluationIs || dre30Id)?" right-restrinct":"");




          }
        }

        bg_visit_type += (day < monthStart || day > monthEnd) ? " visit-nomonth" : "";

        const innerContent = (
          <div
            key={day.toString() + "spec_day"}
            className={outerClass}
            onMouseEnter={() => handleMouseEnter(dayEvent)}
            style={outerStyle}
            onClick={() =>
              onDateClick(dateFns.parse(cloneDay.toString(), "", 0))
            }
          >
            <div className={innerClass} style={innerStyle}>
              <span
                className={"number  cursor-default visit-" + bg_visit_type}
                style={{ ...customStyles.number, ...color, backgroundColor:backgroundColor }}
              >
                {formattedDate}
              </span>
            </div>
          </div>
        );

        days.push(
          ((props.historyOrProjected === "history") ? (
            <Typpy
              placement="right-end"
              content={<TyppyVisit event={dayEvent} />}
              duration={[300, 0]}
              key={day.toString() + "typ"}
            >
              {innerContent}
            </Typpy>
          ) : (innerContent))
        );

        day = dateFns.addDays(day, 1);
      }

      rows.push(
        <div
          key={"cal_dayr" + day.toString()}
          className="row h-[40px]"
          style={customStyles.numberRow}
        >
          {days}
        </div>
      );

      days = [];
    }
    return (
      <div className="body" style={customStyles.body}>
        {rows}
      </div>
    );
  }

  function onDateClick(day: Date) {
    state.selectedDate = day;
    setMyState({ ...state });
  }

  function nextMonth() {
    setIsDisabledSearchMont(true)
    handleChangeMonth(moment(state.selectedMonth).add(1, "month").toDate())

    state.selectedMonth = moment(state.selectedMonth).add(1, "month").toDate();
    setMyState({ ...state });
  }

  function prevMonth() {
    setIsDisabledSearchMont(true)
    handleChangeMonth(moment(state.selectedMonth).add(-1, "month").toDate())

    state.selectedMonth = moment(state.selectedMonth).add(-1, "month").toDate();
    setMyState({ ...state });

  }

  return (
    <div className="calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};

export default Calendar;
