import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { putDischargeRecertConflic } from "../../api/schedulingApi";
import { IVIsitsResolveConflic } from "../../interfaces/IScheduling";

type DefineVisitModalProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  visitDataConflic: any;
};

export const DefineVisitModal = ({
  setOpen,
  visitDataConflic,
}: DefineVisitModalProps) => {
  const [openConfirmModal, setOpenConfirmModal] = useState({
    state: false,
    visit: {
      cityName: "",
      clinitianId: 0,
      desition: 0,
      distance: "",
      name: "",
      patientId: 0,
    },
  });
  const wrapperUndoDoneRef = useRef<HTMLDivElement>(null);
  const wrapperDefineVisit = useRef<HTMLDivElement>(null);

  const handleCloseModal = () => {
    setOpenConfirmModal({
      ...openConfirmModal,
      state: false,
    });
    setOpen(true);
  };

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (
        event.key === "Escape" &&
        wrapperUndoDoneRef.current &&
        !wrapperUndoDoneRef.current.contains(event.target)
      ) {
        event.preventDefault();
        handleCloseModal();
      } else if (
        event.key === "Escape" &&
        wrapperDefineVisit.current &&
        !wrapperDefineVisit.current.contains(event.target)
      ) {
        event.preventDefault();
        setOpen(false);
      }
    };

    window.addEventListener("keydown", keyDownHandler);

    // 👇️ clean up event listener
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, []);
  const getDesition = (value: number) =>
    value === 1 ? "recertifying" : value === 2 ? "discharging" : "";

  const solveConflic = () => {
    const body: IVIsitsResolveConflic = {
      patientId: openConfirmModal.visit.patientId,
      clinitianId: openConfirmModal.visit.clinitianId,
      desition: openConfirmModal.visit.desition,
    };
    putDischargeRecertConflic(body).then((result) => {
      setOpen(false);
    });
  };

  const DefineVisitUndoDone = () => {
    return (
      <div
        className="flex w-[257px] h-[174px] visit-define-modal bg-white"
        ref={wrapperUndoDoneRef}
      >
        <div className="py-[32px] px-[24px] flex flex-col w-[100%] place-content-between">
          <div>
            <div className="flex flex-row">
              <div className="flex justify-center basis-[95%]">
                <span className="define-visit-title text-center ">Got it!</span>
              </div>
              <div className="flex justify-end basis-[5%] relative top-[-15px] right-[-5px]">
                <button
                  title="close-user-button"
                  type="button"
                  onClick={() => {
                    setOpenConfirmModal({
                      ...openConfirmModal,
                      state: false,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    className="modal-patient-button-close-bulk"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
            <h2 className="definte-visit-subtitle text-center mt-[24px]">
              The last visit for{" "}
              <span className="font-bold">{openConfirmModal.visit.name}</span>{" "}
              will be a{" "}
              <span className="font-bold">
                {getDesition(openConfirmModal.visit.desition)}
              </span>
            </h2>

            <div className="flex place-content-end mt-[16px]">
              <button
                className="btn_visit_undo h-[17px] flex items-center place-content-center  rounded-[6px]"
                onClick={() => {
                  setOpenConfirmModal({
                    ...openConfirmModal,
                    state: false,
                  });
                }}
              >
                Undo
              </button>
              <button
                className="btn_visit_done h-[17px] flex items-center place-content-center pl-[24px]  rounded-[6px]"
                onClick={solveConflic}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleOnClickVisit = (desition: any) => {
    setOpenConfirmModal({
      visit: {
        ...visitDataConflic,
        desition: desition,
      },
      state: true,
    });
  };

  return openConfirmModal.state ? (
    <DefineVisitUndoDone />
  ) : (
    <div
      className="flex w-[312px] h-[252px] visit-define-modal bg-white"
      ref={wrapperDefineVisit}
    >
      <div className="py-[32px] px-[24px] flex flex-col w-[100%] place-content-between">
        <div>
          <div className="flex flex-row pb-[16px]">
            <div className="flex justify-center basis-[95%]">
              <span className="define-visit-title text-center ">
                Discharge or recert at EOC
              </span>
            </div>
            <div className="flex justify-end basis-[5%] relative top-[-15px] right-[-5px]">
              <button
                title="close-user-button"
                type="button"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <FontAwesomeIcon
                  icon={faClose}
                  className="modal-patient-button-close-bulk"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
          <span className="definte-visit-subtitle text-center">
            Will you be <span className="font-bold">recertifying</span> or
            <span className="font-bold"> discharging</span> this patient at the
            enf of care?
          </span>
          <div
            className="flex mt-[16px] cursor-pointer"
            onClick={() => handleOnClickVisit(1)}
          >
            <div
              className="rounded-full w-[16px] h-[16px] mr-[10px]"
              style={{ background: `#EC6B66` }}
            ></div>
            <div className="flex flex-column">
              <span className="define-visit-patient-name">
                {visitDataConflic.name}
              </span>
              <span className="define-visit-patient-distance">{`recertifying ${visitDataConflic.cityName} ${visitDataConflic.distance} miles `}</span>
            </div>
          </div>
          <div
            className="flex mt-[16px] cursor-pointer"
            onClick={() => handleOnClickVisit(2)}
          >
            <div
              className="rounded-full w-[16px] h-[16px] mr-[10px]"
              style={{ background: `#FFD15E` }}
            ></div>
            <div className="flex flex-column">
              <span className="define-visit-patient-name">
                {visitDataConflic.name}
              </span>
              <span className="define-visit-patient-distance">{`discharging ${visitDataConflic.cityName} ${visitDataConflic.distance} miles `}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
