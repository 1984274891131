import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import * as Yup from "yup";
import { CareTeamErrorContainer } from "../../../components/patients";
import DropdownCareTeam from "../../../components/patients/DropdownCareTeam";
import { LoaderComponent } from "../../../Controls/Loader";
import { DISCIPLINE } from "../../../helpers/globals";
import { ICareTeam, ICreateCareTeam, IResponseValue } from "../../../interfaces";
import { IUsers } from "../../../interfaces/IUser";
import { GetData } from "../../../Services/Api";
import { careTeamServices } from "../../../Services/careTeam.services";
import { ShowAlert, ValueFromUserData } from "../../../Services/utility";
import { useAppSelector } from "../../../store/hooks";
import { selectIsLoading } from "../../../store/slices/notification/notificationSlice";

const CareTeam = (props: any) => {
  const [initialValues, setInitialValues] = useState({
    teamLeader: 0,
    teamLeaderBackup: 0,
    lpn: 0,
    ot: 0,
    ota: 0,
    pt: 0,
    pta: 0,
    slp: 0,
    sn: 0,
    aid: 0,
    msw: 0,
  } as ICareTeam);

  const isLoading = useAppSelector(selectIsLoading);
  const [isTrySubmit, setIsTrySubmit] = useState(false);
  const isDisabled = isLoading || props.patient === -1;

  const [users, setUsers] = useState<IUsers[]>([]);
  const history = useNavigate();
  const [otherError, setOtherError] = useState<string>("");
  const [valueChanged, setValueChanged] = useState({ id: 0, name: "" });

  useEffect(() => {
    const getRoles = async () => {
      const result = await GetData("/User/GetNamesSimple");
      const users = result.data.map((user: any) => ({
        id: user.id,
        name: user.firstName,
        discipline: user.discipline,
      }));
      setUsers(users ?? []);
    };
    getRoles();
  }, []);

  const handleSaveNew = async (
    values: any,
    { setErrors, setFieldTouched }: any
  ) => {
    setErrors({});
    if (props.patient !== -1) {
      const careTeam: ICreateCareTeam = {
        ...values,
        teamLeader: values.teamLeader,
        ot: values.ot,
        ota: values.ota,
        pt: values.pt,
        pta: values.pta,
        slp: values.slp,
        sn: values.sn,
        aid: values.aid,
        msw: values.msw,
        patientId: props.patient,
      };
      props.setisLoading(true);

      const res = (await careTeamServices().updateCareTeam(
        careTeam
      )) as IResponseValue;

      if (res.status === 200) {
        props.setisLoading(false);

        history(`/patients/${props.patient}`);
      } else {

        setOtherError(res.value);
      }
      props.setisLoading(false);

    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSaveNew}
      validationSchema={Yup.object({
        teamLeader: Yup.number().moreThan(0, "Case manager"),
      })}
    >
      {function CareTeamForm({
        setFieldValue,
        setFieldTouched,
        values,
        errors,
      }) {
        useEffect(() => {
          if (props.patient !== -1) {
            const getCareTeamById = async () => {
              const result: any = await careTeamServices().getCareTeamById(
                props.patient
              );

              if (result.status === 200 && result.value.teamLeader > 0) {
                const careTeam: any = {
                  aid: result.value.aid,
                  msw: result.value.msw,
                  ot: result.value.ot,
                  ota: result.value.ota,
                  pt: result.value.pt,
                  pta: result.value.pta,
                  slp: result.value.slp,
                  sn: result.value.sn,
                  teamLeader: result.value.teamLeader,
                };
                const fields = [
                  "aid",
                  "msw",
                  "ot",
                  "ota",
                  "pt",
                  "pta",
                  "slp",
                  "sn",
                  "teamLeader",
                ];
                setInitialValues(careTeam);
                fields.forEach((field: string) => {
                  setFieldValue(field, careTeam[field], false);
                });
              }
            };
            getCareTeamById();
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
          if (props.patient > -1) {
            const userId = ValueFromUserData("id");
            const userRol = ValueFromUserData("rol");

            if (values.teamLeader === 0) {
              setFieldValue("teamLeader", userId, true);
            }
            if (values.sn === 0 && userRol === "SN") {
              setFieldValue("sn", userId, true);
            }
            if (values.pt === 0 && userRol === "PT") {
              setFieldValue("pt", userId, true);
            }
          }
        }, []);

        const teamLeader = values.teamLeader;
        const sn = values.sn;
        const pt = values.pt;
        const ot = values.ot;
        const slp = values.slp;
        const aid = values.aid;

        useEffect(() => {
          if (props.patient > -1) {

            if (valueChanged.id > 0 && valueChanged.name === "sn") {
              setFieldValue("teamLeader", valueChanged.id, true);
            }
            if (valueChanged.id > 0 && valueChanged.name === "pt" && sn === 0) {
              setFieldValue("teamLeader", valueChanged.id, true);
            }
            if (valueChanged.id > 0 && valueChanged.name === "ot" && sn === 0 && pt === 0) {
              setFieldValue("teamLeader", valueChanged.id, true);
            }
            if (valueChanged.id > 0 && 
              valueChanged.name === "slp" &&
              sn === 0 &&
              pt === 0 &&
              ot === 0
            ) {
              setFieldValue("teamLeader", valueChanged.id, true);
            }
            if (valueChanged.id > 0 &&
              valueChanged.name === "aid" &&
              sn === 0 &&
              pt === 0 &&
              ot === 0 &&
              slp === 0
            ) {
              setFieldValue("teamLeader", valueChanged.id, true);
            }
          }
        }, [valueChanged]);
        const isError = isTrySubmit && Object.keys(errors).length > 0;

        return !isLoading ? (
          <Form>
            <section>
              {(isError || otherError.length > 0) && (
                <div className="w-100">
                  <CareTeamErrorContainer
                    error={errors}
                    otherError={otherError}
                  />
                </div>
              )}

              <h5 className="mt-[40px] mb-[23px] px-[16px] careteam__heading">
                Case manager
              </h5>
              <div className="flex place-content-start items-start py-[20px]">
                <div className="w-[33.33%] px-[16px]">
                  <DropdownCareTeam
                    name="teamLeader"
                    span="*"
                    value={values.teamLeader}
                    label="Case manager"
                    options={users}
                    preText="Select case manager"
                    onSelect={(value: any) => {
                      setFieldTouched("teamLeader", true);
                      setFieldValue("teamLeader", value.id, true);
                      setValueChanged({ id: value.id, name: "teamLeader" });
                    }}
                  />
                </div>
                <div className="w-[33.33%] px-[16px]">
                  <DropdownCareTeam
                    name="teamLeaderBackup"
                    value={values.teamLeaderBackup}
                    label="Backup case manager"
                    options={[]}
                    preText="Select backup"
                    onSelect={(value: any) => {
                      setFieldTouched("teamLeaderBackup", true);
                      setFieldValue("teamLeaderBackup", value.id, true);
                      setValueChanged({
                        id: value.id,
                        name: "teamLeaderBackup",
                      });
                    }}
                    isDisabled="true"
                  />
                </div>
              </div>
            </section>
            <hr />

            <section>
              <h5 className="mt-[40px] mb-[23px] px-[16px] careteam__heading">
                Skilled nursing
              </h5>
              <div className="flex place-content-start items-start py-[20px]">
                <div className="w-[33.33%] px-[16px]">
                  <DropdownCareTeam
                    name="sn"
                    value={values.sn}
                    label="SN"
                    options={[
                      {id: 0, name: 'Select SN', discipline: ''},
                      ...users.filter(
                        (el) =>
                          el.discipline === DISCIPLINE.SN ||
                          el.discipline === DISCIPLINE.ADMIN,
                        []
                      )
                    ]}
                    preText="Select SN"
                    onSelect={(value: any) => {
                      setFieldTouched("sn", true);
                      setFieldValue("sn", value.id, true);
                      setValueChanged({ id: value.id, name: "sn" });
                    }}
                  />
                </div>
                <div className="w-[33.33%] px-[16px]">
                  <DropdownCareTeam
                    name="lpn"
                    value={initialValues.lpn ? initialValues.lpn : 0}
                    label="LPN"
                    options={[]}
                    preText="Select LPN"
                    onSelect={(value: any) => {
                      setFieldTouched("lpn", true);
                      setFieldValue("lpn", value.id, true);
                      setValueChanged({ id: value.id, name: "lpn" });
                    }}
                  />
                </div>
              </div>
            </section>
            <hr />

            <section>
              <h5 className="mt-[40px] mb-[23px] px-[16px] careteam__heading">
                Physical therapy
              </h5>
              <div className="flex place-content-start items-start py-[20px]">
                <div className="w-[33.33%] px-[16px]">
                  <DropdownCareTeam
                    name="pt"
                    value={values.pt}
                    label="PT"
                    options={[
                      {id: 0, name: 'Select PT', discipline: ''},
                      ...users.filter(
                      (el) =>
                        el.discipline === DISCIPLINE.PT ||
                        el.discipline === DISCIPLINE.ADMIN,
                      []
                    )]}
                    preText="Select PT"
                    onSelect={(value: any) => {
                      setFieldTouched("pt", true);
                      setFieldValue("pt", value.id, true);
                      setValueChanged({ id: value.id, name: "pt" });
                    }}
                  />
                </div>
                <div className="w-[33.33%] px-[16px]">
                  <DropdownCareTeam
                    name="pta"
                    value={values.pta}
                    label="PTA"
                    options={[
                      {id: 0, name: 'Select PTA', discipline: ''},
                      
                      ...users.filter(
                      (el) => el.discipline === DISCIPLINE.PTA,
                      []
                    )]}
                    preText="Select PTA"
                    onSelect={(value: any) => {
                      setFieldTouched("pta", true);
                      setFieldValue("pta", value.id, true);
                      setValueChanged({ id: value.id, name: "pta" });
                    }}
                  />
                </div>
              </div>
            </section>
            <hr />

            <section>
              <h5 className="mt-[40px] mb-[23px] px-[16px] careteam__heading">
                Occupational therapy
              </h5>
              <div className="flex place-content-start items-start py-[20px]">
                <div className="w-[33.33%] px-[16px]">
                  <DropdownCareTeam
                    name="ot"
                    value={values.ot}
                    label="OT"
                    options={[
                      {id: 0, name: 'Select OT', discipline: ''},
                      
                      ...users.filter(
                      (el) =>
                        el.discipline === DISCIPLINE.OT ||
                        el.discipline === DISCIPLINE.ADMIN,
                      []
                    )]}
                    preText="Select OT"
                    onSelect={(value: any) => {
                      setFieldTouched("ot", true);
                      setFieldValue("ot", value.id, true);
                      setValueChanged({ id: value.id, name: "ot" });
                    }}
                  />
                </div>
                <div className="w-[33.33%] px-[16px]">
                  <DropdownCareTeam
                    name="ota"
                    value={values.ota}
                    label="OTA"
                    options={[
                      {id: 0, name: 'Select OTA', discipline: ''},
                      
                      ...users.filter(
                      (el) => el.discipline === DISCIPLINE.OTA,
                      []
                    )]}
                    preText="Select OTA"
                    onSelect={(value: any) => {
                      setFieldTouched("ota", true);
                      setFieldValue("ota", value.id, true);
                      setValueChanged({ id: value.id, name: "ota" });
                    }}
                  />
                </div>
              </div>
            </section>
            <hr />

            <section>
              <h5 className="mt-[40px] mb-[23px] px-[16px] careteam__heading">
                Speech language pathology
              </h5>
              <div className="flex place-content-start items-start py-[20px]">
                <div className="w-[33.33%] px-[16px]">
                  <DropdownCareTeam
                    name="slp"
                    value={values.slp}
                    label="SLP"
                    options={[
                      {id: 0, name: 'Select SLP', discipline: ''},
                      
                      ...users.filter(
                      (el) =>
                        el.discipline === DISCIPLINE.OTA ||
                        el.discipline === DISCIPLINE.ADMIN,
                      []
                    )]}
                    preText="Select SLP"
                    onSelect={(value: any) => {
                      setFieldTouched("slp", true);
                      setFieldValue("slp", value.id, true);
                      setValueChanged({ id: value.id, name: "slp" });
                    }}
                  />
                </div>
              </div>
            </section>
            <hr />

            <section>
              <h5 className="mt-[40px] mb-[23px] px-[16px] careteam__heading">
                Nursing Assistant
              </h5>
              <div className="flex place-content-start items-start py-[20px]">
                <div className="w-[33.33%] px-[16px]">
                  <DropdownCareTeam
                    name="aid"
                    value={values.aid}
                    label="AID"
                    options={[
                      {id: 0, name: 'Select AID', discipline: ''},
                      
                      ...users.filter(
                      (el) =>
                        el.discipline === DISCIPLINE.AID ||
                        el.discipline === DISCIPLINE.ADMIN,
                      []
                    )]}
                    preText="Select AID"
                    onSelect={(value: any) => {
                      setFieldTouched("aid", true);
                      setFieldValue("aid", value.id, true);
                      setValueChanged({ id: value.id, name: "aid" });
                    }}
                  />
                </div>
              </div>
            </section>
            <hr />

            <section>
              <h5 className="mt-[40px] mb-[23px] px-[16px] careteam__heading">
                Social worker
              </h5>
              <div className="flex place-content-start items-start py-[20px]">
                <div className="w-[33.33%] px-[16px]">
                  <DropdownCareTeam
                    name="msw"
                    value={values.msw}
                    label="MSW"
                    options={[
                      {id: 0, name: 'Select MSW', discipline: ''},
                      
                      ...users.filter(
                      (el) =>
                        el.discipline === DISCIPLINE.MSW ||
                        el.discipline === DISCIPLINE.ADMIN,
                      []
                    )]}
                    preText="Select MSW"
                    onSelect={(value: any) => {
                      setFieldTouched("msw", true);
                      setFieldValue("msw", value.id, true);
                      setValueChanged({ id: value.id, name: "msw" });
                    }}
                  />
                </div>
              </div>
            </section>
            <hr />
            <section>
              <div className="flex gap-4 w-full place-content-end p-6">
                <button
                  type="button"
                  className="h-[40px] px-[42px] rounded-[6px] flex items-center place-content-center cancel_btn"
                  onClick={() =>
                    props.patient !== -1
                      ? history(`/patients/${props.patient}`)
                      : history("/patients")
                  }
                >
                  Cancel
                </button>
                {!isDisabled && (
                  <button
                    type="submit"
                    className="h-[40px] px-[42px] rounded-[6px] flex items-center place-content-center save_btn careteam"
                    disabled={isDisabled}
                    onClick={() => setIsTrySubmit(true)}
                  >
                    Save
                  </button>
                )}
              </div>
            </section>
          </Form>
        ) : (
          <LoaderComponent />
        );
      }}
    </Formik>
  );
};

export default CareTeam;
