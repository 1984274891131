import React from "react";

type Props = {
    children: JSX.Element | JSX.Element[];
};

export const SuccessLayout = ({ children }: Props) => {
    return (
        <div className="auth__success-main-container">
            <img src="images/success-left.png" className="max-w-full h-auto" alt="..." style={{width:'15%'}}/>            
            <div className="auth__success-content-container">{children}</div>            
            <img src="images/success-right.png" className="max-w-full h-auto" alt="..."  style={{width:'15%'}}/>  
        </div>

    );


};