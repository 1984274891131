import { CFormGroup, CInput, CLabel, CModalFooter } from "@coreui/react";
import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useForm } from "react-hook-form";

export const UploadAvatarModal = (props: any) => {
  var editor: AvatarEditor;
  const setEditorRef = (ed: any) => {
    editor = ed;
  };
  const onChange = (e: any) => {
    const { value } = e.target;
    setScale(parseFloat(value));
  };
  const { register, watch } = useForm();
  const avatar = watch("avatar");

  const [scale, setScale] = useState(1);
  const submitAvatar = () => {
    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();
      props.onAccept(croppedImg);
    }
    props.onClose();
  };

  return (
    <form  ref={props.modalRef}>
      <section className="modal-main-user-upload-avatar">
        {props.children}

        <CFormGroup className="justify-content-center mx-0 px-0" row>
          <AvatarEditor
            ref={setEditorRef}
            image={
              avatar && avatar[0]
                ? avatar[0]
                : props.avatarOnLoad
                ? props.avatarOnLoad
                : "avatars/default-avatar.svg"
            }
            width={200}
            height={200}
            border={1}
            borderRadius={100}
            color={[0, 0, 0, 0.5]}
            scale={scale}
          />
        </CFormGroup>
        <CFormGroup className="justify-content-center mx-0" row>
          <CInput
            id="range-input"
            type="range"
            className={`custom-range col-11 row border-0${
              !(avatar && avatar[0]) && !props.avatarOnLoad ? " d-none" : ""
            }`}
            style={{ paddingRight: 0 }}
            defaultValue={scale}
            step={0.01}
            min={1}
            max={2}
            onChange={onChange}
          />
        </CFormGroup>
        <CFormGroup className="custom-upload-avatar" row>
          <input
            {...register("avatar", {
              required: !props.avatarOnLoad ?? "This field is required",
            })}
            id="avatar-file-input"
            name="avatar"
            accept="image/*"
            type="file"
          />
          <CLabel
            htmlFor="avatar-file-input"
            variant="custom-file"
            className="text-truncate"
          >
            {(avatar && avatar[0]?.name) ?? "Choose file..."}
          </CLabel>
        </CFormGroup>
        <CModalFooter className="px-4">
          <section>
            <div className="flex gap-4 w-full place-content-end p-6">
              <button
                type="button"
                className="h-[35px] px-[42px] rounded-[6px] flex items-center place-content-center cancel_btn"
                onClick={props.onClose}
              >
                Cancel
              </button>

              <button
                type="button"
                onClick={submitAvatar}
                className="h-[31px] px-[42px] rounded-[6px] flex items-center place-content-center save_btn"
              >
                Save
              </button>
            </div>
          </section>
        </CModalFooter>
      </section>
    </form>
  );
};
