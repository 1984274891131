import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

export function LoaderComponent() {
  return (
    <div className="pt-[20%] align-middle h-40 w-full flex items-center place-content-center">
      <div className="grid justify-items-center">
        <FontAwesomeIcon
          className="animate-spin w-10 h-10"
          icon={regular("spinner")}
          height={30}
          width={30}
          strokeWidth={10}
        />
      </div>
    </div>
  );
}
