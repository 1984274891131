import { faExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
// import { cardinalNumber } from "../../../helpers/globals";

export const PatientErrorContainer = (props: any) => {
  const { error } = props;

  // const [messages, setMessages] = useState([]);
  var entriesErrors = Object.entries(error);
  let messages: any[] = [];

  const getArrayMessages = () => {
    entriesErrors.map((element: any) => {
      if (element[0] === "phonList") {
        messages.push("Contact information");
      } else if (element[0] === "phonMDList") {
        messages.push("Physician information");
      }
    });
  };

  var mapErrors = Object.values(error);

  const getSimpleMessage = () => {
    mapErrors.map((element: any) => {
      messages.push(element.message);
    });
  };

  // const getMessage = () =>
  //   mapErrors.map((element: any) => {
  //     if (!Array.isArray(element)) {
  //       messages.push(element);
  //     } else {
  //       const arrayValues = Object.values(element);

  //       arrayValues.map((secondElement: any, index) => {
  //         if (secondElement) {
  //           const obtainMessages = Object.values(secondElement);
  //           obtainMessages.map((thirdElement: any) => {
  //             messages.push({
  //               ...thirdElement,
  //               message: cardinalNumber[index] + " " + thirdElement.message,
  //             });
  //           });
  //         }
  //       });
  //     }
  //   });
  getArrayMessages();
  getSimpleMessage();

  if (props.otherError.length > 0) {
    messages.push(props.otherError);
  }

  return (
    <div className="bg-white">
      <div className="flex items-star p-8 user-error-container">
        <span className="rounded-full w-[30px] h-[30px] flex items-center justify-center exclamation-user-error">
          <FontAwesomeIcon
            color="#D92D20"
            stroke="#FFFFFF"
            strokeWidth={30}
            icon={faExclamation}
            className="h-8 w-8"
            aria-hidden="true"
          />
        </span>
        <div className="ml-5">
          <div className="mt-[5px] user-setting-error-bold-text">
            Unable to save your information.
          </div>
          <div className="mt-[5px]   user-setting-error-normal-text">
            Please complete all required fields before saving:
          </div>
          {messages.map((element: any, index: any) => {
            return (
              <div
                key={index}
                className="mt-[5px]  user-setting-error-bold-text"
              >
                {JSON.stringify(element)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
