import React, { useState } from "react";
import * as utility from "../../Services/utility";
import "tippy.js/dist/tippy.css";
import { useAppSelector } from "../../store/hooks";
import { useNavigate } from "react-router-dom";

import { faArrowUp, faArrowDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoaderSpinner } from "../UI/LoaderSpinner";
import { DISCIPLINE, parseUserRol } from "../../helpers/globals";

const columns = [
  {
    Header: "First Name",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    accessor: "lastName",
  },
  {
    Header: "City",
    accessor: "city",
  },
  {
    Header: "Discipline",
    accessor: "discipline",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "Last active",
    accessor: "lastActive",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

type tableProps = {
  sortHandler: Function;
};

export const TableUsers = (props: tableProps) => {
  const [selectedSort, setSelectedSort] = useState<string>("");
  const { users, loading } = useAppSelector((state) => state.users);
  const [arrowDir, setArrowDir] = useState<string>("u");
  const navigate = useNavigate();

  const handleSelected = (touched: string) => {
    if (touched === "frequency") return;
    if (users.length <= 0) return;
    if (loading === "pending" || loading === "idle") return;

    const arr = arrowDir === "u" ? "d" : "u";

    props.sortHandler(touched, arr);

    setArrowDir(arr);
    setSelectedSort(touched);
  };

  if (loading === "pending" || loading === "idle") {
    return (
      <table className="users__table">
        <thead>
          <tr className="table__head-container">
            {columns.map((col) => (
              <th
                className={
                  "table__head" +
                  (selectedSort === col.accessor ? " sorted " : "") +
                  (col.accessor !== "frequency" ? " hover:bg-[#E5E7EB]" : " ")
                }
                key={col.accessor}
              >
                <div className="row pl-4">
                  <h5 className="user-table">{col.Header}</h5>
                  {selectedSort === col.accessor ? (
                    <FontAwesomeIcon
                      className="ml-[6.5px] w-[9px] h-[12px]"
                      color="#6C737F"
                      stroke="1px"
                      icon={arrowDir === "u" ? faArrowUp : faArrowDown}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table__body">
          <tr className="table__row">
            <td />
            <td />
            <td />
            <td>
              {" "}
              <LoaderSpinner />{" "}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
  return (
    <table className="users__table">
      <thead>
        <tr className="table__head-container">
          {columns.map((col) => (
            <th
              className={
                "table__head" +
                (selectedSort === col.accessor ? " sorted " : "") +
                "table__head hover:bg-[#E5E7EB]"
              }
              key={col.accessor}
              onClick={() => handleSelected(col.accessor)}
            >
              <div className="row pl-4">
                <h5 className="user-table">{col.Header}</h5>

                {selectedSort === col.accessor ? (
                  <FontAwesomeIcon
                    className="ml-[6.5px] w-[9px] h-[12px]"
                    color="#6C737F"
                    size="1x"
                    strokeWidth={1}
                    icon={arrowDir === "u" ? faArrowUp : faArrowDown}
                  />
                ) : (
                  ""
                )}
              </div>
            </th>
          ))}
        </tr>
      </thead>

      <tbody className="table__body">
        {users.length > 0 &&
          users.map((p) => (
            <tr
              onClick={() => navigate(`/user/${p.id}`)}
              key={p.id}
              className="table__row cursor-pointer"
            >
              <td className="table__data">{p.firstName}</td>
              <td className="table__data">{p.lastName}</td>
              <td className="table__data">{p.city}</td>
              <td className="table__data">{parseUserRol(p.discipline as DISCIPLINE)}</td>
              <td className="table__data">{p.address}</td>
              <td className="table__data">{utility.getDate(p.lastActive)}</td>
              <td
                className={
                  "table__data status " + p.status?.toLocaleLowerCase()
                }
              >
                <p>{p.status}</p>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};
