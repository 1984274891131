import { ICreateRecert, IUpdateRecert } from "../interfaces/ICertifications";
import { DeleteData, PostData, PutCert } from "../Services/Api";

export const saveRecert = async (recert: ICreateRecert) => {
  return await PostData("/PatientRecertification/Add", recert);
};

export const updateRecert = async (recert: IUpdateRecert) => {
  return await PutCert("/PatientRecertification/Update", recert);
};

export const deleteRecert = async (id: number) => {
  return await DeleteData(`/PatientRecertification/Delete?id=${id}`);
};
