import React from "react";

export const MyTextAreaRef = ({
  register,
  errors,
  id,
  name,
  span,
  icon,
  inEdition,
  plusClass,
  type,
  fieldName,
  isDirty,
  i,
  ...props
}: any) => {
  // const [field, meta] = useField(props);

  return (
    <div className={`text__area`}>
      <textarea
        className={`max-w-[320px] min-h-[131px] text-input  ${
          !props.icon ? "pl-8" : "pl-[54px]"
        }`}
        {...register(name)}
        {...props}
      />
    </div>
  );
};
