import React from "react";
import { getVisitsColorsByUser, VISITS } from "../../helpers/globals";

export const Legend = () => {
  
  const bgAdmission = getVisitsColorsByUser(VISITS.ADMISSION) 

  const bgEvaluation = getVisitsColorsByUser(VISITS.EVALUATION) 
  const bg30DRE = getVisitsColorsByUser( VISITS.THYRTYDRE) 
  const bgDischarge = getVisitsColorsByUser(VISITS.DISCHARGE) 
  const bgRecert = getVisitsColorsByUser(VISITS.RECERT) 
  const bgRoutine = getVisitsColorsByUser(VISITS.TREATMENT) 
  const bgResumption= getVisitsColorsByUser(VISITS.RESUMPTIONOFCARE) 
  const bgSupervisor = getVisitsColorsByUser(VISITS.SUPERVISOR) 
  const bgOnHold = getVisitsColorsByUser(VISITS.ONHOLD) 


  return (
    <div className="border-t border-b py-4 px-8 w-full flex flex-col items-center">
      <div className="flex gap-[8px]">
        <div className="flex flex-col gap-[8px]">
          <div className="legend__title">
            <span style={{backgroundColor: bgAdmission }} />
            Admission
          </div>
          <div className="legend__title">
            <span style={{backgroundColor:bgResumption }} />
            ROC
          </div>
          <div className="legend__title">
            <span style={{backgroundColor:bgDischarge }}  />
            Discharge
          </div>
        </div>
        <div className="flex flex-col gap-[8px]">
          <div className="legend__title">
            <span style={{backgroundColor:bgEvaluation }}  />
            Evaluation
          </div>

          <div className="legend__title">
            <span style={{backgroundColor:bgRecert }}   />
            Recert
          </div>
          <div className="legend__title">
            <span style={{backgroundColor:bgSupervisor }}   />
            Supervisory
          </div>
        </div>
        <div className="flex flex-col gap-[8px]">
          <div className="legend__title">
            <span style={{backgroundColor:bgRoutine }}   />
            Routine visit
          </div>
          <div className="legend__title">
            <span style={{backgroundColor:bg30DRE }}   />
            30DRE
          </div>

          <div className="legend__title">
            <span style={{backgroundColor:bgOnHold }}  />
            Missed
          </div>
        </div>
      </div>
    </div>
  );
};
