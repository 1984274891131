import Axios from "../interceptors/axios-config";
import { ICareTeam } from "../interfaces";
import { IResponse } from "../interfaces/IResponses";
import { setIsLoading } from "../store/slices/notification/notificationSlice";
import { store } from "../store/store";
const base_url = process.env.REACT_APP_API_URL;

export const careTeamServices = () => ({
  updateCareTeam: async (body: any = {}) => {
    try {
      store.dispatch(setIsLoading(true));
      const careTeam = {
        aid: parseInt(body.aid === "" ? 0 : body.aid),
        msw: parseInt(body.msw === "" ? 0 : body.msw),
        ot: parseInt(body.ot === "" ? 0 : body.ot),
        ota: parseInt(body.ota === "" ? 0 : body.ota),
        patientId: body.patientId,
        pt: parseInt(body.pt === "" ? 0 : body.pt),
        pta: parseInt(body.pta === "" ? 0 : body.pta),
        slp: parseInt(body.slp === "" ? 0 : body.slp),
        sn: parseInt(body.sn === "" ? 0 : body.sn),
        teamLeader: parseInt(body.teamLeader === "" ? 0 : body.teamLeader),
      };
      let response = await Axios.put(
        base_url + "/PatientProfile/CareTeam",
        careTeam
      );

      switch (response.data.status) {
        case 201:
        case 200:
          return { status: 200, value: response.data.data };
        case 401:
          return { status: 401, value: response.data.message };
        case 402:
          return { status: 402, value: response.data.message };

        default:
          return {
            status: response.data.statusCode,
            value: "Resource not found",
          };
      }
    } catch (error) {
      store.dispatch(setIsLoading(false));

      return { status: error, value: error };
    } finally {
      store.dispatch(setIsLoading(false));
    }
  },

  getCareTeamById: async (id: number) => {
    try {
      store.dispatch(setIsLoading(true));
      const response = await Axios.get<IResponse<ICareTeam>>(
        base_url + "/PatientProfile/CareTeam?PatientId=" + id
      );

      switch (response.data.status) {
        case 201:
        case 200:
          return { status: 200, value: response.data.data };
        case 401:
          return { status: 401, value: response.data.message };
        case 402:
          return { status: 402, value: response.data.message };
        default:
          return {
            status: response.data.status,
            value: "Resource not found",
          };
      }
    } catch (error) {
      return { status: error, value: error };
    } finally {
      store.dispatch(setIsLoading(false));
    }
  },
});
