import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAIDFrequency, deleteAIDFrequency, editAIDFrequency } from ".";
import { IAIDCertification } from "../../../interfaces/ICertifications";

export interface certificationAIDState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  certificationAID: IAIDCertification;
  initialCertificationAID: IAIDCertification;
  error: {
    errorType?: string;
    errorMessage?: string;
  };
}

const initialState: certificationAIDState = {
  loading: "idle",
  certificationAID: {} as IAIDCertification,
  initialCertificationAID: {} as IAIDCertification,
  error: {},
};

export const certificationAIDSlice = createSlice({
  name: "certificationAID",
  initialState,
  reducers: {
    setAIDCert: (state, action: PayloadAction<IAIDCertification>) => {
      state.certificationAID = initialState.certificationAID;
      state.certificationAID = action.payload;
    },
    setAIDInitialCertification:(state, action: PayloadAction<IAIDCertification>) => {
      state.initialCertificationAID = action.payload;
    },
    updateAIDCert:(state, action) => {
      state.certificationAID.frequency = action.payload.frequency;
      state.certificationAID.vcount = action.payload.vcount;
      state.certificationAID.wcount = action.payload.wcount;
    },

  },
  extraReducers(builder) {
    builder.addCase(createAIDFrequency.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(createAIDFrequency.fulfilled, (state, action) => {
      state.certificationAID.frequency = action.payload.frecs;
      state.certificationAID.vcount = action.payload.vCount;
      state.certificationAID.wcount = action.payload.wCount;
      state.loading = "succeeded";
    });
    builder.addCase(createAIDFrequency.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });

    builder.addCase(editAIDFrequency.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(editAIDFrequency.fulfilled, (state, action) => {
      state.certificationAID.frequency = action.payload.frecs;
      state.certificationAID.vcount = action.payload.vCount;
      state.certificationAID.wcount = action.payload.wCount;
      state.loading = "succeeded";
    });
    builder.addCase(editAIDFrequency.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });

    builder.addCase(deleteAIDFrequency.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(deleteAIDFrequency.fulfilled, (state, action) => {
      state.certificationAID.frequency = action.payload.frecs;
      state.certificationAID.vcount = action.payload.vCount;
      state.certificationAID.wcount = action.payload.wCount;
      state.loading = "succeeded";
    });
    builder.addCase(deleteAIDFrequency.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });
  },
});

export const { setAIDCert, setAIDInitialCertification,updateAIDCert } =
  certificationAIDSlice.actions;
