import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React, { useState } from "react";
import { DateFormatter, DayPicker } from "react-day-picker";
import {
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { ICreatePatient } from "../../interfaces";

type Props = {
  name: any;
  register: UseFormRegister<ICreatePatient>;
  label: string | JSX.Element;
  selected: any;
  startDateSelected?: Date | undefined;
  endDateSelected?: Date | undefined;
  setSelected: React.Dispatch<React.SetStateAction<any>>;
  setInputValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  inputValue: string | undefined;
  clearErrors: UseFormClearErrors<ICreatePatient>;
  isDirty: any;
  errors: any;
  setFieldValue: UseFormSetValue<ICreatePatient>;
  span?: any;
};

export default function DatePickerPatient({
  register,
  name,
  label,
  isDirty,
  selected,
  startDateSelected,
  endDateSelected,
  setSelected,
  setInputValue,
  inputValue,
  clearErrors,
  errors,
  setFieldValue,
  span,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const closePopper = () => {
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    setIsOpen((open) => !open);
  };

  const handleDaySelect = (date?: Date) => {
    if (date) setSelected(date);
  };
  const handleSaveDaySelected = () => {
    if (selected) {
      setInputValue(format(selected, "MM/dd/y"));
      clearErrors(name);
      setFieldValue(name, format(selected, "MM/dd/y"), { shouldDirty: true });
    } else {
      setInputValue("");
      setFieldValue(name, null, { shouldDirty: true });
    }
    closePopper();
  };

  const footer = (
    <section className="date-picker-footer">
      <div className="flex justify-between pt-3 pr-0 pl-0">
        <button
          type="button"
          className="h-[35px] px-[42px] rounded-[6px] flex items-center place-content-center cancel_btn"
          onClick={closePopper}
        >
          Cancel
        </button>

        <button
          type="button"
          onClick={handleSaveDaySelected}
          className="h-[31px] px-[42px] rounded-[6px] flex items-center place-content-center save_btn"
        >
          Save
        </button>
      </div>
    </section>
  );
  const formatWeekdayName: DateFormatter = (date, options) => {
    return <span>{format(date, "eee", { locale: options?.locale })}</span>;
  };

  const okField = isDirty && errors[name] === undefined;
  const handleKeyDown = (event: any) => {
    if (event.key === "Delete") {
      event.preventDefault();

      setInputValue("");
      setFieldValue(name, null, { shouldDirty: true });
    }
    if (event.key === "Backspace") {
      event.preventDefault();
      setInputValue("");
      setFieldValue(name, null, { shouldDirty: true });
    }
  };
  return (
    <div className="text__input patient-date-holder">
      <label htmlFor={name}>
        {label}
        {span ? <span className="span-required">{span}</span> : ""}
      </label>
      <input
        {...register(name)}
        className={`pl-[16px] text-input ${errors[name] && "input__error"} ${
          okField && "input_edit"
        }`}
        value={inputValue}
        onKeyDown={handleKeyDown}
      />

      <FontAwesomeIcon
        onClick={handleButtonClick}
        icon={faCalendar}
        color="#9AA4B2"
        size="2x"
        className="w-[18px] h-[18px] cursor-pointer"
      />
      {name === "covidStart" ? (
        isOpen && (
          <div className="dialog-datepicker-patient">
            <DayPicker
              mode="single"
              showOutsideDays
              toDate={endDateSelected}
              selected={selected}
              onSelect={handleDaySelect}
              formatters={{ formatWeekdayName }}
              footer={footer}
              defaultMonth={selected}
            />
          </div>
        )
      ) : name === "covidEnd" ? (
        isOpen && (
          <div className="dialog-datepicker-patient">
            <DayPicker
              mode="single"
              showOutsideDays
              fromDate={startDateSelected}
              toDate={new Date()}
              selected={selected}
              onSelect={handleDaySelect}
              formatters={{ formatWeekdayName }}
              footer={footer}
              defaultMonth={selected}
            />
          </div>
        )
      ) : (
        isOpen && (
          <div className="dialog-datepicker-patient">
            <DayPicker
              mode="single"
              showOutsideDays
              selected={selected}
              onSelect={handleDaySelect}
              formatters={{ formatWeekdayName }}
              footer={footer}
              defaultMonth={selected}
            />
          </div>
        )
      )}
    </div>
  );
}
