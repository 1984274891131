import React from "react";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { AppRouter } from "./routers/AppRouter";
import { AlertProvider } from "./components/AlertProvider";

import { registerLicense } from '@syncfusion/ej2-base';


// delete in future
// css imports for callendar ---------------------------------------------------------
import "../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";
// css imports for callendar ---------------------------------------------------------

import "./scss/style.scss";

registerLicense('ORg4AjUWIQA/Gnt2VVhiQlFac19JXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0VhWH5XcXZVT2JcWEI=');


export const App = () => {
  return (
    <Provider store={store}>
      <AlertProvider>
        <AppRouter />
      </AlertProvider>
    </Provider>
  );
};

export default App;
