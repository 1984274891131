import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  saveCareTeamToDb,
  savePatientToDb,
  updatePatientToDb,
} from "../../../api/patientsApi";
import { ICareTeam, ICreatePatient } from "../../../interfaces/IPatient";
import { ICreateCareTeam } from "../../../models";
import { GetCareTeam, GetPatient, DeletePatient } from "../../../Services/Api";

export const getPatient = createAsyncThunk(
  "patient/getById",
  async (id: number | string, thunkApi) => {
    const data = await GetPatient(id);

    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return data.data;
  }
);

export const deletePatient = createAsyncThunk(
  "patient/deleteById",
  async (id: number | string, thunkApi) => {
    const data = await DeletePatient(id);

    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.data.message);
    }
    return data.data;
  }
);

export const getCareTeam = createAsyncThunk(
  "patient/CareTeam",
  async (PatientId: number | string, thunkApi) => {
    const data = await GetCareTeam(PatientId);

    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return data.data;
  }
);

export const savePatient =  createAsyncThunk(
  "patients/add",
  async (patient: ICreatePatient, thunkApi) => {
    const data = await savePatientToDb(patient);
    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return data.data;
  }
);
export const updatePatient = createAsyncThunk(
  "patients/put",
  async (patient: ICreatePatient, thunkApi) => {
    const data = await updatePatientToDb(patient);
    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return data.data;
  }
);

export const saveCareTeam = createAsyncThunk(
  "patients/add",
  async (careTeam: ICreateCareTeam, thunkApi) => {
    const data = await saveCareTeamToDb(careTeam);
    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return data.data;
  }
);
