import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICertification } from "../../../interfaces/ICertifications";
import {
  getCertifications,
  addCertification,
  updateCertification,
  deleteCertification,
} from "./thunks";

export interface certificationsState {
  loading: "idle" | "pending" | "succeeded" | "failed";
  certifications: ICertification[];
  error: {
    errorType?: string;
    errorMessage?: string;
  };
}

const initialState: certificationsState = {
  loading: "idle",
  certifications: [] as ICertification[],
  error: {
    errorType: "",
    errorMessage: "",
  },
};

export const certificationsSlice = createSlice({
  name: "certifications",
  initialState,
  reducers: {
    setCertInCertifications: (
      state,
      action: PayloadAction<{ index: number; cert: ICertification }>
    ) => {      
      // state.certifications[action.payload.index] = action.payload.cert;
    },
    setCertifications: (
      state,
      action
    ) => {      
      state.certifications = initialState.certifications
      state.certifications =action.payload;
      state.loading = "succeeded";
    },
  },
  extraReducers(builder) {
    builder.addCase(getCertifications.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(getCertifications.fulfilled, (state, action) => {
      state.certifications = action.payload;
      state.loading = "succeeded";
    });
    builder.addCase(getCertifications.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });

    builder.addCase(addCertification.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(addCertification.fulfilled, (state, action) => {
      state.certifications.push(action.payload);
      state.loading = "succeeded";
    });
    builder.addCase(addCertification.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });

    builder.addCase(updateCertification.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(updateCertification.fulfilled, (state, action) => {
      const newCertifications = state.certifications.map((c) =>
        action.payload.id === c.id ? action.payload : c
      );
      state.certifications = newCertifications;
      state.loading = "succeeded";
    });
    builder.addCase(updateCertification.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });

    builder.addCase(deleteCertification.pending, (state) => {
      state.loading = "pending";
      state.error = initialState.error;
    });
    builder.addCase(deleteCertification.fulfilled, (state, action) => {
      const newCertifications = state.certifications.filter(
        (c) => c.id !== action.payload
      );
      state.certifications = newCertifications;
      state.loading = "succeeded";
    });
    builder.addCase(deleteCertification.rejected, (state, action) => {
      state.loading = "failed";
      state.error = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });
  },
});

export const { setCertInCertifications,setCertifications } = certificationsSlice.actions;
