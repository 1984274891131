import {
  IPatient,
  IPatientSchedule,
  IClinician,
  IVisitHistory,
  ICertification,
} from "../../Services/Interfaces";

type PatientAction =
  | {
      type: "setPatient";
      payload: IPatient;
    }
  | {
      type: "setEvaluation";
      payload: { evaluationDate: string };
    }
  | {
      type: "setFrequency";
      payload: { frequency: string };
    }
  | {
      type: "setVisitDates";
      payload: { visitDates: [] };
    }
  | {
      type: "setCertifications";
      payload: { certifications: ICertification[] };
    }
  | {
      type: "addCertification";
      payload: { certification: ICertification };
    }
  | {
      type: "deleteCertification";
      payload: { id: number };
    }
  | {
      type: "setPatientSchedule";
      payload: { patientSchedule: IPatientSchedule[] };
    }
  | {
      type: "setAssignedClinicians";
      payload: { assignedClinicians: IClinician[] };
    }
  | {
      type: "setDischarge";
      payload: { discharge: string; dischargeType: string };
    }
  | {
      type: "setComing30";
      payload: { comming30: string };
    }
  | {
      type: "setEOC";
      payload: { eoc: string };
    }
  | {
      type: "setRecert";
      payload: { recert: string; recertType: string };
    }
  | {
      type: "setVisitHistory";
      payload: {
        visitHistory: IVisitHistory[];
      };
    };

export const patientReducer = (
  state: IPatient,
  action: PatientAction
): IPatient => {
  switch (action.type) {
    case "setPatient":
      return action.payload;

    case "setEvaluation":
      return {
        ...state,
        evaluation: action.payload.evaluationDate,
      };

    case "setVisitHistory":
      return {
        ...state,
        visitHistory: action.payload.visitHistory,
      };

    case "setRecert":
      return {
        ...state,
        recert: action.payload.recert,
        recertType: action.payload.recertType,
      };

    case "setEOC":
      return {
        ...state,
        eoc: action.payload.eoc,
      };

    case "setFrequency":
      return {
        ...state,
        frequency: action.payload.frequency,
      };

    case "setVisitDates":
      return {
        ...state,
        visitDates: action.payload.visitDates,
      };

    case "setPatientSchedule":
      return {
        ...state,
        patientSchedule: action.payload.patientSchedule,
      };

    case "setCertifications":
      return {
        ...state,
        certifications: action.payload.certifications,
      };

    case "addCertification":
      return {
        ...state,
        certifications: state.certifications
          ? [...state.certifications, action.payload.certification]
          : [action.payload.certification],
        certificationPeriodCount: state.certificationPeriodCount + 1,
      };

    case "deleteCertification":
      return {
        ...state,
        certifications: state.certifications?.filter(
          (f) => f.id !== action.payload.id
        ),
        certificationPeriodCount: state.certificationPeriodCount - 1,
      };

    case "setAssignedClinicians":
      return {
        ...state,
        assignedClinicians: action.payload.assignedClinicians,
      };

    case "setComing30":
      return {
        ...state,
        comming30DRE: action.payload.comming30,
      };

    case "setDischarge":
      return {
        ...state,
        discharge: action.payload.discharge,
        dischargeType: action.payload.dischargeType,
      };
    default:
      return state;
  }
};
