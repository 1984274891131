export interface IVisitsToCreate {
    patientId: string,
    recertId: string,
    clinitianId: string,
    type: string,
    start:string,
    end:string
}
export const InitialVisitstoCreate = {
    patientId: "",
    recertId: "",
    clinitianId: "",
    type: "",
    start:"",
    end:""
}
export interface IVIsitsResolveConflic {
  patientId: number;
  clinitianId: number;
  desition: number;
}

export interface IVisitsToUpdate {
    id : string,
    patientId: string,
    recertId: string,
    clinitianId: string,
    start:string ,
    end:string,
    isLocked: string,
    isCompleted: string
}

export interface IVisitsAutoSchedule {
    mode : string,
    initialDate: Date,
    timeOffsetMinutes:number
}
