export interface IUserInfo {
  aseptTerms: boolean;
  firstName: string;
  lastName: string;
  avatar: string;
  email: string;
  disciplineId: number;
  address: string;
  phoneNumber: number;
  status: string;
  password?: string;
  weekVisitFormat: string;
  admissionSessionLength: number;
  admissionSessionColor: string;
  evaluationSessionLength: number;
  evaluationSessionColor: string;
  dischargeSessionLength: number;
  dischargeSessionColor: string;
  thirtyDayReEvalSessionLength: number;
  thirtyDayReEvalSessionColor: string;
  recertSessionLength: number;
  recertSessionColor: string;
  treatmentSessionLength: number;
  treatmentSessionColor: string;
  resumptionOfCareSessionLength: number;
  resumptionOfCareSessionColor: string;
  supervisorSessionLength: number;
  supervisorSessionColor: string;
  workingDays: number[];
  start: string;
  end: string;
}

export const UserEmptyState = {
  aseptTerms: false,
  firstName: "",
  lastName: "",
  email: "",
  avatar: "",
  disciplineId: "",
  address: "",
  phoneNumber: "",
  status: "",
  password: "",
  weekVisitFormat: "",
  admissionSessionLength: "",
  admissionSessionColor: "#B42318",
  evaluationSessionLength: "",
  evaluationSessionColor: "#B42318",
  dischargeSessionLength: "",
  dischargeSessionColor: "#B42318",
  thirtyDayReEvalSessionLength: "",
  thirtyDayReEvalSessionColor: "#B42318",
  recertSessionLength: "",
  recertSessionColor: "#B42318",
  treatmentSessionLength: "",
  treatmentSessionColor: "#B42318",
  resumptionOfCareSessionLength: "",
  resumptionOfCareSessionColor: "#B42318",
  supervisorSessionLength: "",
  supervisorSessionColor: "#B42318",
  start: "",
  end: "",
  facebookId: "",
  googleId: "",
  linkedId: "",
};
