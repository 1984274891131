import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { PhonList } from "../interfaces";
import "react-phone-input-2/lib/style.css";

export const MyInputPhoneRef = ({
  register,
  fieldName,
  setFieldValue,
  inEdition,
  list,
  value,
  errors,
  name,
  i,
  clearErrors,
  isDirty,
  ...props
}: any) => {
  let okField = false;

  if (fieldName === "phonList") {
    okField =
      isDirty?.[i]?.number && errors?.phonList?.[i]?.number === undefined;
  } else {
    okField =
      isDirty?.[i]?.number && errors?.phonMDList?.[i]?.number === undefined;
  }

  return (
    <div>
      <span className={i == 0 ? "span-absolute" : "span-absolute-large"}>
        *
      </span>

      <PhoneInput
        country={"us"}
        containerClass={
          fieldName == "phonList"
            ? `custom-phone ${errors?.phonList?.[i]?.number && "error-class"} ${
                okField && "input_edit"
              }`
            : `custom-phone ${
                errors?.phonMDList?.[i]?.number && "error-class"
              } ${okField && "input_edit"}`
        }
        inputClass={
          fieldName == "phonList"
            ? `custom-phone-input ${
                errors?.phonList?.[i]?.number && "input__error"
              } `
            : `custom-phone-input ${
                errors?.phonMDList?.[i]?.number && "input__error"
              } `
        }
        value={value}
        specialLabel={
          i == 0 ? "Primary phone number" : "Secondary phone number"
        }
        inputProps={{
          name,
          ...props,
          ...register,
          // ...register(`phonList.number.${0}`)
        }}
        onChange={(phone) => {
          clearErrors(name);
          setFieldValue(name, phone, { shouldDirty: true });
        }}
      />
    </div>
  );
};
