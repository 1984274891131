// import Multiselect from 'multiselect-react-dropdown';
// import React, { useState } from 'react'
// import { components } from "react-select";
// import { default as ReactSelect } from "react-select";

// const Option = (props:any) => {
//   return (
//     <div>
//       <components.Option {...props}>
//         <input
//           type="checkbox"
//           checked={props.isSelected}
//           onChange={() => null}
//         />{" "}
//         <label>{props.label}</label>
//       </components.Option>
//     </div>
//   );
// };

// export default function MyDropdownMultipleListRef ({
//   register,
//   placeholder,
//   data,
//   value,
//   name,
//   span,
//   inEdition,
//   errors,
//   index,
//   label,
//   field,
//   array,
//   clearErrors,
//   setFieldValue,
//   isDirty,
// }: any) {
//   const colourOptions = [
//     { value: "ocean1", label: "Ocean" },
//     { value: "blue", label: "Blue" },
//     { value: "purple", label: "Purple" },
//     { value: "red", label: "Red" },
//     { value: "orange", label: "Orange" },
//     { value: "yellow", label: "Yellow" },
//     { value: "green", label: "Green" },
//     { value: "forest", label: "Forest" },
//     { value: "slate", label: "Slate" },
//     { value: "silver", label: "Silver" }
//   ];
//   const [optionSelected, setOptionSelected] = useState({
//     optionSelected: null
//   })
//   const options = [
//     { value: 'chocolate', label: 'Chocolate' },
//     { value: 'strawberry', label: 'Strawberry' },
//     { value: 'vanilla', label: 'Vanilla' },
//   ];

//   const handleChange = (selected:any) => {
//     setOptionSelected({
//       optionSelected: selected
//     });
//   };
//   return (
//     <div className="dropdown__patient_multiple">
// <Multiselect
//   hidePlaceholder={true}
//   displayValue="key"
//   showArrow={true}
//   onKeyPressFn={function noRefCheck(){}}
//   onRemove={function noRefCheck(){}}
//   onSearch={function noRefCheck(){}}
//   onSelect={function noRefCheck(){}}
//   style={{

//     multiselectContainer: {
//       color: 'red'
//     },
//     searchBox: {
//       minHeight: "44px",
//       background: "#FFFFFF",
//       border: "1px solid #D2D6DB",
//       boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
//       borderRadius: "8px"
//     }
//   }}
//   options={[
//     {
//       cat: 'Group 1',
//       key: 'Option 1'
//     },
//     {
//       cat: 'Group 1',
//       key: 'Option 2'
//     },
//     {
//       cat: 'Group 1',
//       key: 'Option 3'
//     },
//     {
//       cat: 'Group 2',
//       key: 'Option 4'
//     },
//     {
//       cat: 'Group 2',
//       key: 'Option 5'
//     },
//     {
//       cat: 'Group 2',
//       key: 'Option 6'
//     },
//     {
//       cat: 'Group 2',
//       key: 'Option 7'
//     }
//   ]}
//   showCheckbox
// />
// </div>
//   );
// }
import { faAngleDown, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox, Transition } from "@headlessui/react";
import { useField } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { UseFormRegister, UseFormSetValue } from "react-hook-form";
import { ICreatePatient } from "../interfaces";

export type DropdownOptions = {
  id: number;
  name: string;
};

export type DropDownProps = {
  preText: string;
  options: DropdownOptions[];
  name: string;
  label: string;
  span: string;
  register: UseFormRegister<ICreatePatient>;
  isDirty: any;
  value: any;
  errors: any;
  setFieldValue: UseFormSetValue<ICreatePatient>;
};

export default function MyDropdownMultipleListRef(props: DropDownProps) {
  // const popupHeight =
  //   props.options.length > 0
  //     ? Math.round((props.options.length * 44) / 1.5) + "px"
  //     : props.options.length * 44 + 10 + "px";

      const popupHeight =
      props.options.length > 0
        ? props.options.length * 39 > 117
          ? Math.round((props.options.length * 39) / 1.5) + "px"
          : props.options.length * 39 + 10 + "px"
        : "45px";

  // const [myselected, setMySelected] = useState<DropdownOptions>(
  //   initialValues ?? [{ id: props.value, name: "" }]
  // );



  const [myselected, setMySelected] = useState<DropdownOptions[]>([]);

  // const [field, meta] = useField(props);

  useEffect(() => {
    if (props.value) {
      const findInitialValue = props.options.filter((element) =>
        props.value.includes(element.id)
      );
      setMySelected(findInitialValue);
    }
  }, [props.options, props.value]);
  // const okField = meta.touched && !meta.error;
  // const errorField = meta.touched && meta.error;
  const isChecked = (id: number) => {
    if (myselected.length > 0) {
      const findValue = myselected.filter((element) => element.id === id);

      if (findValue.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const onChangeCheckBox = (e: any) => {
    const value: number = e.target.value;
    const isChecked: boolean = e.target.checked;

    const findValue = props.options.find((element) => element.id == value);

    let data = myselected;
    if (findValue) {
      if (isChecked) {
        const precautionsOrPPE = [
          ...myselected,
          { id: findValue.id, name: findValue.name },
        ];
        if (props.name === "precautions") {
          props.setFieldValue(
            "precautions",
            precautionsOrPPE.map((element) => element.id)
          );
        } else {
          props.setFieldValue(
            "ppe",
            precautionsOrPPE.map((element) => element.id)
          );
        }
      } else {
        data = data.filter((element) => {
          return element.id != findValue.id;
        });
        if (props.name === "precautions") {
          props.setFieldValue(
            "precautions",
            data.map((element) => element.id)
          );
        } else {
          props.setFieldValue(
            "ppe",
            data.map((element) => element.id)
          );
        }
      }
    } else {
      console.log("Element not founded");
    }
  };
  return (
    <div>
      <Listbox value={myselected} onChange={() => {}} multiple>
        <div className="list__box_dropdown_patient  w-100 bg-white">
          <Listbox.Label>{props.label}</Listbox.Label>
          <Listbox.Button className={`w-full cursor-default `}>
            <div className={`patient-container-dropdown`}>
              <span className="patient-value flex">
                {myselected.length > 0
                  ? myselected.map((person) => (
                      <span
                        className="mr-[2px] badge-patient-value"
                        key={person.id}
                      >
                        {person.name[0].toUpperCase() + person.name.slice(1)}
                      </span>
                    ))
                  : props.preText}
              </span>
            </div>
            <span className="dropdown__pointer_patient pointer-events-none">
              <FontAwesomeIcon
                color="#6C737F"
                icon={faAngleDown}
                className="dropdown-pointer"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Listbox.Options
            className={`dropdown-patient-options`}
            style={{ height: popupHeight }}
          >
            {props.options.length > 0 ?
(            props.options.map((person) => (
              <Listbox.Option
                key={person.id}
                className={`dropdown-li-options-multiple relative cursor-default select-none `}
                value={person}
              >
                <div className="flex justify-start align-center">
                  <div className="mr-[10px] checkbox__input">
                    <input
                      type="checkbox"
                      onChange={onChangeCheckBox}
                      defaultChecked={isChecked(person.id)}
                      value={person.id}
                    />
                  </div>
                  <span>
                    {" "}
                    {person.name[0].toUpperCase() + person.name.slice(1)}
                  </span>
                </div>
              </Listbox.Option>
            ))): 
            (
              <Listbox.Option
                className="dropdown-li-options-careteam not-record relative cursor-default select-none"
                value={""}
              >
                <span>Not record</span>
              </Listbox.Option>
            )
            }
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  );
}
