import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone }        from "@fortawesome/pro-solid-svg-icons";
import React from "react";

export const ContactSection = () => {
  return (
    <div className="grid grid-cols-4 gap-6 py-[32px] px-[25px]">
      <div className="flex flex-col justify-center items-center gap-y-2">
        <div className="bg-[#0BB68C] rounded-full w-[56px] h-[56px] flex items-center justify-center custom_hover cursor-pointer">
        <FontAwesomeIcon color="#0BB68C" stroke="#FFFFFF" strokeWidth={60} icon={faPhone} className="h-8 w-8" aria-hidden="true"/>
        </div>
        <h6 className="w-full flex justify-center">Primary</h6>
      </div>
      <div className="flex flex-col justify-center items-center gap-y-2">
        <div className="bg-[#0BB68C] rounded-full w-[56px] h-[56px] flex items-center justify-center custom_hover cursor-pointer">
        <FontAwesomeIcon color="#0BB68C" stroke="#FFFFFF" strokeWidth={60} icon={faPhone} className="h-8 w-8" aria-hidden="true"/>
        </div>
        <h6 className="w-full flex justify-center">Primary</h6>
      </div>
      <div className="flex flex-col justify-center items-center gap-y-2">
        <div className="bg-[#0BB68C] rounded-full w-[56px] h-[56px] flex items-center justify-center custom_hover cursor-pointer">
        <FontAwesomeIcon color="#0BB68C" stroke="#FFFFFF" strokeWidth={60} icon={faPhone} className="h-8 w-8" aria-hidden="true"/>
        </div>
        <h6 className="w-full flex justify-center">Primary</h6>
      </div>
      <div className="flex flex-col justify-center items-center gap-y-2">
        <div className="bg-[#0BB68C] rounded-full w-[56px] h-[56px] flex items-center justify-center custom_hover cursor-pointer">
        <FontAwesomeIcon color="#0BB68C" stroke="#FFFFFF" strokeWidth={60} icon={faPhone} className="h-8 w-8" aria-hidden="true"/>
        </div>
        <h6 className="w-full flex justify-center">Primary</h6>
      </div>
    </div>
  );
};
