import moment from "moment";
import { IUserInfo } from "../models";
import { FormatDate, ValueFromUserData } from "../Services/utility";

enum DISCIPLINE {
  OT = "OT",
  OTA = "OTA",
  PT = "PT",
  PTA = "PTA",
  SLP = "SLP",
  SN = "SN",
  AID = "AID",
  MSW = "MSW",
  ADMIN = "ADMIN",
  USER = "USER",
}
enum VISITS {
  ADMISSION = "admission",
  EVALUATION = "evaluation",
  THYRTYDRE = "30dre",
  DISCHARGE = "discharge",
  RECERT = "recert",
  ROUTINEVISIT = "routineVisit",
  TREATMENT = "treatment",
  RESUMPTIONOFCARE = "resumptionOfCare",
  SUPERVISOR = "supervisor",
  ONHOLD = "onhold"
}
interface IWORKINGDAYS {
  id: number;
  value: number;
  label: string;
}

const mergeAndGetUnique = (arrayA: any, arrayB: any) => {
  return Array.from(new Set([...arrayA, ...arrayB]));
};

const WORKINGDAYS: IWORKINGDAYS[] = [
  { id: 1, value: 1, label: "Sun" },
  { id: 2, value: 1, label: "Mon" },
  { id: 3, value: 1, label: "Tue" },
  { id: 4, value: 1, label: "Wed" },
  { id: 5, value: 1, label: "Thu" },
  { id: 6, value: 1, label: "Fri" },
  { id: 7, value: 1, label: "Sat" },
];
const WORKHOURS = [
  { id: 1, name: "06:00 AM" },
  { id: 2, name: "07:00 AM" },
  { id: 3, name: "08:00 AM" },
  { id: 4, name: "09:00 AM" },
  { id: 5, name: "10:00 AM" },
  { id: 6, name: "11:00 AM" },
  { id: 7, name: "12:00 PM" },
  { id: 8, name: "01:00 PM" },
  { id: 9, name: "02:00 PM" },
  { id: 10, name: "03:00 PM" },
  { id: 11, name: "04:00 PM" },
  { id: 12, name: "05:00 PM" },
  { id: 13, name: "06:00 PM" },
  { id: 14, name: "07:00 PM" },
  { id: 15, name: "08:00 PM" },
  { id: 16, name: "09:00 PM" },
  { id: 17, name: "10:00 PM" },
];

const VISITLENGTHS = [
  { id: 30, name: "30 min" },
  { id: 45, name: "45 min" },
  { id: 60, name: "60 min" },
  { id: 90, name: "90 min (1.5 hrs)" },
  { id: 120, name: "120 min (2 hrs)" },
  { id: 150, name: "150 min (2.5 hrs)" },
  { id: 180, name: "180 min (3 hrs)" },
];

const convertTime12to24 = (time12h: any) =>
  moment(time12h, "hh:mm A").format("HH:mm:ss");

const convertTime24to12 = (time12h: any) =>
  moment(time12h, "HH:mm:ss").format("hh:mm A");

  const convertDateTime24to12 = (time12h: any) =>
  moment(time12h, "MM/DD/y HH:mm:ss").format(" MM/DD/y hh:mm A");

const parseUserRol = (value: DISCIPLINE) => {
  switch (value) {
    case DISCIPLINE.ADMIN:
      return "IT Administrator";
    case DISCIPLINE.USER:
      return "User";
    case DISCIPLINE.SN:
      return "Skilled nursing";
    case DISCIPLINE.OT:
      return "Occupational therapy";
    case DISCIPLINE.OTA:
      return "Occupational therapy Assistant";
    case DISCIPLINE.PT:
      return "Physical therapy";
    case DISCIPLINE.PTA:
      return "Physical therapy Assistant";
    case DISCIPLINE.SLP:
      return "Speech language pathology";
    case DISCIPLINE.AID:
      return "Nursing Assistant";
    case DISCIPLINE.MSW:
      return "Social Worker";

    default:
      break;
  }
};

const cardinalNumber = [
  "First",
  "Second",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Ninth",
  "Tenth",
  "Eleventh",
  "Twelfth",
  "Thirteenth",
  "Fourteenth",
  "Fifteenth",
  "Sixteenth",
  "Seventeenth",
  "Eighteenth",
  "Nineteenth",
  "Twentieth",
];
const numberPerUnitWeek = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
];
// const numberPerUnitMonth = [
//   { value: "0", label: "0" },
//   { value: "1", label: "1" },
//   { value: "2", label: "2" },
//   { value: "3", label: "3" },
//   { value: "4", label: "4" },
//   { value: "5", label: "5" },
//   { value: "6", label: "6" },
//   { value: "7", label: "7" },
//   { value: "8", label: "8" },
//   { value: "9", label: "9" },
//   { value: "10", label: "10" },
//   { value: "11", label: "11" },
//   { value: "12", label: "12" },
//   { value: "13", label: "13" },
//   { value: "14", label: "14" },
//   { value: "15", label: "15" },
//   { value: "16", label: "16" },
//   { value: "17", label: "17" },
//   { value: "18", label: "18" },
//   { value: "19", label: "19" },
//   { value: "20", label: "20" },
//   { value: "21", label: "21" },
//   { value: "22", label: "22" },
//   { value: "23", label: "23" },
//   { value: "24", label: "24" },
//   { value: "25", label: "25" },
//   { value: "26", label: "26" },
//   { value: "27", label: "27" },
//   { value: "28", label: "28" },
//   { value: "29", label: "29" },
//   { value: "30", label: "30" },
// ];
const numberOfUnitsWeek = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];
// const numberOfUnitsMonth = [
//   { value: "1", label: "1" },
//   { value: "2", label: "2" },
// ];

// 2x2, 2w2, 2wk2, 2week2, 2by2
const FREQUENCYFORMAT = [
  { id: 1, name: "2x2", value: "x" },
  { id: 2, name: "2w2", value: "w" },
  { id: 3, name: "2wk2", value: "wk" },
  { id: 4, name: "2week2", value: "week" },
  { id: 5, name: "2by2", value: "by" },
];

const getFrequencyFormatSelected = (id: number) =>
  FREQUENCYFORMAT.find((element) => element.id === id);

const userAPEX_Frequency: string = ValueFromUserData("weekVisitFormat") ?? "w";



const getVisitsColorsByUser = (visit: VISITS) => {
  const userdata:string=sessionStorage.getItem("userData_Apex") || "";
  const formateddata: any = JSON.parse(userdata)

  const compareVisit = visit === VISITS.ROUTINEVISIT ? "treatment" : visit.toString()


  const color = Object.keys(formateddata.visitType).filter((key)=> formateddata.visitType[key].name.toString() === compareVisit)[0]




  if(color && color.length > 0){
    return formateddata.visitType[color].color;

  }
  return visitColorDefault(visit)




}
const visitColorDefault = (visit:VISITS) => {
  switch (visit) {
    case VISITS.ADMISSION:
       return "#7CD4FD"
    case VISITS.EVALUATION:
       return "#7A5AF8"
    case VISITS.THYRTYDRE:
       return "#194185"
    case VISITS.DISCHARGE:
       return "#FAC515"
    case VISITS.RECERT:
       return "#B42318"
    case VISITS.ROUTINEVISIT:
       return "#0BB68C"
       case VISITS.TREATMENT:
        return "#0BB68C"
    case VISITS.RESUMPTIONOFCARE:
       return "#9E165F"
    case VISITS.SUPERVISOR:
       return "#5FE9D0"
       case VISITS.ONHOLD:
        return "#E5E7EB"
  
    default:
      return "#7CD4FD"
  }
}
const getVisitBackgroundColor = (visit: VISITS) => {
  return "bg-["+getVisitsColorsByUser(visit).toLowerCase()+"]"
}

const getUserFormatFrequencyName = (frequency: string) => {
  return (
    FREQUENCYFORMAT.find((element) => element.value === frequency)?.name ?? "w"
  );
};

const getUserFormatFrequencyValue = (frequency: string) =>
  getUserFormatFrequencyName(frequency).split("2")[1];

const changeFrequencyByUserProfile = (
  data: string[],
  frequency: string,
  recortNumber = 0
) => {
  const initialData = data;
  if (recortNumber > 0 && data.length > 5) {
    data = data.slice(0, recortNumber);
  }

  const frequencyByElement = data.map((f: string) => {
    const temp = f.split("w");

    if (temp.length === 1) {
      return f;
    } else {
      const numberPerUnit = temp[0];

      const numberOfUnits = temp[1];
      return `${numberPerUnit}${getUserFormatFrequencyValue(
        frequency
      )}${numberOfUnits}  `;
    }
  });
  if (recortNumber > 0 && initialData.length > 5) {
    frequencyByElement.push("...");
  }
  return frequencyByElement;
};
const changeOneFrequencyByUserProfile = (
  frequency: string,
  userFrequency: string
) => {
  const temp = frequency.split("w");

  if (temp.length === 1) {
    return frequency;
  } else {
    const numberPerUnit = temp[0];

    const numberOfUnits = temp[1];
    return `${numberPerUnit}${getUserFormatFrequencyValue(
      userFrequency
    )}${numberOfUnits}`;
  }
};

const getUserProfileFormatFrequencyValue = (
  value: string,
  frequency: string
) => {
  if (value === "m") {
    return "m";
  }
  return getUserFormatFrequencyValue(frequency);
};

const weekState = {
  valueGroups: {
    numberPerUnit: "0",
    unit: "week",
    numberOfUnits: "1",
  },
  optionGroups: {
    numberPerUnit: numberPerUnitWeek,
    unit: [
      { value: "week", label: "Week" },
      // { value: "month", label: "Month" },
    ],
    numberOfUnits: numberOfUnitsWeek,
  },
};
// const monthState = {
//   valueGroups: {
//     numberPerUnit: "0",
//     unit: "month",
//     numberOfUnits: "1",
//   },
//   optionGroups: {
//     numberPerUnit: numberPerUnitMonth,
//     unit: [
//       { value: "week", label: "Week" },
//       { value: "month", label: "Month" },
//     ],
//     numberOfUnits: numberOfUnitsMonth,
//   },
// };

const getLenghtObj = (value: object) => Object.keys(value).length;

const initialVisitColors = [
  { id: 1, color: "#B42318", isSelected: true },
  { id: 2, color: "#F38744", isSelected: false },
  { id: 3, color: "#FAC515", isSelected: false },
  { id: 4, color: "#039855", isSelected: false },
  { id: 5, color: "#155EEF", isSelected: false },
  { id: 6, color: "#5FE9D0", isSelected: false },
  { id: 7, color: "#EE46BC", isSelected: false },
  { id: 8, color: "#7839EE", isSelected: false },
  { id: 9, color: "#293056", isSelected: false },
];

const nameVisitColors = [
  { id: 1, name: "admissionSessionColor" },
  { id: 2, name: "evaluationSessionColor" },
  { id: 3, name: "treatmentSessionColor" },
  { id: 4, name: "thirtyDayReEvalSessionColor" },
  { id: 5, name: "resumptionOfCareSessionColor" },
  { id: 6, name: "recertSessionColor" },
  { id: 7, name: "dischargeSessionColor" },
  { id: 8, name: "supervisorSessionColor" },
];
 const patientStatus = [
  { id: 1, name: "All" },
  { id: 2, name: "Active" },
  { id: 3, name: "Pending" },
  { id: 4, name: "Hospital" },
  // { id: 5, name: "OnHold" },
  { id: 6, name: "Discharged" },
];

const getIsUndefinedDate = (value: string) =>
  value === "0001-01-01T00:00:00Z" ? undefined : FormatDate(value, "MM/DD/y");

const getIsUndefinedDateTime = (value: string) =>
  value === "0001-01-01T00:00:00Z"
    ? undefined
    : FormatDate(value, "MM/DD/y HH:mm");
const ppeArrayStatic = [
  { id: 1, name: "hand hygiene", precautionFlag: "5-6" },
  { id: 2, name: "gloves", precautionFlag: "5-8" },
  { id: 3, name: "gown", precautionFlag: "5" },
  { id: 4, name: "disinfect equipment", precautionFlag: "5" },
  { id: 6, name: "mask on patient", precautionFlag: "6-7" },
  { id: 7, name: "face protection", precautionFlag: "6" },
  { id: 8, name: "fit-tested N95 mask", precautionFlag: "7" },
  { id: 9, name: "facemask", precautionFlag: "8" },
  { id: 10, name: "protective eyewear/goggle", precautionFlag: "8" },
  { id: 11, name: "proper sharps handling", precautionFlag: "8" },
];
const getVisitTypeToString = (type: string): string => {
  switch (type.toLowerCase()) {
    case "discharge":
      return "Discharge";
    case "routinevisit":
      return "Routine Visit";
    case "30dre":
      return "30DRE";
    case "admission":
      return "Admission";
    case "evaluation":
      return "Evaluation";
    case "recert":
      return "Recert";
    case "roc":
      return "ROC";
    case "supervisor":
      return "Supervisor";
    default:
      return "";
  }
};

export {
  WORKINGDAYS,
  DISCIPLINE,
  WORKHOURS,
  VISITLENGTHS,
  FREQUENCYFORMAT,
  VISITS,
  convertTime12to24,
  convertTime24to12,
  parseUserRol,
  cardinalNumber,
  getLenghtObj,
  numberPerUnitWeek,
  // numberPerUnitMonth,
  numberOfUnitsWeek,
  // numberOfUnitsMonth,
  weekState,
  // monthState,
  initialVisitColors,
  nameVisitColors,
  getFrequencyFormatSelected,
  getUserFormatFrequencyName,
  getUserFormatFrequencyValue,
  changeFrequencyByUserProfile,
  changeOneFrequencyByUserProfile,
  getUserProfileFormatFrequencyValue,
  getIsUndefinedDate,
  mergeAndGetUnique,
  getVisitTypeToString,
  getIsUndefinedDateTime,
  convertDateTime24to12,
  getVisitsColorsByUser,
  getVisitBackgroundColor,
  visitColorDefault,
  patientStatus,
  // ppeArrayStatic,
};
