import { faExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const UserErrorContainer = (props: any) => {
  const { error } = props;
  var mapErrors = Object.values(error);
  if (props.otherError.length > 0) {
    mapErrors.push(props.otherError);
  }

  return (
    <div className="bg-white">
      <div className="flex items-star p-8 user-error-container">
        <span className="rounded-full w-[30px] h-[30px] flex items-center justify-center exclamation-user-error">
          <FontAwesomeIcon
            color="#D92D20"
            stroke="#FFFFFF"
            strokeWidth={30}
            icon={faExclamation}
            className="h-8 w-8"
            aria-hidden="true"
          />
        </span>
        <div className="ml-5">
          <div className="mt-[5px] user-setting-error-bold-text">
            Unable to save your information.
          </div>
          <div className="mt-[5px]   user-setting-error-normal-text">
            Please complete all required fields before saving:
          </div>
          {mapErrors.map((element: any, index: any) => {
            return (
              <div
                key={index}
                className="mt-[5px]  user-setting-error-bold-text"
              >
                {JSON.stringify(element)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
