import React from "react";
import {Navigate} from "react-router-dom";
import {GetCookieValue, getUserObj, isNullOrEmpty} from "../Services/utility";

interface Props {
  path: string;
  children: JSX.Element;
}

export const PrivateRoutes = ({path, children}: Props) => {
  const user = getUserObj();

  const notUserOrCookie = user !== null || GetCookieValue("login-session");

  return notUserOrCookie ? (
    /* render the children if user profile is complete (address property has value) or the app is already on the profile route, otherwise redirect to the profile page  */
    (user?.address !== null && user?.aseptTerms) ||
    path.startsWith("/user/profile/") ? (
      children
    ) : (
      <Navigate to={`/user/profile/${user?.id}`} />
    )
  ) : (
    <Navigate to={"/login"} />
  );
};
