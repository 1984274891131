import React, { useEffect, useReducer } from "react";
import { PatientContext } from "./PatientContex";
import { patientReducer } from "./patientReducer";
import {
  IPatient,
  IPatientSchedule,
  IVisitHistory,
  IClinician,
  ICertification,
} from "../../Services/Interfaces";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const initialState: IPatient = {
  id: 0,
  firstName: "",
  lastName: "",
  preferredName: "",
  address: "",
  cityName: "",
  primaryNumber: "",
  secondaryNumber: "",
  admission: "",
  evaluation: "",
  frequency: "",
  discharge: "",
  dischargeType: "",
  recert: "",
  recertType: "",
  eoc: "",
  most30DRE: "",
  comming30DRE: "",
  notes: "",
  mdNumber: "",
  mdName: "",
  teamLeader: "",
  ot: 0,
  ota: 0,
  pt: 0,
  pta: 0,
  slp: 0,
  sn: 0,
  aid: 0,
  msw: 0,
  teamLeaderName: "",
  otName: "",
  otaName: "",
  ptName: "",
  ptaName: "",
  slpName: "",
  snName: "",
  aidName: "",
  mswName: "",
  status: "",
  certificationPeriodCount: 0,
};

export const PatientContextProvider = ({ children }: Props) => {
  const [patientState, dispatch] = useReducer(patientReducer, initialState);

  // useEffect(() => {
  //
  // }, [patientState.certifications]);

  const setPatient = (patient: IPatient) => {
    dispatch({ type: "setPatient", payload: patient });
  };

  const setComming30 = (comming30: string) => {
    dispatch({ type: "setComing30", payload: { comming30 } });
  };

  const setEvaluation = (date: string) => {
    dispatch({ type: "setEvaluation", payload: { evaluationDate: date } });
  };

  const setVisitHistory = (visitHistory: IVisitHistory[]) => {
    dispatch({ type: "setVisitHistory", payload: { visitHistory } });
  };

  const setFrequency = (frequency: string) => {
    dispatch({ type: "setFrequency", payload: { frequency } });
  };

  const setCertifications = (certifications: ICertification[]) => {
    dispatch({ type: "setCertifications", payload: { certifications } });
  };

  const addCertification = (certification: ICertification) => {
    dispatch({ type: "addCertification", payload: { certification } });
  };
  const setPatientSchedule = (patientSchedule: IPatientSchedule[]) => {
    dispatch({ type: "setPatientSchedule", payload: { patientSchedule } });
  };

  const setAssignedClinicians = (assignedClinicians: IClinician[]) => {
    dispatch({
      type: "setAssignedClinicians",
      payload: { assignedClinicians },
    });
  };

  const setVisitDates = (visitDates: []) => {
    dispatch({
      type: "setVisitDates",
      payload: { visitDates },
    });
  };

  const deleteCertification = (id: number) => {
    dispatch({ type: "deleteCertification", payload: { id } });
  };

  const setDischarge = (discharge: string, dischargeType: string) => {
    dispatch({ type: "setDischarge", payload: { discharge, dischargeType } });
  };

  const setRecert = (recert: string, recertType: string) => {
    dispatch({ type: "setRecert", payload: { recert, recertType } });
  };

  const setEOC = (eoc: string) => {
    dispatch({ type: "setEOC", payload: { eoc } });
  };

  return (
    <PatientContext.Provider
      value={{
        patientState,
        setPatient,
        setEOC,
        setEvaluation,
        setVisitHistory,
        setFrequency,
        setVisitDates,
        setCertifications,
        setDischarge,
        setRecert,
        addCertification,
        deleteCertification,
        setPatientSchedule,
        setAssignedClinicians,
        setComming30,
      }}
    >
      {children}
    </PatientContext.Provider>
  );
};
