import { useEffect, useRef } from "react";

export const useClickOutSide = (handler:any) => {
    const domRef = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
      const handleClickOutside =(event: any) =>{      
             if (domRef.current && !domRef.current.contains(event.target)) handler()
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  
    return domRef;
  }