import React, { useEffect, useState } from "react";

import { faArrowRight, faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "../../store/hooks";

type Props = {
  pageSize: number;
  initialNumber:number;
  total:number;
  action: (pagenumber: number) => void;
};

export const Pagination = ({ pageSize, action, initialNumber, total }: Props) => {
  const [pageNumber, setPageNumber] = useState(initialNumber);
  const [currentNumber, setCurrentNumber] = useState(initialNumber);
  const [first, setFirst] = useState(true);
  const [totalPages, setTotalPages] = useState(
    Math.floor(total / pageSize) + (total % pageSize === 0 ? 0 : 1)
  );
  const [renderRange, setRenderRange] = useState<number[]>([]);

  const isLeftDisabled = () => pageNumber === 1;
  const isRightDisabled = () => pageNumber >= totalPages;

  const handleRageChanges = () => {
    let ini:number = Math.max(pageNumber - 2, 1);
    let end:number = Math.min(pageNumber + 2, totalPages);

    ini = (end === totalPages || end === totalPages-1)? Math.max(totalPages - 6, 1) : ini;
    end = (ini === 1 || ini === 2)? Math.min(Math.max(7,end), totalPages) : end;

    setCurrentNumber(pageNumber);
    setRenderRange(Array.from(Array(end - ini + 1).keys()).map(v => v + ini));
  }

  useEffect(() => {
    const newValue = Math.floor(total / pageSize) + (total % pageSize === 0 ? 0 : 1);
    setTotalPages( newValue);
    
    if (pageNumber > newValue && newValue > 0)
      setPageNumber(newValue);
  
  }, [pageSize, total]);

  useEffect(() => {
    if (!first)
      action(pageNumber);
    setFirst(false);

    handleRageChanges();
  }, [pageNumber]);

  useEffect(() => {
    handleRageChanges();
  }, [totalPages]);

  const handleNext = () => {
    setPageNumber( Math.min(pageNumber+1, totalPages))
  }
  
  const handlePrev = () => {
    setPageNumber( Math.max(pageNumber-1, 1))
  }

  return (
      <div className="flex items-center space-x-2">
        <button
          disabled={isLeftDisabled()}
          className="flex items-start pr-2"
          onClick={() => handlePrev()}
        >
          <FontAwesomeIcon className="m-1 mr-3" size="lg" color={isLeftDisabled() ? "#D2D6DB" : "#1F2A37"} icon={faArrowLeft} /> 
          <h6 className={(isLeftDisabled() ? "text-[#D2D6DB]" : "text-[#1F2A37]") + " flex"}>Previous</h6>
        </button>

        {(renderRange[0] !== 1 && renderRange.length > 0 )?(<div onClick={() => setPageNumber(1)} className="flex pagination__value select-none" key={1}>1</div>):""}
        {(renderRange[0] !== 1 && renderRange[0] !== 2 && renderRange.length > 0 )?(<div className="flex pagination__value select-none spread" key={"sp1"}>...</div>):""}

        {renderRange.map(v => (<div onClick={() => setPageNumber(v)} className={"flex pagination__value select-none" + (v === currentNumber?" current":"")} key={v}> {v} </div>))}

        {(renderRange[ renderRange.length - 1 ] !== totalPages && renderRange[ renderRange.length - 1 ] !== totalPages-1 && renderRange.length > 0 )?(<div className="flex pagination__value select-none spread" key={"sp-1"}>...</div>):""}
        {(renderRange[ renderRange.length - 1 ] !== totalPages && renderRange.length > 0 )?(<div onClick={() => setPageNumber(totalPages)} className="flex pagination__value select-none" key={totalPages}>{totalPages}</div>):""}

        <button
          disabled={isRightDisabled()}
          className="flex items-start pl-2"
          onClick={() => handleNext()}
        >          
          <h6 className={(isRightDisabled() ? "text-[#D2D6DB]" : "text-[#1F2A37]") + " flex"}>Next</h6>
          <FontAwesomeIcon className="m-1 ml-3" size="lg" color={isRightDisabled() ? "#D2D6DB" : "#1F2A37"} icon={faArrowRight} /> 
        </button>
      </div>
    );
};
