import axios from "axios";
import {
  ICareTeam,
  ICreateCareTeam,
  ICreatePatient,
  IPatient,
} from "../interfaces/IPatient";
import { IResponse } from "../interfaces/IResponses";
import {
  getAllPatientsWithPagination,
  GetData,
  GetPatient,
  Save,
  UpdatePatient,
  SaveCareTeam,
} from "../Services/Api";
import { getUserToken } from "../Services/utility";

const token = getUserToken();

export const patientApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "/PatientProfile",
});

export const getAllPatients = async ({
  pagenumber = 1,
  pagesize = 20,
  status = "",
  query = "",
  sortField = "",
  sortDir = "u",
}) => {
  return await getAllPatientsWithPagination({
    pagenumber,
    pagesize,
    status:status === "All" ? "" : status,
    query,
    sortField,
    sortDir,
  });
};

export const savePatientToDb = async (patient: ICreatePatient) => {

  const { data } = await Save({...patient,
      admittingClinician: patient.admittingClinician == 1 ? true : false
  });
  return data;
};
export const updatePatientToDb = async (patient: ICreatePatient) => {
  const { data } = await UpdatePatient(patient);
  return data;
};

export const saveCareTeamToDb = async (careTeam: ICreateCareTeam) => {
  const { data } = await SaveCareTeam(careTeam);
  return data;
};
