import Axios from "axios";
import * as utility from "./utility";
// @ts-ignore
import { saveAs } from "file-saver";
import { IPatientDetailResponse, IPatients } from "./Interfaces/IPatients";
import { IUser } from "./Interfaces/IUser";
import { IPatient } from "./Interfaces";
import { string } from "yup";
import { IResponse } from "../interfaces/IResponses";
import {
  ICareTeam,
  ICompletePatient,
  ICreateCareTeam,
} from "../interfaces/IPatient";
import { store } from "../store/store";
import { setIsLoading } from "../store/slices/notification/notificationSlice";
// import { promises } from "fs";

// TODO: Review
// const base_url = "http://45.77.94.237:5000/api";
const base_url = process.env.REACT_APP_API_URL;
// import { BASE_URL as base_url, BASE_URL } from './utility';
// const base_url = 'https://apex-api.npit.info/api'
let temp: any = undefined;

// Request;
Axios.interceptors.request.use(
  function (config: any) {
    if (config.url.toLowerCase().includes("login")) return config;

    // TODO: review rocket chat config
    if (!config.url.toLowerCase().includes("https://45.77.94.237:3000/")) {
      const token = utility.getUserToken();
      config.headers["X-Referer"] = "WEB";
      //
      if (token !== null && token !== "") {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (temp !== undefined) {
        config.data = temp;
        temp = undefined;
      }
    } else {
      const authToken = utility.readFromLocalStorage("authToken");
      const userId = utility.ValueFromUserData("rcUserId");
      if (authToken) {
        config.headers["X-Auth-Token"] = authToken;
        config.headers.common["X-User-Id"] = userId;
      }
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

//Response
Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (error.response !== undefined) {
      if (error.response.status === 400) {
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        Axios.defaults.headers.common["Authorization"] =
          await `Bearer ${utility.getUserToken()}`;
        return Axios(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

// Login API
export function Login(user: Object) {
  return Axios.post(base_url + "/Auth/Login", user);
}

// GetData
export const GetData = async (END_POINT: string) => {
  const { data } = await Axios.get<any>(base_url + END_POINT);
  return {
    data: data.events || data.data,
    message: data.message,
    status: data.status,
  };
};

export const getAllPatientsWithPagination = async ({
  pagenumber = 1,
  pagesize = 20,
  status = "",
  query = "",
  sortField = "",
  sortDir = "u",
}) => {
  return await Axios.get(
    base_url +
      `/PatientProfile/GetAllSimple?pagenumber=${pagenumber}&pagesize=${pagesize}&query=${query}&Status=${status}&sortField=${sortField}&sortDir=${sortDir}`
  );
};

export const getAllUsersWithPagination = async ({
  pagenumber = 1,
  pagesize = 20,
  status = "",
  query = "",
  sortField = "",
  sortDir = "u",
}) => {
  return await Axios.get(
    base_url +
      `/User/GetAllSimple?pagenumber=${pagenumber}&pagesize=${pagesize}&query=${query}&Status=${status}&sortField=${sortField}&sortDir=${sortDir}`
  );
};

export const getPatientsToSchedule = async (start: string, end: string) => {
  return await GetData(
    `/ManualScheduling/PatientVisitsToSchedule?dayIni=${start}&dayEnd=${end}`
  );
};

export const getVisitDetails = async (id: string | number) => {
  return await GetData(`/ManualScheduling/ViewVisit?id=${id}`);
};

export const getNavigate = async (
  id1: string | number,
  id2: string | number
) => {
  return await GetData(
    `/ManualScheduling/PairVisitLocations?id1=${id1}&id2=${id2}`
  );
};

//Patient API
export async function GetPatientList(
  pageNumber: number,
  pageSize: number,
  status: string = "active",
  query?: string
) {
  const res = await Axios.get<IResponse<[IPatient]>>(
    `${base_url}/PatientProfile/GetAllSimple?pagenumber=${pageNumber}&pagesize=${
      pageSize || 25
    }&${query ? `query=${query}` : ""}&Status=${status}`
  );
  return [res.data, parseInt(res.headers["X-Total-Count"], 10)];
}

export async function GetPatient(id: any) {
  const { data } = await Axios.get<IResponse<ICompletePatient>>(
    base_url + "/PatientProfile/Get?id=" + id
  );
  return data;
}

export async function GetCareTeam(PatientId: any) {
  const { data } = await Axios.get<IResponse<ICareTeam>>(
    base_url + "/PatientProfile/CareTeam?PatientId=" + PatientId
  );
  return data;
}

export async function SaveCareTeam(body: any = {}) {

  let res = await Axios.put(
    base_url + "/PatientProfile/CareTeam?PatientId=",
    body
  );

  return { data: res.data.data, message: res.data.message };
}

export function Save(obj: Object) {
  return Axios.post<IResponse<ICompletePatient>>(
    base_url + "/patientProfile/Add",
    obj
  );
}


export function UpdatePatient(obj: Object) {
  return Axios.put<IResponse<ICompletePatient>>(
    base_url + "/patientProfile/Update",
    obj
  );
}
export function UpdatePatientStatus(patientId: number, status:string) {
  return Axios.put(
    base_url + `/patientProfile/UpdatePatientStatus?patientId=${patientId}&status=${status}`,
  );
}

export async function getFreqAndDw(id: any) {
  const res = await GetData(
    `/PatientProfile/GetFrequencyAndDischarge?id=${id}`
  );
  return res;
}

// USER API

export async function UserList(
  pageNumber: number | undefined = undefined,
  pageSize: number | undefined = undefined
) {
  if (pageNumber === undefined) {
    pageNumber = 1;
    pageSize = 100;
  }
  let res = await Axios.get<IUser>(
    base_url + `/User/GetAll?pagenumber=${pageNumber}&pagesize=${pageSize}`
  );
  return res.data;
}

export async function DeletePatient(id: any) {
  let res = await Axios.delete(base_url + "/PatientProfile/Delete", {
    params: { id },
  });
  return res;
}

// General //

export const getCSVSample = async () => {
  const res = await Axios.get(base_url + "/PatientProfile/csvExample", {
    responseType: "blob",
  });
  saveAs(res.data, "PatientUploadExample" + ".csv");
};

export const GetAllRolesAPI = async () => {
  let response = await Axios.get<any>(`${base_url}/Role/GetAll`);
  return { data: response.data.data.items, message: response.data.message };
};

export const GetCountries = async () => {
  let response = await Axios.get<any>(base_url + "/Country/GetCountries");

  return { data: response.data.data.items, message: response.data.message };
};

export const GetStates = async (countryId: number) => {
  let response = await Axios.get<any>(
    base_url + "/Country/GetStates?countryid=" + countryId
  );

  return { data: response.data.data.items, message: response.data.message };
};

export const GetCities = async (stateId: number) => {
  let response = await Axios.get<any>(
    base_url + "/Country/GetCities?stateid=" + stateId
  );

  return { data: response.data.data.items, message: response.data.message };
};

// User
//TODO View why is not used
// export const GetUserById = async (id: number) => {
//   const response = await Axios.get(`${base_url}/User/GetUser`, {
//     params: { id },
//   });

//   return response.data.data;
// };

export async function DeleteUser(id: any) {
  let res = await Axios.delete(base_url + "User/DeleteUser", {
    params: { id },
  });
  return res;
}

export async function SaveUser(obj: Object) {
  let res = await Axios.post(base_url + "/User/AddUser", obj);
  return { data: res.data.data, message: res.data.message };
}

// POST DATA
export const PostData = async (END_POINT: string, body: any) => {
  const response = await Axios.post<any>(base_url + END_POINT, body);
  return {
    data: response.data.data,
    message: response.data.message,
    status: response.data.status,
  };
};

export const saveSchedule = async (data: any) => {
  const res = await PostData("/PatientSchedule/AddFrequency", data);
  return res;
};

export const editSchedule = async (data: any) => {
  const res = await PutData("/PatientSchedule/UpdateFrequency", data);
  return res;
};

// DELETE DATA
export const DeleteData = async (END_POINT: string, body: any = undefined) => {
  temp = body;
  let response = await Axios.delete<any>(base_url + END_POINT, body);
  return {
    data: response.data.data,
    message: response.data.message,
    status: response.data.status,
  };
};

// UPDATE DATA
export const PutData = async (endpoint: string, body: any = {}) => {
  const { data } = await Axios.put(base_url + endpoint, body);
  return { data: data.data, message: data.message, status: data.status };
};

export const UpdateScheduledVisits = (
  data: any,
  bodyOptions: {
    id?: any;
    start?: Date;
    end?: Date;
    isLocked?: boolean;
    isCompleted?: boolean;
  }
) => {
  const { id, start, end, isCompleted, isLocked } = bodyOptions;
  const temp = data.event._instance.range.start
    .toString()
    .split("GMT")[1]
    .split(" ")[0];
  const body = {
    id: id || data.event._def.publicId,
    recertId: data.event._def.extendedProps.recertId,
    clinitianId: data.event._def.extendedProps.clinitianId,
    patientId: data.event._def.extendedProps.patientId,
    start: start
      ? new Date(start?.toUTCString() + temp!)
      : new Date(data.event._instance.range.start.toUTCString() + temp!),
    end: end
      ? new Date(end?.toUTCString() + temp!)
      : new Date(data.event._instance.range.end.toUTCString() + temp!),
    isLocked: isLocked ?? data.event._def.extendedProps.isLocked,
    isCompleted: isCompleted ?? data.event._def.extendedProps.isCompleted,
  };
  return PutData("/ManualScheduling/UpdateVisit", body);
};
export const PutFrequency = async (
  endPoint: string,
  body: {
    recertId: number;
    patientId: number;
    clinitianId?: number;
    frequency: string;
    index: number;
  }
) => {
  const { data } = await Axios.put(base_url + endPoint, body);
  return { data: data.data, message: data.message, status: data.status };
};

export const cancelFrequencyByDefault= async ( 
  body: {
    patientId: number;
    recertId: number;
    clinitianId?:number;
    frecs: string[] | undefined;
    blocked:boolean[]
  }
) => {
  
  try{ 

      const response  = await Axios.put(base_url + "/PatientSchedule/ForceResertFrequencies", body);
  
      switch (response.data.status) {
        case 201:
        case 200:
          return { status: 200, value: response.data.data };
        case 401:
          return { status: 401, value: response.data.message };
        case 402:
          return { status: 402, value: response.data.message };
        default:
          return {
            status: response.data.status,
            value: "Resource not found",
          };
        }
      } catch(error){
        return { status: 505, value: "An error occurred while trying to connect to the server"};
     } 
};

export const PutCert = async (
  endpoint: string,
  body: {
    patientId: number;
    recertificationDate: Date;
    id: number;
  }
) => {
  const { data } = await Axios.put(base_url + endpoint, body);
  return { data: data.data, message: data.message, status: data.status };
};

// GET ME

export const ME = async () => {
  const result: any = await PostData("/User/Me", {});
  //
  return result;
};
