import { faAngleDown, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox, Transition } from "@headlessui/react";
import { useField } from "formik";
import React, { Fragment, useEffect, useState } from "react";

export type DropdownOptions = {
  id: number;
  name: string;
  discipline: string;
};

export type DropDownProps = {
  preText: string;
  onSelect: Function;
  options: DropdownOptions[];
  name: string;
  label: string;
  span: string;
  isDisabled: boolean;
  icon: string;
  value: any;
  height: "";
  toUp: boolean;
};

export default function DropdownCareTeam(props: any) {
  const popupHeight =
    props.options.length > 0
      ? props.options.length * 39 > 117
        ? Math.round((props.options.length * 39) / 1.5) + "px"
        : props.options.length * 39 + 10 + "px"
      : "45px";

  const initialValues = props.options.filter(
    (element: any) => element.id === props.value
  )[0];

  const [myselected, setMySelected] = useState<DropdownOptions>(
    initialValues ?? { id: props.value, name: "", discipline: "" }
  );

  const [field, meta] = useField(props);

  useEffect(() => {
    const toAdd = props.options.filter(
      (element: any) => element.id === props.value
    )[0] ?? { id: props.value, name: "", discipline: "" };

    if (props.value) {
      setMySelected(toAdd);
    }
  }, [props.options, props.value]);
  const okField = meta.touched && !meta.error && props.value;

  const errorField = meta.touched && meta.error;

  return (
    <div>
      <Listbox
        name={props.name}
        value={props.options.length > 0 ? myselected?.name : ""}
        onChange={(v: any) => {
          props.onSelect(v);
          setMySelected(v);
        }}
      >
        <div className="list__box_dropdown_careteam  w-100 bg-white">
          <Listbox.Label>
            {props.label}
            {props.span && <span className="span-required">{props.span}</span>}
          </Listbox.Label>
          {props.icon && (
            <i
              className={`${props.icon} ${meta.error && "icon__error"}`}
              style={{ color: "#9DA4AE", fontWeight: "900" }}
            ></i>
          )}

          <Listbox.Button
            className={`w-full cursor-default ${okField && "success"} ${
              errorField && "error"
            }`}
          >
            <div className={`careteam-container ${props.icon && "icon"}`}>
              {myselected?.name === "" ? (
                <span className="careteam-placeholder">{props.preText}</span>
              ) : (
                <span className="careteam-value">{myselected?.name}</span>
              )}
            </div>
            <span className="dropdown__pointer_careteam pointer-events-none">
              <FontAwesomeIcon
                color="#6C737F"
                icon={faAngleDown}
                className="dropdown-pointer"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in-out duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`dropdown-careteam-options ${props.height} ${
                props.toUp && "working"
              }`}
              style={{ height: popupHeight }}
            >
              {props.options.length > 0 ? (
                props.options.map((person: any, personIdx: number) => (
                  <Listbox.Option
                    key={personIdx}
                    className={({ active }) =>
                      `dropdown-li-options-careteam relative cursor-default select-none  ${
                        active || myselected.name === person.name
                          ? "bg-gray-50"
                          : ""
                      }`
                    }
                    value={person}
                  >
                    {() => (
                      <>
                        <span>{person.name}</span>
                        {myselected?.name === person.name ? (
                          <span className="dropdown-check-icon">
                            <FontAwesomeIcon
                              color="#0BB68C"
                              icon={faCheck}
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))
              ) : (
                <Listbox.Option
                  className="dropdown-li-options-careteam not-record relative cursor-default select-none"
                  value={""}
                >
                  <span>Not record</span>
                </Listbox.Option>
              )}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
