import React from "react";
import moment from "moment";
import { getVisitBackgroundColor, getVisitsColorsByUser, VISITS } from "../../../helpers/globals";

type Props = {
  event?: {
    dateEnd: string | Date;
    date: string | Date;
    distance: string;
    title: string;
    visitType: string;
    rest?: any;
  };
};

const visitTypeFull = (type: string): string => {
  switch (type) {
    case "D":
      return "Discharge";
    case "RV":
      return "Routine Visit";
    case "30":
      return "30DRE";
    case "A":
      return "Admission";
    case "E":
      return "Evaluation";
    case "R":
      return "Recert";
    case "ROC":
      return "ROC";
      case "S":
        return "Supervisor";
    default:
      return ""
  }
}



export const colorByType = (type: string): string => {
  switch (type) {
    case "D":
      return "discharge";
    case "RV":
      return "routinevisit";
    case "30":
      return "30dre";
    case "A":
      return "admission";
    case "E":
      return "evaluation";
    case "R":
      return "recert";
    case "ROC":
      return "roc";
      case "S":
        return "supervisor";
    default:
      return ""
  }
}
export const getNameVisitByType = (type: string): string => {
  switch (type) {
    case "D":
      return "discharge";
    case "RV":
      return "routine visit";
    case "30":
      return "30dre";
    case "A":
      return "admission";
    case "E":
      return "evaluation";
    case "R":
      return "recert";
    case "ROC":
      return "roc";
      case "S":
        return "supervisor";
    default:
      return ""
  }
}


export const colorByTypeCrude = (type: string | undefined): string => {

  switch (type) {
    case "D":
      return getVisitsColorsByUser(VISITS.DISCHARGE);
    case "RV":
      return getVisitsColorsByUser(VISITS.ROUTINEVISIT);
    case "30":
      return getVisitsColorsByUser(VISITS.THYRTYDRE);
    case "A":
      return getVisitsColorsByUser(VISITS.ADMISSION);
    case "E":
      return getVisitsColorsByUser(VISITS.EVALUATION);
    case "R":
      return getVisitsColorsByUser(VISITS.RECERT);
    case "ROC":
      return getVisitsColorsByUser(VISITS.RESUMPTIONOFCARE);
      case "S":
        return getVisitsColorsByUser(VISITS.SUPERVISOR);
    case undefined:
    default:
       return getVisitsColorsByUser(VISITS.ONHOLD);
  }
}
export const backgroundColorByTypeCrude = (type: string | undefined): string => {

  switch (type) {
    case "D":
      return getVisitBackgroundColor(VISITS.DISCHARGE);
    case "RV":
      return getVisitBackgroundColor(VISITS.ROUTINEVISIT);
    case "30":
      return getVisitBackgroundColor(VISITS.THYRTYDRE);
    case "A":
      return getVisitBackgroundColor(VISITS.ADMISSION);
    case "E":
      return getVisitBackgroundColor(VISITS.EVALUATION);
    case "R":
      return getVisitBackgroundColor(VISITS.RECERT);
    case "ROC":
      return getVisitBackgroundColor(VISITS.RESUMPTIONOFCARE);
      case "S":
        return getVisitBackgroundColor(VISITS.SUPERVISOR);
    case undefined:
    default:
       return getVisitsColorsByUser(VISITS.ONHOLD);
  }
}

export const TyppyVisit = ({ event }: Props) => {
  const handleMouseEnter = () => {
    setTimeout(() => {
      const arrow = document.getElementsByClassName("tippy-arrow")[0];
    }, 500);
  };

  if (!event) return <></>;

  const background =colorByTypeCrude(event.visitType)


  return (
    <div
      onMouseEnter={handleMouseEnter}
      data-for="foo"
      className={"visitTyppy flex flex-col gap-y-[6px] visit-" +colorByType(event.visitType)
    }
      style={{ backgroundColor: background }}
    >
      <span className="title uppercase">{visitTypeFull(event.visitType)}</span>
      <span className="sub__title">
        {moment(event.date).format("HH:mm")} - {moment(event.dateEnd).format("HH:mm")}
      </span>
    </div>
  );
};
