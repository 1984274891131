import {
  faClose,
  faSpinner,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { unwrapResult } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Picker from "react-scrollable-picker";
import Popup from "reactjs-popup";
import {
  changeOneFrequencyByUserProfile,
  getUserProfileFormatFrequencyValue,
  // monthState,
  // numberOfUnitsMonth,
  numberOfUnitsWeek,
  // numberPerUnitMonth,
  numberPerUnitWeek,
} from "../../../helpers/globals";
import { useClickOutSide } from "../../../hooks/useClickOutSide";
import {
  ICreateFrequency,
  IDeleteFrequency,
  IEditFrequency,
} from "../../../interfaces/IFrequency";
import { ShowAlert, ValueFromUserData } from "../../../Services/utility";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  createFrequency,
  deleteFrequency,
  editFrequency,
} from "../../../store/slices/certification";

const daysState = {
  valueGroups: {
    numberPerUnit: "0",
  },
  optionGroups: {
    numberPerUnit: numberPerUnitWeek,
  },
};

const weekState = {
  valueGroupsWeek: {
    numberPerUnit: "1",
  },
  optionGroupsWeek: {
    numberPerUnit: numberOfUnitsWeek,
  },
};

export const FreqStep2 = (props: {
  calendarUpdater: () => void;
  onEditEnd: () => void;
  certification: any;
  haveAID: boolean;
  domRef: React.MutableRefObject<HTMLDivElement | null>;
}) => {
  const { certification } = useAppSelector((state) => state.certification);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div: any = props.domRef.current;
    if (div && isAddUpdateFreq) {
      div.style.overflow = "hidden";
    } else {
      div.style.overflow = "scroll";
    }
  });

  const { patient } = useAppSelector((state) => state.patient);
  const [buzzy, setBuzzy] = useState<boolean>(false);
  const [isAddUpdateFreq, setisAddUpdateFreq] = useState(false);
  const dispatch = useAppDispatch();
  const [id, setId] = useState(-1);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openConfirmUpdate, setOpenConfirmUpdate] = useState(false);

  const [freqDays, setFreqDays] = useState(daysState);
  const [freqWeek, setFreqWeek] = useState(weekState);
  const handleCloseFreq = () => {
    setId(-1);
    setisAddUpdateFreq(false);
    setFreqDays(daysState);
    setFreqWeek(weekState);
  };

  const scrollRefDays = useRef<HTMLDivElement | null>(null);
  const scrollRefWeek = useRef<HTMLDivElement | null>(null);
  const throttle = (fn: any, wait: any) => {
    let time = Date.now();
    return function (e: any) {
      if (time + wait - Date.now() < 0) {
        fn(e);
        time = Date.now();
      }
    };
  };

  const changeValScroll = (e: any) => {
    e.persist();
    const div: any = props.domRef.current;

    const deltaY: string = (e.deltaY / 100).toString();
    const diff = parseInt(deltaY, 10);
    div.style.overflow = "hidden";

    if (scrollRefDays.current && scrollRefDays.current.contains(e.target)) {

      const currentValue = freqDays.valueGroups.numberPerUnit;

      const currentIndex = freqDays.optionGroups.numberPerUnit.findIndex(
        (i) => i.value === currentValue
      );

      let newIndex = currentIndex + diff;
      if (newIndex < 0) {
        newIndex = 0;
      }
      if (newIndex > freqDays.optionGroups.numberPerUnit.length - 1) {
        newIndex = freqDays.optionGroups.numberPerUnit.length - 1;
      }
      setFreqDays(({ valueGroups }) => ({
        ...freqDays,
        valueGroups: {
          ...valueGroups,
          numberPerUnit: freqDays.optionGroups.numberPerUnit[newIndex].value,
        },
      }));
    } else if (
      scrollRefWeek.current &&
      scrollRefWeek.current.contains(e.target)
    ) {

      const currentValue = freqWeek.valueGroupsWeek.numberPerUnit;

      const currentIndex = freqWeek.optionGroupsWeek.numberPerUnit.findIndex(
        (i) => i.value === currentValue
      );

      let newIndex = currentIndex + diff;
      if (newIndex < 0) {
        newIndex = 0;
      }
      if (newIndex > freqWeek.optionGroupsWeek.numberPerUnit.length - 1) {
        newIndex = freqWeek.optionGroupsWeek.numberPerUnit.length - 1;
      }

      setFreqWeek(({ valueGroupsWeek }) => ({
        ...freqWeek,
        valueGroupsWeek: {
          ...valueGroupsWeek,
          numberPerUnit:
            freqWeek.optionGroupsWeek.numberPerUnit[newIndex].value,
        },
      }));
    } else {
      return;
    }
  };

  const handleOnClickFreq = (freq: string, id: number) => {
    let temp: string = freq;
    let strsplit: string[] = temp.split("w");

    const numberPerUnit = strsplit[0];
    const numberOfUnits = strsplit[1];
    setFreqDays({
      valueGroups: {
        numberPerUnit: numberPerUnit,
      },
      optionGroups: {
        numberPerUnit: numberPerUnitWeek,
      },
    });
    setFreqWeek({
      valueGroupsWeek: {
        numberPerUnit: numberOfUnits,
      },
      optionGroupsWeek: {
        numberPerUnit: numberOfUnitsWeek,
      },
    });

    setId(id);
    setisAddUpdateFreq(true);
  };

  const handleSaveButton = () => {
    if (id > -1) {
      handleEditFrequency();
    } else {
      handleAddFrequency();
    }
  };
  const handleChange = (name: any, value: any) => {
    setFreqDays(({ valueGroups }) => ({
      ...freqDays,
      valueGroups: {
        ...valueGroups,
        [name]: value,
      },
    }));
  };
  const handleChangeWeek = (name: any, value: any) => {
    setFreqWeek(({ valueGroupsWeek }) => ({
      ...freqWeek,
      valueGroupsWeek: {
        ...valueGroupsWeek,
        [name]: value,
      },
    }));
  };

  const handleDelete = (index: number) => {
    const body: IDeleteFrequency = {
      index,
      patientId: patient.id,
      recertId: certification.id,
    };
    setBuzzy(true);

    dispatch(deleteFrequency(body))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        props.calendarUpdater();
        handleCloseFreq();
      })
      .catch((rejectedValueOrSerializedError) => {
        ShowAlert(rejectedValueOrSerializedError, "error");
      })
      .finally(() => {
        // setOpenConfirmDelete(false);
        handleCloseFreq();
        setBuzzy(false);
      });
  };
  const refDeleteModal = useClickOutSide(() => {
    setOpenConfirmDelete(false);
  });

  const refUpdateModal = useClickOutSide(() => {
    setOpenConfirmUpdate(false);
    props.onEditEnd();
  });
  const navigate = useNavigate();
  const handleAddFrequency = () => {
    const frequency = `${freqDays.valueGroups.numberPerUnit}w${freqWeek.valueGroupsWeek.numberPerUnit}`;

    const body: ICreateFrequency = {
      recertId: certification.id,
      patientId: patient.id,
      frequency: frequency,
    };

    setBuzzy(true);    
    dispatch(createFrequency(body))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        setisAddUpdateFreq(false);
        setFreqDays(daysState);
        setFreqWeek(weekState);
        props.onEditEnd();
      })
      .catch((rejectedValueOrSerializedError) => {
        //console.log(rejectedValueOrSerializedError)
        if(rejectedValueOrSerializedError.toString().includes("visit has been scheduled and marked as")){
          navigate("/caseload-view");
        }        
        ShowAlert(rejectedValueOrSerializedError, "error");
      })
      .finally(() => {
        setBuzzy(false);
        handleCloseFreq();
      });
  };
  const handleEditFrequency = () => {
    const frequency = `${freqDays.valueGroups.numberPerUnit}w${freqWeek.valueGroupsWeek.numberPerUnit}`;

    const body: IEditFrequency = {
      recertId: certification.id,
      patientId: patient.id,
      frequency: frequency,
      index: id + 1,
    };

    setBuzzy(true);
    dispatch(editFrequency(body))
      .then(unwrapResult)
      .then((originalPromiseResult) => {
        props.calendarUpdater();
        handleCloseFreq();
      })
      .catch((rejectedValueOrSerializedError) => {
        ShowAlert(rejectedValueOrSerializedError, "error");
      })
      .finally(() => {
        handleCloseFreq();
        setBuzzy(false);
      });
  };

  const UpdateFrecuencyModal = () => (
    <Popup
      open={openConfirmUpdate}
      closeOnDocumentClick
      lockScroll
      contentStyle={{
        width: "100%",
        height: "100%",
      }}
      onClose={() => {
        setOpenConfirmUpdate(false);
      }}
    >
      <div
        className="flex w-[365px] h-[309px] delete_patient_popup bg-white"
        ref={refUpdateModal}
      >
        <div className="py-[32px] px-[24px] flex flex-col w-[100%] place-content-between">
          <div>
            <div className="flex flex-row">
              <div className="flex justify-center basis-[95%]">
                <h2 className="text-center ">Update frequency</h2>
              </div>
              <div className="flex justify-end basis-[5%">
                <button
                  title="close-user-button"
                  type="button"
                  onClick={() => setOpenConfirmUpdate(false)}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    className="modal-patient-button-close-bulk"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
            <hr />
            <h3 className="text-center mt-[24px]">Are you sure?</h3>
            <p className="text-center mt-[24px]">
              Save the update/change to the frequency for{" "}
              <span>
                {patient.firstName} {patient.lastName}{" "}
              </span>
            </p>
          </div>
          <div className="flex place-content-between">
            <button
              className="pop_btn h-[35px] flex items-center place-content-center px-[50px] rounded-[6px]"
              onClick={() => {
                setOpenConfirmUpdate(false);
                props.onEditEnd();
              }}
            >
              Cancel
            </button>
            <button
              className="pop_btn pop_save_btn h-[35px] flex items-center place-content-center px-[50px] rounded-[6px]"
              onClick={handleEditFrequency}
            >
              {buzzy ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  className="animate-spin"
                  size="1x"
                />
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );
  const DeleteModal = () => {
    return (
      <Popup
        open={openConfirmDelete}
        lockScroll
        contentStyle={{
          width: "100%",
          height: "100%",
        }}
        onClose={() => {
          setOpenConfirmDelete(false);
        }}
      >
        <div
          className="flex w-[365px] h-[309px] delete_patient_popup bg-white"
          ref={refDeleteModal}
        >
          <div className="py-[32px] px-[24px] flex flex-col w-[100%] place-content-between">
            <div>
              <div className="flex flex-row">
                <div className="flex justify-center basis-[95%]">
                  <h2 className="text-center ">Delete frequency</h2>
                </div>
                <div className="flex justify-end basis-[5%">
                  <button
                    title="close-user-button"
                    type="button"
                    onClick={() => {
                      setOpenConfirmDelete(false);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faClose}
                      className="modal-patient-button-close-bulk"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
              <hr />
              <h3 className="text-center mt-[24px]">Are you sure?</h3>
              <p className="text-center mt-[24px]">
                Delete this frequency of {patient.firstName} {patient.lastName}
              </p>
            </div>
            <div className="flex place-content-between">
              <button
                className="pop_btn h-[35px] flex items-center place-content-center px-[50px] rounded-[6px]"
                onClick={() => {
                  setOpenConfirmDelete(false);
                }}
              >
                Cancel
              </button>
              <button
                className="pop_btn pop_delete_btn h-[35px] flex items-center place-content-center px-[50px] rounded-[6px]"
                onClick={() => handleDelete(id + 1)}
              >
                {buzzy ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="animate-spin"
                    size="1x"
                  />
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };
  const { optionGroups, valueGroups } = freqDays;
  const { optionGroupsWeek, valueGroupsWeek } = freqWeek;
  const userFrequency = ValueFromUserData("weekVisitFormat") ?? "w";

  return (
    <>
      <DeleteModal />
      <UpdateFrecuencyModal />
      <div className="flex flex-col h-full gap-[10px] mr-[50px]">
        <div
          className="step__container step2__box flex flex-col  "
          style={{
            width: "294px",
            maxHeight: "315px",
            minHeight: "315px",
            marginTop: `${!props.haveAID && "52px"}`,
          }}
        >
          {isAddUpdateFreq ? (
            id === -1 ? (
              //IS ADD
              <>
                <div className="relative ">
                  <div className="frecuency__field_container">
                    <div className="flex flex-wrap justify-start content-start gap-[3px] mt-[9px] w-full ">
                      {certification.frequency.map((f, i) => {
                        return (
                          <div key={i} className="frecuency__field edit">
                            {changeOneFrequencyByUserProfile(f, userFrequency)}
                          </div>
                        );
                      })}
                      <div className="frecuency__field add">
                        {freqDays.valueGroups.numberPerUnit +
                          getUserProfileFormatFrequencyValue(
                            "week",
                            userFrequency
                          ) +
                          freqWeek.valueGroupsWeek.numberPerUnit}
                        <span className="relative">
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            onClick={handleCloseFreq}
                            color="#F04438"
                            size="1x"
                            className="w-[16px] h-[16px] cursor-pointer"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <div
                    className="mt-[20px]"
                    ref={(r) => {
                      scrollRefDays.current = r;
                    }}
                    onWheel={throttle(changeValScroll, 150)}
                  >
                    <Picker
                      optionGroups={optionGroups}
                      valueGroups={valueGroups}
                      onChange={handleChange}
                      ref={scrollRefDays}
                    />
                  </div>
                  <div className="mt-[30px] flex flex-col justify-center items-center">
                    <span className="cert__text_title">Week</span>
                  </div>
                  <div
                    className="mt-[20px]"
                    ref={(r) => {
                      scrollRefWeek.current = r;
                    }}
                    onWheel={throttle(changeValScroll, 150)}
                  >
                    <Picker
                      optionGroups={optionGroupsWeek}
                      valueGroups={valueGroupsWeek}
                      onChange={handleChangeWeek}
                      ref={scrollRefWeek}
                    />
                  </div>
                </div>
              </>
            ) : (
              //IS UPDATE
              <>
                <div className="relative ">
                  <div className="frecuency__field_container">
                    <div className="flex flex-wrap justify-start content-start gap-[3px] mt-[9px] w-full ">
                      {certification.frequency.map((f, i) => {
                        if (id === i) {
                          return (
                            <div className="frecuency__field add" key={i}>
                              {freqDays.valueGroups.numberPerUnit +
                                getUserProfileFormatFrequencyValue(
                                  "week",
                                  userFrequency
                                ) +
                                freqWeek.valueGroupsWeek.numberPerUnit}
                              <span className="relative">
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  onClick={() => handleDelete(id + 1)}
                                  color="#F04438"
                                  size="1x"
                                  className="w-[16px] h-[16px] cursor-pointer"
                                />
                              </span>
                            </div>
                          );
                        } else {
                          return (
                            <div key={i} className="frecuency__field edit">
                              {changeOneFrequencyByUserProfile(
                                f,
                                userFrequency
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <div
                    className="mt-[20px]"
                    ref={(r) => {
                      scrollRefDays.current = r;
                    }}
                    onWheel={throttle(changeValScroll, 150)}
                  >
                    <Picker
                      optionGroups={optionGroups}
                      valueGroups={valueGroups}
                      onChange={handleChange}
                      ref={scrollRefDays}
                    />
                  </div>
                  <div className="mt-[30px] flex flex-col justify-center items-center">
                    <span className="cert__text_title">Week</span>
                  </div>
                  <div
                    className="mt-[20px]"
                    ref={(r) => {
                      scrollRefWeek.current = r;
                    }}
                    onWheel={throttle(changeValScroll, 150)}
                  >
                    <Picker
                      optionGroups={optionGroupsWeek}
                      valueGroups={valueGroupsWeek}
                      onChange={handleChangeWeek}
                      ref={scrollRefWeek}
                    />
                  </div>
                </div>
              </>
            )
          ) : (
            <div className="flex flex-wrap justify-start content-start gap-[3px] mt-[9px] w-full h-[195px] ">
              {certification.frequency.map((f, i) => (
                <div
                  key={i}
                  className={`frecuency__field ${
                    certification.blocked[i] ? "disabled" : "edit"
                  }`}
                  onClick={() =>
                    certification.blocked[i] ? {} : handleOnClickFreq(f, i)
                  }
                >
                  {changeOneFrequencyByUserProfile(f, userFrequency)}
                </div>
              ))}
            </div>
          )}
          {!isAddUpdateFreq ? (
            <div className="flex justify-end relative bottom-[-47px]">
              <button
                onClick={() => setisAddUpdateFreq(true)}
                type="button"
                className="add__btn"
                style={{ width: "118px" }}
              >
                Add Frequency
              </button>
            </div>
          ) : (
            <div className="flex justify-end relative bottom-[-20px]">
              <button
                onClick={handleSaveButton}
                type="button"
                className="add__btn"
                style={{ width: "118px" }}
              >
                {buzzy ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    className="animate-spin"
                    size="1x"
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
