import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

type Props = {
  action?: string;
  className?: string;
};

export const LoaderSpinner = ({ action = "Loading", className = "flex align-items-center" }: Props) => {
  return (
    <span className={className}>
      <FontAwesomeIcon
        className="animate-spin mr-3"
        icon={regular("spinner")}
      />
      {action}
    </span>
  );
};
