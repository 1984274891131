import React, { useCallback, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import { useClickOutSide } from "../../../hooks/useClickOutSide";

type Props = {
  plusclass:string;
  name?: string;
  title: string;
  children: JSX.Element | JSX.Element[];
  close: () => void;
  setClose?:() => void;
  domRef? :React.RefObject<HTMLDivElement>;
};

export const CertificationLayout = ({
  name,
  children,
  close,
  title,
  plusclass,
  setClose,
  domRef,
}: Props) => {
  const modalDelete = useClickOutSide(() => {
    setClose&&  setClose()
  })

  
 
  return (
    <div className={"certification__container " + plusclass} ref={title == "Delete Certification" ? modalDelete: domRef}>
      <div className="flex place-content-between items-center pb-[16.5px] border-b border-[#cdcac6] mb-[24px] h-[41px]">
        <h1 className="certification__title">{title}</h1>
        <FontAwesomeIcon onClick={setClose ? setClose : close} className="w-[20px] h-[20px] cursor-pointer" size="1x" color="#384250" icon={faClose} />
      </div>
      {name && <div className="certification__name mb-[24px]">{name}</div>}
      {children}
    </div>
  );
};
