import React, { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { ICertification } from "../../../interfaces/ICertifications";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setCert } from "../../../store/slices/certification";
import { getCertifications } from "../../../store/slices/certifications";
import { getCareTeam, getPatient } from "../../../store/slices/patient";
import { LoaderSpinner } from "../../UI";
import { FrequencyForm } from "./FrequencyForm";

type CertPopuProps = {
  haveFrequencies:boolean;
}

const CertPopup = ({haveFrequencies}:CertPopuProps) => {
  const [open, setOpen] = useState(false);
  const { patient } = useAppSelector((state) => state.patient);
  const [isLoading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const patientIsDischarged = patient?.status === "Discharged"

  useEffect(() => {
    if (open) {
      let temp: any = document.getElementById("root");
      temp.style.filter = "blur(2.5px)";
      document.body.classList.add('modal__freq');

    } else {
      let temp: any = document.getElementById("root");
      temp.style.filter = "none";
      document.body.classList.remove('modal__freq');
    }

    return () => {
      let temp: any = document.getElementById("root");
      temp.style.filter = "none";
      document.body.classList.remove('modal__freq');
    };
  }, [open]);

  const close = () => {
    dispatch(getPatient(patient.id || 0));
    dispatch(getCareTeam(patient.id || 0));
    setOpen(false);
  };
  

  const handleOpen = () => {
    setLoading(true);
    dispatch(getCertifications(patient.id))
      .then((result) => {
        const lastCertification:ICertification = Array.from(result.payload).slice(-1)[0] as ICertification;
        dispatch(setCert(lastCertification));
        setOpen(true)
      })
      .catch(() => setOpen(false))
      .finally(() => setLoading(false));
  };

  

  return (
    <>
      <button
        className="btn__primary custom_hover w-[139px]"
        disabled={isLoading || patientIsDischarged}
        style={{ marginRight: "16px", height: "35px" }}
        onClick={() => handleOpen()}
        
      >
        {isLoading ? (
          <LoaderSpinner
            action=""
            className="flex align-items-center justify-center"
          />
        ) : (
          `${haveFrequencies ? "Update Frequency" : "Add Frequency"}`
        )}
      </button>

      <Popup
        open={open}
        onClose={close}
        lockScroll
        modal
        closeOnDocumentClick={false}
      >
         <FrequencyForm
           close={close}
        />
      </Popup>
    </>
  );
};

export default CertPopup;
