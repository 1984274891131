import React from "react";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export const GreenUserLayout = ({ children }: Props) => {
  return (
    <div className="user__green-container px-8 mt-5 mx-10">{children}</div>
  );
};
