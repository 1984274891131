// import * as chatService from "./util/chat";

// export function getUserToken(userData): string {
//     return getUserObj() === null ? "" : getUserObj().token;
//   }

//   export function getUserToken(): string {
//     let data: any = sessionStorage.getItem("userData");
//     if (typeof data === "undefined" || data === null){
//         return ""
//     }
//       else { return JSON.parse(data) }
//   }

import _ from "lodash";
import moment from "moment";
import { ME } from "./Api";
declare global {
  interface Window {
    google: any;
    runConfig: any;
    _x: any;
  }
}

export const HourOptions = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24,
];

export const HOURS = [
  "00:00 - 02:00",
  "02:00 - 04:00",
  "04:00 - 06:00",
  "06:00 - 08:00",
  "08:00 - 10:00",
  "10:00 - 12:00",
  "12:00 - 14:00",
  "14:00 - 16:00",
  "16:00 - 18:00",
  "18:00 - 20:00",
  "20:00 - 22:00",
  "22:00 - 00:00",
];

export const WEEKDAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const MONTHS: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const IsStaging: boolean =
  window.location.href.includes("apex-admin.netlify");

// TODO: Fix this before deploy
// export const BASE_URL = IsStaging
//   ? "https://apex-api.npit.info/api"
//   : "https://api.logicly.ai/api";

export const RunConfig = window.runConfig;

export const SAMPLEPATIENTCSV: string = `${process.env.REACT_APP_API_URL}/PatientProfile/csvExample`;

window.google = window.google || {};

export let GOOGLE = window.google;

function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function readFromLocalStorage(key: string) {
  const data = sessionStorage.getItem(key);

  if (!data) return null;

  return isJson(data) ? JSON.parse(data) : data;
}

export function getUserToken(): string {
  return getUserObj() ? getUserObj().token : "";
}

export function getUserObj() {
  return readFromLocalStorage("userData_Apex");
}

export function updateUserInSessionStotage<I>(key: string, value: I) {
  const user = getUserObj();
  const newUser = { ...user, [key]: value };
  setLocalStorage("userData_Apex", JSON.stringify(newUser));
}

export function updateNewUserInSessionStotage<I>(
  key: string,
  value: I,
  user: any
) {
  const newUser = { ...user, [key]: value };
  setLocalStorage("userData_Apex", JSON.stringify(newUser));
}

export const ValueFromUserData = (key: string) => {
  let UserData: any = getUserObj();
  if (!UserData) {
    return "";
  }
  return UserData[key];
};

export function setLocalStorage(key: string, value: string) {
  sessionStorage.setItem(key, value);
  return true;
}

export function ChatLogin(CallBack: any = undefined) {
  let rcUserName = ValueFromUserData("rcUserName");
  let rcPassword = ValueFromUserData("rcPassword");
  if (!rcPassword || !rcUserName) {
    return;
  }
  let obj = {
    user: rcUserName,
    password: rcPassword,
    // user: ValueFromUserData("fullName"),
    // password: ValueFromUserData("id")
  };

  // chatService.login(obj, CallBack);
}

export function IsAdmin() {
  let temp: any = ValueFromUserData("roleName");
  return temp === "ADMIN";
}

export function IsClinician() {
  let temp: any = ValueFromUserData("roleName");
  return temp === "USER";
}

export function IsSuperAdmin() {
  let temp: any = ValueFromUserData("roleName");
  return temp === "SUPERADMIN";
}

export function getRole(roleId: any) {
  let obj: any = {};

  if (roleId === 1) {
    obj.role = "Admin";
    obj.color = "purple_pill";
    return obj;
  } else if (roleId === 2) {
    obj.role = "User";
    obj.color = "yellow_pill";
    return obj;
  }
  // else if (roleId === 0) {
  //   obj.role = "Patient";
  //   obj.color = "green_pill";
  //   return obj;
  // }
  else {
    obj.role = "N/A";
    obj.color = "gray_pill";
    return obj;
  }
}

export const getDate = (val: any, _format: string = "ll") => {
  if (val && new Date(val).getTime() > 0) {
    return moment(val).utcOffset(new Date().getTimezoneOffset()).format(_format);
  }
  return "N/A";
};

export function FormatDate(date: any, _format: string = "YYYY-MM-DD") {
  return moment(date).utc().format(_format);
}

export function FormatToLocalDateTime(date: any, _format: string = "MM/DD/y hh:mm A") {
  return moment(date).format(_format);
}
export function GetParamsFromSearch(query: string) {
  let temp = new URLSearchParams(query);
  return temp;
}

export function getColor(type: any) {
  if (type === undefined) return "green";
  if (type === "Recert") return "#d40000";
  if (type === "Discharge") return "#edd300";
  if (type === "Evaluation") return "#ba96d7";
  if (type === "30DRE") return "#759ae0";
  if (type === "RoutineVisit") return "rgb(125, 184, 133)";
}

export async function navigateToGoogleMaps(
  lat: number,
  long: number,
  sourceLat: any = ValueFromUserData("lat"),
  sourceLong: any = ValueFromUserData("long")
) {
  // navigator.geolocation.getCurrentPosition(
  //   async pos => {
  //

  // let startLat = pos['coords']['latitude']
  // let startLong = pos['coords']['longitude']

  window.open(
    "https://maps.google.com/maps?saddr=" +
      sourceLat +
      "," +
      sourceLong +
      "&daddr=" +
      lat +
      "," +
      long +
      "&amp;ll="
  );
  //   },
  //   () => {}
  // )
}

export const isNullOrEmpty = (str: any) => {
  let returnValue = false;
  if (
    !str ||
    str == null ||
    str === "null" ||
    str === "" ||
    str === "{}" ||
    str === "undefined" ||
    str === undefined ||
    str.length === 0
  ) {
    returnValue = true;
  }
  return returnValue;
};

export async function GetCurrentCoordinates(CallBack: any) {
  navigator.geolocation.getCurrentPosition(
    async (pos) => {
      CallBack(pos.coords);
    },
    (err: any) => {
      CallBack(err);
    }
  );
}

export async function SetCookie(
  cookie_key: string,
  data: string,
  expiresIn: number // in ms
) {
  // SET COOKIE //
  document.cookie =
    `${cookie_key} = ` +
    data +
    "; expires=" +
    new Date(new Date().getTime() + expiresIn).toUTCString() +
    ";";
}

export const GetCookieValue = (Key: string) => {
  let val = document.cookie.match("(^|[^;]+)\\s*" + Key + "\\s*=\\s*([^;]+)");
  return val ? val.pop() : "";
};

let _alert: any = undefined;
export const SetAlertMethod = (alertMethod: any) => {
  if (!_alert) {
    _alert = alertMethod;
  }
};

export const ShowAlert = (message: any, type: string = "success") => {
  if (_alert) {
    if (type === "error") {
      _alert.error(message);
    } else {
      _alert.show(message);
    }
  }
};

export const SORT = (
  column: any,
  idx: number,
  OriginalOrder: any[],
  CB: any = () => {}
) => {
  // _.sortBy default sort is asc
  let newSortState: string = "";
  let SORTED: any[] = _.sortBy(OriginalOrder, (o) => {
    if (column.sortType === "date") {
      return new Date(o[column.accessor]);
    } else {
      return o[column.accessor];
    }
  });

  if (column.sortState === "asc") {
    newSortState = "desc";
    SORTED.reverse();
  } else if (column.sortState === "desc") {
    newSortState = "";

    SORTED = [...OriginalOrder];
  } else {
    newSortState = "asc";
    // do nothing
  }

  if (CB) {
    CB(SORTED, newSortState, idx);
  }
};

export const GetAccessRoleWise = (level: any) => {
  if (level === 0) {
    return "SUPERADMIN";
  } else if (level === 1) {
    return "ADMIN";
  } else if (level === 2) {
    return "ADMIN|USER|SN|OT|PT|SLP";
  } else if (level === 3) {
    return "ADMIN|USER|SN|OT|PT|SLP";
  } else if (level === 4) {
    return "ADMIN|USER|SN|OT|PT|SLP|MSW|OTA|PTA|AID";
  }
};

export const UTCNow = () => {
  return moment(new Date()).format("YYYY-MM-DD") + "T00:00:00.000Z";
};

export const ManagePatientAllowed = () => {
  const role: string = ValueFromUserData("roleName");
  const roles = ["ADMIN", "PT", "OT", "SN", "SLP", "USER"];

  return role && roles.includes(role.toUpperCase());
};

export const UpdateUserDataInLocal = async () => {
  let _me: any = await ME();
  if (_me.data && _me.status === 200) {
    delete _me.data.password;
    setLocalStorage("userData_Apex", JSON.stringify(_me.data));
  }
};

export const ReduceString = (text: any) => {
  if (typeof text !== "string") return "";
  return text.toLowerCase().replace(/ /g, "");
};

export const logs = (...logs: any) => {
  if (process.env.NODE_ENV === "development") {
    console.log(logs);
  }
};
