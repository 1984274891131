import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useField } from "formik";
import React, { useState } from "react";
interface Props {
  label: string | JSX.Element;
  name: string;
  type?: "text" | "email" | "password";
  placeholder?: string;
  icon?: IconProp;
  sicon?: IconProp;
  isPlan?: boolean;
  [x: string]: any;
}

export const UserInput = ({
  label,
  isDisabled = false,
  isPlan = false,
  ...props
}: Props) => {
  const [field, meta] = useField(props);

  const isError = meta.error?.length ?? 0;

  const okField = meta.touched && isError === 0;
  const errorField = meta.touched && meta.error;

  return (
    <div className={`text__input user__input`}>
      {isDisabled ? (
        <>
          <label htmlFor={props.id || props.name}>{label}</label>

          <input
            className="input-without-border pl-8"
            {...field}
            {...props}
            disabled
          />
        </>
      ) : (
        <>
          <label htmlFor={props.id || props.name}>
            {label}
            {props.span ? (
              <span className="span-required">{props.span}</span>
            ) : (
              ""
            )}
          </label>

          {props.icon && (
            // <i className={`${props.icon} ${meta.error && "icon__error"}`}></i>
            <FontAwesomeIcon
              className={`user-icon-position ${meta.error && "icon__error"}`}
              icon={props.icon}
            />
          )}
          <input
            className={`text-input user-input ${errorField && "input__error"} ${
              !props.icon ? "pl-8" : "pl-[38px]"
            }  ${okField && "input-success-border"} ${isPlan && "isPlan"}`}
            {...field}
            {...props}
            type={props.type ?? "text"}
          />
          {props.sicon && (
            <FontAwesomeIcon
              className={`user-icon-position-show ${
                meta.error && "icon__error"
              }`}
              icon={props.sicon}
            />
          )}
        </>
      )}
    </div>
  );
};
