import { CButton } from "@coreui/react";
import {
  faClose,
  faEnvelope,
  faLockKeyhole,
  faEllipsisVertical,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "./../../../interceptors/axios-config";
import { Formik, Form } from "formik";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GoogleLoginButton,
  LinkedInLoginButton,
  FacebookLoginButton,
} from "react-social-login-buttons";
import Popup from "reactjs-popup";
import {
  LoginSocialGoogle,
  IResolveParams,
  LoginSocialLinkedin,
  LoginSocialFacebook,
} from "reactjs-social-login";
import * as Yup from "yup";
import { TermsConditions } from "../../../components/auth/TermsConditions";
import { LoaderSpinner } from "../../../components/UI";
import {
  SelectVisitColor,
  UploadAvatarModal,
  UserInput,
  UserPasswordInput,
} from "../../../components/users";
import DropdownsUser from "../../../components/users/DropdownsUser";
import AddressInput from "../../../Controls/AddressInput";
import { LoaderComponent } from "../../../Controls/Loader";
import {
  getLenghtObj,
  initialVisitColors,
  WORKHOURS,
  FREQUENCYFORMAT,
  convertTime12to24,
  WORKINGDAYS,
  parseUserRol,
  nameVisitColors,
  convertTime24to12,
  VISITLENGTHS,
  DISCIPLINE,
} from "../../../helpers/globals";
import { useAsync } from "../../../hooks/useAsync";
import { useClickOutSide } from "../../../hooks/useClickOutSide";
import { IResponseValue } from "../../../interfaces";
import { UserEmptyState, IUserInfo } from "../../../models";
import { userServices } from "../../../Services/user.services";
import {
  ValueFromUserData,
  getUserObj,
  setLocalStorage,
  IsAdmin,
  IsSuperAdmin,
  ShowAlert,
  isNullOrEmpty,
} from "../../../Services/utility";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { linkSocialAccount } from "../../../store/slices/auth";
import { selectIsLoading } from "../../../store/slices/notification/notificationSlice";
import { GreenUserLayout, UserErrorContainer } from "./Components";
import { useLocation } from "react-router-dom";
import checked_visit from "../../../assets/icons/checked-visit.png";
import checked_visit_black from "../../../assets/icons/check-mark-icon.png";

interface UserId {
  user_id: number;
}

interface VisitColor {
  id: number;
  color: string;
  isSelected: boolean;
}

const UserProfile: React.FC<any> = () => {
  const [otherError, setOtherError] = useState("");
  const location = useLocation();
  const isProfilePath: boolean = location.pathname.split("profile").length > 1;

  const isLoading = useAppSelector(selectIsLoading);
  // const dispatch = useAppDispatch();
  const [user, setUser] = useState(UserEmptyState);
  const [initialUser, setInitialUser] = useState({});
  const navigate = useNavigate();
  const params: any = useParams();
  // const [isDetail, setIsDetail] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);

  const initialOpenVisitiColorModal = {
    open: false,
    name: "",
    id: 0,
  };
  const [openVisitColorModal, setOpenVisitColorModal] = useState(
    initialOpenVisitiColorModal
  );
  const [acceptBeforeContinue, setAcceptBeforeContinue] = useState(false);
  const [aseptTermsBeforContinue, setAseptTermsBeforeContinue] =
    useState(false);
  const userId: UserId = getLenghtObj(params) > 0 ? params : { user_id: 0 };
  const [isTrySubmit, setIsTrySubmit] = useState(false);
  const isAddMode: boolean = userId.user_id <= 0;
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [valuesVisitColor, setValuesVisitColor] = useState({
    admissionSessionColor: initialVisitColors,
    evaluationSessionColor: initialVisitColors,
    dischargeSessionColor: initialVisitColors,
    thirtyDayReEvalSessionColor: initialVisitColors,
    recertSessionColor: initialVisitColors,
    treatmentSessionColor: initialVisitColors,
    resumptionOfCareSessionColor: initialVisitColors,
    supervisorSessionColor: initialVisitColors,
  });
  const dispatch = useAppDispatch();

  // useEffect(() => {
  //   if (isAddMode) {
  //     setIsDetail(false);
  //   }
  // }, [isAddMode]);
  let [userLocation, setUserLocation] = useState({
    address: "",
    lat: 0,
    long: 0,
    cityName: "",
  });

  const [errorObject, setErrorObject] = useState({
    Address: 0,
  });
  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name"),
    lastName: Yup.string().required("Last Name"),
    email: Yup.string().email("Invalid email format").required("Email"),
    disciplineId: Yup.number().required("Disciplined"),
    weekVisitFormat: Yup.number().required("Week Visit Format"),
    admissionSessionLength: Yup.number().required("Admission"),
    address: Yup.string().required("Addresss"),
    dischargeSessionLength: Yup.number().required("Discharge"),
    evaluationSessionLength: Yup.number().required("Evaluation"),
    recertSessionLength: Yup.number().required("Recert"),
    resumptionOfCareSessionLength: Yup.number().required("Resumption"),
    thirtyDayReEvalSessionLength: Yup.number().required("30 Days Re-eval"),
    start: Yup.number().required("Start time"),
    end: Yup.number().required("End time"),
    treatmentSessionLength: Yup.number().required("Treatment"),
    password: Yup.string()
      .nullable()
      .min(6, "Password must be at least 6 characters"),

    confirmPassword: Yup.string()
      .nullable()
      .when("password", (password, schema) => {
        if (password) return schema.required("Confirm Password is required");
      })
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });
  const goToTop = () => {
    window.scrollTo({
      top: 150,
      behavior: "smooth",
    });
  };

  function onSubmit(fields: any, { setValues, setErrors }: any) {
    setErrors({});
    let { start, end } = fields;
    const startDate = WORKHOURS.filter(
      (value: any) => value.id === start,
      []
    )[0].name;
    const endDate = WORKHOURS.filter((value: any) => value.id === end, [])[0]
      .name;


    if (start >= end) {
      setErrors({
        start: "The start time cannot be greater than or equal to the end time",
      });
    } else {
      const frequencyFormat = FREQUENCYFORMAT.find(
        (element) => element.id === fields.weekVisitFormat
      )?.value;
      const workingdaysToNumber: number[] = workingDays.map(
        (working) => working.value
      );

      const finalUser: IUserInfo = {
        ...fields,
        password: fields.password ?? "",
        start: convertTime12to24(startDate),
        end: convertTime12to24(endDate),
        status: "Active",
        admissionSessionColor: valuesVisitColor.admissionSessionColor.find(
          (element) => element.isSelected
        )?.color,
        evaluationSessionColor: valuesVisitColor.evaluationSessionColor.find(
          (element) => element.isSelected
        )?.color,
        treatmentSessionColor: valuesVisitColor.treatmentSessionColor.find(
          (element) => element.isSelected
        )?.color,
        thirtyDayReEvalSessionColor:
          valuesVisitColor.thirtyDayReEvalSessionColor.find(
            (element) => element.isSelected
          )?.color,
        resumptionOfCareSessionColor:
          valuesVisitColor.resumptionOfCareSessionColor.find(
            (element) => element.isSelected
          )?.color,
        recertSessionColor: valuesVisitColor.recertSessionColor.find(
          (element) => element.isSelected
        )?.color,
        dischargeSessionColor: valuesVisitColor.dischargeSessionColor.find(
          (element) => element.isSelected
        )?.color,
        supervisorSessionColor: valuesVisitColor.supervisorSessionColor.find(
          (element) => element.isSelected
        )?.color,
        weekVisitFormat: frequencyFormat,
        workingDays: workingdaysToNumber,
      };

      if (isAddMode) {
        createUser(finalUser, setErrors);
      } else {
        updateUser(userId.user_id, finalUser, setValues, setErrors);
      }
    }
  }

  const createUser = async (user: IUserInfo, setErrors: any) => {
    const response = (await userServices().addUser(
      user,
      avatar
    )) as IResponseValue;

    if (response.status === 200) {
      navigate("/users");
    } else if (
      response.value ===
      "There is already a user/agency registered with this email address."
    ) {
      setErrors({ email: "Email is already taken" });
    } else if (
      response.value ===
        "Object reference not set to an instance of an object." ||
      response.value === "A zip code cannot be extracted from this address."
    ) {
      setErrors({ address: "Invalid address" });
    } else {
      setOtherError(response.value);
    }
  };

  const updateUser = async (
    id: any,
    userToUpdate: IUserInfo,
    setValues: any,
    setErrors: any
  ) => {
    const response = (await userServices().updateUser(
      id,
      userToUpdate,
      newAvatar,
      avatar
    )) as IResponseValue;
    if (response.status === 200) {
      const firstName: string = response.value.firstName;
      const lastName: string = response.value.lastName;
      const fullName: string = firstName + lastName;
      if (parseInt(id) === ValueFromUserData("id")) {
        const userLogged = getUserObj();

        const userAPEX = {
          ...userLogged,
          address: response.value.address,
          aseptTerms: response.value.aseptTerms,
          email: response.value.aseptTerms.email,
          firstName: firstName,
          fullName: fullName,
          lastName: lastName,
          lat: userLocation.lat,
          long: userLocation.long,
          roleId: response.value.disciplineId,
          roleName: response.value.discipline,
          weekVisitFormat: response.value.weekVisitFormat,
          userStartTime: response.value.start,
          userEndTime: response.value.end,
          visitType:response.value.visitType,
          workingDays:response.value.workingDays
        
        };
        

        setLocalStorage("userData_Apex", JSON.stringify(userAPEX));
      }
      if (isProfilePath) {
        const startDate = convertTime24to12(response.value.start);

        const endDate = convertTime24to12(response.value.end);

        const start = WORKHOURS.filter(
          (element: any) => element.name === startDate,
          []
        )[0].id;
        const end = WORKHOURS.filter(
          (element: any) => element.name === endDate,
          []
        )[0].id;
        
        setValues({
          ...user,
          firstName:firstName,
          fullName: fullName,
          lastName: lastName,
          admissionSessionLength: response.value.admissionSessionLength,
          admissionSessionColor: response.value.admissionSessionColor,
          dischargeSessionColor: response.value.dischargeSessionColor,
          dischargeSessionLength: response.value.dischargeSessionLength,
          end:end,
          evaluationSessionColor: response.value.evaluationSessionColor,
          evaluationSessionLength: response.value.evaluationSessionLength,
          recertSessionColor: response.value.recertSessionColor,
          recertSessionLength: response.value.recertSessionLength,
          resumptionOfCareSessionColor: response.value.resumptionOfCareSessionColor,
          resumptionOfCareSessionLength: response.value.resumptionOfCareSessionLength,
          start: start,
          supervisorSessionColor: response.value.supervisorSessionColor,
          supervisorSessionLength: response.value.supervisorSessionLength,
          thirtyDayReEvalSessionColor: response.value.thirtyDayReEvalSessionColor,
          thirtyDayReEvalSessionLength: response.value.thirtyDayReEvalSessionLength,
          treatmentSessionColor: response.value.treatmentSessionColor,
          treatmentSessionLength: response.value.treatmentSessionLength,
          weekVisitFormat: FREQUENCYFORMAT.find(
            (element) => element.value === response.value.weekVisitFormat
          )?.id,

          address: response.value.address,
        });
        const userAvatar = user.avatar as any;
        if (user.avatar !== null) {
          setImage(
            `data:${userAvatar.contentType};base64,${userAvatar.fileContents}`
          );
          setAvatar(avatar);
        }

        goToTop();
        // setIsDetail(true);
      } else {
        navigate("/users");
      }
    } else if (
      response.value ===
      "There is already a user/agency registered with this email address."
    ) {
      setErrors({ email: "Email is already taken" });
    } else if (
      response.value ===
        "Object reference not set to an instance of an object." ||
      response.value === "A zip code cannot be extracted from this address."
    ) {
      setErrors({ address: "Invalid address" });
    } else {
      setOtherError(response.value);
    }
  };

  const handleChangeVisitColor = (color: string) => {
    const name = openVisitColorModal.name;

    const updateVisitColor = getArrayVisitColor(name).map((value) => {
      if (value.isSelected === true) {
        return {
          id: value.id,
          color: color,
          isSelected: value.isSelected,
        };
      } else {
        return {
          id: value.id,
          color: value.color,
          isSelected: false,
        };
      }
    });
    setValuesVisitColor({
      ...valuesVisitColor,
      [name]: updateVisitColor,
    });
    setOpenVisitColorModal(initialOpenVisitiColorModal);
  };

  const getArrayVisitColor = (name: string) => {
    const {
      admissionSessionColor,
      evaluationSessionColor,
      dischargeSessionColor,
      thirtyDayReEvalSessionColor,
      recertSessionColor,
      treatmentSessionColor,
      resumptionOfCareSessionColor,
      supervisorSessionColor,
    } = valuesVisitColor;

    switch (name) {
      case "admissionSessionColor":
        return admissionSessionColor;
      case "evaluationSessionColor":
        return evaluationSessionColor;
      case "dischargeSessionColor":
        return dischargeSessionColor;
      case "thirtyDayReEvalSessionColor":
        return thirtyDayReEvalSessionColor;
      case "recertSessionColor":
        return recertSessionColor;
      case "treatmentSessionColor":
        return treatmentSessionColor;
      case "resumptionOfCareSessionColor":
        return resumptionOfCareSessionColor;
      case "supervisorSessionColor":
        return supervisorSessionColor;
      default:
        return admissionSessionColor;
    }
  };

  const onClickIconColor = (element: VisitColor, name: string) => {
    const updateVisitColor = getArrayVisitColor(name).map((value: any) => {
      if (value.id === element.id) {
        return {
          id: element.id,
          color: element.color,
          isSelected: !element.isSelected,
        };
      } else {
        return {
          id: value.id,
          color: value.color,
          isSelected: false,
        };
      }
    });
    setOpenVisitColorModal({
      ...openVisitColorModal,
      name: name,
    });
    setValuesVisitColor({ ...valuesVisitColor, [name]: updateVisitColor });
  };

  const [workingDays, setWorkingDays] = useState(WORKINGDAYS);

  const currentColorAdmission: string =
    getArrayVisitColor(openVisitColorModal.name).find(
      (element) => element.isSelected
    )?.color ?? "#B42318";

  const defaultColorAdmissionElement = getArrayVisitColor(
    openVisitColorModal.name
  ).find((element) => element.isSelected);
  const defaultColorAdmission =
    initialVisitColors.find(
      (color) => color.id === defaultColorAdmissionElement?.id
    )?.color ?? "#B42318";
  const isSomeOneColorSelected =
    getArrayVisitColor(openVisitColorModal.name).filter(
      (element) => element.isSelected === true
    )[0]?.id > 0;

  const handleWeekdayUser = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { id, value } = e.target as HTMLInputElement;

    const searchWorkingDay = workingDays.map((working) => {
      if (working.id === parseInt(id)) {
        return {
          id: working.id,
          value: parseInt(value) === 1 ? 0 : 1,
          label: working.label,
        };
      } else {
        return working;
      }
    });

    setWorkingDays(searchWorkingDay);
  };
  const classWeekdayButton = (value: number) =>
    value === 1
      ? "weekday-user-button weekday-selected"
      : "weekday-user-button";

  const [roles, setRoles] = useState<[]>([]);

  const isAdministrator = IsAdmin() ?? IsSuperAdmin();
  const userCanEdit = () =>
    isAdministrator || userId.user_id == ValueFromUserData("id");

  const [showAvatarModal, setShowAvatarModal] = useState(false);

  const modalRef = useClickOutSide(() => {
    setShowAvatarModal(false);
  });
  const modalDelete = useClickOutSide(() => {
    setOpenDelete(false);
  });
  const [picture, setPicture] = useState(null);
  const [avatar, setAvatar] = useState<any>(null);
  const [newAvatar, setNewAvatar] = useState(false);

  const cleanup = () => {
    // if (!isDetail) {
      setPicture(null);
      setAvatar(null);
      setNewAvatar(true);
    // }
  };
  const setImage = (newImage: any) => {
    if (picture) {
      cleanup();
    }
    setPicture(newImage);
  };

  const setRolesAsync = (values: any) => {
    let roles = values.data.items
      .filter((role: any) => role.name !== DISCIPLINE.USER)
      .map((role: any) => ({
        id: role.id,
        name: parseUserRol(role.name),
      }));
    setRoles(roles);
  };
  const successAsync = () =>
    Axios.get<any>(`${process.env.REACT_APP_API_URL}/Role/GetAll`);

  useAsync(successAsync, setRolesAsync);

  const onLoginStart = useCallback(() => {
    console.log("login started");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    console.log("logout success");
  }, []);

  return (
    <Formik
      initialValues={UserEmptyState}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {function UserForm({
        errors,
        setFieldValue,
        setFieldTouched,
        values,
        setValues,
      }) {
        useEffect(() => {
          setFieldValue("aseptTerms", acceptTerms);
        }, [acceptTerms]);

        useEffect(() => {
          !isAddMode && getUser();
        }, []);

        const getUser = async () => {
          const response = await userServices().getUserBydId(userId.user_id);

          if (response.status === 200) {
            setInitialUser(response.value);
            const user: IResponseValue = response as IResponseValue;

            const fields = [
              "aseptTerms",
              "address",
              "admissionSessionLength",
              "dischargeSessionLength",
              "discipline",
              "disciplineId",
              "email",
              "end",
              "evaluationSessionLength",
              "firstName",
              "id",
              "lastName",
              "phoneNumber",
              "recertSessionLength",
              "resumptionOfCareSessionLength",
              "start",
              "status",
              "thirtyDayReEvalSessionLength",
              "treatmentSessionLength",
              "supervisorSessionLength",
              "weekVisitFormat",
            ];
            const newVisitColors: any = {
              admissionSessionColor: [],
              evaluationSessionColor: [],
              treatmentSessionColor: [],
              thirtyDayReEvalSessionColor: [],
              resumptionOfCareSessionColor: [],
              recertSessionColor: [],
              dischargeSessionColor: [],
              supervisorSessionColor: [],
            };
            nameVisitColors.forEach((visitColors) => {
              const findColor = getArrayVisitColor(visitColors.name).find(
                (element) => {
                  return element.color === user.value[visitColors.name];
                }
              );

              if (findColor) {
                const updateVisitColorFromAPI = getArrayVisitColor(
                  visitColors.name
                ).map((value) => {
                  if (value.color === user.value[visitColors.name]) {
                    return {
                      id: value.id,
                      color: user.value[visitColors.name],
                      isSelected: true,
                    };
                  } else {
                    return {
                      id: value.id,
                      color: value.color,
                      isSelected: false,
                    };
                  }
                });
                newVisitColors[visitColors.name] = updateVisitColorFromAPI;
              } else {
                const updateVisitColorFromAPI = getArrayVisitColor(
                  visitColors.name
                ).map((value) => {
                  if (value.id === 1) {
                    return {
                      id: value.id,
                      color: user.value[visitColors.name],
                      isSelected: true,
                    };
                  } else {
                    return {
                      id: value.id,
                      color: value.color,
                      isSelected: false,
                    };
                  }
                });

                newVisitColors[visitColors.name] = updateVisitColorFromAPI;
              }
            });

            setValuesVisitColor(newVisitColors);

            const startDate = convertTime24to12(user.value.start);

            const endDate = convertTime24to12(user.value.end);

            const start = WORKHOURS.filter(
              (element: any) => element.name === startDate,
              []
            )[0].id;
            if (
              userId.user_id == ValueFromUserData("id") &&
              user.value.address === null
            ) {
              setAcceptBeforeContinue(true);
            }

            if (
              userId.user_id == ValueFromUserData("id") &&
              !user.value.aseptTerms
            ) {
              setAseptTermsBeforeContinue(true);
            }

            const end = WORKHOURS.filter(
              (element: any) => element.name === endDate,
              []
            )[0].id;
            const wokingDaysArray = user.value.workingDays;

            const workingDaysAPI = workingDays.map((working) => {
              return { ...working, value: wokingDaysArray[working.id - 1] };
            });
            setWorkingDays(workingDaysAPI);

            if (user.value.avatar !== null) {
              setImage(
                `data:${user.value.avatar.contentType};base64,${user.value.avatar.fileContents}`
              );
              setAvatar(avatar);
            }
            const userField = {
              ...user.value,
              start: start,
              end: end,
              address: user.value.address ?? "",
              weekVisitFormat: FREQUENCYFORMAT.find(
                (element) => element.value === user.value.weekVisitFormat
              )?.id,
            };

            setUser({ ...userField, avatar: user.value.avatar });
            fields.forEach((field: string) => {
              setFieldValue(field, userField[field], false);
            });
            setUserLocation({
              address: user.value.address,
              lat: user.value.lat,
              long: user.value.long,
              cityName: user.value.cityName,
            });
          }
        };

        const isError =
          isTrySubmit && Object.keys(errors).length > 0 ;

        const handleCancelEvent = () => {
          navigate("/users");
          // if (isDetail && isProfilePath) {
          //   goToTop();
          // } else if (isDetail) {
          //   navigate("/users");
          // } else if (isAddMode) {
          //   navigate("/users");
          // } else {
          //   setValues(user);
          //   const userAvatar = user.avatar as any;
          //   if (user.avatar !== null) {
          //     setImage(
          //       `data:${userAvatar.contentType};base64,${userAvatar.fileContents}`
          //     );
          //     setAvatar(avatar);
          //   }

          //   goToTop();
          //   setIsDetail(true);
          // }
        };

        const handleDeletePatient = async () => {
          setOpenDelete(false);

          const response = await userServices().deleteUser(userId.user_id);

          if (response?.status === 200) {
            ShowAlert("Succesfull deletion");
            navigate("/users");
          } else {
            ShowAlert(response?.value, "error");
          }
        };
        const fullName =
          (values.firstName + " " + values.lastName).length > 15
            ? (values.firstName + " " + values.lastName).substring(0, 15) +
              "..."
            : values.firstName + " " + values.lastName;

        const isMounted = useRef(true);

        useEffect(() => {
          return () => {
            isMounted.current = false;
          };
        }, []);
        const handleAseptTerms = () => {
          const userToUpdate = initialUser as any;
          const finalUser: IUserInfo = {
            ...userToUpdate,
            aseptTerms: true,
          };
          const userObj = getUserObj();
          const userSession = {
            ...userObj,
            aseptTerms: true,
          };

          userServices()
            .updateUser(userId.user_id, finalUser, newAvatar, avatar)
            .then((result) => {
              if (result.status === 200) {
                setAseptTermsBeforeContinue(false);
                setLocalStorage("userData_Apex", JSON.stringify(userSession));
              }
            })
            .catch((error) => {
              ShowAlert(error, "error");
            });
        };
        return !isLoading || acceptBeforeContinue ? (
          <Form className="pl-[1.5rem] pr-[1.5rem]">
            <Popup
              open={acceptBeforeContinue}
              closeOnDocumentClick={false}
              onClose={() => setAcceptBeforeContinue(false)}
            >
              <div
                className={"certification__container delete-cert-form"}
                style={{ width: "439px", height: "237px" }}
              >
                <div className="flex place-content-between items-center pb-[16.5px] border-b border-[#cdcac6] mb-[24px] h-[41px]">
                  <h1 className="certification__title">Information</h1>
                </div>

                <div className="flex flex-col justify-between content-between">
                  <div className="delete-cert mb-[10px]">
                    <div className="text">
                      Before you can continue, you need to provide your profile
                      information.
                    </div>
                  </div>
                  <div className="flex w-full place-content-between gap-x-6 mt-[10px]">
                    <button
                      onClick={() => setAcceptBeforeContinue(false)}
                      className="auth__green-btn-signup w-full"
                    >
                      Accept
                    </button>
                  </div>
                </div>
              </div>
            </Popup>
            <Popup
              open={aseptTermsBeforContinue}
              closeOnDocumentClick={false}
              onClose={() => setAseptTermsBeforeContinue(false)}
            >
              <div
                className={"certification__container delete-cert-form"}
                style={{ width: "439px", height: "237px" }}
              >
                <div className="flex place-content-between items-center pb-[16.5px] border-b border-[#cdcac6] mb-[24px] h-[41px]">
                  <h1 className="certification__title">Information</h1>
                </div>

                <div className="flex flex-col justify-between content-between">
                  <div className="delete-cert mb-[10px]">
                    <div className="text">
                      Before you can continue, please read and accept{" "}
                      <a
                        href="https://www.logicly.ai/#/terms"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Service and
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://www.logicly.ai/#/privacy"
                        className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                  <TermsConditions
                    setAcceptTerms={setAcceptTerms}
                    acceptTerms={acceptTerms}
                  />
                  <div className="flex w-full place-content-between gap-x-6 mt-[10px]">
                    <button
                      onClick={handleAseptTerms}
                      disabled={acceptTerms === false}
                      className="auth__green-btn-signup w-full"
                    >
                      {isLoading ? (
                        <LoaderSpinner className="items-center" />
                      ) : (
                        "Accept"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </Popup>

            {/* </Popup> */}

            <Popup
              open={showAvatarModal}
              onClose={() => setShowAvatarModal(false)}
              lockScroll
              closeOnDocumentClick
            >
              <UploadAvatarModal
                modalRef={modalRef}
                show={showAvatarModal}
                onAccept={(value: string) => {
                  fetch(value)
                    .then((res) => res.blob())
                    .then((blob) => {
                      setAvatar(blob);
                    });
                  setImage(value);
                  setNewAvatar(true);
                }}
                onClose={() => setShowAvatarModal(false)}
                avatarOnLoad={picture}
              />
            </Popup>
            <Popup
              open={openDelete}
              closeOnDocumentClick
              lockScroll
              contentStyle={{
                width: "100%",
                height: "100%",
              }}
              onClose={() => {
                setOpenDelete(false);
              }}
            >
              <div
                className="flex w-[365px] h-[309px] delete_patient_popup bg-white"
                ref={modalDelete}
              >
                <div className="py-[32px] px-[24px] flex flex-col w-[100%] place-content-between">
                  <div>
                    <div className="flex flex-row">
                      <div className="flex justify-center basis-[95%]">
                        <h2 className="text-center ">Delete {fullName}</h2>
                      </div>
                      <div className="flex justify-end basis-[5%">
                        <button
                          title="close-user-button"
                          type="button"
                          onClick={() => setOpenDelete(false)}
                        >
                          <FontAwesomeIcon
                            icon={faClose}
                            className="modal-patient-button-close-bulk"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <h3 className="text-center mt-[24px]">Are you sure?</h3>
                    <p className="text-center mt-[24px]">
                      Deleting this user will remove user record and all
                      associated data. This <span>cannot</span> be undone.
                    </p>
                  </div>
                  <div className="flex place-content-between">
                    <button
                      className="pop_btn h-[35px] flex items-center place-content-center px-[50px] rounded-[6px]"
                      onClick={() => {
                        setOpenDelete(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="pop_btn pop_delete_btn h-[35px] flex items-center place-content-center px-[50px] rounded-[6px]"
                      onClick={handleDeletePatient}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </Popup>
            <GreenUserLayout>
              <div className="user__avatar_container">
                <img
                  id="picture"
                  src={picture ?? "avatars/default-avatar.svg"}
                  className="avatar__image"
                  alt="user-avatar"
                />
              </div>
            </GreenUserLayout>
            <div className="px-8 mx-10 bg-white">
              <div className="d-flex justify-content-end bg-white pt-5">
                <div>
                  {
                  // userCanEdit() &&
                  // (values.status === "Active" || values.status === "") ? (
                  //   // <button
                  //   //   type="button"
                  //   //   onClick={() => {
                  //   //     setIsDetail(false);
                  //   //   }}
                  //   //   className="user-edit-tab-button"
                  //   //   style={{ width: "58px" }}
                  //   // >
                  //   //   Edit
                  //   // </button>
                  // ) : 
                  !isAddMode && userCanEdit() ? (
                    <button
                      type="button"
                      onClick={() => {
                        setOpenDelete(true);
                      }}
                      className="user-delete-tab-button"
                    >
                      Delete
                    </button>
                  ) : values.status === "Inactive" ? (
                    <button
                      type="button"
                      onClick={() => {
                        setOpenDelete(true);
                      }}
                      className="user-delete-tab-button"
                    >
                      Delete
                    </button>
                  ) : (
                    <div className="p-8" />
                  )}
                </div>
              </div>
              {(isError || otherError.length > 0) && (
                <UserErrorContainer error={errors} otherError={otherError} />
              )}

              <section>
                <span className="mt-[40px] mb-[23px] px-[16px] user-setting-text">
                  User settings
                </span>
              </section>
              <hr />
              <section>
                <div className="flex place-content-between items-center py-[20px]">
                  <div className="w-[33.33%] px-[16px] col">
                    <div className="user-info-text mb-1 font-bold">
                      User info
                    </div>
                    <div className="user-info-text-secondary">
                      Name, email, discipline, address, timezone, include
                      weekend in weekview
                    </div>
                  </div>
                  <div className="w-[33.33%] px-[16px] patient-form">
                    <UserInput
                      label={<span>First name</span>}
                      name="firstName"
                      placeholder="Your first name"
                      // isDisabled={isDetail}
                      span="*"
                    />
                  </div>
                  <div className="w-[33.33%] px-[16px] patient-form">
                    <UserInput
                      label={<span>Last name</span>}
                      name="lastName"
                      placeholder="Your last name"
                      // isDisabled={isDetail}
                      span="*"
                    />
                  </div>
                </div>
                <div className="flex place-content-start items-start py-[20px] w-100">
                  <div className="w-[33.33%] px-[16px] col">
                    <div className="user-info-text mb-1" />
                    <div className="user-info-text-secondary" />
                  </div>
                  <div className="w-[33.33%] px-[16px] patient-form">
                    <UserInput
                      icon={faEnvelope}
                      label={<span>Email</span>}
                      name="email"
                      placeholder="Your email address"
                      // isDisabled={isDetail}
                      type="email"
                      span="*"
                    />
                      <p className="form-hint-text-user mt-2">
                        This is your login for Logicly
                      </p>
                  </div>
                  <div className="w-[33.33%] px-[16px] flex flex-column mb-3 patient-form">
                    <DropdownsUser
                      name="disciplineId"
                      value={values.disciplineId}
                      label="Discipline"
                      options={roles}
                      preText="Select your discipline"
                      onSelect={(value: any) => {
                        setFieldTouched("disciplineId", true);
                        setFieldValue("disciplineId", value.id, true);
                      }}
                      span="*"
                      // isDisabled={isDetail}
                    />
                  </div>
                </div>
              </section>
              {/* <hr /> */}
              <section>
                <div className="flex place-content-between items-center py-[20px]">
                  <div className="w-[33.33%] px-[16px] col">
                    <div className="user-info-text mb-1 font-bold">
                      Your photo
                    </div>
                    <div className="user-info-text-secondary">
                      This is your profile image. Your photo is never used for
                      any reason other than your account.
                    </div>
                  </div>
                  <div className="w-[33.33%] px-[16px]">
                    <div className="user__avatar_container_second">
                      <img
                        id="picture2"
                        src={picture ?? "avatars/default-avatar.svg"}
                        className="avatar__image_second"
                        alt="user-avatar-second"
                      />
                    </div>
                  </div>
                  <div className="w-[33.33%] px-[16px] ">
                    <div className="d-flex justify-content-end">
                      <CButton
                        variant="outline"
                        className="remove-avatar-button"
                        onClick={() =>  cleanup()}
                      >
                        Remove
                      </CButton>
                      <CButton
                        variant="outline"
                        className="update-avatar-button"
                        onClick={() =>  setShowAvatarModal(true)}
                      >
                        Update
                      </CButton>
                    </div>
                  </div>
                </div>
              </section>
              <hr />

              <section>
                <div className="d-flex justify-content-between pb-3 pt-3">
                  <div className="w-[33.33%] px-[16px] col">
                    <div className="user-info-text my-6 font-bold">
                      {" "}
                      Home address
                    </div>
                    <div className="user-info-text-secondary">
                      We use this to calculate your daily route.Your information
                      is never shared.
                    </div>
                  </div>
                  <div className="w-[33.33%] px-[16px] col">
                    <div className="my-6 patient-form">
                      {/* {isDetail ? (
                        <UserInput
                          label={<span>Home Address</span>}
                          name="address"
                          placeholder="Home Address"
                          isDisabled={true}
                          type="text"
                          span="*"
                        />
                      ) : ( */}
                        <AddressInput
                          id="address"
                          name="address"
                          Data={(data: any) => {
                            setFieldValue("address", data.address, false);

                            setErrorObject({
                              ...errorObject,
                              Address: 0,
                            });

                            setUserLocation({
                              address: data.address,
                              lat: data.lat,
                              long: data.long,
                              cityName: data.vicinity || data.address,
                            });
                          }}
                          address={userLocation.address}
                          UnsetAddress={() => {
                            setUserLocation({
                              address: "",
                              cityName: "",
                              lat: 0,
                              long: 0,
                            });
                          }}
                        />
                      {/* )} */}

                      {errorObject.Address === 1 ? (
                        <div className="controller-outer">
                          <div className="err-block" />
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="w-[33.33%] px-[16px] col">
                    <div className="my-6" />
                  </div>
                </div>
              </section>
              <hr />
              {userId.user_id > 0 && (
                <>
                  <section>
                    <div className="flex place-content-start items-start py-[30px]">
                      <div className="w-[33.33%] px-[16px] col">
                        <div className="user-info-text mb-1 font-bold">
                          Password
                        </div>
                        <div className="user-info-text-secondary">
                          Change your password
                        </div>
                      </div>
                      <div className="w-[33.33%] px-[16px] patient-form">
                        <UserPasswordInput
                          label={<span>Password</span>}
                          icon={faLockKeyhole}
                          name="password"
                          placeholder="Enter your password"
                          type="password"
                          // isDisabled={isDetail}
                          span="*"
                          field="password"
                          setFieldValue={setFieldValue}
                        />

                        <br />
                        <br />
                        <UserPasswordInput
                          label={<span>Confirm password</span>}
                          icon={faLockKeyhole}
                          name="confirmPassword"
                          placeholder="Enter your password"
                          // isDisabled={isDetail}
                          span="*"
                          type="password"
                          field="confirmPassword"
                          setFieldValue={setFieldValue}
                        />
                      </div>
                      <div className="w-[33.33%] px-[16px] col">
                        <div className="user-info-text mb-1 font-bold" />
                        <div className="user-info-text-secondary" />
                      </div>
                    </div>
                  </section>
                  <hr />
                </>
              )}

              <section>
                <div className="flex place-content-start items-start py-[30px]">
                  <div className="w-[33.33%] px-[16px] col">
                    <div className="user-info-text mb-1 font-bold">
                      Plan info
                    </div>
                    <div className="user-info-text-secondary">
                      You can change this in billing
                    </div>
                  </div>
                  <div className="w-[33.33%] px-[16px] patient-form">
                    <UserInput
                      label={<span>Plan tier</span>}
                      name="planInfo"
                      placeholder="Clinician"
                      // isDisabled={isDetail}
                      isPlan={true}
                      disabled
                    />
                  </div>

                  <div className="w-[33.33%] px-[16px] col">
                    <div className="d-flex justify-content-end">
                      <CButton
                        variant="outline"
                        className="change-user-plan"
                        // disabled={isDetail}
                      >
                        Change your plan
                      </CButton>
                    </div>
                  </div>
                </div>
              </section>

              <hr />

              <section>
                <div className="flex place-content-between items-start pt-[30px] pb-[5px]">
                  <div className="w-[33.33%] px-[16px] col">
                    <div className="user-info-text mb-1 font-bold">
                      Visit type
                    </div>
                    <div className="user-info-text-secondary">
                      Define default lengths of visit and the colors to use for
                      the calendar.
                    </div>
                  </div>
                  <div className="w-[33.33%] px-[16px] flex flex-column patient-form">
                    <DropdownsUser
                      name="admissionSessionLength"
                      value={values.admissionSessionLength}
                      label="Adminision"
                      options={VISITLENGTHS}
                      preText="Length of visit"
                      onSelect={(value: any) => {
                        setFieldTouched("admissionSessionLength", true);
                        setFieldValue("admissionSessionLength", value.id, true);
                      }}
                      span="*"
                      // isDisabled={isDetail}
                    />

                    <DropdownsUser
                      name="evaluationSessionLength"
                      value={values.evaluationSessionLength}
                      label="Evaluation"
                      options={VISITLENGTHS}
                      preText="Length of visit"
                      onSelect={(value: any) => {
                        setFieldTouched("evaluationSessionLength", true);
                        setFieldValue(
                          "evaluationSessionLength",
                          value.id,
                          true
                        );
                      }}
                      span="*"
                      // isDisabled={isDetail}
                    />
                    <DropdownsUser
                      name="treatmentSessionLength"
                      value={values.treatmentSessionLength}
                      label="Routine Visit"
                      options={VISITLENGTHS}
                      preText="Length of visit"
                      onSelect={(value: any) => {
                        setFieldTouched("treatmentSessionLength", true);
                        setFieldValue("treatmentSessionLength", value.id, true);
                      }}
                      span="*"
                      // isDisabled={isDetail}
                    />

                    <DropdownsUser
                      name="thirtyDayReEvalSessionLength"
                      value={values.thirtyDayReEvalSessionLength}
                      label="30 Day Re-Eval"
                      options={VISITLENGTHS}
                      preText="Length of visit"
                      onSelect={(value: any) => {
                        setFieldTouched("thirtyDayReEvalSessionLength", true);
                        setFieldValue(
                          "thirtyDayReEvalSessionLength",
                          value.id,
                          true
                        );
                      }}
                      span="*"
                      // isDisabled={isDetail}
                    />

                    <DropdownsUser
                      name="resumptionOfCareSessionLength"
                      value={values.resumptionOfCareSessionLength}
                      label="Resumption of care"
                      options={VISITLENGTHS}
                      preText="Length of visit"
                      onSelect={(value: any) => {
                        setFieldTouched("resumptionOfCareSessionLength", true);
                        setFieldValue(
                          "resumptionOfCareSessionLength",
                          value.id,
                          true
                        );
                      }}
                      span="*"
                      // isDisabled={isDetail}
                    />
                    <DropdownsUser
                      name="recertSessionLength"
                      value={values.recertSessionLength}
                      label="Recertification"
                      options={VISITLENGTHS}
                      preText="Length of visit"
                      onSelect={(value: any) => {
                        setFieldTouched("recertSessionLength", true);
                        setFieldValue("recertSessionLength", value.id, true);
                      }}
                      span="*"
                      // isDisabled={isDetail}
                    />

                    <DropdownsUser
                      name="dischargeSessionLength"
                      value={values.dischargeSessionLength}
                      label="Discharge"
                      options={VISITLENGTHS}
                      preText="Length of visit"
                      onSelect={(value: any) => {
                        setFieldTouched("dischargeSessionLength", true);
                        setFieldValue("dischargeSessionLength", value.id, true);
                      }}
                      span="*"
                      // isDisabled={isDetail}
                    />
                    <DropdownsUser
                      name="supervisorSessionLength"
                      value={values.supervisorSessionLength}
                      label="Supervisor "
                      options={VISITLENGTHS}
                      preText="Length of visit"
                      onSelect={(value: any) => {
                        setFieldTouched("supervisorSessionLength", true);
                        setFieldValue(
                          "supervisorSessionLength",
                          value.id,
                          true
                        );
                      }}
                      span="*"
                      // isDisabled={isDetail}
                    />
                  </div>
                  <div className="w-[33.33%] px-[16px] flex flex-column justify-end">
                    {nameVisitColors.map((arrayColors) => {
                      return (
                        <>
                          <div
                            className="flex justify-end gap-3 h-[42px] mb-[30px] items-center"
                            key={arrayColors.id}
                          >
                            {getArrayVisitColor(arrayColors.name).map(
                              (element) => {
                                return (
                                  <div
                                    key={element.id}
                                    className={`elipse__visit ${
                                      element.isSelected && "selected"
                                    }`}
                                    style={{ background: element.color }}
                                    onClick={() => {
                                      // if (!isDetail) {
                                        onClickIconColor(
                                          element,
                                          arrayColors.name
                                        );
                                      // }
                                    }}
                                  >
                                    {element.isSelected &&
                                    (!element.color ||
                                      element.color === "#FFFFFF" ||
                                      element.color === "#ffffff") ? (
                                      <img src={checked_visit_black} alt="" />
                                    ) : (
                                      element.isSelected && (
                                        <img src={checked_visit} alt="" />
                                      )
                                    )}
                                  </div>
                                );
                              }
                            )}
                            <button
                              title="open-visit-color-button"
                              type="button"
                              onClick={() => {
                                // if (!isDetail) {
                                  setOpenVisitColorModal({
                                    open: true,
                                    name: arrayColors.name,
                                    id: arrayColors.id,
                                  });
                                // }
                              }}
                            >
                              <FontAwesomeIcon
                                icon={faEllipsisVertical}
                                className="modal-patient-button-close-bulk"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                          <div className="relative">
                            <div
                              className={`visit-color-picker-modal 
                              ${
                              (  isSomeOneColorSelected &&
                                openVisitColorModal.open &&
                                arrayColors.id === openVisitColorModal.id )? "open" : "closed"
                                // !isDetail
                                  //  "open"
                                  // : "closed"
                              }`}
                            >
                              <SelectVisitColor
                                onClose={() =>
                                  setOpenVisitColorModal(
                                    initialOpenVisitiColorModal
                                  )
                                }
                                handleChangeVisitColor={handleChangeVisitColor}
                                initialColor={
                                  currentColorAdmission.startsWith("#")
                                    ? currentColorAdmission
                                    : "#B42318"
                                }
                                defaultColorAdmission={defaultColorAdmission}
                              />
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </section>
              <hr />
              <section>
                <div className="flex place-content-between items-start pt-[30px] pb-[5px]">
                  <div className="w-[33.33%] px-[16px] col">
                    <div className="user-info-text mb-1 font-bold">
                      Frequency format
                    </div>
                    <div className="user-info-text-secondary">
                      Define what format you use for frequencies
                    </div>
                  </div>
                  <div className="w-[33.33%] px-[16px] flex flex-column patient-form">
                    <DropdownsUser
                      name="weekVisitFormat"
                      value={values.weekVisitFormat}
                      label="Frequency format"
                      options={FREQUENCYFORMAT}
                      preText="Select format"
                      onSelect={(value: any) => {
                        setFieldTouched("weekVisitFormat", true);
                        setFieldValue("weekVisitFormat", value.id, true);
                      }}
                      span="*"
                      // isDisabled={isDetail}
                    />
                  </div>
                  <div className="w-[33.33%] px-[16px] flex flex-column"></div>
                </div>
              </section>
              <hr />
              <section>
                <div className="d-flex justify-content-start py-[20px]">
                  <div className="px-[16px] w-[35.35%] mt-[7px]">
                    <div className="user-info-text mb-1 font-bold">
                      Working days
                    </div>
                    <div className="user-info-text-secondary">
                      Select the days that you want Logicly to schedule visits
                      for you. These are typically deteremined by your home
                      health agency, ie M-F
                    </div>
                    <br />
                    <div className="user-info-text mb-1 mt-[28px] font-bold">
                      Working hours
                    </div>
                    <div className="user-info-text-secondary">
                      These are the hours that you want to work. Logicly will
                      only schedule visits during these hours. If length of time
                      between start and end are too short, Logicly will not be
                      able to schedule all visits.
                    </div>
                  </div>
                  <div className="px-[16px] col">
                    <div className="row mt-[27px]">
                      {workingDays.map((workingday) => {
                        return (
                          <button
                            id={workingday.id.toString()}
                            key={workingday.label}
                            className={classWeekdayButton(workingday.value)}
                            onClick={handleWeekdayUser}
                            type="button"
                            value={workingday.value}
                            // disabled={isDetail}
                          >
                            {workingday.label}
                          </button>
                        );
                      })}
                    </div>

                    <div className="row mt-[53px] patient-form">
                      <div style={{ width: "25%", marginRight: "25px" }}>
                        <DropdownsUser
                          name="start"
                          value={values.start}
                          label="Start time"
                          options={WORKHOURS}
                          preText="00:00 PM"
                          onSelect={(value: any) => {
                            setFieldTouched("start", true);
                            setFieldValue("start", value.id, true);
                            if (value.id >= parseInt(values.end)) {
                              setFieldValue("end", value.id + 1, true);
                            }
                            if (value.id === 17) {
                              setFieldValue("end", value.id, true);
                            }
                          }}
                          span="*"
                          // isDisabled={isDetail}
                          icon="fa-solid fa-clock user-icon-clock-position"
                          toUp={true}
                        />
                      </div>
                      <div style={{ width: "25%" }}>
                        <DropdownsUser
                          name="end"
                          value={values.end}
                          label="End time"
                          options={WORKHOURS.filter(
                            (work: any) => work.id > parseInt(values.start)
                          )}
                          preText="00:00 PM"
                          onSelect={(value: any) => {
                            setFieldTouched("end", true);
                            setFieldValue("end", value.id, true);
                          }}
                          span="*"
                          // isDisabled={isDetail}
                          icon="fa-solid fa-clock user-icon-clock-position"
                          toUp={parseInt(values.start) <= 10}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <hr />
              <section>
                <div className="flex place-content-start items-start py-[30px]">
                  <div className="w-[33.33%] px-[16px] col">
                    <div className="user-info-text my-6 font-bold">
                      {" "}
                      Social Media Info
                    </div>
                    <div className="user-info-text-secondary">
                      Link and integretate your social into a single account.
                      Once configured, buttons will be disable and you will be
                      able to login with the accounts.
                    </div>
                  </div>
                  <div className="w-[33.33%] px-[16px] col">
                    <LoginSocialGoogle
                      className={`mb-[16px] social-login social-google${
                        isNullOrEmpty(user.googleId) ? "" : " disable"
                      }`}
                      client_id={process.env.REACT_APP_GG_APP_ID || ""}
                      onLoginStart={onLoginStart}
                      redirect_uri={process.env.REACT_APP_REDIRECT_URI || ""}
                      scope="openid profile email"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                      onResolve={({ provider, data }: IResolveParams) => {
                        dispatch(
                          linkSocialAccount({
                            provider: provider,
                            accessToken: data?.access_token ?? "",
                            password: "",
                          })
                        ).then(({ type, error, payload }: any) => {
                          if (type === "auth/link-social/rejected") {
                            ShowAlert(error.message, "error");
                            return;
                          }
                          getUser().then(() =>
                            ShowAlert("Account linked successfuly")
                          );
                        });
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <GoogleLoginButton text="Sign in with Google" />
                    </LoginSocialGoogle>

                    <LoginSocialLinkedin
                      className={`mb-[16px] social-login social-linkedin${
                        isNullOrEmpty(user.linkedId) ? "" : " disable"
                      }`}
                      client_id={process.env.REACT_APP_LINKEDIN_APP_ID || ""}
                      client_secret={
                        process.env.REACT_APP_LINKEDIN_APP_SECRET || ""
                      }
                      redirect_uri={process.env.REACT_APP_REDIRECT_URI || ""}
                      onLoginStart={onLoginStart}
                      onResolve={({ provider, data }: IResolveParams) => {
                        dispatch(
                          linkSocialAccount({
                            provider: provider,
                            accessToken: data?.access_token ?? "",
                            password: "",
                          })
                        ).then(({ type, error, payload }: any) => {
                          if (type === "auth/link-social/rejected") {
                            ShowAlert(error.message, "error");
                            return;
                          }
                          getUser().then(() =>
                            ShowAlert("Account linked successfuly")
                          );
                        });
                      }}
                      onReject={(err: any) => {
                        console.log(err);
                      }}
                    >
                      <LinkedInLoginButton text="Sign in with LinkedIn" />
                    </LoginSocialLinkedin>

                    <LoginSocialFacebook
                      className={`mb-[16px] social-login social-facebook${
                        isNullOrEmpty(user.facebookId) ? "" : " disable"
                      }`}
                      appId={process.env.REACT_APP_FB_APP_ID || ""}
                      fieldsProfile={
                        "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
                      }
                      scope="public_profile email"
                      onLoginStart={onLoginStart}
                      onLogoutSuccess={onLogoutSuccess}
                      redirect_uri={process.env.REACT_APP_REDIRECT_URI || ""}
                      onResolve={({ provider, data }: IResolveParams) => {
                        dispatch(
                          linkSocialAccount({
                            provider: provider,
                            accessToken: data?.accessToken ?? "",
                            password: "",
                          })
                        ).then(({ type, error, payload }: any) => {
                          if (type === "auth/link-social/rejected") {
                            ShowAlert(error.message, "error");
                            return;
                          }
                          getUser().then(() =>
                            ShowAlert("Account linked successfuly")
                          );
                        });
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <FacebookLoginButton text="Sign in with Facebook" />
                    </LoginSocialFacebook>
                  </div>
                  <div className="w-[33.33%] px-[16px] col" />
                </div>
              </section>

              <hr />
              <section>
                <div className="flex gap-4 w-full place-content-end p-6">
                  <button
                    type="button"
                    className="h-[35px] px-[42px] rounded-[6px] flex items-center place-content-center cancel_btn"
                    onClick={handleCancelEvent}
                  >
                    Cancel
                  </button>
                    <button
                      type="submit"
                      onClick={() => {
                        setIsTrySubmit(true);
                        goToTop();
                      }}
                      className="h-[31px] px-[42px] rounded-[6px] flex items-center place-content-center save_btn"
                      // disabled={isDetail}
                    >
                      Save
                    </button>
                </div>
              </section>
            </div>
          </Form>
        ) : (
          <LoaderComponent />
        );
      }}
    </Formik>
  );
};

export default UserProfile;
