import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { faArrowRight, faClose } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useNavigate } from "react-router-dom";

export const ErrorSuccessBulk = (props: any) => {
  const navigate = useNavigate();
  const modalPatientTitle = `modal-patient-title modal-patient-title-${props.type}`;
  const modalPatientBodyTitle = `m-3 modal-patient-body-title modal-patient-body-title-${props.type}`;
  const modalPatientBodyTitleDetail = `m-3 modal-patient-bodyDetail-title modal-patient-bodyDetail-title-${props.type}`;
  const modalPatientFooterTitle = `modal-patient-footer-title modal-patient-footer-title-${props.type}`;
  const modalPatientArror = `modal-patient-arrow modal-patient-arrow-${props.type}`;
  const mainClass = `${
    props.type === "error"
      ? "modal-patient-success-error error"
      : "modal-patient-success-error success"
  }`;
  const footerClass = `${
    props.type === "error"
      ? "mx-3 mt-[40px] d-flex justify-content-end"
      : "mx-3 mt-[40px] d-flex justify-content-end"
  }  `;
  const handleFooterButton = () => {
    if (props.type === "success") {
      props.onClose();
      navigate(0);
    } else {
      props.onClose();
    }
  };
  return (
    <section className={mainClass}>
      {props.isLoading ? (
        <>
          <div className="pt-[20%] align-middle h-40 w-full flex spinner-container">
            <div className="grid justify-items-center">
              <FontAwesomeIcon
                className="w-20 h-20"
                icon={regular("spinner")}
                height={30}
                width={30}
                strokeWidth={15}
                style={{ animation: "spin 2s linear infinite" }}
                color="#027a48"
              />
            </div>
          </div>
          <div className="loading-title ">
            <span>Importing caseload...</span>
          </div>
        </>
      ) : (
        <div className="mx-3 my-4">
          <div className="d-flex justify-content-between mx-3 mb-5 mt-4">
            <div className={modalPatientTitle}>{props.title}</div>
            <button
              title="close-patient-button"
              type="button"
              onClick={props.onClose}
            >
              <FontAwesomeIcon
                icon={faClose}
                className="modal-patient-button-close-success-error"
                aria-hidden="true"
              />
            </button>
          </div>
          <div className={modalPatientBodyTitle}>{props.bodyTitle}</div>
          <div className={modalPatientBodyTitleDetail}>{props.bodyDetail}</div>
          <div className={footerClass}>
            <button
              type="button"
              onClick={handleFooterButton}
              className={modalPatientFooterTitle}
            >
              {props.footerTitle}&nbsp;&nbsp;
            </button>
            <FontAwesomeIcon
              icon={faArrowRight}
              className={modalPatientArror}
              aria-hidden="true"
            />
          </div>
        </div>
      )}
    </section>
  );
};
