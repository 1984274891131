import React, { useEffect, useState, useRef, useCallback } from "react";
import "datatables.net";
import "reactjs-popup/dist/index.css";
import "tippy.js/dist/tippy.css";
import { extend, Internationalization } from "@syncfusion/ej2-base";
import { useClickOutSide } from "../../../hooks/useClickOutSide";

import {
  ScheduleComponent,
  Week,
  Month,
  Inject,
  FieldModel,
  PopupOpenEventArgs,
} from "@syncfusion/ej2-react-schedule";
import { EventSettingsModel } from "@syncfusion/ej2-react-schedule";
import { ViewsDirective, ViewDirective } from "@syncfusion/ej2-react-schedule";
import { DragAndDrop, Resize } from "@syncfusion/ej2-react-schedule";
import {
  ActionEventArgs,
  CellClickEventArgs,
  EventClickArgs,
} from "@syncfusion/ej2-react-schedule";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { DragAndDropEventArgs } from "@syncfusion/ej2-react-navigations";

import {
  visitsToSchedule,
  visitsScheduled,
  visitsAdd,
  visitsUpdate,
  defaultVisitData,
  getDischargeRecertConflic,
  checkMissed30DRE,
  defaultVisitPatientData,
} from "../../../api/schedulingApi";
import { ShowAlert } from "../../../Services/utility";

import {
  eventTemplateHandlerWeek,
  eventTemplateHandlerMonth,
  treeTemplateHandler,
  visitsSearchBoxTemplate,
  VisitsSearchWaiting,
} from "./ScheduleTemplates";
import {
  dateRange,
  DayTemplateHandleMonth,
  DayTemplateHandleWeek,
} from "./ScheduleTemplates";

import moment from "moment";
import {
  InitialVisitstoCreate,
  IVisitsToCreate,
  IVisitsToUpdate,
} from "../../../interfaces/IScheduling";
import _ from "lodash";
import { useGlobalState } from "use-global-state-react";
import Popup from "reactjs-popup";
import VisitDetailPopup, {
  visitInfoProps,
} from "../../../components/visits/VisitDetail";
import AutoSchedule from "../../../components/visits/AutoSchedule";
import { endOfDay } from "date-fns";
import { DefineVisitModal } from "../../../components/visits/DefineVisitModal";
import { faLoveseat } from "@fortawesome/pro-regular-svg-icons";

type DateErrorMessage = {
  index: number;
  date: string;
};

const CaseloadView = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  // // The scroll listener
  // const handleScroll = useCallback(() => {
  //   console.log("scrolling");
  // }, []);

  // // Attach the scroll listener to the div
  // useEffect(() => {
  //   const div: any = ref.current;
  //   console.log("RUN");
  //   if (div) {
  //     div.addEventListener("scroll", handleScroll);
  //   }
  // }, [handleScroll]);

  const [visitDataConflic, setVisitDataConflic] = useState([]);
  const [openDefineVisit, setOpenDefineVisit] = useState<boolean>(false);

  const [inprogress, setInprogress] = useState<boolean>(true);
  const [scheduleMode, setScheduleMode] = useState<string>("week");
  const [openDetails, setOpenDetails] = useState<visitInfoProps>({
    isOpen: false,
    vistData: {},
    vistDataId: 0,
  });
  const [open30DREConfirmation, setOpen30DREConfirmation] =
    useState<boolean>(false);
  const [dataToRemoveFromLeft, setDataToRemoveFromLeft] = useState<any>(null);
  const [confirm30DRE, setConfirm30DRE] = useState<boolean>(false);
  const [data30DRE, setData30DRE] = useState<IVisitsToCreate>(
    InitialVisitstoCreate
  );
  const modalAlert = useClickOutSide(() => {
    setOpen30DREConfirmation(false);
  });
  const handleAdd30DRE = async () => {
    // callAPI
    const { data: result } = await visitsAdd({
      patientId: data30DRE.patientId.toString(),
      recertId: data30DRE.recertId.toString(),
      clinitianId: data30DRE.clinitianId.toString(),
      type: data30DRE.type.toString(),
      start: data30DRE.start,
      end: data30DRE.end,
    });
    if (result.status !== 200) {
      ShowAlert(result.message, "error");
      return;
    } else {
      // add to calendar
      scheduleObj?.addEvent(result.data);
      console.log(result.data);

      // remove from left list
      listObj?.removeNodes(dataToRemoveFromLeft);
    }

    setOpen30DREConfirmation(false);
  };
  const handleOpenCloseVisitDetail = (value: boolean) => {
    setOpenDetails({
      ...openDetails,
      isOpen: value,
    });
  };
  // month is the initial option, so, ini month, end month
  const [dayIni, setDayIni] = useState<Date>(
    moment(new Date()).startOf("week").toDate()
  );
  const [dayEnd, setDayEnd] = useState<Date>(
    moment(new Date()).endOf("week").toDate()
  );

  const userdata: string = sessionStorage.getItem("userData_Apex") || "";
  const [startTimeWork, setStartTimeWork] = useState<string>("00:00");
  const [workingDays, setWorkingDays] = useState<string[]>([]);
  const [endTimeWork, setEndTimeWork] = useState<string>("24:00");
  const [Original30DRE, setOriginal30DRE] = useState<string>();

  useEffect(() => {
    getCalendarData();
    getListData();

    if (!openDetails) {
      let temp: any = document.getElementById("root");
      temp.style.filter = "none";
    }

    return () => {
      let temp: any = document.getElementById("root");
      temp.style.filter = "none";
    };
  }, [openDefineVisit]);

  const [clinitianId] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [groupText, setGroupText] = useState<string>("");
  const [refreshData, setRefreshData] = useState(false);

  const [treeViewData, setTreeViewData] = useState<{ [key: string]: Object }[]>(
    []
  );
  const [calendarEventData, setCalendarEventData] = useState<
    { [key: string]: Object }[]
  >([]);

  // const [calendarItems, setCalendarItems] = useState<any>([]);

  const [dateRange, setDateRange] = useGlobalState<dateRange>(
    "DATE_RANGE_VISIT_ADD",
    { startDate: new Date(), endDate: new Date(), inUse: false }
  );
  const callendarRef = useRef<ScheduleComponent | null>(null);

  useEffect(() => {
    const formateddata: any = JSON.parse(userdata);
    setStartTimeWork(
      formateddata.userStartTime.toString().substring(0, 5) || "00:00"
    );

    setWorkingDays(formateddata.workingDays);
    // setEndTimeWork(formateddata.userEndTime.toString().substring(0, 5) || "24:00")
  }, []);

  useEffect(() => {
    getCalendarData();
    getListData();

    if (!openDetails) {
      let temp: any = document.getElementById("root");
      temp.style.filter = "none";
    }

    return () => {
      let temp: any = document.getElementById("root");
      temp.style.filter = "none";
    };
  }, [dayEnd, clinitianId, refreshData]);

  let scheduleObj: ScheduleComponent | null;
  let listObj: TreeViewComponent;
  const getCalendarData = async () => {
    try {
      // setInprogress(true);
      setCalendarEventData([]);
      const { data: result } = await visitsScheduled(
        dayIni.toISOString(),
        dayEnd.toISOString(),
        clinitianId,
        0
      );
      getDischargeRecertConflic(
        dayIni.toISOString(),
        dayEnd.toISOString()
      ).then(({ data }) => {
        if (data?.data?.length > 0) {
          setVisitDataConflic(data.data);
          setOpenDefineVisit(true);
        }
      });
      checkMissed30DRE(dayIni.toISOString(), dayEnd.toISOString());

      if (result.status !== 200) {
        ShowAlert(result.message, "error");
        return;
      } else {
        setCalendarEventData(result.data.concat([defaultVisitData]));
        // setCalendarItems(result.data.concat([defaultVisitPatientData]));
      }
      setRefreshData(false);
    } catch (error: any) {
      ShowAlert(error.message, "error");
    } //finally {
    //   setInprogress(false);
    // }
  };

  const getListData = async () => {
    try {
      setInprogress(true);
      setTreeViewData([]);
      const { data: result } = await visitsToSchedule(
        dayIni.toISOString(),
        dayEnd.toISOString(),
        clinitianId
      );

      if (result.status !== 200) {
        ShowAlert(result.message, "error");
      } else {
        setTreeViewData(result.data);
      }
    } catch (error: any) {
      ShowAlert(error.message, "error");
    } finally {
      setInprogress(false);
    }
  };

  const filterTreeData = (treeD: { [key: string]: Object }[]) => {
    if (searchText === "") return treeD;
    else {
      const filtered = treeD.filter(
        (item) =>
          item?.patientName?.toString().toLowerCase().indexOf(searchText) >
            -1 ||
          item?.city?.toString().toLowerCase().indexOf(searchText) > -1 ||
          item?.type?.toString().toLowerCase().indexOf(searchText) > -1 ||
          item?.distance?.toString().toLowerCase().indexOf(searchText) > -1
      );
      return filtered;
    }
  };

  const groupedTreeData = (treeD: { [key: string]: Object }[]) => {
    if (groupText === "") return treeD;
    else {
      const grouped = _.groupBy(treeD, groupText);
      let sol: { [key: string]: Object }[] = [];

      for (let key in grouped) {
        sol.push({
          id: "gl_" + key,
          groupT: key,
          className: "gl_header",
        });
        sol = sol.concat(grouped[key]);
      }

      return sol;
    }
  };

  const listFields: Object = {
    dataSource: groupedTreeData(filterTreeData(treeViewData)),
    id: "id",
    text: "type",
  };
  const callendarFields: FieldModel = {
    id: "id",
    startTime: { name: "start" },
    endTime: { name: "end" },
  };
  const localData: EventSettingsModel = {
    dataSource: calendarEventData,
    fields: callendarFields,
  };

  const onCompleteHandler = (args: ActionEventArgs) => {
    if (
      args.requestType === "dateNavigate" ||
      args.requestType === "viewNavigate"
    ) {
      if (scheduleObj) {
        const dates = scheduleObj.selectedDate;
        if (scheduleObj.viewIndex === 0) {
          // week
          setDayIni(moment(dates.toISOString()).startOf("week").toDate());
          setScheduleMode("week");
          setDayEnd(moment(dates.toISOString()).endOf("week").toDate());
        } else if (scheduleObj.viewIndex === 1) {
          // month
          setDayIni(
            moment(dates.toISOString())
              .startOf("month")
              .startOf("week")
              .toDate()
          );
          setScheduleMode("month");
          setDayEnd(
            moment(dates.toISOString()).endOf("month").endOf("week").toDate()
          );
        }
      }
    }
  };

  let changeCollition: boolean = false;
  const onActionBegin = (args: ActionEventArgs) => {

    if (scheduleMode !== "month") {
      if (
        args.requestType !== "eventRemove" &&
        args.requestType !== "dateNavigate" &&
        args.requestType !== "viewNavigate" &&
        args.requestType !== "eventChange" &&
        args.requestType !== "eventCreate"
      )
        args.cancel = true;

      // update collition status
      if (args.requestType === "eventCreate") {
        const eventData = args.data as { [key: string]: Object }[];
        const start: Date = eventData[0].start as Date;
        const end: Date = eventData[0].end as Date;

        const collitionEvents = scheduleObj
          ?.getEvents(start, end)
          .map((element) => {
            element.collitionCount += 1;
            return element;
          }) as Record<string, any>[];

        if (collitionEvents.length > 0) {
          changeCollition = true;
          scheduleObj?.saveEvent(collitionEvents);
          changeCollition = false;
        }
      }

      // update collition status
      if (args.requestType === "eventRemove") {
        const eventData = args.deletedRecords as { [key: string]: Object }[];
        const start: Date = eventData[0].start as Date;
        const end: Date = eventData[0].end as Date;

        const collitionEvents = scheduleObj
          ?.getEvents(start, end)
          .map((element) => {
            element.collitionCount -= 1;
            return element;
          }) as Record<string, any>[];

        if (collitionEvents.length > 0) {
          changeCollition = true;
          scheduleObj?.saveEvent(collitionEvents);
          changeCollition = false;
        }
      }

      // rezise or drag
      if (args.requestType === "eventChange" && !changeCollition) {
        const eventData = args.data as { [key: string]: Object };
        const start: Date = eventData.start as Date;
        const end: Date = eventData.end as Date;

        const selectedDay = new Date(start).getDay();

        const now: any = new Date();
        const endDay: any = end;

        // LEGEND diffInDays=0 TODAY, -1 is Afer Today

        const diffInDays = Math.floor((now - endDay) / (1000 * 60 * 60 * 24));
        if (diffInDays > 0) {
          ShowAlert(
            "It is not possible to move or change a visit when the day is over."
          );
          args.cancel = true;
          return;
        }

        const isSelectedDayNotWorking =
          parseInt(workingDays[selectedDay]) === 0;

        if (isSelectedDayNotWorking) {
          args.cancel = true;
          ShowAlert("It is not possible to move a visit on non-working days");
          return;
        }

        if (eventData.isCompleted.toLocaleString() === "true") {
          ShowAlert("Can not move a completed visit", "error");
          args.cancel = true;
          return;
        }

        const dayEvents = scheduleObj?.getEvents(
          moment(start).startOf("day").toDate(),
          moment(start).endOf("day").toDate()
        );
        const finded: any = dayEvents?.find(
          (val) =>
            val.patientId.toString() === eventData.patientId.toString() &&
            val.id !== eventData.id
        );

        if (finded) {
          ShowAlert(
            "Can not be more than one visit for a patient in the same day",
            "error"
          );
          args.cancel = true;
          return;
        }

        const toUpdt: IVisitsToUpdate = {
          patientId: eventData.patientId.toString(),
          recertId: eventData.recertId.toString(),
          clinitianId: eventData.clinitianId.toString(),
          start: start.toISOString(),
          end: end.toISOString(),
          isLocked: eventData.isLocked.toString(),
          isCompleted: eventData.isCompleted.toString(),
          id: eventData.id.toString(),
        };

        visitsUpdate(toUpdt)
          .catch((err: any) => {
            args.cancel = true;
            ShowAlert(err, "error");
            return;
          })
          .then((data: any) => {
            if (data?.data?.status === 200) {
              // check old collitions
              scheduleObj?.deleteEvent(eventData.id);
              // check new collitions
              const updD = data?.data?.data;
              scheduleObj?.addEvent(updD);
            } else {
              getCalendarData();

              const message = data?.data?.message;

              //Find the Date and Change
              const regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})Z$/g;

              const splitMessage: string[] = message.split(" ");
              const finalMessageError = splitMessage
                .map((element: string) =>
                  element.match(regex)
                    ? moment(element).utc().format("YYYY-MM-DD")
                    : element
                )
                .join(" ");

              ShowAlert(finalMessageError, "error");
              args.cancel = true;
              return;
            }
            args.cancel = true;
            return;
          });
      }
    }
  };

  const onCellClickHandler = (args: CellClickEventArgs): void => {
    args.cancel = true;
  };

  const onEventClickHandler = (args: EventClickArgs): void => {
    if (args.name === "eventClick") {
      const visit = args.event as { [key: string]: Object };

      let temp: any = document.getElementById("root");
      temp.style.filter = "blur(2.5px)";
      // temp.style.heigth = '100vm';

      setOpenDetails({
        isOpen: true,
        vistData: visit,
        vistDataId: visit.patientId as number,
      });
    }
    args.cancel = true;
  };

  const nodeDragStartHandle = (args: DragAndDropEventArgs) => {
    if (scheduleMode !== "month") {
      //console.log("start drag")
      const nodeId = args.draggedNodeData.id;
      if (nodeId.toString().slice(0, 3) === "gl_" || dateRange.inUse) {
        args.cancel = true;
        return;
      }

      const nodeData = listObj.getTreeData(
        args.draggedNodeData.id.toString()
      )[0];
      //console.log(nodeData)
      let start: Date, end: Date;
      if (nodeData.dateType.toString() === "day") {
        start = moment(nodeData.dateValue.toString()).toDate();
        end = moment(nodeData.dateValue.toString()).endOf("day").toDate();
        setOriginal30DRE(
          start.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
          })
        );
        if (nodeData.type.toString() === "30DRE") {
          let originalDate = start;
          start = moment(originalDate).subtract(4, "days").toDate();
          end = moment(originalDate).add(3, "days").toDate();
        }
      } else {
        const values = nodeData.dateValue.toString().split(" - ");

        start = moment(values[0]).toDate();

        end = moment(values[1]).endOf("day").toDate();
      }
      //console.log(start) to see what date is passing
      //console.log(end)
      setDateRange({ startDate: start, endDate: end, inUse: true });
    }
  };

  const nodeDraggingHandler = (args: DragAndDropEventArgs) => {
    if (scheduleMode !== "month") {
      args.target.onmouseenter = (e: Event) => {
        // e.preventDefault()
        args.target.classList.add("cell-border-dashed");
      };

      args.target.onmouseleave = (e: Event) => {
        // e.preventDefault()
        args.target.classList.remove("cell-border-dashed");
      };
    }
  };

  const getMouseEnterAndPaintBorder = () => {
    const elements: Element[] = Array.from(
      document.getElementsByClassName("e-work-cells")
    );
    for (let element of elements) {
      element.addEventListener("mouseenter", (e: Event) => {
        element.classList.remove("cell-border-dashed");
      });
    }
  };
  const cancelDrag = (args: DragAndDropEventArgs) => {
    args.cancel = true;
    setDateRange({
      startDate: new Date(),
      endDate: new Date(),
      inUse: false,
    });
     getListData();
    return;
  };

  // drag from list and drop in callendar
  const nodeDragStopHandler = async (args: DragAndDropEventArgs) => {
    getMouseEnterAndPaintBorder();
    let cellData = scheduleObj?.getCellDetails(args.target);
    // block list grouping
    if (args.position !== null) {
      cancelDrag(args);
    } else {
      args.cancel = true;
    }
    if (cellData !== undefined) {
      /*Calculating start and end time of new visit based on user settings*/
      //TO IMPROVE
      let start: string = cellData.startTime.toISOString();

      const formateddata: any = JSON.parse(userdata);

      const nodeType = listObj
        .getTreeData(args.draggedNodeData.id.toString())[0]
        .type.toString();
      let visitLength: number = 30;

      Object.keys(formateddata.visitType).map((visit: any) => {
        const compareVisitName =
          nodeType == "RoutineVisit" ? "treatment" : nodeType.toLowerCase();

        if (
          formateddata.visitType[visit].name.toString().toLowerCase() ==
          compareVisitName
        ) {
          visitLength = formateddata.visitType[visit].length;
          return;
        }
      });
      let end: string = new Date(
        cellData.startTime.getTime() + visitLength * 60000
      ).toISOString();
      //let end: string = cellData.endTime.toISOString(); //default

      /*End calculating start and end time*/

      if (cellData.isAllDay) {
        // monht view
        const visisInTheDay = scheduleObj?.getEvents(
          cellData.startTime,
          cellData.endTime
        );
        if (visisInTheDay == undefined || visisInTheDay.length < 1) {
          end = moment(start).add(15, "minutes").toDate().toISOString();
        } else {
          start = new Date(
            Math.max(...visisInTheDay.map((v) => v.end))
          ).toISOString();
          const optionsD = [
            moment(start).add(15, "minutes").toDate(),
            moment(start).endOf("day").toDate(),
          ];
          const optionsN = optionsD.map((v) => Date.parse(v.toISOString()));

          let posibibleEnd = new Date(Math.min(...optionsN));
          end = posibibleEnd.toISOString();
        }
      }
      const selectedDay = new Date(start).getDay();

      const isSelectedDayNotWorking = parseInt(workingDays[selectedDay]) === 0;

      // const isDifferentDate = cellData.startTime.getDay() !== dateRange.startDate.getDay()

      if (isSelectedDayNotWorking) {
        ShowAlert("It is not possible to plan a visit on non-working days");
        cancelDrag(args);
      }

      if (
        dateRange.startDate > cellData.startTime ||
        dateRange.endDate < cellData.startTime
      ) {
        ShowAlert(
          "The visit cannot be scheduled outside its permitted range.",
          "error"
        );
        args.cancel = true;
        setDateRange({
          startDate: new Date(),
          endDate: new Date(),
          inUse: false,
        });
        return;
      }

      try {
        const nodeData = listObj.getTreeData(
          args.draggedNodeData.id.toString()
        )[0];
        //console.log("call api")
        const valueStartCompare: any = new Date(
          nodeData.dateValue.toString()
        ).getDay();
        const valueNowCompare: any = new Date(start).getDay();
        if (
          nodeData.type.toString() === "30DRE" &&
          valueStartCompare !== valueNowCompare
        ) {
          setOpen30DREConfirmation(true);
          setDataToRemoveFromLeft(args.draggedNodeData.id.toString());
          setData30DRE({
            patientId: nodeData.patientId.toString(),
            recertId: nodeData.recertId.toString(),
            clinitianId: nodeData.clinitianId.toString(),
            type: nodeData.type.toString(),
            start: start,
            end: end,
          });
        } else {
          // callAPI
          const { data: result } = await visitsAdd({
            patientId: nodeData.patientId.toString(),
            recertId: nodeData.recertId.toString(),
            clinitianId: nodeData.clinitianId.toString(),
            type: nodeData.type.toString(),
            start: start,
            end: end,
          });
          setRefreshData(false);
          if (result.status !== 200) {
            ShowAlert(result.message, "error");
            if(result.message !== "Arrays most match in size"){
            cancelDrag(args)
            }
          } else {
            // add to calendar
            scheduleObj?.addEvent(result.data);
            // remove from left list
            listObj?.removeNodes([args.draggedNodeData.id.toString()]);
            checkMissed30DRE(dayIni.toISOString(), dayEnd.toISOString()).then(
              () => {
                setRefreshData(true);
              }
            );
          }


        }
      } catch (error: any) {
        ShowAlert(error.message, "error");
        //  getListData();
        cancelDrag(args)
      }
    }

    setDateRange({
      startDate: new Date(),
      endDate: new Date(),
      inUse: false,
    });
    getListData();
  };

  const handleSearch = (text: string) => {
    setSearchText(text);
  };

  const handleSelect = (text: string) => {
    setGroupText(text);
  };

  const handleDetailClose = () => {
    let temp: any = document.getElementById("root");
    temp.style.filter = "none";
    setOpenDetails({ isOpen: false, vistData: {}, vistDataId: 0 });
  };
  const onChangeVisit = () => {
    getCalendarData();
    getListData();
    handleDetailClose();
  };
  const handleDeleteVisit = (id: number) => {
    scheduleObj?.deleteEvent(id);
    handleDetailClose();
    getListData();
  };

  const handleAutoSchedule = () => {
    getListData();
    getCalendarData();
  };

  const hadleDateForAutoSchedule = (): Date => {
    if (scheduleMode === "month") {
      const val = (Number(dayIni) + Number(dayEnd)) / 2;
      const sol = moment(new Date(val)).startOf("month").toDate();
      return sol;
    }
    return dayIni;
  };

  return (
    <>
      <div className="flex h-100">
        <div className="col-md-3 visits-adjust">
          {visitsSearchBoxTemplate({
            NameList: "hola",
            onSearch: handleSearch,
            onSelect: handleSelect,
          })}
          <div className="col w-100 mr-10 mt-14 visits-adjust-list">
            {inprogress ? (
              <VisitsSearchWaiting />
            ) : (
              <TreeViewComponent
                fields={listFields}
                allowDragAndDrop={scheduleMode === "week"}
                ref={(lists) => (listObj = lists as TreeViewComponent)}
                nodeDragStart={nodeDragStartHandle}
                nodeDragging={nodeDraggingHandler}
                nodeDragStop={nodeDragStopHandler}
                nodeTemplate={treeTemplateHandler}
              />
            )}
          </div>
          {/* <AutoSchedule
            mode={scheduleMode}
            dayIni={hadleDateForAutoSchedule()}
            onAutoSchedule={handleAutoSchedule}
            ceroPatients={
              treeViewData.length <= 0 && calendarEventData.length <= 1
            }
          /> */}
        </div>
        <div className="col-md-9" ref={ref}>
          <div
            className="rounded-[20px] py-10 px-8 flex flex-col visits-adjust-calendar place-content-between calendar-container caseload-view"
            style={{
              backgroundColor: "white",
              margin: "40px 32px 40px 0",
            }}
          >
            <ScheduleComponent
              currentView="Week"
              width="100%"
              height="100%"
              actionBegin={onActionBegin}
              cellClick={onCellClickHandler}
              eventClick={onEventClickHandler}
              actionComplete={onCompleteHandler}
              allowDragAndDrop={scheduleMode === "week"}
              allowMultiCellSelection={false}
              allowMultiDrag={false}
              allowMultiRowSelection={false}
              startHour={startTimeWork}
              endHour={endTimeWork}
              cellDoubleClick={(args: CellClickEventArgs) => {
                args.cancel = true;
              }}
              popupOpen={(args: PopupOpenEventArgs) => {
                args.cancel = true;
              }}
              ref={(schedule) => {
                scheduleObj = schedule;
                callendarRef.current = schedule;
              }}
              eventSettings={localData}
            >
              <ViewsDirective>
                <ViewDirective
                  option="Week"
                  timeFormat="h:mm a"
                  dateFormat="MMM dd"
                  eventTemplate={eventTemplateHandlerWeek}
                  timeScale={{ enable: true, slotCount: 4 }}
                  dateHeaderTemplate={DayTemplateHandleWeek}
                />
                <ViewDirective
                  option="Month"
                  showWeekNumber={false}
                  eventTemplate={eventTemplateHandlerMonth}
                  cellHeaderTemplate={DayTemplateHandleMonth}
                />
              </ViewsDirective>
              <Inject services={[Week, Month, DragAndDrop, Resize]} />:
            </ScheduleComponent>
          </div>
        </div>
      </div>

      {/* Visit details popup */}
      <Popup
        open={openDetails.isOpen}
        closeOnEscape={false}
        // closeOnDocumentClick={true}
        closeOnDocumentClick
        lockScroll
        contentStyle={{
          width: "100%",
          height: "100%",
        }}
        onClose={handleDetailClose}
      >
        <VisitDetailPopup
          onClose={handleDetailClose}
          onChangeVisit={onChangeVisit}
          vistData={openDetails.vistData}
          visitId={openDetails.vistDataId}
          onDelete={handleDeleteVisit}
          setRefreshData={setRefreshData}
          handleOpenCloseVisitDetail={handleOpenCloseVisitDetail}
          start={dayIni}
          end={dayEnd}
        />
      </Popup>

      <Popup
        open={openDefineVisit}
        closeOnEscape={false}
        // closeOnDocumentClick={true}
        lockScroll
        contentStyle={{
          width: "100%",
          height: "100%",
        }}
        onClose={handleDetailClose}
      >
        <DefineVisitModal
          setOpen={setOpenDefineVisit}
          visitDataConflic={visitDataConflic[0]}
        />
      </Popup>
      <Popup
        open={open30DREConfirmation}
        closeOnDocumentClick
        lockScroll
        contentStyle={{
          width: "100%",
          height: "100%",
        }}
        onClose={() => {
          setOpen30DREConfirmation(false);
        }}
      >
        <div
          className="flex w-[365px] h-[309px] delete_patient_popup bg-white"
          ref={modalAlert}
        >
          <div className="py-[32px] px-[24px] flex flex-col w-[100%] place-content-between">
            <div>
              <hr />
              <h3 className="text-center mt-[24px]">
                30DRE is due on {Original30DRE}, are you sure you want to put it
                here?
              </h3>
            </div>
            <div className="flex place-content-between">
              <button
                className="pop_btn h-[35px] flex items-center place-content-center px-[50px] rounded-[6px]"
                onClick={() => {
                  setOpen30DREConfirmation(false);
                }}
              >
                Cancel
              </button>
              <button
                className="pop_btn pop_save_btn h-[35px] flex items-center place-content-center px-[50px] rounded-[6px]"
                onClick={handleAdd30DRE}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};
export default CaseloadView;
