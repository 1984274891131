import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICareTeam } from "../../../interfaces/IPatient";
import { savePatient, getCareTeam } from "./thunks";

export interface careTState {
  ctLoading: "idle" | "pending" | "succeeded" | "failed";
  careTeam: ICareTeam;
  ctError: {
    errorType?: string;
    errorMessage?: string;
  };
}

const initialState: careTState = {
  ctLoading: "idle",
  careTeam: {} as ICareTeam,
  ctError: { errorType: "", errorMessage: "" },
};

export const careTeamSlice = createSlice({
  name: "careTeam",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCareTeam.pending, (state) => {
      state.ctLoading = "pending";
      state.ctError = initialState.ctError;
    });
    builder.addCase(getCareTeam.fulfilled, (state, action) => {
      state.careTeam = action.payload;
      state.ctLoading = "succeeded";
    });
    builder.addCase(getCareTeam.rejected, (state, action) => {
      state.ctLoading = "failed";
      state.ctError = {
        errorType: action.error.message,
        errorMessage: action.payload as string,
      };
    });

    // builder.addCase(savePatient.pending, (state) => {
    //   state.loading = "pending";
    //   state.error = initialState.error;
    // });
    // builder.addCase(savePatient.fulfilled, (state, action) => {
    //   state.careTeam = action.payload;
    //   state.loading = "succeeded";
    // });
    // builder.addCase(savePatient.rejected, (state, action) => {
    //   state.loading = "failed";
    //   state.error = {
    //     errorType: action.error.message,
    //     errorMessage: action.payload as string,
    //   };
    // });
  },
});

export const {} = careTeamSlice.actions;
