import moment from "moment";
import React from "react";
import { changeFrequencyByUserProfile } from "../../helpers/globals";
import { IDetails } from "../../interfaces/IPatient";
import * as utility from "../../Services/utility";
import { ValueFromUserData } from "../../Services/utility";

type Props = {
  title: string;
  info: { data: IDetails[] };
  truncate?: boolean;
};

const roleAcotation = (role: string): string => {
  if (role === "TeamLeader") return "TL";
  return role;
};

export const dateTransformerDetails = (
  data: string,
  data_type: string,
  title?: string
): string => {
  if (data === null || data === undefined) return "";

  const pos: number = data?.indexOf(" - ");
  switch (data_type) {
    case "Range":
    case "Week":
      return (
        utility.getDate(data.slice(0, pos), "L") +
        " - " +
        utility.getDate(data.slice(pos + 3), "L")
      );
    case "RangeSpetial":
      return utility.getDate(data.slice(pos + 3), "L");
    case "Date":
      return utility.getDate(data, "L");
    case "List":
      if (title === "Frequencies") {
        const arrayFrequency = data.split(",");
        const userAPEX_Frequency: string =
          ValueFromUserData("weekVisitFormat") ?? "w";

        return changeFrequencyByUserProfile(
          arrayFrequency,
          userAPEX_Frequency,5
        ).toString();
      }
      return data;
    default:
      return data;
  }
};

export const certificationPeriod = (currCert: string): string => {
  return (
    utility.getDate(currCert, "L") +
    " - " +
    utility.getDate(moment(currCert).add(59, "days").toISOString(), "L")
  );
};

export const DetailsCard = ({ title, info, truncate }: Props) => {
  if (info.data && info.data.find((x) => x.data && x.data !== ""))
    return (
      <div className="min-h-60">
        <div className="card-detail-title">{title}</div>
        <div style={{ paddingTop: "16px" }}>
          {info.data.map(({ clinitian_type, data, data_type }, i) =>
            data ? (
              <div
                key={title + "_" + i}
                className="flex flex-col detail__card"
                style={{ paddingTop: "7px" }}
              >
                <div className="flex w-full">
                  <div
                    className="card-detail-title"
                    style={{ textTransform: "uppercase" }}
                  >
                    {roleAcotation(clinitian_type)}
                  </div>
                  <span
                    className={
                      "ml-[20px] grow justify-start" +
                      (truncate ? " truncate" : "")
                    }
                    style={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "12px",
                      lineHeight: "15px",
                      color: "#4D5761",
                    }}
                  >
                    {dateTransformerDetails(data, data_type, title)}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )
          )}
        </div>
      </div>
    );

  return <></>;
};
