import React from "react";
import { transitions, positions, Provider } from "react-alert";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: "5px",
  // you can also just use 'scale'
  transition: transitions.FADE,
  containerStyle: {
    // background: 'red'
  },
};

const AlertTemplate = ({ style, options, message, close }: any) => (
  <div className={`_alert ${options.type}`} style={style}>
    {message}
    <button onClick={close}>
      <i className="fas fa-times hand"></i>
    </button>
  </div>
);

export const AlertProvider = ({ children }: Props) => (
  <Provider template={AlertTemplate} {...options}>
    {children}
  </Provider>
);
