import Axios from "../interceptors/axios-config";
import { setIsLoading } from "../store/slices/notification/notificationSlice";
import { store } from "../store/store";
const base_url = process.env.REACT_APP_API_URL;

export const patientServices = () => ({
  getNearPatientLocations: async (
    centerLat = 0.0,
    centerLong = 0.0,
    distance = -1,
    maxPoints = 200
  ) => {
    try {
      const response = await Axios.get(
        base_url +
          `/PatientProfile/GetNearPatientLocations?centerLat=${centerLat}&centerLong=${centerLong}&distance=${distance}&maxPoints=${maxPoints}`
      );

      switch (response.data.status) {
        case 201:
        case 200:
          return { status: 200, value: response.data.data };
        case 401:
          return { status: 401, value: response.data.message };
        case 402:
          return { status: 402, value: response.data.message };
        default:
          return {
            status: response.data.status,
            value: "Resource not found",
          };
      }
    } catch (error) {
      return { status: error, value: error };
    } finally {
      store.dispatch(setIsLoading(false));
    }
  },
  getCertificationsAID: async (body:any)=> {

    try {
    const response = await Axios.get(base_url+`/PatientSchedule/GetFrequencies?recertId=${body.recertId}&patientId=${body.patientId}&clinitianId=${body.clinitianId}`)

      switch (response.data.status) {
        case 201:
        case 200:
          return { status: 200, value: response.data.data };
        case 401:
          return { status: 401, value: response.data.message };
        case 402:
          return { status: 402, value: response.data.message };
        default:
          return {
            status: response.data.status,
            value: "Resource not found",
          };
      }
    } catch (error) {
      return { status: error, value: error };
    }

    
  }
});
