import Axios from "axios";
import * as utility from "../Services/utility";
const fetchClient = () => {
  // TODO: Review

  let temp: any = undefined;

  // Request;
  Axios.interceptors.request.use(
    function (config: any) {
      if (config.url.toLowerCase().includes("login")) return config;

      // TODO: review rocket chat config
      if (!config.url.toLowerCase().includes("https://45.77.94.237:3000/")) {
        const token = utility.getUserToken();
        config.headers["X-Referer"] = "WEB";
        //
        if (token !== null && token !== "") {
          config.headers.Authorization = `Bearer ${token}`;
        }
        if (temp !== undefined) {
          config.data = temp;
          temp = undefined;
        }
      } else {
        const authToken = utility.readFromLocalStorage("authToken");
        const userId = utility.ValueFromUserData("rcUserId");
        if (authToken) {
          config.headers["X-Auth-Token"] = authToken;
          config.headers.common["X-User-Id"] = userId;
        }
      }
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );

  //Response
  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;

      if (error.response !== undefined) {
        if (error.response.status === 400) {
          return Promise.reject(error);
        }

        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          Axios.defaults.headers.common["Authorization"] =
            await `Bearer ${utility.getUserToken()}`;
          return Axios(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );
  return Axios;
};

export default fetchClient();
