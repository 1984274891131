import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "../../../api/userApi";

type Options = {
  pagenumber?: number;
  pagesize?: number;
  status?: string;
  query?: string;
  sortField?: string;
  sortDir?: string;
};

export const usersLoad = createAsyncThunk(
  "users/load",
  async (options: Options, thunkApi) => {
    const { data, headers } = await getAllUsers(options);
    const total = parseInt(headers["x-total-count"]);
    if (data.status !== 200) {
      return thunkApi.rejectWithValue(data.message);
    }
    return { users: data.data, total };
  }
);
