import React from "react";
import { useAppSelector } from "../../../store/hooks";



type FreqStep3Props = {
haveAID:boolean;
}
export const FreqStep3 = ({haveAID}:FreqStep3Props) => {
  const { certification } = useAppSelector((state) => state.certification);
  const { certificationAID } = useAppSelector((state) => state.certificationAID);



  return (
    <div className="flex h-full ml-[50px]">
      <div
        className="step__container flex flex-col gap-[24px]  py-9 place-content-evenly items-center cert-week-count"
        style={{
          width: "294px",
          maxHeight: "315px",
          minHeight: "315px",
          marginTop: "52px",
        }}
      >
        <div className="flex flex-col items-center gap-[10px]">
          <div>Total Number Of Visits</div>
          <div className="counter"> {haveAID ? certificationAID.vcount : certification.vcount} Visits </div>
        </div>
        <div className="flex flex-col items-center gap-[10px]">
          <div>Frequency Length</div>
          <div className="counter"> {haveAID ? certificationAID.wcount :certification.wcount} Weeks </div>
        </div>
      </div>
    </div>
  );
};
